import React, { useState } from 'react'
import { WebsiteForm } from '../../../../../mobx/components/website-form';
import { useStores } from '../../../../../mobx/useStores';
import { UI } from '../../../../../core/ui';
import { FormGroup, Input, RotateLoader, Switch } from '@lib/components';
import { FastField } from 'formik';
import { SettingsActionButtonContainer, SettingsActionButtonWrapper } from '../../../restaurant/views/settings-v2';
import { ImageUpload } from '../website/upload_image';
import { CookieColorsMobile, convertColortoHex } from '../website/color_picker_mobile';
type FormValues = T.Schema.Website.WebsiteSchema["sections"]["header"];

export const HeaderV2 =  () => {
    const store = useStores()
    const w = store.website!;
    let initialValues = w.sections.header;
    const [colors, setColors] = useState(
        {
			backgroundColor: '#f50000',
			backgroundImageColor: '#ffffff',
			textColor: '#ffffff',
		}
    )
    return (
        <WebsiteForm<FormValues>
        submit={async (w, values) => {
            w.sections.header = values;
            const update = { $set: { "sections.header": values } };
            return { w, update };
        }}
        validators={{}}
        initialValues={initialValues}
        onSuccess={() => { }}
        onError={() => UI.notification.error("An error occurred")}
        onSuccessMessage="Settings Updated"
        onErrorMessage="">
        {({ form, error }) => {
            const { isSubmitting, setFieldValue } = form;
            return (
                <div className="p-4"  style={{ height: '93vh', overflowY: 'scroll', overflowX: 'hidden', marginBottom: '20px'}}>

                    <FastField
                        name="hidden"
                        render={({ field }: any) => (
                            <FormGroup title="" optional={true}>
                                <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                                    <div style={{ fontSize: '18px', lineHeight: '24px', fontWeight: 600 }}>Hide Section</div>
                                    <div>
                                    <Switch
                                        id="section.hidden"
                                        checked={field.value}
                                        onChange={(e) => setFieldValue("hidden", e.target.checked)} />
                                    </div>
                                </div>
                            </FormGroup>
                        )}
                    />

                    <FastField
                        name="text"
                        render={({ field }: any) => (
                            <FormGroup title="Title" optional={true}>
                                <Input type="text" {...field} />
                            </FormGroup>
                        )}
                    />

                    <FastField
                        name="subtitle"
                        render={({ field }: any) => (
                            <FormGroup title="Subtitle" optional={true}>
                                <Input type="text" {...field} />
                            </FormGroup>
                        )}
                    />

                    <FastField
                        name="image_background"
                        render={({ field }: any) => (
                            <FormGroup
                                title="Header Background Image"
                                help="File name can't contain special charaters"
                            >
                                <ImageUpload 
                                    value={[field.value]} 
                                    onChange={(files: any[]) => setFieldValue('image_background', files[0])} onRemove={() => setFieldValue('image_background', null)}/>
                            </FormGroup>
                        )}
                    />

                    <FastField
                        name="image_background_tint"
                        render={({ field }: any) => (
                            <FormGroup title="">
                                <CookieColorsMobile 
                                    color={field.value && convertColortoHex(field.value) || colors.backgroundImageColor}
                                    onColorChange={(color: any) => {
                                       setColors({ ...colors, backgroundImageColor: color });
                                        field.onChange({ target: { name: 'image_background_tint', value: color } });
                                    }}
                                    colorField="Background Image Tint"
                                />
                            </FormGroup>
                        )}
                    />

                    <FastField
                        name="color_background"
                        style={{ marginBottom: '30px'}}
                        render={({ field }: any) => (
                            <FormGroup title="">
                                <CookieColorsMobile 
                                    color={field.value && convertColortoHex(field.value) || colors.backgroundColor}
                                    onColorChange={(color: any) => {
                                        setColors({ ...colors,  backgroundColor: color });
                                        field.onChange({ target: { name: 'color_background', value: color } });
                                    }}
                                    colorField="Background Color"
                                />
                            </FormGroup>
                        )}
                    />

                    <FastField
                        name="color_text"
                        render={({ field }: any) => (
                            <FormGroup title="">
                                <CookieColorsMobile
                                    color={field.value && convertColortoHex(field.value) || colors.textColor}
                                    onColorChange={(color: any) => {
                                        setColors({ ...colors, textColor: color });
                                        field.onChange({ target: { name: 'textColor', value: color } });
                                    }}
                                    colorField="Text Color"
                                />
                            </FormGroup>
                        )}
                    />

                    {error && <FormGroup error={error} />}
                    <SettingsActionButtonContainer className='no-sidebar'>
                        <SettingsActionButtonWrapper style={{left: 0}}>
                            <button 
                                type="button" 
                                className='btn-cancel'
                                onClick={ () => store.setActiveMobileMenu(
                                    store.activeMobileMenu.referrer.pop()!,
                                    Array.from(new Set(store.activeMobileMenu.referrer))
                                )}>
                                Cancel
                            </button>
                            <button  type="submit" disabled={isSubmitting}>
                            {isSubmitting && <RotateLoader size={2} color="white" />}
                            {!isSubmitting && "Save"}
                            </button>
                        </SettingsActionButtonWrapper>
                    </SettingsActionButtonContainer>
                </div>
            );
        }}
    </WebsiteForm>  
    )
}