import React, { useState } from "react";
import { SettingsActionButtonContainer, SettingsActionButtonWrapper, SettingsFormGroup, SettingsFormGroupInput, SettingsFormLabelContainer, SettingsFormLabelLeftContainer } from "..";
import { TabOptionsContainer } from "../tipping";
import styled from "styled-components";
import { RotateLoader } from "@lib/components";
import { useStores } from "../../../../../../mobx/useStores";
import { Untrusive } from "@lib/common";


const ThemesTabContainer = styled.div<{activeDesignMode?: boolean}>`
    display: flex;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    width: 100%;
    flex-direction: ${props => props.activeDesignMode ? 'column': 'row'}
`

const ThemesTabItem = styled.div<{dark?: boolean}>`
display: flex;
gap: 8px;
flex-direction: column;
align-items: flex-start;
flex: 1 0 0;
width: 100%;
cursor: pointer;

border-radius: 4px;

&:hover, &.selected {

    & div[class^="themes__ThemesTabItemContainer"] {
        border: 1px solid var(--Primary-Red-Orange-1, #FB2F2F);
        background: var(--Primary-Accent-Wisp-Pink-1, #FFF3F3);
    }
}

`

const ThemesTabItemHeader = styled.h2`
color: var(--Secondary-Dark-Grey-1, #4B4F5E);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 171.429% */
`

const ThemesTabItemContainer = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
align-self: stretch;
padding: 16px;
border-radius: 4px;
background: var(--Primary-Light-Alabaster-1, #F5F5F5);

`

const ThemesTabItemTitle = styled.div<{variant?: string}>`
display: flex;
padding: 12px;
align-items: center;
gap: 8px;
align-self: stretch;

border-bottom: 1px solid var(--Secondary-Light-Grey-1, #DFDFDF);
background: var(--Neutral-Gray, #F1F4F7);
color: var(--Secondary-Dark-1, #0C202A);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 142.857% */

${props => props.variant === 'dark' ? 
`background: var(--Secondary-Dark-Grey-1, #4B4F5E);color: var(--Neutral-White, #FEFEFE);` : null}
`

const ThemesTabItemWrapper = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
align-self: stretch;
border-radius: 4px;
border: 1px solid var(--Secondary-Light-Grey-1, #DFDFDF);
overflow: hidden;
`
const ThemesTabItemContent = styled.div<{variant?: string}>`
display: flex;
height: 80px;
padding: 12px;
align-items: flex-start;
gap: 8px;
align-self: stretch;

background: var(--Primary-Light-Alabaster-1, #F5F5F5);

${props => props.variant === 'dark' ? 
`background: var(--Secondary-Dark-1, #0C202A);
color: var(--Neutral-Gray, #F1F4F7);;` : null}
`

const ThemesTabItemButtonWrapper = styled.div<{variant?: string}>`
display: flex;
padding: 12px;
align-items: center;
gap: 8px;
align-self: stretch;

border-top: 1px solid var(--Secondary-Light-Grey-1, #DFDFDF);
background: var(--Neutral-Gray, #F1F4F7);


& button {
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;

    border-radius: 4px;
    background: var(--Primary-Red-Orange-1, #FB2F2F);
    border: 1px solid var(--Primary-Red-Orange-1, #FB2F2F);
    color: var(--Neutral-Gray, #F1F4F7);
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 114.286% */
}

${props => props.variant === 'dark' ? 
`background: var(--Secondary-Dark-Grey-1, #4B4F5E);` : null}
`

export const SettingsWebsiteWrapper = styled.div`
    padding-bottom: 83px;
    background-color: #fefefe;
    width: 100%;

    @media (min-width: 768px) {
        padding-bottom: 0px;
    }
`
interface Props {
    activeDesignMode?: boolean,
    saveRestaurant?: any,
    injected?: any
    restaurant: T.Schema.Restaurant.RestaurantSchema
}
export const SettingsWebsiteTheme = (props: Props) => {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const { activeDesignMode, saveRestaurant, injected, restaurant} = props
    const r = restaurant!;
    const [selectedTheme, setSelectedTheme] = useState< "light"| "dark">(r.website.colors.text == "rgba(50, 50, 50, 1)" ? 'light': 'dark' )
    
    const setTheme = async (theme: "light" | "dark") => {

		const proceed = confirm("Changing your theme will overwrite your existing colors across your entire store. Proceed?");

		if (!proceed) {
			return;
		}
        setIsSubmitting(true)
        await saveRestaurant({
			process: (r: any) => {

				if (theme === "light") {

					r.website.colors.primary_text = "white";
					r.website.colors.text = "rgba(50, 50, 50, 1)";
					r.website.colors.background = "rgba(244, 244, 244, 1)";

					r.website.colors.box = "rgba(255, 255, 255, 1)";
					r.website.colors.box_text = "rgba(50, 50, 50, 1)";

					r.website.sections.top_nav.colors.background = "rgba(255, 255, 255, 1)";
					r.website.sections.top_nav.colors.text = "rgba(50, 50, 50, 1)";

					r.website.sections.header.colors.background = "rgba(33, 33, 33, 1)";
					r.website.sections.header.colors.text = "rgba(255, 255, 255, 1)";

					r.website.sections.footer.colors.background = "rgba(45, 45, 45, 1)";
					r.website.sections.footer.colors.text = "rgba(255, 255, 255, 1)";
				}
				else if (theme === "dark") {

					r.website.colors.primary_text = "white";
					r.website.colors.text = "rgba(240, 240, 240, 1)";
					r.website.colors.background = "rgba(20, 20, 20, 1)";

					r.website.colors.box = "rgba(45, 45, 45, 1)";
					r.website.colors.box_text = "rgba(240, 240, 240, 1)";

					r.website.sections.top_nav.colors.background = "rgba(30, 30, 30, 1)";
					r.website.sections.top_nav.colors.text = "rgba(240, 240, 240, 1)";

					r.website.sections.header.colors.background = "rgba(240, 240, 240, 1)";
					r.website.sections.header.colors.text = "rgba(45, 45, 45, 1)";

					r.website.sections.footer.colors.background = "rgba(30, 30, 30, 1)";
					r.website.sections.footer.colors.text = "rgba(240, 240, 240, 1)";

				}

				return {
					update: {
						$set: {
							"website.colors": r.website.colors,
							"website.sections": r.website.sections,
						},
					},
				};

			},
			successMsg: "Theme applied",
			before: () => Untrusive.start(),
			onSuccess: () => {
                setIsSubmitting(false)
                Untrusive.stop()
            },
			onFail: () => Untrusive.stop(),
			onError: () => Untrusive.stop(),
            shouldUpdateStore: true
		}, injected);

	}
    return (
        <SettingsWebsiteWrapper style={{marginTop: 16}}>
            <SettingsFormGroup>
                <SettingsFormGroupInput>
                    <SettingsFormLabelContainer>
                        <SettingsFormLabelLeftContainer>
                            <p>We recommend picking a theme and then changing your primary color under the colors section.</p>
                        </SettingsFormLabelLeftContainer>
                    </SettingsFormLabelContainer>
                </SettingsFormGroupInput>
                <ThemesTabContainer activeDesignMode={activeDesignMode}>
                    <ThemesTabItem 
                        className={selectedTheme == 'light' ? 'selected': ''}
                        onClick={() => setSelectedTheme('light')}>
                        <ThemesTabItemHeader>Light Theme</ThemesTabItemHeader>
                        <ThemesTabItemContainer>
                            <ThemesTabItemWrapper>
                                <ThemesTabItemTitle>Title</ThemesTabItemTitle>
                                <ThemesTabItemContent>Content</ThemesTabItemContent>
                                <ThemesTabItemButtonWrapper>
                                    <button>Button</button>
                                </ThemesTabItemButtonWrapper>
                            </ThemesTabItemWrapper>
                           
                        </ThemesTabItemContainer>
                    </ThemesTabItem>
                    <ThemesTabItem className={selectedTheme == 'dark' ? 'selected': ''} onClick={() => setSelectedTheme('dark')}>
                        <ThemesTabItemHeader>Dark Theme</ThemesTabItemHeader>
                        <ThemesTabItemContainer>
                            <ThemesTabItemWrapper>
                                <ThemesTabItemTitle variant="dark">Title</ThemesTabItemTitle>
                                <ThemesTabItemContent variant="dark">Content</ThemesTabItemContent>
                                <ThemesTabItemButtonWrapper variant="dark">
                                    <button>Button</button>
                                </ThemesTabItemButtonWrapper>
                            </ThemesTabItemWrapper>
                        </ThemesTabItemContainer>
                    </ThemesTabItem>
                </ThemesTabContainer>
            </SettingsFormGroup>
            <SettingsActionButtonContainer activeDesignMode={activeDesignMode}>
                <SettingsActionButtonWrapper>
                    <button 
                        onClick={() => setTheme(selectedTheme)}
                        type="button" disabled={isSubmitting}>
                        {isSubmitting && <RotateLoader size={2} color="white" />}
                        {!isSubmitting && "Save"}
                    </button>
                </SettingsActionButtonWrapper>
            </SettingsActionButtonContainer>
        </SettingsWebsiteWrapper>
    )
}
