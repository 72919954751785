import React, { useEffect, useState } from 'react'
import { TabGroup, TabItem } from "../settings-v2/receipt-printing"
import { EligibleItemsContainer, SettingsActionButtonContainer, SettingsActionButtonWrapper, SettingsFormGroup, SettingsFormGroupInput, SettingsFormInputContainer, SettingsFormLabelContainer, SettingsFormLabelLeftContainer, SettingsFormLabelRightContainer } from '../settings-v2'
import { IconHelp } from '../../../../components/v2/icons'
import { FormTreeSelect, RestaurantIconSelect, RotateLoader } from '@lib/components'
import { CookieColorPickerContent, CookieColorPickerWrapper, CookieColorsContainer, CookieColorsRow } from '../settings-v2/policies-agreements'
import { ColorPicker } from '@lib/components/src'
import { RestaurantUtils } from '@lib/common'
import { useStores } from '../../../../../mobx/useStores'
import { RestaurantForm } from '../../../../../mobx/components/restaurant-form'
import { FastField } from 'formik'
import cloneDeep from "lodash/cloneDeep";
import shortid from "shortid";

type FormValues = T.Schema.Restaurant.Menu.RestaurantDishTag;

interface Props {
    selectedTagIndex?: number
}

export const ItemTags = (props: Props) => {
    const { selectedTagIndex } = props
    const [tab ,setTab] = useState('general')
    const [iconType, setIconType] = useState('text')
    const [add_to, setAddTo] = useState<string[]>([])
    const [selectedIndex, setSelectedIndex] = useState(selectedTagIndex)

    const store = useStores()
    const restaurant = store.restaurant!
    const { activeMobileMenu, setActiveMobileMenu } = store
    let initialValues
    const create_item = (): T.Schema.Restaurant.Menu.RestaurantDishTag => {
		return {
			_id: shortid.generate(),
			name: "",
			type: "none",
			text: "Example",
			icon: "",
			color: {
				icon: {
					background: "#fff",
					text: "#ff7d4b",
				},
				wrapper: {
					background: "#ff7d4b",
					text: "#fff",
				},
			},
		};
	};


    useEffect(() => {
        setSelectedIndex(selectedTagIndex)
    }, [selectedTagIndex])
    if (selectedIndex  && selectedIndex > -1) {
        const item = restaurant.dish_tags[selectedIndex];
        initialValues = cloneDeep(item);
    } else {
        initialValues = create_item();
    }
    return (<div style={{background: '#fefefe', paddingBottom: 81}}>
        <TabGroup className='flex-col-mobile' style={{ overflow: 'unset'}}>
            <TabItem 
                active={tab == 'general'}
                className="flex-unset-desktop"
                onClick={() => setTab('general')}>General</TabItem>
            <TabItem
                active={tab == 'add'}
                className="flex-unset-desktop"
                onClick={() => setTab('add')}>Add / Remove from Items</TabItem>
        </TabGroup>
        <RestaurantForm<FormValues>
					submit={async (r, values) => {

						const existing = r.dish_tags.findIndex((p) => p._id === values._id);

						if (existing === -1) {
							r.dish_tags.push(values);
						}
						else {
							r.dish_tags[existing] = values;
						}

						for (const [i, m] of r.menus.entries()) {
							for (const [k, c] of m.categories.entries()) {
								for (const [z, d] of c.dishes.entries()) {
									if (add_to.indexOf(d._id) === -1) {
										const index = d.tags.indexOf(values._id);
										if (index !== -1) {
											r.menus[i].categories[k].dishes[z].tags.splice(index, 1);
										}
									}
									else {
										const index = d.tags.indexOf(values._id);
										if (index === -1) {
											r.menus[i].categories[k].dishes[z].tags.push(values._id);
										}
									}
								}
							}
						}

						const update = {
							$set: {
								menus: r.menus,
								dish_tags: r.dish_tags,
							},
						};

						return { r, update };
					}}
					validators={{
						name: (values) => {
							if (!values.name)
								return { name: "This field is required" };
						},
					}}
					initialValues={initialValues}
					onSuccess={() => {
                        setSelectedIndex(-1)
                        store.setActiveMobileMenu(
                            store.activeMobileMenu.referrer.pop()!,
                            Array.from(new Set(store.activeMobileMenu.referrer))
                        )
                    }}
					onSuccessMessage={activeMobileMenu.name === "edit-dish-tag" ? "Tag edited" : "Tag created"}
                    width='100%'
				>
                    {({ form, error, getFieldError }) => {
                    const { isSubmitting, setFieldValue, setFieldTouched, setFieldError, submitCount, values } = form;
                    return (<>
                        <SettingsFormGroup>
                            {tab == 'general' && <>
                            <SettingsFormGroupInput
                                style={{
                                    paddingBottom: 16,
                                    borderBottom: '1px dashed #dfdfdf'
                                }}>
                                <SettingsFormLabelContainer>
                                    <SettingsFormLabelLeftContainer>Name</SettingsFormLabelLeftContainer>
                                    <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                </SettingsFormLabelContainer>
                                <SettingsFormInputContainer>
                                    <FastField
                                        name="name"
                                        render={({ field }: any) => (
                                        <input {...field} required />
                                     )}/>
                                </SettingsFormInputContainer>
                            </SettingsFormGroupInput>
                            <SettingsFormGroupInput
                                style={{
                                    paddingBottom: 16,
                                    borderBottom: '1px dashed #dfdfdf'
                                }}>
                                <SettingsFormLabelContainer>
                                    <SettingsFormLabelLeftContainer>Tag Text</SettingsFormLabelLeftContainer>
                                    <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                </SettingsFormLabelContainer>
                                <SettingsFormInputContainer>
                                    <FastField
                                        name="text"
                                        render={({ field }: any) => (
                                        <input {...field} />
                                     )}/>
                                </SettingsFormInputContainer>
                            </SettingsFormGroupInput>
                            <SettingsFormGroupInput>
                                <SettingsFormLabelContainer>
                                    <SettingsFormLabelLeftContainer>Icon Type</SettingsFormLabelLeftContainer>
                                    <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                </SettingsFormLabelContainer>
                                <FastField
                                    name="type"
                                    render={({ field }: any) => (
                                    <TabGroup style={{
                                        padding: '0 0 16px',
                                        borderBottom: '1px dashed #dfdfdf',
                                        overflow: 'unset'
                                    }}>
                                        <TabItem
                                            active={iconType == 'text'}
                                            onClick={() => {
                                                setIconType('text')
                                                setFieldValue("type", 'text')
												setFieldValue("icon", "")
                                            }}
                                            className="flex-unset-desktop w-108">Text</TabItem>
                                        <TabItem 
                                            active={iconType == 'icon'}
                                            onClick={() => {
                                                setIconType('icon')
                                                setFieldValue("type", 'icon')
												setFieldValue("icon", "")
                                            }}
                                            className="flex-unset-desktop w-108">Icon</TabItem>
                                        <TabItem
                                            active={iconType == 'none'}
                                            onClick={() => {
                                                setIconType('none')
                                                setFieldValue("type", 'none')
												setFieldValue("icon", "")
                                            }}
                                            className="flex-unset-desktop w-108">None</TabItem>
                                    </TabGroup>
                                )} />
                            </SettingsFormGroupInput>
                            {iconType == 'text' && <SettingsFormGroupInput
                                style={{
                                    paddingBottom: 16,
                                    borderBottom: '1px dashed #dfdfdf'
                                }}>
                                <SettingsFormLabelContainer>
                                    <SettingsFormLabelLeftContainer>Icon Text</SettingsFormLabelLeftContainer>
                                    <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                </SettingsFormLabelContainer>
                                <SettingsFormInputContainer>
                                    <FastField
                                        name="icon"
                                        render={({ field }: any) => (
                                        <input {...field} />
                                     )}/>
                                </SettingsFormInputContainer>
                            </SettingsFormGroupInput> }
                            {iconType == 'icon' && <SettingsFormGroupInput
                                style={{
                                    paddingBottom: 16,
                                    borderBottom: '1px dashed #dfdfdf'
                                }}>
                                <SettingsFormLabelContainer>
                                    <SettingsFormLabelLeftContainer>Select an Icon</SettingsFormLabelLeftContainer>
                                    <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                </SettingsFormLabelContainer>
                                <FastField
                                    name="icon"
                                    render={({ field }: any) => (
                                    <RestaurantIconSelect 
                                        selected={field.value}
										onChange={(icon) => setFieldValue("icon", icon)} />
                                )} />
                            </SettingsFormGroupInput> }

                            <SettingsFormGroupInput style={{gap: 16, borderBottom: '1px dashed var(--Secondary-Light-Grey-1, #DFDFDF)', paddingBottom: 16}}>
                                <SettingsFormLabelContainer>
                                    <SettingsFormLabelLeftContainer>Tag Color</SettingsFormLabelLeftContainer>
                                    <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                </SettingsFormLabelContainer>
                                <CookieColorsContainer>
                                    <CookieColorsRow>
                                        <p style={{flex: '0 0 91px'}}>Background</p>
                                        <CookieColorPickerWrapper>
                                            <FastField
                                            name="color.wrapper.background"
                                            render={({ field }: any) => (
                                            <CookieColorPickerContent className='w-100px'>
                                                <ColorPicker
                                                color={'#000'}
                                                onChange={(color) => setFieldValue('color.wrapper.background', color ) }
                                                />
                                            </CookieColorPickerContent>
                                            )} />
                                        </CookieColorPickerWrapper>
                                    </CookieColorsRow>
                                </CookieColorsContainer>
                                <CookieColorsContainer>
                                    <CookieColorsRow>
                                        <p style={{flex: '0 0 91px'}}>Text</p>
                                        <CookieColorPickerWrapper>
                                        <FastField
                                            name="color.wrapper.text"
                                            render={({ field }: any) => (
                                            <CookieColorPickerContent className='w-100px'>
                                                <ColorPicker
                                                color={'#000'}
                                                onChange={(color) => setFieldValue('color.wrapper.text', color ) }
                                                />
                                            </CookieColorPickerContent>
                                            )} />
                                        </CookieColorPickerWrapper>
                                    </CookieColorsRow>
                                </CookieColorsContainer>
                            </SettingsFormGroupInput>
                            <SettingsFormGroupInput style={{gap: 16, paddingBottom: 16}}>
                                <SettingsFormLabelContainer>
                                    <SettingsFormLabelLeftContainer>Icon Color</SettingsFormLabelLeftContainer>
                                    <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                </SettingsFormLabelContainer>
                                <CookieColorsContainer>
                                    <CookieColorsRow>
                                        <p style={{flex: '0 0 91px'}}>Background</p>
                                        <CookieColorPickerWrapper>
                                        <FastField
                                            name="color.icon.background"
                                            render={({ field }: any) => (
                                            <CookieColorPickerContent className='w-100px'>
                                                <ColorPicker
                                                color={'#000'}
                                                onChange={(color) => setFieldValue('color.icon.background', color ) }
                                                />
                                            </CookieColorPickerContent>
                                            )} />
                                        </CookieColorPickerWrapper>
                                    </CookieColorsRow>
                                </CookieColorsContainer>
                                <CookieColorsContainer>
                                    <CookieColorsRow>
                                        <p style={{flex: '0 0 91px'}}>Text / Icon</p>
                                        <CookieColorPickerWrapper>
                                        <FastField
                                            name="color.icon.text"
                                            render={({ field }: any) => (
                                            <CookieColorPickerContent className='w-100px'>
                                                <ColorPicker
                                                color={'#000'}
                                                onChange={(color) => setFieldValue('color.icon.text', color ) }
                                                />
                                            </CookieColorPickerContent>
                                            )} />
                                        </CookieColorPickerWrapper>
                                    </CookieColorsRow>
                                </CookieColorsContainer>
                            </SettingsFormGroupInput>
                            </>}
                            {tab == 'add' && <>
                            <SettingsFormGroupInput style={{gap: 16}}>
                                <SettingsFormLabelContainer>
                                    <SettingsFormLabelLeftContainer style={{alignItems: 'center'}}>
                                        Item Tag
                                    </SettingsFormLabelLeftContainer>
                                </SettingsFormLabelContainer>
                                <EligibleItemsContainer className='menus-v2'>
                                    <FormTreeSelect
                                        id={`add-to-dishes`}
                                        selected={add_to}
                                        nodes={RestaurantUtils.menu.getTreeFilter(restaurant, () => true)}
                                        onChange={(add) => {
                                            setAddTo(add)
                                        }}
                                    />
                                </EligibleItemsContainer>
                            </SettingsFormGroupInput>
                            </>}
                        </SettingsFormGroup>
                        <SettingsActionButtonContainer>
                            <SettingsActionButtonWrapper style={{left: 0}}>
                                <button type="submit" disabled={isSubmitting}>
									{isSubmitting && <RotateLoader size={2} color="white" />}
                                    {!isSubmitting && "Save"}
                                </button>
                            </SettingsActionButtonWrapper>
                        </SettingsActionButtonContainer>
                        </>)
                    }}

              </RestaurantForm>
    </div>)
}
