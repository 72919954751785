import React, { useState } from 'react';
import { FaClock } from 'react-icons/fa';
import styled from 'styled-components';
import { Button } from '../../../button';
import { Modal } from '../../../layout/modal';
import { ModalContent, ModalTitle } from '../../../layout/modal/content';
import {
  CardDetails,
  CardDetailsLeft,
  CardHeader,
  CardHeaderRight,
  HeaderRightItem,
  mapToServiceName,
  ServiceStyle,
} from '../../card';
import { OrderIcon } from '../../card/card-icon';
import { CommonText } from '../../commonText';
import { DeliveryProvider } from '../../deliveryProvider';

const getOrderItemBg = (type: string) => {
  switch (type) {
    case 'Unconfirmed':
      return '#e5ac00';
    case 'Confirmed':
      return '#A5C100';
    case 'Ready':
      return '#0064C8';
    case 'On-route':
      return '#00A6DD';
    case 'Completed':
      return '#009100';
    case 'Cancelled':
      return '#BC362F';
    default:
      return '#e5ac00';
  }
};
export const OrderStatusWrapper = styled.div`
  padding: 16px;
  display: grid;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(202px, 1fr));
  grid-gap: 15px;
  padding: 17px 0;
`;
export const OrderStatusItem = styled.div<{ type: string; height?: string | undefined  }>`
  padding: 8px 16px 8px 16px;
  background: ${({ type }) => getOrderItemBg(type)};
  width: 100%;
  // height: ${({ height }) => height ? height : "40px"};
  border-radius: 4px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;
export const OrderQuantity = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: rgba(12, 32, 42, 0.2);
  div {
    position: relative;
    display: inline-block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
export type TStatusItem = {
  id: string;
  text: string;
  quantity?: number;
  type: T.Schema.Order.OrderStatuses;
};

type ChangeStatusModalProps = {
  order: T.Schema.Order.OrderSchema | any;
  selectedItemIds: string[];
  changeStatusActive: boolean;
  setChangeStatusActive: (value: boolean) => void;
  handleChangeStatus: (order: T.Schema.Order.OrderSchema, status: T.Schema.Order.OrderStatuses) => void;
  orderStatusData: TStatusItem[];
};

const getOrderStatusItemTitle = (order: T.Schema.Order.OrderSchema, item: TStatusItem) => {
  if (order && order.status && item && item.type === order.status) {
    return `${item.text} (Current Status)`;
  }
  return item.text;
};

export const ChangeStatusModal = ({
  selectedItemIds,
  order,
  changeStatusActive,
  setChangeStatusActive,
  handleChangeStatus,
  orderStatusData,
}: ChangeStatusModalProps) => {
  const changeStatus = (type: T.Schema.Order.OrderStatuses) => {
    if (type !== 'confirmed') {
      handleChangeStatus(order, type);
    }
  };
  return (
    <Modal active={changeStatusActive} width={542} close={() => setChangeStatusActive(false)}>
      <ModalTitle className="round-top text-center">
        <h3>Change Order Status</h3>
      </ModalTitle>
      <CardHeader>
        <ServiceStyle service={order.config.service}>{mapToServiceName(order.config.service)}</ServiceStyle>
        <CardHeaderRight>
          {order.config.service === 'dine_in' && <div>{order.config.table}</div>}
          {order.config.service === 'delivery'&& order.ready_in && order.ready_in.timestamp && (
            <>
              <FaClock />
              <CommonText size="semiMedium" weight={500} color="#4B4F5E">
                {new Date(order.ready_in.timestamp - Date.now()).getMinutes()} Mins
              </CommonText>
            </>
          )}
          <HeaderRightItem></HeaderRightItem>
        </CardHeaderRight>
      </CardHeader>
      <CardDetails allSelected={selectedItemIds.length === order.dishes.length}>
        <CardDetailsLeft>
          <DeliveryProvider provider={order.config.delivery_provider} />
          <OrderIcon />
          <CommonText size="semiMedium" weight={500} color="#4B4F5E">
            {order.number}
          </CommonText>
        </CardDetailsLeft>
        <CommonText size="semiMedium" weight={500} color="#4B4F5E">
          {selectedItemIds.length} / {order.dishes.length} Items
        </CommonText>
      </CardDetails>
      <ModalContent style={{ minHeight: '283px' }}>
        <OrderStatusWrapper>
          {orderStatusData.map(item => (
            <OrderStatusItem key={item.id} type={item.text} onClick={() => changeStatus(item.type)}>
              <div>{getOrderStatusItemTitle(order, item)}</div>
            </OrderStatusItem>
          ))}
        </OrderStatusWrapper>
        <div>
          <Button type="button" full className="m-r-2" onClick={() => setChangeStatusActive(false)}>
            Cancel
          </Button>
        </div>
      </ModalContent>
    </Modal>
  );
};
