import React from 'react';
import { CommonText } from '@lib/components';
import styled, { css } from 'styled-components';
import { MENU_ITEM_TYPE } from '../constant';
import { calculateMomentAfterMinutes, isDurationCrossingNextDay } from '../../orders/sidebars/pauseServicesSidebar/constants';
import { isMobile } from 'react-device-detect';

const PauseTagWrapper = styled.div<{ mobile: boolean; }>`
  background: #bc362f;
  gap: 10px;
  border-radius: 4px;
  height: 100%;
  font-size: 12px;
  padding: 2px 8px;
  white-space:nowrap;
  @media (max-width: 768px) {
    min-width: 90px;
    font-size: 8px;
      &.hide-mobile {
      display: none;
    }
  }
`;

type PauseTagProps = {
  pauseMenu: boolean | undefined;
  type: string;
  pauseDuration?: number;
  pauseStart?: number;
  isPauseInfinity?: boolean;
};

export const PauseTag: React.FC<PauseTagProps> = ({ pauseMenu = false, type, pauseDuration, pauseStart , isPauseInfinity}) => {
  const nextDayLabel = isDurationCrossingNextDay(pauseDuration, pauseStart) ? "tomorrow" : "";

  if (!pauseMenu || type !== MENU_ITEM_TYPE.DISH || !pauseStart) {
    return <></>;
  }
  const pauseLabel = !isPauseInfinity ? `Paused Until: ${calculateMomentAfterMinutes(pauseDuration, pauseStart)} ${nextDayLabel}` : `Pause Indefinitely`;
  return (
    <PauseTagWrapper mobile={isMobile}>
      <CommonText size="small" weight={700} color="#fff" style={{
        fontSize: 'inherit'
      }}>
        {pauseLabel}
      </CommonText>
    </PauseTagWrapper>
  );
};
