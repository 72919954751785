import React from "react";
import { 
    SettingsFormGroup,
    SettingsFormLabelLeftContainer,
    SettingsSwitchContainer,
    SettingsActionButtonWrapper,
    SettingsActionButtonContainer
} from ".";
import { RotateLoader, Switch } from "@lib/components";
import { RestaurantForm } from "../../../../../mobx/components/restaurant-form";
import { UI } from "../../../../../core/ui";
import { useStores } from "../../../../../mobx/useStores";
import { FastField } from "formik";
interface FormValues {
	business: T.Schema.Restaurant.RestaurantSchema["settings"]["business"];
}
export const SettingsOrdering = () => {
    const store = useStores()
    const r = store.restaurant!;
    let initialValues: FormValues  = {
        business: r.settings.business
    }
    return (
        <div style={{paddingTop: 16, width: '100%'}}>
            <RestaurantForm<FormValues>
            submit={async (r, values) => {
                r.settings.business = values.business;
                const update = { $set: { "settings.business": values.business } };
                return { r, update };
            }}
            initialValues={initialValues}
            onError={() => UI.notification.error("An error occurred")}
            onSuccessMessage="Settings Updated"
            width="100%">
            {({ form, error }) => {
            const { isSubmitting, setFieldValue, errors } = form;
            return (
                <>
                <SettingsFormGroup style={{gap: 16, paddingBottom: 32}}>
                    <SettingsSwitchContainer style={{gap: 16, paddingTop: 8, paddingBottom: 8}}>
                        <SettingsFormLabelLeftContainer style={{fontWeight: 500}}>
                        Using Awaiting Payments Status
                        </SettingsFormLabelLeftContainer>
                        <FastField 
                            name="business.using_awaiting_payment"
                            render={({ field }: any) =>
                            <Switch
                                id="using_awaiting_payment"
                                checked={field.value || false}
                                onChange={(e) =>  setFieldValue("business.using_awaiting_payment", e.target.checked)}
                                variant="black"
                                />
                            }
                        />
                    </SettingsSwitchContainer>
                    <SettingsSwitchContainer style={{gap: 16, paddingTop: 8, paddingBottom: 8}}>
                        <SettingsFormLabelLeftContainer style={{fontWeight: 500}}>
                            Disabled Ordering
                        </SettingsFormLabelLeftContainer>
                        <FastField 
                            name="business.ordering_disabled"
                            render={({ field }: any) =>
                            <Switch
                                id="ordering_disabled"
                                checked={field.value || false}
                                onChange={(e) =>  setFieldValue("business.ordering_disabled", e.target.checked)}
                                variant="black"
                                />
                            }
                        />
                    </SettingsSwitchContainer>
                </SettingsFormGroup>
                <SettingsActionButtonContainer>
                    <SettingsActionButtonWrapper>
                        <button type="button" 
                            className="default btn-cancel" 
                            onClick={ () => store.setActiveMobileMenu(
                                store.activeMobileMenu.referrer.pop()!,
                                Array.from(new Set(store.activeMobileMenu.referrer))
                            )}>
                                Cancel 
                        </button>
                        <button type="submit" disabled={isSubmitting}>
                            {isSubmitting && <RotateLoader size={2} color="white" />}
                            {!isSubmitting && "Save"}
                        </button>
                    </SettingsActionButtonWrapper>
                </SettingsActionButtonContainer>
                </>
            )}}
            </RestaurantForm>
        </div>
    )
}
