import React from 'react';
import { WebsiteForm } from "../../../../../mobx/components/website-form";
import { useStores } from "../../../../../mobx/useStores";
import { UI } from '../../../../../core/ui';
import { FastField } from 'formik';
import { FormGroup, LinkTag, RotateLoader, Switch } from '@lib/components';
import _ from 'lodash';
import { FieldFont } from '@lib/components/src';
import { GoogleCreateService } from '../../../../../core/libs/google';
import { SettingsActionButtonContainer, SettingsActionButtonWrapper } from '../../../restaurant/views/settings-v2';

let GoogleService: any = {}

type FormValues = T.Schema.Website.WebsiteSchema["fonts"];

export const FontsV2 = () => {
    const store = useStores()
    const w = store.website!;
    let initialValues = w.fonts;
    GoogleService = GoogleCreateService(); //(config.services.google.api_key, reseller)
    return (
        <WebsiteForm<FormValues>
        submit={async (w, values) => {
        w.fonts = values;
        const update = { $set: { fonts: w.fonts } };
        return { w, update };
        }}
        validators={{}}
        initialValues={initialValues}
        onSuccess={() => {}}
        onError={() => UI.notification.error('An error occurred')}
        onSuccessMessage="Settings Updated"
        onErrorMessage=""
        >
        {({ form, error }) => {
        const { isSubmitting, setFieldValue } = form;
        return (
            <div className="p-4">
            <FastField
                name="use_google"
                shouldUpdate={(nextProps: any, currentProps: any) =>
                nextProps.formik.values.use_google !== currentProps.formik.values.use_google
                }
                render={({ field }: any) => (
                <>
                    <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                    <FormGroup
                    title="Use Google Fonts"
                    no_border
                    style={{
                        marginBottom: 0,
                    }}
                    >
                    </FormGroup>
                    <Switch
                        id="use_google"
                        checked={_.isNil(field.value) ? true : field.value}
                        onChange={(e) => {
                        setFieldValue('use_google', e.target.checked);
                        if(!e.target.checked) {
                            //set default hosted fonts
                            setFieldValue('heading', {
                            category: "sans-serif",
                            family: "Roboto",
                            query: "Roboto:100,100italic,300,300italic,regular,italic,500,500italic,700,700italic,900,900italic",
                            });
                            setFieldValue('body', {
                            category: "sans-serif",
                            family: "Lato",
                            query: "Lato:100,100italic,300,300italic,regular,italic,700,700italic,900,900italic",
                            });
                        }
                        }}
                    />
                    </div>
                    <FormGroup style={{ marginTop: '16px'}}>
                    <p>
                    Loading of Google fonts may be prohibited in some countries. Disable this feature to use fonts from our server.
                        View all available fonts at{' '}
                        <LinkTag href="https://fonts.google.com" target="_blank">
                        https://fonts.google.com
                        </LinkTag>
                    </p>
                    </FormGroup>
                </>
                )}
            />

            <FastField
                name="heading"
                shouldUpdate={(nextProps: any, currentProps: any) =>
                nextProps.formik.values.use_google !== currentProps.formik.values.use_google
                || nextProps.formik.values.heading !== currentProps.formik.values.heading
                }
                render={({ field }: any) => (
                <FormGroup title="Heading Font">
                    <FieldFont
                    value={field.value.family}
                    onChange={font => setFieldValue('heading', font)}
                    google={GoogleService}
                    self_hosted={form.values.use_google == false}
                    />
                </FormGroup>
                )}
            />

            <FastField
                name="body"
                shouldUpdate={(nextProps: any, currentProps: any) =>
                nextProps.formik.values.use_google !== currentProps.formik.values.use_google
                || nextProps.formik.values.body !== currentProps.formik.values.body
                }
                render={({ field }: any) => (
                <FormGroup title="Body Font">
                    <FieldFont
                    value={field.value.family}
                    onChange={font => setFieldValue('body', font)}
                    google={GoogleService}
                    self_hosted={form.values.use_google == false}
                    />
                </FormGroup>
                )}
            />

            <SettingsActionButtonContainer className='no-sidebar'>
                <SettingsActionButtonWrapper style={{left: 0}}>
                    <button 
                        type="button" 
                        className='btn-cancel'
                        onClick={ () => store.setActiveMobileMenu(
                            store.activeMobileMenu.referrer.pop()!,
                            Array.from(new Set(store.activeMobileMenu.referrer))
                        )}>
                        Cancel
                    </button>
                    <button  type="submit" disabled={isSubmitting}>
                    {isSubmitting && <RotateLoader size={2} color="white" />}
                    {!isSubmitting && "Save"}
                    </button>
                </SettingsActionButtonWrapper>
            </SettingsActionButtonContainer>
        </div>
        );
        }}
        </WebsiteForm>
    )
}

