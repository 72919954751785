import React from 'react';
import uuid from 'uuid';
import shortid from 'shortid';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { inject, observer } from 'mobx-react';
import { FastField, FastFieldProps } from 'formik';

import {
  Button,
  FormGroup,
  Input,
  RotateLoader,
  Switch,
} from '@lib/components';
import { UI } from '../../../../../../../core/ui';
import { MobxComponent } from '../../../../../../../mobx/components';
import { RestaurantForm } from '../../../../../../../mobx/components/restaurant-form';
import { SettingsActionButtonContainer, SettingsActionButtonWrapper } from '../../../settings-v2';

interface Props {
  close?: () => void;
}

type FormValues =
  T.Schema.Restaurant.RestaurantSchema['settings']['loyalty_providers'];

const WEBHOOK_BASE_URI = 'https://api.walletly.ai/api/v4/pass/webhook';

@inject('store')
@observer
export class SettingsFormIntegrationWalletly extends MobxComponent<Props, {}> {
  initialValues: FormValues;

  constructor(props: Props) {
    super(props);
    this.state = {
      market: null,
    };
    const r = this.injected.store.restaurant!;
    this.initialValues = r.settings.loyalty_providers || {};

    if (!this.initialValues?.walletly) {
      this.initialValues.walletly = {
        enabled: false,
        api_key: '',
        brand_id: '',
        secret_key: uuid.v4(),
        integration_id: '',
      };
    }

    if (
      this.initialValues.walletly &&
      !this.initialValues.walletly.secret_key
    ) {
      this.initialValues.walletly.secret_key = uuid.v4();
    }
  }

  validateRequiredField(value: string) {
    let error;
    if (_isEmpty(value)) {
      error = 'This field value cannot be empty.';
    }
    return error;
  }

  getError(errors: any, key: string): string {
    if (errors && errors.walletly) {
      const message = _get(errors.walletly, key, '');
      return _isEmpty(message) ? '' : message;
    }
    return '';
  }

  parseIntegrationId(value: string) {
    if (value.startsWith('https://')) {
      const url = new URL(value);
      return url.pathname.split('/').at(-2);
    }

    return value;
  }

  constructUpdatePayload(
    restaurant: T.Schema.Restaurant.RestaurantSchema,
    formValues: FormValues
  ) {
    restaurant.settings.loyalty_providers = formValues;
    const webhooks = restaurant.webhooks || [];
    const webhook = webhooks.find(w => w.url.startsWith(WEBHOOK_BASE_URI));
    if (formValues?.walletly?.enabled) {
      if (!webhook) {
        webhooks.push({
          _id: shortid.generate(),
          secret: formValues.walletly.secret_key,
          url: `${WEBHOOK_BASE_URI}/${formValues.walletly.brand_id}/${formValues.walletly.integration_id}/${formValues.walletly.secret_key}`,
          enabled: true,
          events: {
            order_new: true,
            order_update_status: true,
            order_update_ready_time: true,
            booking_new: true,
            booking_update_status: true,
            booking_cancel: true,
            order_cancel: true,
            order_completed_updated: true,
            order_cancelled_updated: true,
          },
        });
      } else {
        webhook.enabled = true;
      }
    } else if (webhook && !formValues?.walletly?.enabled) {
      webhook.enabled = false;
    }

    const update = {
      $set: {
        'settings.loyalty_providers': restaurant.settings.loyalty_providers,
        webhooks,
      },
    };
    return { r: restaurant, update };
  }

  render() {
    const org = this.injected.store.organisation!;
    return (
      <RestaurantForm<FormValues>
        submit={async (restaurant, values) =>
          this.constructUpdatePayload(restaurant, values)
        }
        initialValues={this.initialValues}
        onSuccess={() => {
          if (this.props.close) {
            this.props.close();
          }
        }}
        onError={() => UI.notification.error('An error occurred')}
        onSuccessMessage="Settings Updated"
        width='100%'
      >
        {({ form, error }) => {
          const { isSubmitting, setFieldValue, errors } = form;
          return (
            <>
              <FastField name="walletly.enabled">
                {({ field }: FastFieldProps) => (
                  <FormGroup
                    title="Enabled"
                    help="Integrate shared loyalty provider to let customers accumulate and spend Walletly points."
                  >
                    <Switch
                      id="walletly-enabled-switch"
                      checked={field.value || false}
                      onChange={e =>
                        setFieldValue('walletly.enabled', e.target.checked)
                      }
                    />
                  </FormGroup>
                )}
              </FastField>

              <FastField
                name="walletly.api_key"
                validate={this.validateRequiredField}
              >
                {({ field }: FastFieldProps) => (
                  <FormGroup
                    title="API Key"
                    help="Your Walletly API key. Get it from the account settings."
                    error={this.getError(errors, 'api_key')}
                  >
                    <Input type="text" {...field} value={field.value || ''} />
                  </FormGroup>
                )}
              </FastField>

              <FastField
                name="walletly.brand_id"
                validate={this.validateRequiredField}
              >
                {({ field }: FastFieldProps) => (
                  <FormGroup
                    title="Brand ID"
                    help="The Walletly Brand you want to link with."
                    error={this.getError(errors, 'brand_id')}
                  >
                    <Input type="text" {...field} value={field.value || ''} />
                  </FormGroup>
                )}
              </FastField>

              <FastField
                name="walletly.secret_key"
                validate={this.validateRequiredField}
              >
                {({ field }: FastFieldProps) => (
                  <FormGroup
                    title="Secret Key"
                    help="You'll need this when creating an integration link in Walletly"
                    error={this.getError(errors, 'secret_key')}
                  >
                    <Input
                      type="text"
                      {...field}
                      readOnly
                      value={field.value}
                    />
                  </FormGroup>
                )}
              </FastField>

              <FastField
                name="walletly.integration_id"
                validate={this.validateRequiredField}
              >
                {({ field }: FastFieldProps) => (
                  <FormGroup
                    title="Integration ID"
                    help="Copy the webhook URL from the Walletly integration screen and paste it here."
                    error={this.getError(errors, 'integration_id')}
                  >
                    <Input
                      type="text"
                      {...field}
                      value={field.value || ''}
                      onChange={e =>
                        form.setFieldValue(
                          'walletly.integration_id',
                          this.parseIntegrationId(e.target.value)
                        )
                      }
                    />
                  </FormGroup>
                )}
              </FastField>

              {error && <FormGroup error={error} />}

              {org.dashboard && org.dashboard === 'v2' ?
								<SettingsActionButtonContainer>
									<SettingsActionButtonWrapper style={{left: 0}}>
									<Button full={true} color="primary" type="submit" disabled={isSubmitting}>
										{isSubmitting && <RotateLoader size={2} color="white" />}
										{!isSubmitting && "Save"}
									</Button>
									</SettingsActionButtonWrapper>
								</SettingsActionButtonContainer>
							:<Button full={true} color="primary" type="submit" disabled={isSubmitting}>
								{isSubmitting && <RotateLoader size={2} color="white" />}
								{!isSubmitting && "Save"}
							</Button> }
            </>
          );
        }}
      </RestaurantForm>
    );
  }
}
