import * as React from "react";
import { darken } from "polished";
import {
	styled,
	Input,
	Tooltip,
	Button,
	InputGroup,
	EllipsisIcon,
	} from "@lib/components";
import {
	FaChevronRight,
	FaChevronDown,
	FaPlay,
} from "react-icons/fa";
import { Draggable } from "react-beautiful-dnd";
import { IoIosPause } from "react-icons/io";
import { PausedItemsCountTag } from "../menus/components/PausedItemsCountTag";
import { PauseTag } from "../menus/components/PauseTag";
import { ResumeModal } from "../menus/components/ResumeModal";
import { MENU_ITEM_TYPE } from "../menus/constant";
import { MenuCardActions, MenuCardActionsIconWrapper, MenuCardTitle, TextEllipsis } from ".";
import { IconCopyAlt, IconDelete, IconEdit, IconMoreOptions } from "../../../../components/v2/icons";

const Wrapper = styled("div")``;

const Item = styled("div")<{ drag?: boolean; isPausing?: boolean; active?: boolean }>`
	display: flex;
padding: 16px;
align-items: center;
gap: 12px;
align-self: stretch;
word-break: break-all;

border-radius: 4px;
background: var(--Neutral-White, #FEFEFE);
@media (max-width: 768px) {
     background: ${({ active }) => active ? "#FFF3F3" : "#FFF"};
	 border:  ${({ active }) => active ? "1px solid #FB2F2F" : "1px solid transparent"};
}
&.desktop-only {
 display: none;

    & > p {
        color: var(--Secondary-Smokey-Grey-1, #6B7270);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }
    @media (min-width: 768px) {
        display: flex;
    }
}

	border-bottom: ${({ isPausing }) => isPausing ? "1px solid #FB2F2F" : "1px solid transparent"}
	
`;

export const ItemButton = styled(Button)`
	color: ${({ theme }) => darken(0.6, theme.box.background)};
	&:hover {
		color: ${({ theme }) => theme.box.text};
	}
`;
export const MoreActionMobileMenu = styled(Button)`
display: none;
align-items: center;
gap: 4px;
flex: 1 0 0;
align-self: stretch;
height: 24px;
background: none;
border: none;
&:hover {
	background: none;
	border: none;
	box-shadow: none;
}
@media (max-width: 768px) {
	padding: 0;
    &.show-button-mobile {
		display: flex;
	}
}
`;

const SubWrapper = styled("div")`
	display: flex;
	justify-content: flex-end;
	padding-top: 6px;
`;

const SubContent = styled("div")`
	width: calc(100% - 25px);
`;

const ButtonsGroupRight = styled("div")`
	display: flex;
	align-items: center;
`;

interface MenuListItemProps {
	id: string;
	type: string;
	name: React.ReactNode;
	index: number;
	mode: "normal" | "reOrder" | "editPrices";
	onCopy?: (index: number) => void;
	onEdit?: (index: number) => void;
	onPause?: (index: number) => void;
	onRemove?: (index: number) => void;
	onClick?: (index: number) => void;
	onPriceEdit?: (price: number) => void;
	buttons?: React.ReactNode;
	children?: React.ReactNode;
	price?: number;
	currency?: T.Core.Business.BusinessCurrency & {
		step: string;
	};
	isPausing?: boolean;
	pauseDuration?: number;
	pauseStart?: number;
	totalItems?: number;
	totalPausedItems?: number;
	isPauseInfinity?: boolean;
	active?: string;
	isItem?: boolean;
	isMenu?: boolean;
}
interface MenuListItemState {
	active: boolean;
	resumeModalOpen: boolean;
}

const toolTipWidth = 60;

export class MenuListItemV2 extends React.PureComponent<
	MenuListItemProps,
	MenuListItemState
> {
	constructor(props: MenuListItemProps) {
		super(props);
		this.state = { 
			active: false,
			resumeModalOpen: false
		};
		this.toggleActive = this.toggleActive.bind(this);
	}

	toggleActive() {
		this.setState({ active: !this.state.active });
		if (this.props.onClick) {
			this.props.onClick(this.props.index);
		}
	}

	render() {
		const {
			id,
			type,
			name,
			index,
			onCopy,
			onEdit,
			onPause,
			onRemove,
			onPriceEdit,
			children,
			buttons,
			mode,
			price,
			currency,
			isPausing,
			pauseDuration,
			pauseStart,
			totalItems,
			totalPausedItems,
			isPauseInfinity,
			isItem,
			isMenu=false
		} = this.props;

		const { active, resumeModalOpen } = this.state;
		
		const handleCloseResumeModal = () => {
			this.setState({
				resumeModalOpen: false
			});
		}
		const handleOpenResumeModal = () => {
			this.setState({
				resumeModalOpen: true
			})
		}
	
		return (
			<>
			<ResumeModal isOpen={resumeModalOpen} handleClose={handleCloseResumeModal} menuItemIds={[id]} />
			<Draggable
				draggableId={id}
				index={index}
				type={type}
				isDragDisabled={mode !== "reOrder"}
			>
				{(provided) => (
					<Wrapper
						id={id}
						className="list-item"
						ref={provided.innerRef}
						{...provided.draggableProps}
						{...provided.dragHandleProps}
					>
						<Item
							onClick={this.toggleActive}
							drag={mode === "reOrder"}
							isPausing={isPausing && type === MENU_ITEM_TYPE.DISH}
							active={this.props.active === id}
						>
							<MenuCardTitle className="flex-line centered flex-grow" style={{
								wordBreak: "break-all", 
								maxWidth: '100%'
							}}>
								{!!children && !isItem &&
									(active ? (
										<div onClick={this.toggleActive}>
										<FaChevronDown className="m-r-1" style={{
											minWidth: "30px"
										}} />
										</div>
										
									) : (
										<div onClick={this.toggleActive}>
										<FaChevronRight className="m-r-3" style={{
											minWidth: "30px"
										}} />
										</div>
										
									))}
								<TextEllipsis>{name}</TextEllipsis>
							</MenuCardTitle>
							{mode === "normal" && (
								<div style={{ display: 'flex', gap: '16px', alignItems: 'center', justifyContent: 'center'}}>
									{
										[MENU_ITEM_TYPE.MENU, MENU_ITEM_TYPE.CATEGORY].includes(type) && isPausing && <PausedItemsCountTag totalItems={totalItems} totalPausedItems={totalPausedItems} />
									}
									<PauseTag isPauseInfinity={isPauseInfinity} pauseMenu={isPausing} type={type} pauseDuration={pauseDuration} pauseStart={pauseStart} />
									{buttons}
									{onPause && isPausing && type === MENU_ITEM_TYPE.DISH && <Tooltip
										text="Resume"
										width={toolTipWidth}
										position="top"
									>
										<MenuCardActionsIconWrapper
											onClick={(e) => {
												e.stopPropagation();
												handleOpenResumeModal();
											}}
										>
											<FaPlay />
										</MenuCardActionsIconWrapper>
									</Tooltip>}
									{onPause && !isPausing && type === MENU_ITEM_TYPE.DISH && <Tooltip
										text="Pause"
										width={toolTipWidth}
										position="top"
									>
										<MenuCardActionsIconWrapper
											onClick={(e) => {
												e.stopPropagation();
												onPause(index);
											}}
										>
											<IoIosPause />
										</MenuCardActionsIconWrapper>
									</Tooltip>}
									{onEdit && (
										<MenuCardActionsIconWrapper
										className={!!isMenu ? "hide-mobile" : ""}
										onClick={(e) => {
											e.stopPropagation();
											onEdit(index);
										} }>
											<IconEdit />
										</MenuCardActionsIconWrapper>
									)}
									{onRemove && (
										<MenuCardActionsIconWrapper 
										className={!!isMenu ? "hide-mobile" : ""}
										onClick={(e) => {
											e.stopPropagation();
											onRemove(index);
										} }>
										<IconDelete />
										</MenuCardActionsIconWrapper>
									)}
									{onCopy && (
										<MenuCardActionsIconWrapper 
										className={!!isMenu ? "hide-mobile" : ""}
										onClick={(e) => {
											e.stopPropagation();
											onCopy(index);
										} }>
										<IconCopyAlt />
										</MenuCardActionsIconWrapper>
									)}
									
										<MoreActionMobileMenu
											className={!!isMenu ? "show-button-mobile" : ""}
										>
											<IconMoreOptions />
										</MoreActionMobileMenu>
								</div>
							)}
							{mode === "editPrices" && type === "DISH" && (
								<>
									<InputGroup
										iconHtml={<div className="font-semi-bold">
											{currency!.symbol}
										</div>}
									></InputGroup>
									<Input
										type="number"
										step={currency!.step}
										style={{
											maxWidth: "15rem",
											marginRight: "0.2rem",
										}}
										min={0}
										required={true}
										value={price}
										onChange={(e) => {
											e.stopPropagation();

											if (e.target.validity.valid ||
												e.target.value === "") {
												const newPrice = parseFloat(
													e.target.value
												);

												onPriceEdit!(newPrice || 0);
											}
										} } />
								</>
							)}
						 
						</Item>
						{children && active && (
							<SubWrapper>
								<SubContent>{children}</SubContent>
							</SubWrapper>
						)}
					</Wrapper>
				)}
			</Draggable>
			
			</>
		);
	}
}
