import * as React from "react";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../../../mobx/components/index";
import { withTranslation, WithTranslation } from "react-i18next";
import { logger } from "@lib/common";
import { UI } from "../../../../../core/ui";
import { FooterSection, RotateLoader, Sidebar, styled } from "@lib/components";
import { Button } from "@lib/components";
import { BlockInfo } from "@lib/components";
import { Modal } from "@lib/components";
import { ModalContent, ModalTitle } from "@lib/components";
import { APIForm } from "./form";
import { ItemListWrapper, ItemListContent, ItemListItem, ItemListItemMobile } from "@lib/components";
import { FaTrashAlt } from "react-icons/fa";
import { Tag } from "@lib/components";
import { LinkTag } from "@lib/components";
import { SidebarAPIComponent } from "./sidebar-api";
import { AddingStaffWrapper } from "../staff/dashboard-staff-v2";
import { MobileModal, MobileModalBody, MobileModalContent, MobileModalFooter, MobileModalHeader, SalesTaxesAddButton, SettingsActionButtonContainer, SettingsActionButtonWrapper, SettingsFormGroup, SettingsFormGroupInput, SettingsFormInputContainer, SettingsFormLabelContainer, SettingsFormLabelLeftContainer, SettingsFormLabelRightContainer } from "../../../restaurant/views/settings-v2";
import { IconClose, IconDelete, IconDeleteAlt, IconHelp, IconPlusRed } from "../../../../components/v2/icons";
import { APIFormV2 } from "./form-v2";

const DevelopperPageWrapper = styled.div`
  .hidden {
	display: none!important;
  }

  .shown {
	display: block!important;
  }
`;
const APIKeyMobileWrapper = styled.p`
	background-color: #F5F5F5;
	color: #6B7270;
	display: flex;
	padding: 8px;
	align-items: center;
	border-radius: 4px;
`

const DocumentationButton = styled.button`
display: flex;
padding: 8px 16px;
align-items: center;
gap: 4px;
flex: 1 0 0;
align-self: stretch;
appearance: none;
border-radius: 4px;
background: var(--Neutral-White, #FEFEFE);
border: 1px solid #fefefe;
color: var(--Secondary-Smokey-Grey-1, #6B7270);
text-align: center;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 700;
line-height: 24px; /* 200% */
`

interface Props extends WithTranslation { }
interface State {
	modal_active: boolean;
	sidebar_active: boolean;
	modal_index: number;
	delete_index: number | null;
	sidebar_delete_index: number | null;
	delete_loading: boolean;
	keyword: string | null;
}

@inject("store") @observer
class DashboardAPIClass extends MobxComponent<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			modal_active: false,
			sidebar_active: false,
			sidebar_delete_index: null,
			modal_index: -1,
			delete_index: null,
			delete_loading: false,
			keyword: null
		};
	}

	componentWillMount() {
		const { store } = this.injected;
		store.updateApis({ loading: true, error: "" });
	}

	componentDidMount() {
		this.get();
	}

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
		if (this.state.modal_active && !prevState.modal_active) {
			const modal = document.getElementById("api-form-modal");
			if (modal) {
				setTimeout(() => {
					if (modal.scroll) {
						modal.scroll({ top: 0, left: 0, behavior: "auto" });
					}
				}, 100);
			}
		}
	}

	get = async () => {
		const { store } = this.injected;
		store.updateApis({ loading: true, error: "", items: [] });
		try {
			const response = await store.api.api_find({});
			const response2 = await store.api.restaurants();
			if (response.outcome || response2.outcome) {
				store.updateApis({
					loading: false,
					error: response.outcome ? response.message : "Something went wrong, try again or contact us",
					items: [],
				});
			}
			else {
				store.updateRestaurants({
					items: response2.items,
				});
				store.updateApis({
					loading: false,
					error: "",
					items: response.items,
				});
			}
		}
		catch (e) {
			logger.captureException(e);
			store.updateApis({
				loading: false,
				error: "Something went wrong, try again or contact us",
				items: [],
			});
		}
	}

	delete_api = async (index: number | null) => {
		if (index === null) return;
		const { store } = this.injected;
		const { items } = store.apis;
		try {
			this.setState({ delete_loading: true });
			const res = await store.api.api_delete({ _id: items[index]._id });
			if (res.outcome) {
				UI.notification.error(res.message);
				this.setState({ delete_loading: false });
			}
			else {
				UI.notification.success("API deleted");
				const newItems = [...items];
				this.setState({
					delete_index: res.outcome ? index : null,
					delete_loading: false,
				});
				newItems.splice(index, 1);
				store.updateApis({ items: newItems });
			}
		}
		catch (e) {
			logger.captureException(e);
			this.setState({ delete_loading: false });
		}
	}

	loading = () => {
		return (
			<div className="m-t-12">
				<RotateLoader size={3} />
			</div>
		);
	}

	error = () => {
		const { store } = this.injected;
		const { error } = store.apis;
		return (
			<div className="m-t-12 text-center">
				<p className="big error-text m-b-4">{error}</p>
				<Button color="primary" size="sm" onClick={this.get}>
					Try Again
				</Button>
			</div>
		);
	}

	content = () => {
		const { store } = this.injected;
		const { items } = store.apis;
		const { restrictions, setActiveMobileMenu } = store;
		const { keyword } = this.state;
		const isV2 = store.organisation && store.organisation.dashboard === 'v2.1';
		return (
			<DevelopperPageWrapper>
				<div className={ store.organisation && store.organisation.dashboard === 'v2.1' ? 'hidden' : 'shown'}>
					<div className="flex-l-r-center m-b-7">
						<div>
							<h2 className="">API Keys</h2>
							{store.showMainSupport && <LinkTag href="https://apidocs.cloudwaitress.com" target="_blank" className="block m-t-2">View Documentation</LinkTag>}
						</div>
						{restrictions.api && restrictions.api.create && (
							<Button
								color="primary"
								round={true}
								size="xs"
								onClick={() => this.setState({ modal_active: true, modal_index: -1 })}>
								Create API Key
							</Button>
						)}
					</div>

					{items.length === 0 && (
						<div className="text-center m-t-12">
							<BlockInfo className="flex-center max200 center">
								No api keys found
							</BlockInfo>
						</div>
					)}

					{items.length !== 0 && (
						<ItemListWrapper>
							<ItemListContent>
								{items.map((s, i) => (
									<ItemListItem key={i} onClick={() => {
										if (restrictions.api && restrictions.api.edit) {
											this.setState({ modal_index: i, modal_active: true });
										}
									}}>
										<div className="p-lr-4 p-tb-2">
											<p className="m-b-1 big font-semi-bold">{s.name}</p>
											<p className="small">API Key <Tag onClick={(e) => e.stopPropagation()} className="cursor-text m-l-1 p-lr-2">{s.api_key}</Tag></p>
										</div>
										<div>
											<Button
												type="button"
												color="white"
												className="no-border"
												style={{ height: "62px" }}
												paddinglr={8}
												onClick={(e) => {
													if (restrictions.api && restrictions.api.delete) {
														e.stopPropagation();
														this.setState({ delete_index: i });
													}
												}}>
												{restrictions.api && restrictions.api.delete && <FaTrashAlt />}
											</Button>
										</div>
									</ItemListItem>
								))}
							</ItemListContent>
						</ItemListWrapper>
					)}
				</div>
				<div className={ store.organisation && store.organisation.dashboard === 'v2.1' ? 'shown' : 'hidden' }>
				<SettingsFormLabelContainer className="desktop-only" style={{marginBottom: 24}}>
                <SettingsFormLabelLeftContainer>
                    <SettingsFormInputContainer style={{padding: '6px 12px'}}>
                    <input
                        style={{width: 312}}
                        type="text"
                        onChange={(e) => this.setState({ keyword: e.target.value})}
                        placeholder="Search API Key" />
                    </SettingsFormInputContainer>
                    
                </SettingsFormLabelLeftContainer>
				<div style={{display: 'flex', gap: 16}}>
                <SalesTaxesAddButton 
                    variant="red"
                    type='button' 
					style={{padding: '7px 16px', fontSize: 12}}
                    onClick={() => {!isV2 ? this.setState({ modal_active: true, modal_index: -1 }) : setActiveMobileMenu('add-key', 'developers')}}>
                    <IconPlusRed /> Add Api Key
                </SalesTaxesAddButton>
				<DocumentationButton
					style={{padding: '7px 16px', fontSize: 12}}
                   onClick={() => window.open('https://apidocs.cloudwaitress.com', '_blank')}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
						<path fillRule="evenodd" clipRule="evenodd" d="M4 3.125C4 2.82663 4.11853 2.54048 4.3295 2.3295C4.54048 2.11853 4.82663 2 5.125 2H11.875C12.1734 2 12.4595 2.11853 12.6705 2.3295C12.8815 2.54048 13 2.82663 13 3.125V12.125C13 12.4234 12.8815 12.7095 12.6705 12.9205C12.4595 13.1315 12.1734 13.25 11.875 13.25H5.125C4.82663 13.25 4.54048 13.1315 4.3295 12.9205C4.11853 12.7095 4 12.4234 4 12.125V3.125ZM11.875 3.125H5.125V12.125H11.875V3.125ZM6.25 5.9375C6.25 5.78832 6.30926 5.64524 6.41475 5.53975C6.52024 5.43426 6.66332 5.375 6.8125 5.375H10.1875C10.3367 5.375 10.4798 5.43426 10.5852 5.53975C10.6907 5.64524 10.75 5.78832 10.75 5.9375C10.75 6.08668 10.6907 6.22976 10.5852 6.33525C10.4798 6.44074 10.3367 6.5 10.1875 6.5H6.8125C6.66332 6.5 6.52024 6.44074 6.41475 6.33525C6.30926 6.22976 6.25 6.08668 6.25 5.9375ZM6.8125 8.1875C6.66332 8.1875 6.52024 8.24676 6.41475 8.35225C6.30926 8.45774 6.25 8.60082 6.25 8.75C6.25 8.89918 6.30926 9.04226 6.41475 9.14775C6.52024 9.25324 6.66332 9.3125 6.8125 9.3125H8.5C8.64918 9.3125 8.79226 9.25324 8.89775 9.14775C9.00324 9.04226 9.0625 8.89918 9.0625 8.75C9.0625 8.60082 9.00324 8.45774 8.89775 8.35225C8.79226 8.24676 8.64918 8.1875 8.5 8.1875H6.8125Z" fill="#4B4F5E"/>
					</svg>  Documentation
                </DocumentationButton>
				</div>
            </SettingsFormLabelContainer>
					<div>
						{items.length === 0 && (
							<div className="text-center m-t-12">
								<BlockInfo className="flex-center max200 center">
									No api keys found
								</BlockInfo>
							</div>
						)}

						{ store.organisation && store.organisation.dashboard === 'v2.1' && items.length !== 0 && (
							<ItemListWrapper className="v2">
								<ItemListContent className={(store.organisation && store.organisation.dashboard) || ''}>
									{items.filter(s =>  s.name.toLowerCase().includes((keyword && keyword.toLowerCase()) || '')).map((s, i) => (
										<ItemListItemMobile className={(store.organisation && store.organisation.dashboard) || ''} key={i} onClick={() => {
											if (restrictions.api && restrictions.api.edit) {
												this.setState({ modal_index: i}, () => {
													store.setActiveMobileMenu('edit-key', 'developers')
												});
											}
										}}>
											<div className="p-lr-4 p-tb-2" style={{ width: '100%'}}>
												<div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: 8}}>
													<div className="m-b-1 big font-semi-bold" style={{ display: 'flex', alignItems: 'center'}}>{s.name}</div>
													<div>
														<Button
															style={{
																height: 'auto',
																padding: 0
															}}
															type="button"
															color="white"
															className="no-border"
															paddinglr={8}
															onClick={(e) => {
																if (restrictions.api && restrictions.api.delete) {
																	e.stopPropagation();
																	this.setState({ sidebar_delete_index: i });
																}
															}}>
															{restrictions.api && restrictions.api.delete && <IconDeleteAlt />}
														</Button>
											</div>
												</div>
												<APIKeyMobileWrapper className="small" >API Key: <span onClick={(e) => e.stopPropagation()} className="cursor-text m-l-1 p-lr-2">{s.api_key}</span></APIKeyMobileWrapper>
											</div>
											
										</ItemListItemMobile>
									))}
								</ItemListContent>
							</ItemListWrapper>
						)}
					</div>
				</div>
			</DevelopperPageWrapper>
		);
	}

	modal = () => {
		const { store } = this.injected;
		const { items } = store.apis;
		const { modal_active, modal_index } = this.state;

		const type = modal_index === -1 ? "create" : "edit";

		let edit_id = "";
		let initialValues: T.Schema.API.APICESchema = {
			name: "",
			restaurants: [],
		};

		if (type === "edit") {
			const found = items[modal_index];
			edit_id = found._id;
			initialValues = {
				name: found.name,
				restaurants: found.restaurants,
			};
		}

		return (
			<Modal
				id="api-form-modal"
				width={520}
				active={modal_active}
				alignTop={true}
				close={() => this.setState({ modal_active: false, modal_index: -1 })}>
				<ModalTitle className="round-top text-center">
					<h3>{type === "create" ? "Create New Key" : "Edit Key"}</h3>
				</ModalTitle>
				<ModalContent>
					<APIForm
						edit_id={edit_id}
						restaurants={store.restaurants.items.map((r) => ({ value: r._id, label: r.name }))}
						initialValues={initialValues}
						back={() => this.setState({ modal_active: false, modal_index: -1 })}
					/>
				</ModalContent>
			</Modal>
		);

	}

	sidebar = () => {
		const { store } = this.injected;
		const { items } = store.apis;
		const { sidebar_active, modal_index } = this.state;

		const type = modal_index === -1 ? "create" : "edit";

		let edit_id = "";
		let initialValues: T.Schema.API.APICESchema = {
			name: "",
			restaurants: [],
		};

		if (type === "edit") {
			const found = items[modal_index];
			edit_id = found._id;
			initialValues = {
				name: found.name,
				restaurants: found.restaurants,
			};
		}

		return (
			<Sidebar
				isOpen={!!sidebar_active}
				headerTitle={type === "create" ? "Create New Key" : "Edit Key"}
				onClose={() => this.setState({ sidebar_active: false, modal_index: -1 })}
				width={424}>
					<div style={{ padding: '16px'}}>
						<APIForm
							edit_id={edit_id}
							restaurants={store.restaurants.items.map((r) => ({ value: r._id, label: r.name }))}
							initialValues={initialValues}
							back={() => this.setState({ sidebar_active: false, modal_index: -1 })}
						/>
					</div>
					
			</Sidebar>
		);

	}

	sidebar_delete = () => {
		const { sidebar_delete_index, delete_loading } = this.state;
		const { store } = this.injected;
		const { restrictions } = store;
		const { items } = store.apis;
		const handleDeleteApi = () => {
			this.delete_api(sidebar_delete_index);
			this.setState({ sidebar_delete_index: null });
		}
		let active = false;
		let item;
		if (sidebar_delete_index !== null) {
			active = true;
			item = items[sidebar_delete_index];
		}

		if (!restrictions.api || !restrictions.api.delete) {
			return null;
		}

		if(active) {
			return (
				<MobileModal>
					<MobileModalContent size="sm">
						<MobileModalHeader>
							Delete Key
							<span
								onClick={() => this.setState({ sidebar_delete_index: null})}>
								<IconClose />
							</span>
						</MobileModalHeader>
						<MobileModalBody>
						<p style={{fontSize: 14}}>{item ? item.name : ""}</p>
						<p style={{fontSize: 14}}>Are you sure you want to delete this api key. Once deleted, it cannot be recovered</p>
						</MobileModalBody>
						<MobileModalFooter>
							<SettingsActionButtonWrapper 
								onClick={handleDeleteApi}
								position='relative'
								noborder nopadding>
								<button>
								{delete_loading && <RotateLoader size={2} color="white" />}
								{!delete_loading && "Confirm"}
								</button>
							</SettingsActionButtonWrapper>
						</MobileModalFooter>
					</MobileModalContent>
				</MobileModal>
				// <Sidebar
				// 	headerTitle="Delete API Key"
				// 	width={window.innerWidth}
				// 	onClose={() => this.setState({ sidebar_delete_index: null})}
				// 	isOpen={active}>
				// 		<p style={{ padding: '0 16px' }}>{item ? item.name : ""}</p>
				// 		<p style={{ padding: '16px' }}>Are you sure you want to delete this api key. Once deleted, it cannot be recovered</p>
				// 	<FooterSection style={{ width: '100%', justifyContent: 'center' }}>
				// 		<Button className="m-r-2 width100 " onClick={() => this.setState({ sidebar_delete_index: null })}>Cancel</Button>
				// 		<Button className="width100" color="primary" onClick={handleDeleteApi}>
				// 			{delete_loading && <RotateLoader size={2} color="white" />}
				// 			{!delete_loading && "Confirm"}
				// 		</Button>
				// 	</FooterSection>
				// </Sidebar>
			);
		}
		return null
	}
	modal_delete = () => {
		const { delete_index, delete_loading } = this.state;
		const { store } = this.injected;
		const { restrictions } = store;
		const { items } = store.apis;

		let active = false;
		let item;
		if (delete_index !== null) {
			active = true;
			item = items[delete_index];
		}

		if (!restrictions.api || !restrictions.api.delete) {
			return null;
		}

		return (
			<Modal
				width="sm"
				close={() => this.setState({ delete_index: null })}
				active={active}>
				<ModalContent>
					<h4 className="m-b-1">Delete API Key</h4>
					<p className="underline">{item ? item.name : ""}</p>
				</ModalContent>
				<ModalContent>
					<p className="lhp big">Are you sure you want to delete this api key. Once deleted, it cannot be recovered</p>
				</ModalContent>
				<ModalContent className="flex-right">
					<Button className="m-r-2 width100 max128" onClick={() => this.setState({ delete_index: null })}>Cancel</Button>
					<Button className="width100 max100px" color="primary" onClick={() => this.delete_api(delete_index)}>
						{delete_loading && <RotateLoader size={2} color="white" />}
						{!delete_loading && "Delete"}
					</Button>
				</ModalContent>
			</Modal>
		);
	}
	
	form2 = () => {
		const { store } = this.injected;
		const { items } = store.apis;
		const { sidebar_active, modal_index } = this.state;

		const type = modal_index === -1 ? "create" : "edit";

		let edit_id = "";
		let initialValues: T.Schema.API.APICESchema = {
			name: "",
			restaurants: [],
		};

		if (type === "edit") {
			const found = items[modal_index];
			edit_id = found._id;
			initialValues = {
				name: found.name,
				restaurants: found.restaurants,
			};
		}

		return (
			<div style={{background: '#fefefe'}}>
				<APIFormV2
					edit_id={edit_id}
					restaurants={store.restaurants.items.map((r) => ({ value: r._id, label: r.name }))}
					initialValues={initialValues}
					back={() =>{ 
					store.setActiveMobileMenu(
						store.activeMobileMenu.referrer.pop()!,
						Array.from(new Set(store.activeMobileMenu.referrer))
					)}}
				/>
			</div>
		);

	}
	render() {
		const { store } = this.injected;
		const { loading, error, items } = store.apis;
		const { activeMobileMenu } = store
		return (
			<div>
				{loading && this.loading()}
				{(!loading && error) && this.error()}
				{(!loading && !error && activeMobileMenu.referrer.length == 0) && this.content()}
				{this.modal()}
				{this.modal_delete()}
				{this.sidebar_delete()}
				{this.sidebar()}
				{activeMobileMenu.referrer.includes('developers') &&  this.form2()}
				{/* { store.organisation && store.organisation.dashboard === 'v2.1' &&
				<SidebarAPIComponent 
					active={this.state.sidebar_active} 
					index={this.state.modal_index} 
					items={items} 
					restaurants={store.restaurants.items} 
					handleClose={() => this.setState({ sidebar_active: false, modal_index: -1 })}/>} */}
			</div>
		);
	}

}

export const DashboardAPI = withTranslation()(DashboardAPIClass);
