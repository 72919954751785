import * as React from "react";
import styled from "styled-components";

const ArrowIconWrapper = styled.div`
    svg{
        position: absolute;
        top: 50%;
        right: 12px;
        transform: translateY(-50%);
        display: inline-block;
    }
`;
export const ArrowDownIcon = () => (
    <ArrowIconWrapper>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M12 14.9748C11.8667 14.9748 11.7374 14.9498 11.612 14.8998C11.4867 14.8498 11.3827 14.7831 11.3 14.6998L6.70005 10.0998C6.51672 9.91647 6.42505 9.68314 6.42505 9.3998C6.42505 9.11647 6.51672 8.88314 6.70005 8.6998C6.88338 8.51647 7.11672 8.4248 7.40005 8.4248C7.68338 8.4248 7.91672 8.51647 8.10005 8.6998L12 12.5998L15.9 8.6998C16.0834 8.51647 16.3167 8.4248 16.6 8.4248C16.8834 8.4248 17.1167 8.51647 17.3 8.6998C17.4834 8.88314 17.575 9.11647 17.575 9.3998C17.575 9.68314 17.4834 9.91647 17.3 10.0998L12.7 14.6998C12.6 14.7998 12.4917 14.8708 12.375 14.9128C12.2584 14.9548 12.1334 14.9755 12 14.9748Z" fill="#4B4F5E" />
        </svg>
    </ArrowIconWrapper>

);