import React, { useState } from 'react';
import { SettingsFormGroup } from '..';
import { FaPlus } from 'react-icons/fa';
import { IconArrowForward, IconBack } from '../../../../../components/v2/icons';
import styled from 'styled-components';
import { useStores } from '../../../../../../mobx/useStores';
import { PaymentMethods, Untrusive, defaultCardConnectSettings } from '@lib/common';
import { difference } from 'lodash';
import { SettingsFormPaymentsATHMovil } from '../../settings/forms/payments/ath_movil';
import { SettingsFormPaymentsAuthorized } from '../../settings/forms/payments/authorized';
import { SettingsFormPaymentsBamboraAPAC } from '../../settings/forms/payments/bambora_apac';
import { SettingsFormPaymentsBamboraNa } from '../../settings/forms/payments/bambora_na';
import { SettingsFormPaymentsStripeBancontact } from '../../settings/forms/payments/bancontact';
import { SettingsFormPaymentsCabbagePay } from '../../settings/forms/payments/cabbagepay';
import { SettingsFormPaymentsCard } from '../../settings/forms/payments/card';
import { SettingsFormPaymentsCardConnect } from '../../settings/forms/payments/cardconnect';
import { SettingsFormPaymentsCash } from '../../settings/forms/payments/cash';
import { SettingsFormPaymentsCheckout } from '../../settings/forms/payments/checkout';
import { SettingsFormPaymentsCheckoutApplePay } from '../../settings/forms/payments/checkout_apple_pay';
import { SettingsFormPaymentsCheckoutGooglePay } from '../../settings/forms/payments/checkout_google_pay';
import { SettingsFormPaymentsElavon } from '../../settings/forms/payments/elavon';
import { SettingsFormPaymentsFiserv } from '../../settings/forms/payments/fiserv';
import { SettingsFormPaymentsGkash } from '../../settings/forms/payments/gkash';
import { SettingsFormPaymentsGravity } from '../../settings/forms/payments/gravity';
import { SettingsFormPaymentsIpay88 } from '../../settings/forms/payments/ipay88';
import { SettingsFormPaymentsPaygate } from '../../settings/forms/payments/paygate';
import { SettingsFormPaymentsPayMongo } from '../../settings/forms/payments/paymongo';
import { SettingsFormPaymentsPayPal } from '../../settings/forms/payments/paypal';
import { SettingsFormPaymentsPayWay } from '../../settings/forms/payments/payway';
import { SettingsFormPaymentsPesapal } from '../../settings/forms/payments/pesapal';
import { SettingsFormPaymentsPoliPay } from '../../settings/forms/payments/polipay';
import { SettingsFormPaymentsRazorPay } from '../../settings/forms/payments/razor_pay';
import { SettingsFormPaymentsRedDot } from '../../settings/forms/payments/red_dot';
import { SettingsFormPaymentsSGE } from '../../settings/forms/payments/sgepay';
import { SettingsFormPaymentsStripe } from '../../settings/forms/payments/stripe';
import { SettingsFormPaymentsStripeDigitalWallet } from '../../settings/forms/payments/stripe_digital_wallet';
import { SettingsFormPaymentsSwish } from '../../settings/forms/payments/swish';
import { SettingsFormPaymentsVivaWallet } from '../../settings/forms/payments/viva_wallet';
import { WithTranslation, useTranslation, withTranslation } from 'react-i18next';
import { AddPaymentMethod } from './add-payment-method';
import { MainContainer, MenuItem } from '../../../../../components/v2/layout.c';
import { inject, observer, useObserver } from 'mobx-react';
import { MobxComponent } from '../../../../../../mobx/components';
import shortid from 'shortid';
import Avatar from "react-avatar";

const AddPaymentMethodWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-item: center;
  gap: 16px;
  padding: 12px;
  width: 100%;
`;
const PlusIconWrapper = styled.div`
  display: flex;
  width: 48px;
  height: 48px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 99px;
  background: #009100;
`;
const AddPaymentMethodButton = styled.div`
  flex-grow: 8;
  align-self: center;
  color: #4b4f5e;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`;
const PaymentMethodAddedWrapper = styled.div`
  width: 100%;
`;
const PaymentMethodAdded = styled.div`
  display: flex;
  padding: 12px;
  align-items: flex-start;
  gap: 16px;
  border-radius: 4px;
  background: var(--Neutral-White, #fefefe);
  justify-content: space-between;
  width: 100%;
`;
const IconPaymentAddedWrapper = styled.div`
  display: flex;
  width: 48px;
  height: 48px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: #f1f4f7;
  border-radius: 99px;
`;
const SettingFormsWrapper = styled.div`
  background-color: #fefefe;
  top: 0;
  z-index: 1000;
  width: 100%;
  
`;

export const PaymentMethodWrapper = styled.div`
    width: 100%;
    transition: width .3s ease-in-out;
    position: relative;
    background-color: #fff;
    margin-top: 16px;
    .mobile-view {
    display: none;
  }
  
    @media (max-width: 767px) {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 99;
      height: 100vh;
      overflow: scroll;
      padding-bottom: 50px;
      &.desktop-view {
      display: none;
      }
      &.mobile-view {
        display: flex;
        padding: none;
      }
`
const PaymentFormWrapper = styled.div`
  color: #4b4f5e;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
`

const forms: any = {
  cash: <SettingsFormPaymentsCash isMobile />,
  card: <SettingsFormPaymentsCard isMobile />,
  stripe: <SettingsFormPaymentsStripe isMobile />,
  bancontact: <SettingsFormPaymentsStripeBancontact isMobile />,
  paypal: <SettingsFormPaymentsPayPal isMobile />,
  cardconnect: <SettingsFormPaymentsCardConnect isMobile />,
  paygate_payweb: <SettingsFormPaymentsPaygate isMobile />,
  poli_pay: <SettingsFormPaymentsPoliPay isMobile />,
  ipay88: <SettingsFormPaymentsIpay88 isMobile />,
  bambora_apac: <SettingsFormPaymentsBamboraAPAC isMobile />,
  pesapal: <SettingsFormPaymentsPesapal isMobile />,
  ath_movil: <SettingsFormPaymentsATHMovil isMobile />,
  checkout: <SettingsFormPaymentsCheckout isMobile />,
  checkout_apple_pay: <SettingsFormPaymentsCheckoutApplePay isMobile />,
  checkout_google_pay: <SettingsFormPaymentsCheckoutGooglePay isMobile />,
  stripe_digital_wallet: <SettingsFormPaymentsStripeDigitalWallet isMobile />,
  razor_pay: <SettingsFormPaymentsRazorPay isMobile />,
  gravity: <SettingsFormPaymentsGravity isMobile />,
  authorized: <SettingsFormPaymentsAuthorized isMobile />,
  bambora_na: <SettingsFormPaymentsBamboraNa isMobile />,
  elavon: <SettingsFormPaymentsElavon isMobile />,
  red_dot: <SettingsFormPaymentsRedDot isMobile />,
  gkash: <SettingsFormPaymentsGkash isMobile />,
  sgepay: <SettingsFormPaymentsSGE isMobile />,
  payway: <SettingsFormPaymentsPayWay isMobile />,
  swish: <SettingsFormPaymentsSwish isMobile />,
  fiserv: <SettingsFormPaymentsFiserv isMobile />,
  viva_wallet: <SettingsFormPaymentsVivaWallet isMobile />,
  cabbagepay: <SettingsFormPaymentsCabbagePay isMobile />,
  paymongo: <SettingsFormPaymentsPayMongo isMobile />,
};

interface Props extends WithTranslation {}
interface State {
  allMethods: string[];
  openAddPaymentMethod: boolean;
  selectedMethod: string | null;
  addPaymentType: string;
}

@inject('store')
@observer
export class PaymentSettingsClass extends MobxComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    const { store } = this.injected;
    const restaurant = store.restaurant!;
    const payments = restaurant.settings.payments;
    const methods = Object.keys(payments).map(key => key);

    this.state = {
      allMethods: difference([...PaymentMethods, 'custom'], methods),
      openAddPaymentMethod: false,
      selectedMethod: null,
      addPaymentType: ''
    };
  }

  handleAddMethod = () => {
    const { selectedMethod, allMethods } = this.state;
    if (selectedMethod) {
      this.setState({
        allMethods: allMethods.filter(method => method !== selectedMethod),
        openAddPaymentMethod: false,
      });
      this.addPaymentMethod();
      this.setState({ openAddPaymentMethod: false, selectedMethod: null })
    }
  };
  addPaymentMethod = async () => {
    const type = this.state.selectedMethod;

    let paymentData: any | undefined;
    if (type === 'cash' || type === 'card' || type === 'custom') {
      paymentData = {
        enabled: false,
        services: [],
        label: type === 'custom' ? 'Custom Payment Method' : '',
      } as T.Schema.Restaurant.Payments.RestaurantPayment;
    } else if (type === 'stripe') {
      paymentData = {
        enabled: false,
        label: '',
        services: [],
        currency: '',
        secret_key: '',
        publishable_key: '',
        min_order: 0,
        stripe_layout: '',
        themes: '',
        stripe_version: ''
      } as T.Schema.Restaurant.Payments.RestaurantPaymentStripe;
    } else if (type === 'paypal') {
      paymentData = {
        enabled: false,
        label: '',
        services: [],
        currency: '',
        client_id: '',
        secret: '',
      } as T.Schema.Restaurant.Payments.RestaurantPaymentPayPal;
    } else if (type === 'cardconnect') {
      paymentData = defaultCardConnectSettings();
    } else if (type === 'paygate_payweb') {
      paymentData = {
        enabled: false,
        label: '',
        services: [],
        currency: '',
        secret: '',
        paygate_id: '',
      } as T.Schema.Restaurant.Payments.RestaurantPaymentPaygatePayweb;
    } else if (type === 'poli_pay') {
      paymentData = {
        enabled: false,
        label: '',
        services: [],
        currency: '',
        merchant_id: '',
        auth_code: '',
      } as T.Schema.Restaurant.Payments.RestaurantPaymentPoliPay;
    } else if (type === 'ipay88') {
      paymentData = {
        enabled: false,
        label: '',
        services: [],
        currency: '',
        merchant_code: '',
        merchant_key: '',
      } as T.Schema.Restaurant.Payments.RestaurantPaymentIpay88;
    } else if (type === 'bambora_apac') {
      paymentData = {
        enabled: false,
        testing: false,
        label: '',
        services: [],
        currency: '',
        merchant_id: '',
        api_username: '',
        api_password: '',
      } as T.Schema.Restaurant.Payments.RestaurantPaymentBamboraAPAC;
    } else if (type === 'pesapal') {
      paymentData = {
        enabled: false,
        label: 'Pesapal',
        services: [],
        testing: false,
        consumer_key: '',
        consumer_secret: '',
        currency: '',
      } as T.Schema.Restaurant.Payments.RestaurantPaymentPesapal;
    } else if (type === 'ath_movil') {
      paymentData = {
        enabled: false,
        env: '',
        label: 'ATH Móvil',
        public_token: '',
        private_token: '',
        theme: 'btn',
        lang_code: '',
        timeout: 0,
      } as T.Schema.Restaurant.Payments.RestaurantPaymentATHMovil;
    } else if (type === 'checkout') {
      paymentData = {
        enabled: false,
        label: 'Credit Card',
        services: [],
        currency: '',
        checkout_public_key: '',
        checkout_secret_key: '',
      } as T.Schema.Restaurant.Payments.RestaurantPaymentCheckout;
    } else if (type === 'checkout_apple_pay') {
      paymentData = {
        enabled: false,
        label: 'Apple Pay (Checkout.com)',
        services: [],
        currency: '',
        checkout_public_key: '',
        checkout_secret_key: '',
        apple_pay_merchant_id: '',
        apple_pay_merchant_domain: '',
        apple_pay_merchant_identity_cert: '',
        apple_pay_merchant_identity_key: '',
      } as T.Schema.Restaurant.Payments.RestaurantPaymentCheckoutApplePay;
    } else if (type === 'checkout_google_pay') {
      paymentData = {
        enabled: false,
        label: 'Google Pay (Checkout.com)',
        services: [],
        currency: '',
        checkout_public_key: '',
        checkout_secret_key: '',
      } as T.Schema.Restaurant.Payments.RestaurantPaymentCheckoutGooglePay;
    } else if (type === 'stripe_digital_wallet') {
      let stripedigitalpay_settings;
      const restaurant_id = this.injected.store.restaurant?._id!;
      if (restaurant_id) {
        const response = await this.injected.store.api.dashboard_restaurant_payment_prefill_stripedigitalpay({
          restaurant_id,
        });
        if (!response.outcome) {
          stripedigitalpay_settings =
            response.data as T.Schema.Restaurant.Payments.RestaurantPaymentStripeDigitalWallet;
        }
      }
      if (stripedigitalpay_settings) {
        paymentData = stripedigitalpay_settings;
        paymentData.enabled = false;
        paymentData.label = 'Google/Apple Pay';
      } else {
        paymentData = {
          enabled: false,
          label: 'Google/Apple Pay',
          services: [],
          currency: '',
          stripe_public_key: '',
          stripe_secret_key: '',
          stripe_domain_association: '',
        } as T.Schema.Restaurant.Payments.RestaurantPaymentStripeDigitalWallet;
      }
    } else if (type === 'razor_pay') {
      paymentData = {
        enabled: false,
        label: 'Razor Pay',
        services: [],
        currency: '',
        razor_pay_public_key: '',
        razor_pay_secret_key: '',
      } as T.Schema.Restaurant.Payments.RestaurantPaymentRazorPay;
    }
    //NEWPAYMENTTYPE
    else if (type === 'gravity') {
      paymentData = {
        enabled: false,
        label: 'Credit Card',
        services: [],
        // currency: "",
        gravity_oid: '',
        gravity_auth_key: '',
        gravity_environment: 'https://api.emergepay.chargeitpro.com/virtualterminal/v1',
        gravity_assets: 'https://assets.emergepay.chargeitpro.com',
      } as T.Schema.Restaurant.Payments.RestaurantPaymentGravity;
    } else if (type === 'authorized') {
      paymentData = {
        enabled: false,
        label: 'Credit Card',
        services: [],
        // currency: "",
        authorized_login_id: '',
        authorized_transaction_id: '',
      } as T.Schema.Restaurant.Payments.RestaurantPaymentAuthorized;
    } else if (type == 'bambora_na') {
      paymentData = {
        enabled: false,
        label: 'Worldline',
        services: [],
        merchant_id: '',
        passcode: '',
        avs: false,
        avs_only_postal_code: true,
      } as T.Schema.Restaurant.Payments.RestaurantPaymentBamboraNA;
    } else if (type === 'elavon') {
      paymentData = {
        enabled: false,
        label: 'Elavon',
        services: [],
        ssl_merchant_id: '',
        ssl_user_id: '',
        ssl_pin: '',
      } as T.Schema.Restaurant.Payments.RestaurantPaymentElavon;
    } else if (type === 'red_dot') {
      paymentData = {
        enabled: false,
        label: 'Red Dot Payment',
        services: [],
        testing: false,
        merchant_id: '',
        secret_key: '',
      } as T.Schema.Restaurant.Payments.RestaurantPaymentRedDot;
    } else if (type === 'gkash') {
      paymentData = {
        enabled: false,
        label: 'Gkash',
        services: [],
        testing: false,
        merchant_id: '',
        signature_key: '',
      } as T.Schema.Restaurant.Payments.RestaurantPaymentGkash;
    } else if (type === 'sgepay') {
      paymentData = {
        enabled: false,
        label: 'Credit Card',
        services: [],
        testing: false,
        Mid: '',
        RequestId: '',
      } as T.Schema.Restaurant.Payments.RestaurantPaymentSGE;
    } else if (type === 'payway') {
      paymentData = {
        enabled: false,
        label: 'ABA Pay',
        services: [],
        testing: false,
        merchant_id: '',
        api_key: '',
      } as T.Schema.Restaurant.Payments.RestaurantPaymentPayway;
    } else if (type === 'bancontact') {
      paymentData = {
        enabled: false,
        label: '',
        services: [],
        currency: 'EUR',
        min_order: 0,
        connect_account_id: '',
        custom_payment_email: '',
        disable_email_receipt: false,
      } as T.Schema.Restaurant.Payments.RestaurantPaymentStripeBancontact;
    } else if (type === 'swish') {
      paymentData = {
        enabled: false,
        label: 'Swish',
        services: [],
        env: 'production',
        number: '',
        cert: '',
        key: '',
      } as T.Schema.Restaurant.Payments.RestaurantPaymentSwish;
    } else if (type === 'fiserv') {
      paymentData = {
        enabled: false,
        label: 'Fiserv',
        services: [],
        env: 'production',
        api_key: '',
        api_secret: '',
        store_name: '',
        connect_url: '',
        connect_secret: '',
      } as T.Schema.Restaurant.Payments.RestaurantPaymentFiserv;
    } else if (type === 'viva_wallet') {
      paymentData = {
        enabled: false,
        label: 'Viva Wallet',
        services: [],
        env: 'production',
        merchant_id: '',
        api_key: '',
        oauth_client_id: '',
        oauth_client_secret: '',
        is_isv_partner: false,
        isv_amount: 0,
      } as T.Schema.Restaurant.Payments.RestaurantPaymentVivaWallet;
    } else if (type === 'cabbagepay') {
      paymentData = {
        enabled: false,
        label: 'CabbagePay',
        services: [],
        env: 'production',
        client_id: '',
        client_secret: '',
        connected_account: '',
      } as T.Schema.Restaurant.Payments.RestaurantPaymentCabbagePay;
    } else if (type === 'paymongo') {
      paymentData = {
        enabled: false,
        label: 'PayMongo',
        services: [],
        public_key: '',
        secret_key: '',
        enabled_payment_methods: [],
      } as T.Schema.Restaurant.Payments.RestaurantPaymentPayMongo;
    }
    let key: any;
    if(type){
      key = type === 'custom' ? shortid.generate() : type;
    }

    this.setState({ addPaymentType: '' });
    // this.props.onClose();

    Untrusive.start();

    await this.saveRestaurant({
      successMsg: 'Payment method created',
      errorMsg: 'Failed to create payment method, try again or contact us',
      process: r => {
        r.settings.payments[key] = paymentData;
        return {
          update: {
            $set: {
              'settings.payments': r.settings.payments,
            },
          },
        };
      },
    });

    Untrusive.stop();
  };

  render() {
    const { openAddPaymentMethod, selectedMethod, allMethods } = this.state;
    const { store, t } = this.injected;
    const restaurant = store.restaurant!;
    const payments = restaurant.settings.payments;
    const methods = Object.keys(payments).map(key => key);
    const checkAvatarName = (name: string) => {
      const sanitized = name.replace(/[^a-zA-Z\s]/g, '');
      const words = sanitized.split(' ');
      const initials = words.slice(0, 2).map(word => word.charAt(0)).join('');
      return initials;
    };
    return (
      <div style={{paddingBottom: 83, width: "100%"}}>
        {!openAddPaymentMethod ? (
          <>
            {!selectedMethod && <><SettingsFormGroup style={{ borderBottom: '1px solid #DFDFDF' }}>
              <AddPaymentMethodWrapper onClick={() => this.setState({ openAddPaymentMethod: true })}>
                <PlusIconWrapper>
                  <FaPlus />
                </PlusIconWrapper>
                <AddPaymentMethodButton>Add Payment Method</AddPaymentMethodButton>
                <div style={{ padding: '12px' }}>
                  <IconArrowForward />
                </div>
              </AddPaymentMethodWrapper>
            </SettingsFormGroup><SettingsFormGroup>
                <PaymentMethodAddedWrapper>
                  {methods.map(method => (
                    <PaymentMethodAdded key={method} onClick={() => this.setState({ selectedMethod: method })}>
                      {/* <IconPaymentAddedWrapper>icon</IconPaymentAddedWrapper> */}
                      <Avatar name={checkAvatarName(t(`constants.payment.backend_method.${method}`))} size="40" round={true} style={{ marginRight: '10px' }} />
                      <div style={{ alignSelf: 'center', flexGrow: 8 }}>
                        {t(`constants.payment.backend_method.${method}`)}
                      </div>
                      <div style={{ alignSelf: 'center' }}>
                        <IconArrowForward />
                      </div>
                    </PaymentMethodAdded>
                  ))}
                </PaymentMethodAddedWrapper>
              </SettingsFormGroup></>}

            {selectedMethod && (
              <SettingFormsWrapper>
                <PaymentMethodWrapper>
                  <MenuItem className='payment-method-style'>
                    <span  onClick={() => this.setState({ selectedMethod: null })}>
                    <IconBack />
                    </span>
                    <span style={{ display: 'flex', justifyContent: 'center', flexGrow: 8, fontWeight: 600 }}>
                      {t(`constants.payment.backend_method.${selectedMethod}`)}
                    </span>
                  </MenuItem>
                  <div className='mobile-view' >
                    {selectedMethod && forms[selectedMethod]}
                  </div>
                  <PaymentFormWrapper className='desktop-view'>
                    {selectedMethod && forms[selectedMethod]}
                  </PaymentFormWrapper>
                </PaymentMethodWrapper>
              </SettingFormsWrapper>
            )}
          </>
        ) : (
          <SettingsFormGroup>
            <PaymentMethodWrapper>
              <MenuItem className='payment-method-style'>
                <div onClick={() => this.setState({ openAddPaymentMethod: false, selectedMethod: null })}>
                <IconBack />
                </div>
                <span style={{ display: 'flex', justifyContent: 'center', flexGrow: 8, fontWeight: 600, paddingRight: '10px' }}>
                  Choose Payment Method
                </span>
              </MenuItem>
              <AddPaymentMethod
              allMethods={allMethods}
              selectedMethod={selectedMethod}
              onMethodSelect={(method: string | null) => this.setState({ selectedMethod: method })}
              onAddMethod={this.handleAddMethod} />
            </PaymentMethodWrapper>
          </SettingsFormGroup>
           
        )}
      </div>
    );
  }
}

export const PaymentSettings = withTranslation()(PaymentSettingsClass);
