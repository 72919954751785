import React, { useState } from "react";
import { SettingsWebsiteWrapper } from "./themes";
import { OptionalText, SettingsActionButtonContainer, SettingsActionButtonWrapper, SettingsFormGroup, SettingsFormGroupInput, SettingsFormInputContainer, SettingsFormLabelContainer, SettingsFormLabelLeftContainer, SettingsFormLabelRightContainer, SettingsSwitchContainer } from "..";
import { CookieColorPickerContent, CookieColorPickerWrapper, CookieColorsContainer, CookieColorsRow } from "../policies-agreements";
import { ColorPicker, FormUpload, RotateLoader, Switch } from "@lib/components";
import { CoreUtils } from "@lib/common";
import { IconHelp, IconUploadImage } from "../../../../../components/v2/icons";
import { ImageLogoContainer } from "../email-notification";
import { RestaurantForm } from "../../../../../../mobx/components/restaurant-form";
import { useStores } from "../../../../../../mobx/useStores";
import { UI } from "../../../../../../core/ui";
import { FastField } from "formik";
interface Props {
    activeDesignMode?: boolean
}
type FormValues = T.Schema.Restaurant.RestaurantSchema["website"]["social_links"];

export const SettingsWebsiteSocial = (props: Props) => {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const { activeDesignMode } = props
    const store = useStores()
    const r = store.restaurant!;
    let initialValues = r.website.social_links
    return (
        <RestaurantForm<FormValues>
        submit={async (r, values) => {
            r.website.social_links = values;
            const update = { $set: { "website.social_links": r.website.social_links } };
            return { r, update };
        }}
        initialValues={initialValues}
        onError={() => UI.notification.error("An error occurred")}
        onSuccessMessage="Settings Updated"
        width="100%"
    >
        {({ form, error }) => {
            const { isSubmitting, setFieldValue, setFieldTouched } = form;
            return (
        <SettingsWebsiteWrapper>
            <SettingsFormGroup>
                <SettingsFormGroupInput>
                    <SettingsFormLabelContainer>
                        <SettingsFormLabelLeftContainer>Facebook <OptionalText>(Optional)</OptionalText></SettingsFormLabelLeftContainer>
                    </SettingsFormLabelContainer>
                    <SettingsFormInputContainer>
                        
							<FastField
								name="facebook"
								render={({ field }: any) => (
										<input type="url" {...field} />
									
								)}
							/>
                    </SettingsFormInputContainer>
                </SettingsFormGroupInput>
                <SettingsFormGroupInput>
                    <SettingsFormLabelContainer>
                        <SettingsFormLabelLeftContainer>Instagram <OptionalText>(Optional)</OptionalText></SettingsFormLabelLeftContainer>
                    </SettingsFormLabelContainer>
                    <SettingsFormInputContainer>
                                                
							<FastField
								name="instagram"
								render={({ field }: any) => (
										<input type="url" {...field} />
									
								)}
							/>
                    </SettingsFormInputContainer>
                </SettingsFormGroupInput>
                <SettingsFormGroupInput>
                    <SettingsFormLabelContainer>
                        <SettingsFormLabelLeftContainer>Twitter <OptionalText>(Optional)</OptionalText></SettingsFormLabelLeftContainer>
                    </SettingsFormLabelContainer>
                    <SettingsFormInputContainer>
                                                
							<FastField
								name="twitter"
								render={({ field }: any) => (
										<input type="url" {...field} />
									
								)}
							/>
                    </SettingsFormInputContainer>
                </SettingsFormGroupInput>
                <SettingsFormGroupInput>
                    <SettingsFormLabelContainer>
                        <SettingsFormLabelLeftContainer>Pinterest <OptionalText>(Optional)</OptionalText></SettingsFormLabelLeftContainer>
                    </SettingsFormLabelContainer>
                    <SettingsFormInputContainer>
                                               
							<FastField
								name="pinterest"
								render={({ field }: any) => (
										<input type="url" {...field} />
									
								)}
							/>
                    </SettingsFormInputContainer>
                </SettingsFormGroupInput>
                <SettingsFormGroupInput>
                    <SettingsFormLabelContainer>
                        <SettingsFormLabelLeftContainer>Youtube <OptionalText>(Optional)</OptionalText></SettingsFormLabelLeftContainer>
                    </SettingsFormLabelContainer>
                    <SettingsFormInputContainer>
                                                
							<FastField
								name="youtube"
								render={({ field }: any) => (
										<input type="url" {...field} />
									
								)}
							/>
                    </SettingsFormInputContainer>
                </SettingsFormGroupInput>
                <SettingsFormGroupInput>
                    <SettingsFormLabelContainer>
                        <SettingsFormLabelLeftContainer>Tiktok <OptionalText>(Optional)</OptionalText></SettingsFormLabelLeftContainer>
                    </SettingsFormLabelContainer>
                    <SettingsFormInputContainer>
                                                
							<FastField
								name="tiktok"
								render={({ field }: any) => (
										<input type="url" {...field} />
									
								)}
							/>
                    </SettingsFormInputContainer>
                </SettingsFormGroupInput>
            </SettingsFormGroup>
            <SettingsActionButtonContainer activeDesignMode={activeDesignMode}>
                <SettingsActionButtonWrapper>
                    <button type="submit" disabled={isSubmitting}>
                        {isSubmitting && <RotateLoader size={2} color="white" />}
                        {!isSubmitting && "Save"}
                    </button>
                </SettingsActionButtonWrapper>
            </SettingsActionButtonContainer>
        </SettingsWebsiteWrapper>
            )}}
        </RestaurantForm>

    )
}
