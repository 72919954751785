export const updateOptionsUtil = (
  id: string,
  optionData: TOptionItem[],
  setOptions: (value: TOptionItem[]) => void,
  multipleSelection: boolean
) => {
  const matchedOption = optionData.find(item => item.id === id);
  if (multipleSelection) {
    const options = optionData.map(item => {
      if (matchedOption && item.id === matchedOption.id) {
        return {
          ...item,
          isActive: !item.isActive
        }
      }
      return item;
    });
    setOptions(options);
  } else {
    const options = optionData.map(item => {
      if (matchedOption) {
        if (item.id === matchedOption.id) {
          return {
          ...item,
          isActive: !item.isActive
        }
        } else {
          return {
          ...item,
          isActive: false
        }
        }
      }
      return item;
    });
    setOptions(options);
  }
};

export const deselectAllOptions = (optionData: TOptionItem[], setOptions: (value: TOptionItem[]) => void) => {
  setOptions(
    optionData.map(item => {
      item.isActive = false;
      return item;
    })
  );
};
const mapServiceKeyToName = (key: string) => {
  switch (key) {
    case 'pickup':
      return 'Pickups';
    case 'delivery':
      return 'Deliveries';
    case 'dine_in':
      return 'Dine-Ins';
    case 'booking':
      return 'Table Bookings';
  }
};
export const getPauseTime = (restaurant: T.Schema.Restaurant.RestaurantSchema, pause_end: number) => {
  if(pause_end){
      const pauseTime = pause_end - Date.now();
      const minutes = Math.floor(pauseTime / 1000);
      return minutes;
  }
  if (
    restaurant.settings &&
    restaurant.settings.business &&
    restaurant.settings.business.operations &&
    restaurant.settings.business.operations.pause_end
  ) {
    const pauseEnd = restaurant.settings.business.operations?.pause_end;
    if (pauseEnd) {
      const pauseTime = pauseEnd - Date.now();
      const minutes = Math.floor(pauseTime / 1000);
      return minutes;
    }
  }
  return 0;
};
export const getPauseServices = (restaurant: T.Schema.Restaurant.RestaurantSchema) => {
  if (
    restaurant.settings &&
    restaurant.settings.business &&
    restaurant.settings.business.operations &&
    restaurant.settings.business.operations.services
  ) {
    const services = restaurant.settings.business.operations.services;
    if (services.length) {
      return services
        .map(item => {
          item = mapServiceKeyToName(item);
          return item;
        })
        .join(', ');
    }
    return [];
  }
  return [];
};
