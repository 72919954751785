import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { FastField } from 'formik';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { isUUID } from 'validator';
import {
  FormGroup,
  Button,
  RotateLoader,
  Input,
  Switch,
  styled,
} from '@lib/components';
import { UI } from '../../../../../../../core/ui';
import { MobxComponent } from '../../../../../../../mobx/components/index';
import { RestaurantForm } from '../../../../../../../mobx/components/restaurant-form';
import { config } from '../../../../../../../config';
import { SettingsActionButtonContainer, SettingsActionButtonWrapper } from '../../../settings-v2';

interface Props {
  close?: () => void;
}

type FormValues =
  T.Schema.Restaurant.RestaurantSchema['settings']['services']['delivery']['providers'];

const InfoBox = styled.div`
  color: #084298;
  background-color: #cfe2ff;
  border-color: #b6d4fe;
  position: relative;
  padding: 0.75rem 1.25rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  margin-top: 1rem;
`;

const ErrorBox = styled.div`
  color: red;
  padding: 4px 0;
`;

@inject('store')
@observer
export class SettingsFormIntegrationPostmates extends MobxComponent<Props, {}> {
  initialValues: FormValues;
  webhookUrl: string;

  constructor(props: Props) {
    super(props);
    const r = this.injected.store.restaurant!;
    this.initialValues = r.settings.services.delivery.providers;
    this.webhookUrl = `${config.urls.api2}/v1/postmates/${r._id}/webhook`;
  }

  validateCustomerID(value: string) {
    let error;
    if (_isEmpty(value) || !value.startsWith('cus_')) {
      error = 'Invalid Postmates customer ID provided.';
    }
    return error;
  }

  validateKey(value: string) {
    value = value.trim();
    if (_isEmpty(value)) {
      return 'This field value cannot be empty.';
    }
    if (!isUUID(value)) {
      return 'This field value must be a valid UUID.';
    }

    return '';
  }

  validateRequiredField(value: string) {
    let error;
    if (_isEmpty(value)) {
      error = 'This field value cannot be empty.';
    }
    return error;
  }

  getError(errors: any, key: string): string {
    if (errors && errors.postmates) {
      const message = _get(errors.postmates, key, '');
      return _isEmpty(message) ? '' : message;
    }
    return '';
  }

  render() {
    const org = this.injected.store.organisation!;
    return (
      <RestaurantForm<FormValues>
        submit={async (r, values) => {
          r.settings.services.delivery.providers = values;
          const update = {
            $set: {
              'settings.services.delivery.providers':
                r.settings.services.delivery.providers,
            },
          };
          return { r, update };
        }}
        initialValues={this.initialValues}
        onSuccess={() => {
          if (this.props.close) {
            this.props.close();
          }
        }}
        onError={() => UI.notification.error('An error occurred')}
        onSuccessMessage="Settings Updated"
        width='100%'
      >
        {({ form, error }) => {
          const { errors, isSubmitting, setFieldValue } = form;
          return (
            <>
              <FormGroup>
                <p className="font-semi-bold big m-b-2 color-red">NOTES</p>
                <p className="lhp">
                  Postmates provides us with two separating environments:
                  Sandbox and Production.
                </p>
                <br />
                <p className="lhp">
                  When configuring Postmates, make sure you choose the right
                  keys and identifiers for your current environment. If you
                  enable the testing mode setting, you must provide keys and
                  identifiers for sandbox environment.
                </p>
              </FormGroup>

              <FastField
                name="postmates.authentication_key"
                validate={this.validateKey}
                render={({ field }: any) => (
                  <FormGroup
                    title="Postmates Authentication Key"
                    help="Your Postmates authentication key. You can find this key under Postmates partner dashboard - Authentication Keys section."
                  >
                    <Input
                      type="text"
                      {...field}
                      value={field.value || ''}
                      required={true}
                    />
                    {this.getError(errors, 'authentication_key') && (
                      <ErrorBox>
                        {this.getError(errors, 'authentication_key')}
                      </ErrorBox>
                    )}
                  </FormGroup>
                )}
              />

              <FastField
                name="postmates.customer_id"
                validate={this.validateCustomerID}
                render={({ field }: any) => (
                  <FormGroup
                    title="Postmates Customer ID"
                    help="Your Postmates customer ID. You can find this key under Postmates partner dashboard - Account Identifiers section."
                  >
                    <Input
                      type="text"
                      {...field}
                      value={field.value || ''}
                      required={true}
                    />
                    {this.getError(errors, 'customer_id') && (
                      <ErrorBox>
                        {this.getError(errors, 'customer_id')}
                      </ErrorBox>
                    )}
                  </FormGroup>
                )}
              />

              <FastField
                name="postmates.webhook_signature_secret"
                validate={this.validateKey}
                render={({ field }: any) => (
                  <FormGroup
                    title="Postmates Webhook Signature Secret"
                    help="Your Postmates webhook signature secret. You can find this key under Postmates partner dashboard - Webhooks section."
                  >
                    <Input
                      type="text"
                      {...field}
                      value={field.value || ''}
                      required={true}
                    />
                    {this.getError(errors, 'webhook_signature_secret') && (
                      <ErrorBox>
                        {this.getError(errors, 'webhook_signature_secret')}
                      </ErrorBox>
                    )}
                  </FormGroup>
                )}
              />

              <FormGroup
                title="Postmates Webhook URL"
                help="Please copy this URL and paste it on Postmates Dashboard - Developer Section when configuring your Postmates Webhook URL."
              >
                <Input
                  id="webhook-url-input"
                  type="text"
                  value={this.webhookUrl}
                  readOnly={true}
                />

                <Button
                  type="button"
                  color="primary"
                  size="xs"
                  className="m-t-2"
                  onClick={() => {
                    const input = document.querySelector('#webhook-url-input');
                    if (input) {
                      // @ts-ignore
                      input.select();
                      document.execCommand('copy');
                    }
                  }}
                >
                  Copy URL
                </Button>
              </FormGroup>

              <FastField
                name="postmates.pickup_phone_number"
                validate={this.validateRequiredField}
                render={({ field }: any) => (
                  <FormGroup
                    title="Pickup Phone Number"
                    help="Your store phone number. Postmates uses this phone number when creating new delivery. This phone number will be used by courier to reach your store."
                  >
                    <Input
                      type="tel"
                      {...field}
                      value={field.value || ''}
                      required={true}
                    />
                    {this.getError(errors, 'pickup_phone_number') && (
                      <ErrorBox>
                        {this.getError(errors, 'pickup_phone_number')}
                      </ErrorBox>
                    )}
                  </FormGroup>
                )}
              />

              <FastField
                name="postmates.tax_exempt"
                render={({ field }: any) => (
                  <FormGroup
                    title="Disable Tax on Delivery Fee"
                    help="Should the delivery fee be exempt from tax?"
                  >
                    <Switch
                      id="postmates-tax-exempt-switch"
                      checked={field.value || false}
                      onChange={e =>
                        setFieldValue('postmates.tax_exempt', e.target.checked)
                      }
                    />
                  </FormGroup>
                )}
              />

              <FastField
                name="postmates.testing"
                render={({ field }: any) => (
                  <FormGroup
                    title="Test Mode"
                    help="Enable this to use Postmates in Test Mode. You should use this setting when you want to test the Postmates delivery before switching to production."
                  >
                    <Switch
                      id="postmates-testing-switch"
                      checked={field.value || false}
                      onChange={e =>
                        setFieldValue('postmates.testing', e.target.checked)
                      }
                    />
                  </FormGroup>
                )}
              />

              <FastField
                name="postmates.enabled"
                render={({ field }: any) => (
                  <FormGroup
                    title="Enabled"
                    help="Enabling this will automatically trigger the Postmates delivery process for new orders."
                  >
                    <Switch
                      id="postmates-enabled-switch"
                      checked={field.value || false}
                      onChange={e =>
                        setFieldValue('postmates.enabled', e.target.checked)
                      }
                    />
                    <InfoBox>
                      <strong>Note:</strong> Delivery fees from third-party
                      integrations are overriden by system-set values. Go over
                      to{' '}
                      <strong>
                        Settings &gt; Services &gt; Deliveries &gt; Fees
                      </strong>{' '}
                      and set it to None.
                    </InfoBox>
                  </FormGroup>
                )}
              />

              {error && <FormGroup error={error} />}

              {org.dashboard && org.dashboard === 'v2' ?
								<SettingsActionButtonContainer>
									<SettingsActionButtonWrapper style={{left: 0}}>
									<Button full={true} color="primary" type="submit" disabled={isSubmitting}>
										{isSubmitting && <RotateLoader size={2} color="white" />}
										{!isSubmitting && "Save"}
									</Button>
									</SettingsActionButtonWrapper>
								</SettingsActionButtonContainer>
							:<Button full={true} color="primary" type="submit" disabled={isSubmitting}>
								{isSubmitting && <RotateLoader size={2} color="white" />}
								{!isSubmitting && "Save"}
							</Button> }
            </>
          );
        }}
      </RestaurantForm>
    );
  }
}
