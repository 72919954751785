import * as React from 'react';
import { Sidebar, styled, FooterSection, SidebarBodySection, SidebarBodySectionTop, Switch, CommonText, GroupOptionItems, IconCircle, ModalContent, ModalMobileTabs, OrderIcon, OrderItems, OrderTotals, Tag, css, LinkTag } from '@lib/components';
import { Button } from '@lib/components';
import { useState } from 'react';
import { MobXProviderContext, useObserver } from 'mobx-react';
import { FaAngleDown, FaAngleUp, FaCheck, FaMapMarker } from 'react-icons/fa';
import { FORMATS, OrderUtils, SortType } from '@lib/common';
import { log } from 'console';
import { IoMdClose } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import _get from 'lodash/get';
import { useStores } from '../../../../../../mobx/useStores';
import Big from 'big.js';

const DetailOrderHeader = styled.div`
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  font-family: Inter;
`;
const OrderStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: #0c202a;
`;
const StatusStyle = styled.div`
  display: flex;
  padding: 4px 12px;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  border-radius: 99px;
  background-color: #009100;
  color: #fff;
`;
const EstimatedTimeStyle = styled.div`
  font-style: normal;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
`;
const OrderInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
`;
const DetailTabStyle = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
`;
const OrderInfo = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;
const TitleInfo = styled.div`
  height: 24px;
  min-width: 148px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #6b7270;
  margin-right: 16px;
  display: flex;
  align-items: center;
`;
const NumberTag = styled.span`
  border-radius: 50%;
  background: #eaeaea;
  padding: 5px;
  min-width: 20px;
  font-size: 11px;
  text-align: center;
  font-weight: 600;
`;
const ActionButtonWrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  border-top: 1px solid #dfdfdf;
  background: #fff;
`;
const ChangeStatusButton = styled.div`
  display: flex;
  min-width: 226px;
  background-color: #fb2f2f;
  color: #fff;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 12px;
`;
const CancelButton = styled.div`
  background-color: #f1f4f7;
  color: #4b4f5e;
  min-width: 114px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
`;
const DeliveryTracking = styled.div`
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 4px;
  background-color: #fb2f2f;
  color: #fff;
  border-radius: 4px;
`;
const MoreActionButtonWrapper = styled.div`
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const MoreActionButton = styled.div<{ isDeleteButton?: boolean }>`
  display: flex;
  width: 396px;
  padding: 12px 16px;
  align-items: center;
  height: 64px;
  justify-content: center;
  border-radius: 4px;
  ${({ isDeleteButton }) =>
    isDeleteButton
      ? css`
          background-color: #bc362f;
          color: #fefefe;
        `
      : css`
          background-color: #f5f5f5;
          color: #4b4f5e;
        `}
`;
const DeliveryAddressStyle = styled.div`
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  justify-content: center;
  border: 1px solid #4b4f5e;
  color: #4b4f5e;
  font-weight: 700;
  width: 123px;
`;
const DetailOrderInfo = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
`;
interface DetailOrderV2Props {
  active: boolean;
  close: () => void;
  order: any;
  orderItem: any
}

export const DetailOrderV2: React.FC<DetailOrderV2Props> = ({ active, close, order, orderItem }) => {
    return useObserver(() => {
        const [tab, setTab] = useState(0);
        const [openOrderInfoPart, setOpenOrderInfoPart] = useState(true);
        const [openDeliveryPart, setOpenDeliveryPart] = useState(true);
        const [openCustomerPart, setOpenCustomerPart] = useState(true);
        const store = useStores();
        const { restrictions } = store;
        const hidePrices = restrictions.misc && restrictions.misc.hide_prices;
        const region = store.restaurant! && store.restaurant.settings.region;
        const printerNames = store.restaurant! && store.restaurant.settings.printers;
        const mapData = store.restaurant! && store.restaurant.location.map_data;
        const timezones = store.intl.s.tz;
        const uom = region.kmmile === 'KM' ? "Km's" : 'miles';
        const rates = region.tax.rates;
        const conversionNumber = region.kmmile === 'KM' ? 1000 : 1609;

        const handleOpenOrderInfo = () => {
          setOpenOrderInfoPart(preState => !preState);
        };
        const handleOpenDelivery = () => {
          setOpenDeliveryPart(preState => !preState);
        };
        const handleOpenCustomer = () => {
          setOpenCustomerPart(preState => !preState);
        };
       
        const { date, time } = order.config;
        const handleTabChange = (selectedTab: any) => {
          setTab(selectedTab.value);
        };
        const { t } = useTranslation();
        const log = order.log;
        const orderLogTitles: any = {
          status_update: 'Update Status',
          due_update: 'Update Due Time',
          print_request: 'Print Request',
          tookan_book: 'Book Delivery Tookan',
          uber: 'Uber Update',
        };
        const formatDate = (dateString: any) => {
          const dateObj = new Date(dateString);
          const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
          const monthsOfYear = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
          const dayOfWeek = daysOfWeek[dateObj.getDay()];
          const month = monthsOfYear[dateObj.getMonth()];
          const day = dateObj.getDate();
          const year = dateObj.getFullYear();
          const formattedDate = `${dayOfWeek}, ${month} ${day}, ${year}`;
          return formattedDate;
        }
        const getEsitmatedTime = (timestamp: number) => {
          const dateObj = new Date(timestamp);
          const hours = dateObj.getHours();
          const minutes = dateObj.getMinutes();
          const ampm = hours >= 12 ? 'PM' : 'AM';
          const formattedHours = hours % 12 || 12;
          const currentTime = `${formattedHours}:${minutes} ${ampm}`;
          return currentTime;
          }
        const today = new Date();
        const ampm = today.getHours() >= 12 ? 'PM' : 'AM';
        const currentHours =
          (today.getHours() % 12) +
          ':' +
          (today.getMinutes() < 10 ? '0' + today.getMinutes() : today.getMinutes()) +
          ' ' +
          ampm;
      
        const checkDue = () => {
          if (order.config.due === 'now') {
            return 'Now / ASAP';
          }
          const timestamp = moment.tz(`${date} ${time}`, FORMATS.moment.datetime, timezones).valueOf();
          return t('datetimeFromTimestamp', {
            value: timestamp,
          });
        };
        const checkDeliveryTracking = () => {
          if (OrderUtils.hasDeliveryError(order)) {
            return <span className="error-text">Failed to book delivery</span>;
          }
      
          const trackingUrl = OrderUtils.getTrackingUrl(order);
          const returnTrackingUrl = OrderUtils.getReturnTrackingUrl(order);
          const deliveryStatus = OrderUtils.getDeliveryStatusFromProvider(order);
          if (order.status !== 'cancelled') {
            return (
              <>
                {trackingUrl && (
                  <DeliveryTracking>
                    <a href={trackingUrl}>
                      <OrderIcon service={order.config.service as T.Schema.Order.OrderServices} /> Track Delivery
                    </a>
                  </DeliveryTracking>
                )}
              </>
            );
          }
          if (returnTrackingUrl && order.status === 'cancelled') {
            return (
              <span>
                Cancelled delivery (Track{' '}
                <LinkTag target="_blank" href={trackingUrl}>
                  Return Delivery
                </LinkTag>
                )
              </span>
            );
          }
      
          return null;
        };
        const checkDeliveryAddress = () => {
          let directions = null;
          const oc = order.config;
          if (mapData.type !== 'custom') {
            const directionsUrl = `https://www.google.com/maps/dir/?api=1&origin=${mapData.lat},${mapData.lng}&destination=${oc.lat},${oc.lng}`;
            directions = (
              <DeliveryAddressStyle>
                <a href={directionsUrl} target="_blank">
                <FaMapMarker /> Directions
                </a>
              </DeliveryAddressStyle>
            );
          }
          return (
            <span>
              {directions}
            </span>
          );
        };
          let checkoutFields = _get(order, 'checkout_fields');
      
        const checkContactlessDelivery = (checkoutFields: any) => {
          const desiredLabel = "Request No Contact Delivery";
          const desiredField = checkoutFields ? checkoutFields.find(
            (field: any) => field.question.label === desiredLabel
          ) : false;
        
          if (desiredField) {
            const desiredAnswer = desiredField.answer;
        
            if (desiredAnswer === "no") {
              return <div style={{ color: '#fb2f2f' }}>No Requested</div>;
            } else if (desiredAnswer === "yes") {
              return <div style={{ color: '#51A351' }}>Requested</div>;
            }
          }
          return <div style={{ color: 'red' }}> No Requested</div>;
        };
        return (
          <Sidebar isOpen={active} headerTitle={`Detail Order - ${order._id}`} onClose={close} isDetailOrder width={460}>
          <SidebarBodySection>
          <DetailOrderHeader>
              <div style={{ display: 'flex' }}>
                <div>
                  <IconCircle
                    size={28}
                    iconSizeModifier={12}
                    icon={
                      <OrderIcon
                        service={order.config.service as T.Schema.Order.OrderServices}
                        deliveryProvider={order.config.delivery_provider}
                      />
                    }
                    background={'red'}
                  />
                </div>
                <OrderStyle className="m-lr-2">{order.number}</OrderStyle>
                {/* <StatusStyle>{status}</StatusStyle> */}
              </div>
            </DetailOrderHeader>
            <div style={{ display: 'flex', gap: '24px', padding: '16px' }}>
              <img src="/images/cooking/cook.gif" width="128px" height="128px" />
              <EstimatedTimeStyle>
                <div style={{ fontSize: '16px', lineHeight: '24px' }}>Estimated Ready Time</div>
                <div style={{ fontSize: '42px', lineHeight: '42px' }}>{currentHours}</div>
                <div style={{ fontSize: '16px', lineHeight: '24px' }}>{formatDate(new Date())}</div>
              </EstimatedTimeStyle>
            </div>
            <ModalMobileTabs
              value={tab}
              onChange={handleTabChange}
              tabs={[
                { label: 'Details', value: 0 },
                { label: 'Items', value: 1 },
                { label: 'Log', value: 2 },
              ]}
            />
           <div style={{ height: '71vh'}}>

           {tab === 0 && (
              <DetailTabStyle>
                <OrderInfoWrapper>
                  <div onClick={handleOpenOrderInfo} style={{ display: 'flex', justifyContent: 'space-between', width: '100%', cursor: 'pointer' }}>
                    <div style={{ fontSize: '16px', fontWeight: 700 }}>Order Info</div>
                    <div>{openOrderInfoPart ? <FaAngleDown /> : <FaAngleUp />}</div>
                  </div>
                  {openOrderInfoPart && (
                    <OrderInfo>
                      <div style={{ display: 'flex' }}>
                        <TitleInfo>Placed</TitleInfo>
                        <DetailOrderInfo>
                          {t('datetimeFromTimestamp', {
                            value: order.created,
                          })}
                        </DetailOrderInfo>
                      </div>
                      <div style={{ display: 'flex' }}>
                        <TitleInfo>Due</TitleInfo>
                        <DetailOrderInfo>{checkDue()}</DetailOrderInfo>
                      </div>
                      <div style={{ display: 'flex' }}>
                        <TitleInfo>Type</TitleInfo>
                        <DetailOrderInfo>{t(`constants.services.${order.config.service}`)}</DetailOrderInfo>
                      </div>
                      <div style={{ display: 'flex' }}>
                        <TitleInfo>Contactless Delivery</TitleInfo>
                        <DetailOrderInfo>{checkContactlessDelivery(checkoutFields)}</DetailOrderInfo>
                      </div>
                      <div style={{ display: 'flex' }}>
                        <TitleInfo>Payment Method</TitleInfo>
                        <DetailOrderInfo>{order.payment.method}</DetailOrderInfo>
                      </div>
                    </OrderInfo>
                  )}
                </OrderInfoWrapper>
                {order.config.service === 'delivery' && (
                  <OrderInfoWrapper>
                    <div onClick={handleOpenDelivery} style={{ display: 'flex', justifyContent: 'space-between', width: '100%', cursor: 'pointer' }}>
                      <div style={{ fontSize: '16px', fontWeight: 700 }}>Delivery</div>
                      <div>{openDeliveryPart ? <FaAngleDown /> : <FaAngleUp />}</div>
                    </div>
                    {openDeliveryPart && (
                      <OrderInfo>
                        <div style={{ display: 'flex' }}>
                          <TitleInfo>Courier</TitleInfo>
                          <DetailOrderInfo>{order.config.delivery_provider}</DetailOrderInfo>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <TitleInfo>Delivery Tracking URL</TitleInfo>
                          <DetailOrderInfo>{checkDeliveryTracking()}</DetailOrderInfo>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <TitleInfo>Delivery Address</TitleInfo>
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <DetailOrderInfo>{order.config.destination}</DetailOrderInfo>
                            <DetailOrderInfo>
                               {checkDeliveryAddress()}
                            </DetailOrderInfo>
                          </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <TitleInfo>Estimated Time</TitleInfo>
                          <DetailOrderInfo>{order.delivery_in && getEsitmatedTime(order.delivery_in.timestamp)}</DetailOrderInfo>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <TitleInfo>Arrival Date</TitleInfo>
                          <DetailOrderInfo>{order.delivery_in && formatDate(order.delivery_in.timestamp)}</DetailOrderInfo>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <TitleInfo>Delivery Status</TitleInfo>
                          <DetailOrderInfo>For {status}</DetailOrderInfo>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <TitleInfo>Distance</TitleInfo>
                          <DetailOrderInfo>
                            {order.config.distance ? Big(order.config.distance / conversionNumber).toFixed(3): 0}
                            {uom}
                          </DetailOrderInfo>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <TitleInfo>Driving Time</TitleInfo>
                          <DetailOrderInfo>{order.config.driving_time ? Big(order.config.driving_time / 60).toFixed(2): 0} mins</DetailOrderInfo>
                        </div>
                      </OrderInfo>
                    )}
                  </OrderInfoWrapper>
                )}
                <OrderInfoWrapper>
                  <div onClick={handleOpenCustomer} style={{ display: 'flex', justifyContent: 'space-between', width: '100%', cursor: 'pointer' }}>
                    <div style={{ fontSize: '16px', fontWeight: 700 }}>Customer</div>
                    <div>{openCustomerPart ? <FaAngleDown /> : <FaAngleUp />}</div>
                  </div>
                  {openCustomerPart && (
                    <OrderInfo>
                      <div style={{ display: 'flex' }}>
                        <TitleInfo>Name</TitleInfo>
                        <DetailOrderInfo>{order.customer.name}</DetailOrderInfo>
                      </div>
                      <div style={{ display: 'flex' }}>
                        <TitleInfo>Email</TitleInfo>
                        <DetailOrderInfo>{order.customer.email}</DetailOrderInfo>
                      </div>
                      <div style={{ display: 'flex' }}>
                        <TitleInfo>Contact Number</TitleInfo>
                        <DetailOrderInfo>{order.customer.phone}</DetailOrderInfo>
                      </div>
                    </OrderInfo>
                  )}
                </OrderInfoWrapper>
              </DetailTabStyle>
            )}
            {tab === 1 && (
              <ModalContent paddinglr={20} paddingtb={15}>
                <OrderItems
                  items={orderItem.dishes}
                  renderDetails={{
                    enabled: true,
                    prices: !hidePrices,
                    option_prices: !hidePrices,
                    combo_choice_names: true,
                    disable_print_name: true,
                    sort_type: SortType.ByCart.toString(),
                  }}
                />
                {!hidePrices && (
                  <OrderTotals
                    tax_in_prices={orderItem.bill.tax_in_prices}
                    fees={orderItem.bill.fees || []}
                    taxes={orderItem.bill.taxes || []}
                    discount={orderItem.bill.discount}
                    promo={null}
                    totalCart={orderItem.bill.cart}
                    total={orderItem.bill.total}
                    tip={typeof orderItem.bill.tip === 'undefined' ? 0 : order.bill.tip}
                  />
                )}
                {rates && rates.map((rate: any, index: any) => {
                  {
                    return rate.tax_indicator_flag ? (
                      <p
                        key={index}
                        className="small"
                        style={{
                          marginTop: '10px',
                        }}
                      >
                        {rate.tax_indicator_string}
                      </p>
                    ) : (
                      <div key={index}></div>
                    );
                  }
                })}
              </ModalContent>
            )}
            {tab === 2 && (
              <ModalContent paddinglr={20} paddingtb={20} style={{height: `calc(100vh - 312px)`}}>
                {!!(!log || log.length === 0) && <p className="text-center p-tb-8 big">No log entries...</p>}
      
                {!!(log && log.length > 0) && (
                  <div className="">
                    <p className="m-b-3 p-b-3 border-white-b-15 small1">Sorted from newest to oldest</p>
                    {log.map(({ user, timestamp, data }: any, i: any) => {
                      return (
                        <div key={i} className={i === 0 ? '' : 'm-t-3 p-t-3 border-white-dash-t-15'}>
                          <div
                            style={{
                              marginBottom: '7px',
                            }}
                            className="flex-line centered"
                          >
                            <NumberTag className="m-r-2">{log.length - i}</NumberTag>
                            <p
                              className="font-semi-bold uppercase small"
                              style={{
                                color: '#838383',
                              }}
                            >
                              {orderLogTitles[data.type]}
                            </p>
                          </div>
                          <p className="lhp">
                            {data.type === 'status_update' &&
                              `Updated from "${t(`order.status.${data.from}`)}" to "${t(`order.status.${data.to}`)}"`}
                            {data.type === 'due_update' &&
                              order.config.service === 'delivery' &&
                              `Delivery time updated to "${t(`datetimeFromTimestamp`, {
                                value: data.due,
                              })}"`}
                            {data.type === 'due_update' &&
                              order.config.service !== 'delivery' &&
                              `Ready time updated to "${t(`datetimeFromTimestamp`, {
                                value: data.due,
                              })}"`}
                            {data.type === 'print_request' && `Print request sent to printer "${data.printer}"`}
                            {data.type === 'tookan_book' &&
                              (data.success
                                ? 'Successfully booked delivery job with Tookan'
                                : 'Failed to book delivery job with Tookan')}
                            {data.type === 'uber' && `${data.message}`}
                          </p>
                          <div className="m-t-2">
                            <Tag className="m-r-2">
                              {t(`datetimeFromTimestamp`, {
                                value: timestamp,
                              })}
                            </Tag>
                            <Tag className="small">{user === 'system' ? 'System' : user}</Tag>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </ModalContent>
            )}
           </div>
            
          </SidebarBodySection>
        </Sidebar>
        );
    })
};
