import React, { useState } from "react"
import { Button, CommonText, FormGroup, Input, LinkTag, RotateLoader, Sidebar } from "@lib/components"
import { MobXProviderContext } from "mobx-react"
import { MobileModal, MobileModalBody, MobileModalContent, MobileModalFooter, MobileModalHeader } from "../../../../settings-v2"
import { IconClose, LogIcon, MailIcon, TrashIcon } from "../../../../../../../components/v2/icons"
import { GroupItemsTick } from "../../../../../components/groupItemsTick"
import styled from "styled-components"
import BottomModal from "../../../../../components/BottomModal"
import { UI } from "../../../../../../../../core/ui"
import { cloneDeepSafe, logger, Untrusive } from "@lib/common"

interface optionType {
    id: string;
    label: string;
    value: string;
    icon: () => JSX.Element;
}
type MoreActionsModalProps = {
    setShowModal: (show: boolean) => void;
    showModal: boolean;
    currentCustomer: any;
}

const optionsForEmail = [
    {
        id: 'edit_email',
        label: 'Edit Email',
        value: 'edit_email',
        icon: <MailIcon size={24} color="#4B4F5E" />
    },
    {
        id: 'edit_password',
        label: 'Edit Password',
        value: 'edit_password',
        icon: <LogIcon />
    },
    {
        id: 'delete_customer',
        label: 'Delete Customer',
        value: 'delete_customer',
        icon: <TrashIcon />
    },
];

const optionsForGuest = [
    {
        id: 'delete_customer',
        label: 'Delete Customer',
        value: 'delete_customer',
        icon: <TrashIcon />
    },
];

export const MoreActionsWrapper = styled.div`
    margin-bottom: 12px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .mobile-view {
        display: none;
    }
    .desktop-view {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
    }
    @media (max-width: 767px) {
        .mobile-view {
            width: 100%;
            display: block;
        }
        .desktop-view {
            display: none;
        }
    }
`;

const DesktopSidebarContent = styled.div`
    padding: 16px;
`;
const DesktopSidebarTittle = styled.div`
    display: flex;
    gap: 5px;
    margin-bottom: 16px;
`;

const DesktopBottomControl = styled.div`
    position: fixed;
    bottom: 0;
    width: calc(100vw - 57px);
    right: 0;
`;

const ActionButton = styled.div`
	display: flex;
	padding: 8px 16px;
	align-items: center;
	gap: 4px;
	border-radius: 4px;
	background: #F1F4F7;
	color: #4B4F5E;
	text-align: center;
	font-family: Inter;
	font-size: 12px;
	font-style: normal;
	font-weight: 700;
    height: 38px;
    cursor: pointer;
`

export const MoreActionsModal: React.FC<MoreActionsModalProps> = ({ currentCustomer, showModal, setShowModal }) => {
    const { store } = React.useContext(MobXProviderContext);
    const [activeItem, setActiveItem] = useState<optionType | undefined>(undefined);
    const [moreActionsData, setMoreActionsData] = useState({
        login: currentCustomer?.details?.email, password: ''
    })
    const checkCustomerType = store.restrictions.restaurant.customers_actions && currentCustomer && currentCustomer.type  === "email";
    const handleSelectItem = (item: any) => {
        setActiveItem(item);
    };
    const handleSelectSidebarItem = (item: any) => {
        setActiveItem(item);
        setShowModal(false)
    };
    const handleClose = () => {
        setShowModal(false)
        setActiveItem(undefined)
        store.updateCustomerControl({ currentCustomer: null })
    }
    
    const moreActionsBody = () => {
        return (
            <>
                {activeItem?.id === 'edit_email' &&
                    <Input
                        placeholder={`Enter a new e-mail address for the customer`}
                        required={true}
                        value={moreActionsData.login}
                        onChange={(e: any) => setMoreActionsData({ ...moreActionsData, login: e.target.value })}
                    />
                }
                {activeItem?.id === 'edit_password' &&
                    <>
                        <Input
                            placeholder={`Enter password`}
                            required={true}
                            value={moreActionsData.login}
                            onChange={(e: any) => setMoreActionsData({ ...moreActionsData, password: e.target.value })}
                        />
                        <CommonText size="semiMedium" color="#6B7270">Enter a new password for the customer. Minimum 6 characters. This will log the customer out</CommonText>
                    </>
                }
                {activeItem?.id === 'delete_customer' &&
                    <CommonText size="semiMedium" color="#6B7270">Once deleted, it cannot be recovered. Delete the customer?</CommonText>
                }
                {!activeItem?.id && <GroupItemsTick options={checkCustomerType ? optionsForEmail : optionsForGuest} activeId={activeItem?.id ? activeItem?.id : ''} selectItem={handleSelectItem} />}
            </>
        )
    }

    const handleUpdate = async () => {
        const { login, password } = moreActionsData;
        try {
			if (activeItem?.id === "edit_email") {
                if (login === null) { }
                else if (login === "") {
                    UI.notification.error("Please enter an e-mail address");
                }
                else {
                    Untrusive.start();
    
                    const response = await store.api.customer_update_email({ _id: currentCustomer._id, login });
                    if (response.outcome) {
                        UI.notification.error(response.message, { timeout: 6000 });
                    }
                    else {
                        const newCustomer = cloneDeepSafe(currentCustomer);
                        newCustomer.login = login;
                        newCustomer.details.email = login;
                        store.updateCustomerComplete(newCustomer);
                        UI.notification.success("Customer e-mail updated");
                        handleClose();
                    }
                }
            }
    
            if (activeItem?.id === "edit_password") {
                if (password === null) { }
                else if (password.length < 6 || password.length > 1024) {
                    UI.notification.error("Minimum password length 6 characters");
                }
                else {
                    Untrusive.start();
                    const response = await store.api.customer_update_password({ _id: currentCustomer._id, password });
                    if (response.outcome) {
                        UI.notification.error(response.message, { timeout: 6000 });
                    }
                    else {
                        UI.notification.success("Customer password updated");
                        handleClose();
                    }
                }
            }
    
            if (activeItem?.id === "delete_customer") {
                const proceed = confirm("Once deleted, it cannot be recovered. Delete the customer?");
                if (!proceed) { }
                else {
                    Untrusive.start();
                    const restaurant_id = store.restaurant!._id;
                    const response = await store.api.customer_delete({ _id: currentCustomer._id, restaurant_id });
                    if (response.outcome) {
                        UI.notification.error(response.message, { timeout: 6000 });
                    }
                    else {
                        handleClose();
                        store.removeCustomer(currentCustomer._id);
                        UI.notification.success("Customer deleted");
                    }
                }
            }
		}
		catch (e) {
			logger.captureException(e);
			UI.notification.error("An error occurred, try again soon or contact us", { timeout: 6000 });
		}
		finally {
			Untrusive.stop();
		}
        
    }
    return (
        <MoreActionsWrapper>
            <div className='mobile-view'>
                {showModal && <MobileModal>
                    <MobileModalContent>
                        <MobileModalHeader>
                            <CommonText size="medium" color="#4B4F5E">{activeItem?.label ? activeItem?.label : 'More Actions'} </CommonText>
                            <span onClick={() => {
                                setShowModal(false)
                                setActiveItem(undefined)
                            }}>
                                <IconClose />
                            </span>
                        </MobileModalHeader>
                        <MobileModalBody>
                            {moreActionsBody()}
                        </MobileModalBody>
                        <MobileModalFooter>
                            {(activeItem?.id === 'edit_email' || activeItem?.id === 'edit_password') ?
                                <Button color="primary" full={true}  onClick={handleUpdate}>Update</Button>
                                : activeItem?.id === 'delete_customer' ?
                                    <Button color="primary" full={true}  onClick={handleUpdate}>Confirm</Button>
                                    : ''
                            }
                        </MobileModalFooter>
                    </MobileModalContent>
                </MobileModal>
                }
            </div>
            <div className='desktop-view'>
                <Sidebar isOpen={showModal} headerTitle="More Actions" onClose={() => setShowModal(false)} >
                    <DesktopSidebarContent>
                        <DesktopSidebarTittle>
                            <CommonText size="semiMedium" weight={500} color="#6B7270">Select action to be applied to</CommonText>
                            <CommonText size="semiMedium" weight={700} color="#6B7270">{currentCustomer?.details?.name}</CommonText>
                        </DesktopSidebarTittle>
                        <GroupItemsTick options={checkCustomerType ? optionsForEmail : optionsForGuest} activeId={activeItem?.id ? activeItem?.id : ''} selectItem={handleSelectSidebarItem} />
                    </DesktopSidebarContent>
                </Sidebar>
                <BottomModal open={activeItem} title={activeItem?.label ? activeItem?.label : 'More Actions'}
                    onClose={handleClose} footer={<>
                        <Button color="primary" onClick={handleUpdate}>Confirm</Button>
                        <Button type="button" overrideColor={'#F1F4F7'} customColor={'#4B4F5E'} onClick={() => setActiveItem(undefined)} >
                            Cancel
                        </Button>
                    </>}
                >
                    {moreActionsBody()}
                </BottomModal>
            </div>

        </MoreActionsWrapper>
    )

}