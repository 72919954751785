import * as React from "react";
const autobind = require("class-autobind").default;
import styled, { css } from "../../../styled/index";
import darken from "polished/lib/color/darken";
import { CS } from "../../../styles/index";
import { BaseInputCSS } from "../input/index";
import { FaTimes } from "react-icons/fa";

export const TagTimes = styled("div")`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 5px;
  margin-left: 8px;
  cursor: pointer;
  background: ${({ theme }) => darken(0.07, theme.input.background)};
`;

export const Tag = styled("div")`
  display: flex;
  height: 24px;
  line-height: 24px;
  padding: 0 0 0 8px;
  background: ${({ theme }) => darken(0.12, theme.input.background)};
  border-radius: 5px;
  overflow: hidden;
`;

export const Input = styled("input")`
  ${BaseInputCSS};
  border: none;
  width: auto;
  flex-grow: 1;
  height: 24px;
  line-height: normal;
  padding: 0 5px;
  &:focus {
    border: none !important;
  }
`;

export const Wrapper = styled("div") <{ shadow?: "one" | "two" | "three"; }>`
  ${BaseInputCSS};
  ${(props) => props.shadow ? CS.shadow.light[props.shadow] : ""};
  position: relative;
  display: flex;
  flex-wrap: wrap;
  height: auto;
  padding: 3px;
  > * {
    margin: 3px;
  }
`;

interface State {
	input: string;
}

interface Props {
	tags: string[];
	placeholder?: string;
	onChange: (tags: string[]) => void;
	max?: number;
	onError?: (error: string) => void;
	validator?: (val: string) => string | null;
	shadow?: "one" | "two" | "three";
}

export class InputTags extends React.PureComponent<Props, State> {

	constructor(props: Props) {
		super(props);
		autobind(this);
		this.state = {
			input: "",
		};
	}

	onEnter(e: React.KeyboardEvent<HTMLInputElement>) {
		e.stopPropagation();
		if (e.key === "Enter") {
			const { input } = this.state;
			const { max, validator } = this.props;
			if (max !== undefined && this.props.tags.length >= max) {
				this.error(`Maximum of ${max} values allowed`);
				return;
			}
			if (validator !== undefined) {
				const valid = validator(input);
				if (valid !== null) {
					this.error(valid);
					return;
				}
			}
			const tags = [...this.props.tags];
			tags.push(input);
			this.props.onChange(tags);
			this.setState({ input: "" });
		}
	}

	onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
		this.setState({ input: e.target.value });
	}

	remove(i: number) {
		const tags = [...this.props.tags];
		tags.splice(i, 1);
		this.props.onChange(tags);
	}

	error(e: string) {
		if (this.props.onError) {
			this.props.onError(e);
		}
	}

	render() {
		const { max } = this.props;
		const { tags, shadow, placeholder } = this.props;
		const { input } = this.state;
		const maxTagsReached = max !== undefined ? tags && tags.length >= max : false;
		return (
			<Wrapper
				shadow={shadow}>
				{tags.map((tag, i) => (
					<Tag key={i}>
						{tag}
						<TagTimes onClick={() => this.remove(i)}>
							<FaTimes />
						</TagTimes>
					</Tag>
				))}
				{!maxTagsReached &&
					<Input
						form="doesnt-exists"
						placeholder={placeholder || "Type a value and press enter"}
						value={input}
						onChange={this.onInputChange}
						onKeyPress={this.onEnter} />
				}
			</Wrapper>
		);
	}

}
