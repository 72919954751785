import * as React from 'react';
import { FastField, Field } from 'formik';
import { FormGroup, Button, RotateLoader, Switch, Input, Textarea, styled } from '@lib/components';
import { MobxComponent } from '../../../../../../../mobx/components/index';
import { inject, observer } from 'mobx-react';
import { RestaurantForm } from '../../../../../../../mobx/components/restaurant-form';
import { UI } from '../../../../../../../core/ui';
import { PaymentBaseFields } from './base';
import _isEmpty from 'lodash/isEmpty';
import _toString from 'lodash/toString';
import _cloneDeep from 'lodash/cloneDeep';
import { SaveButtonWrapper, SettingsActionButtonContainer, SettingsActionButtonWrapper } from '../../../settings-v2';

interface Props {
  isMobile?: boolean;
}
interface State {}
type FormValues = T.Schema.Restaurant.Payments.RestaurantPaymentStripeDigitalWallet;

const ErrorBox = styled.div`
  color: red;
  padding: 4px 0;
`;

@inject('store')
@observer
export class SettingsFormPaymentsStripeDigitalWallet extends MobxComponent<Props, State> {
  initialValues: FormValues;

  constructor(props: Props) {
    super(props);
    const r = this.injected.store.restaurant!;
    this.initialValues = r.settings.payments.stripe_digital_wallet || {
      enabled: false,
      label: '',
      services: [],
      stripe_public_key: '',
      stripe_secret_key: '',
      stripe_domain_association: '',
    };
  }

  validateRequiredField(value: string) {
    let error;
    if (_isEmpty(value)) {
      error = 'This field value cannot be empty.';
    }
    return error;
  }

  validateStripePublicKey(value: string) {
    let error;
    if (_isEmpty(value) || !value.startsWith('pk_')) {
      error = 'Invalid Stripe.com public key provided.';
    }
    return error;
  }

  validateStripeSecretKey(value: string) {
    let error;
    if (_isEmpty(value) || !value.startsWith('sk_')) {
      error = 'Invalid Stripe.com secret key provided.';
    }
    return error;
  }

  async checkAndRegisterDomain() {
    const r = this.injected.store.restaurant!;
    const restaurant_id = r!._id;
    const response = await this.injected.store.api.dashboard_restaurant_domain_verify({ restaurant_id });
    if (response.outcome) {
      UI.notification.error(response.message, { timeout: 6000 });
    }
  }

  render() {
    const { isMobile } = this.props;
    const { store } = this.injected;
    const { organisation } = store;

    return (
      <RestaurantForm<FormValues>
        submit={async (r, values) => {
          r.settings.payments.stripe_digital_wallet = values;
          const update = {
            $set: { 'settings.payments.stripe_digital_wallet': r.settings.payments.stripe_digital_wallet },
          };
          this.checkAndRegisterDomain();
          return { r, update };
        }}
        initialValues={this.initialValues}
        onError={() => UI.notification.error('An error occurred')}
        onSuccessMessage="Settings Updated"
      >
        {({ form, error }) => {
          const { errors, isSubmitting, setFieldValue } = form;
          return (
            <>
            <div className="p-4">
              <FastField
                name="enabled"
                render={({ field }: any) => (
                  !isMobile ? (
                    <FormGroup title="Enabled" help="Allows customers to pay using this method">
                    <Switch
                      id="enable-switch"
                      checked={field.value}
                      onChange={e => setFieldValue('enabled', e.target.checked)}
                    />
                  </FormGroup>
                  ) : (
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px', paddingBottom: '20px', borderBottom: '1px dashed #DFDFDF'}}>
                    <div style={{ fontSize: '14px', fontWeight: 600 }}>Enabled Payment Method</div>
                    <div>
                    <Switch
                      id="enable-switch"
                      checked={field.value}
                      onChange={e => setFieldValue('enabled', e.target.checked)}
                    />
                    </div>
                  </div>
                  )
                )}
              />

              <FastField
                name="stripe_secret_key"
                validate={this.validateStripeSecretKey}
                render={({ field }: any) => (
                  <FormGroup
                    title="Stripe Secret Key"
                    help="Your Stripe secret API key, do not to share this with anyone"
                  >
                    <Input type="text" {...field} autoSave="false" autoCorrect="false" />
                    {errors.stripe_secret_key && <ErrorBox>{errors.stripe_secret_key}</ErrorBox>}
                  </FormGroup>
                )}
              />

              <FastField
                name="stripe_public_key"
                validate={this.validateStripePublicKey}
                render={({ field }: any) => (
                  <FormGroup
                    title="Stripe Public Key"
                    help="Your Stripe public API key that will be used to initiate online charges"
                  >
                    <Input type="text" {...field} autoSave="true" autoCorrect="false" />
                    {errors.stripe_public_key && <ErrorBox>{errors.stripe_public_key}</ErrorBox>}
                  </FormGroup>
                )}
              />

              <Field
                name="stripe_domain_association"
                render={({ field }: any) => (
                  <FormGroup
                    optional={true}
                    title="Apple Pay Domain Association"
                    help={
                      <p style={{ fontSize: '14px', lineHeight: '18px' }}>
                        The domain assocation string can be downloaded from this URI &nbsp;
                        <a
                          style={{ color: 'blue' }}
                          href="https://stripe.com/files/apple-pay/apple-developer-merchantid-domain-association"
                          rel="noopener"
                        >
                          Stripe Apple Pay Domain Association File
                        </a>
                        .
                        <br />
                        By adding the domain association, Apple Pay will be configured for supporting devices.
                      </p>
                    }
                  >
                    <Textarea {...field} autoSave="false" autoCorrect="false" />
                    {errors.stripe_domain_association && <ErrorBox>{errors.stripe_domain_association}</ErrorBox>}
                  </FormGroup>
                )}
              />

              <FormGroup>
                <div>
                  <div style={{ marginBottom: '10px' }}>
                    <p
                      style={{
                        fontSize: '16px',
                        fontWeight: 600,
                        color: 'red',
                        marginBottom: '10px',
                      }}
                    >
                      Notes
                    </p>
                    <p style={{ fontSize: '14px', lineHeight: '18px' }}>
                      Customers see a “Pay now” button or an Apple Pay button, depending on what their device and
                      browser combination supports. If neither option is available, they don’t see the button.
                      Supporting Apple Pay requires additional steps, but compatible devices automatically support
                      browser-saved cards, Google Pay, and Microsoft Pay.
                      <br />
                      Apple Pay with the Payment Request Button requires macOS 10.12.1+ or iOS 10.1+.
                    </p>
                  </div>
                </div>
              </FormGroup>

              <PaymentBaseFields defaultLabel="Stripe Digital Wallet" setFieldValue={setFieldValue} />

              {error && <FormGroup error={error} />}

              {organisation && organisation.dashboard === 'v2.1' ?
                <SaveButtonWrapper>
                  <SettingsActionButtonContainer className="no-sidebar desktop-view">
                  <SettingsActionButtonWrapper style={{left: 0}}>
                    <button  type="submit" disabled={isSubmitting} >
                      {isSubmitting && <RotateLoader size={2} color="white" />}
                      {!isSubmitting && "Save"}
                    </button>
                  </SettingsActionButtonWrapper>
                </SettingsActionButtonContainer>
              </SaveButtonWrapper>
              : <Button full={true} color="primary" type="submit" disabled={isSubmitting}>
                  {isSubmitting && <RotateLoader size={2} color="white" />}
                  {!isSubmitting && 'Save'}
                </Button>
              }
             

            </div>
            {organisation && organisation.dashboard === 'v2.1' && 
            <SaveButtonWrapper>
              <SettingsActionButtonContainer style={{ width: '100%', marginLeft: 0 }} className='mobile-view'>
                <SettingsActionButtonWrapper>
                  <Button full={true} color="primary" type="submit" disabled={isSubmitting}>
                    {isSubmitting && <RotateLoader size={2} color="white" />}
                    {!isSubmitting && 'Save'}
                  </Button>
                </SettingsActionButtonWrapper>
              </SettingsActionButtonContainer>
              </SaveButtonWrapper>
            }
              </>
          );
        }}
      </RestaurantForm>
    );
  }
}
