import * as React from "react";
import { Button } from "@lib/components";
import { DataLocales, DistanceMeasures } from "@lib/common";
import { UI } from "../../../../../core/ui";
import { logger } from "@lib/common";
import { Input } from "@lib/components";
import { Switch } from "@lib/components";
import { FormGroup } from "@lib/components";
import { InputAddress } from "@lib/components";
import { FieldOpeningHours } from "@lib/components";
import { FieldSubdomain } from "@lib/components";
import { Formik, Form, FastField, FormikProps, FormikActions } from "formik";
import { RotateLoader } from "@lib/components";
import { SelectAdv } from "@lib/components";
import { SubscriptionPlansV4 } from "@lib/components";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../../../mobx/components";
import { FormFieldValidators, FormHelpers } from "../../../../../core/form";
import { validators } from "@lib/common";
import { DataCountries, DataCurrencies, DataCurrenciesList } from "@lib/common";
import { withTheme } from "@lib/components";
import { ButtonGroup } from "@lib/components";
import moment from "moment-timezone";
import { StripeElementCard } from "@lib/components";
import { GoogleCreateService } from "../../../../../core/libs/google";
import { MapboxAPI } from "../../../../../core/libs/mapbox";
import { ChargebeeSubscriptionPlans } from "@lib/common";
import { config } from "../../../../../config"
import { MobileModal, MobileModalBody, MobileModalContent, MobileModalFooter, MobileModalHeader, OpeningHoursCard, OpeningHoursCardScheduleWrapper, OpeningHoursCardWrapper, OpeningHoursContainer, SalesTaxesAddButton, SettingsActionButtonContainer, SettingsActionButtonWrapper, SettingsCustomOption, SettingsCustomOptionsContainer, SettingsFormGroup, SettingsFormGroupInput, SettingsFormInputContainer, SettingsFormLabelContainer, SettingsFormLabelLeftContainer, SettingsFormLabelRightContainer, SettingsSwitchContainer } from "../../../restaurant/views/settings-v2";
import { IconCheck, IconClose, IconHelp, IconHelpV2, IconHour, IconPlusRed, IconSelectRadio, IconSelectRadioActive } from "../../../../components/v2/icons";
import { defaultOpeningHour } from "../../../restaurant/views/settings-v2/location";
import { runOnceNotification } from "../../../../utils/run_once_notification";
import styled from "styled-components";
import InputMask from "react-input-mask";

let GoogleService: any = {}

interface Props {
	back?: () => void;
	theme: T.Core.BaseTheme.ThemeInterface;
}

interface State {
	error: string | null;
	card_name: string;
	card_error: string;
	show_modal: boolean;
	openingHour: any;
	openingHourIndex: number;
	edit: boolean;
}

type FormValues = T.API.RestaurantCreateRequest;

const initialValues: FormValues = {
	subdomain: "",
	name: "",
	address: "",
	map_data: {
		type: "osm",
		components: {},
		lat: 0,
		lng: 0,
	},
	phone: "",
	opening_hours: [{
		day: "Monday",
		open: "09:00",
		close: "21:00",
		h24: false,
	}],
	locale: "",
	timezone: "",
	formats: {
		date: "DD/MM/YYYY",
		time: "h:mm a",
	},
	currency: null,
	taxes: [],
	tax_in_prices: false,
	subscription_plan: "",
	kmmile: "KM"
};

const timezoneList = moment.tz.names();

const SubscriptionCard = styled.div`
display: flex;
padding: 16px;
flex-direction: column;
align-items: flex-start;
gap: 16px;
align-self: stretch;

border-radius: 4px;
border: 1px solid var(--Secondary-Light-Grey-1, #DFDFDF);
background: var(--Neutral-Gray, #F1F4F7);
cursor: pointer;

&.active {
	border: 1px solid var(--Primary-Red-Orange-1, #FB2F2F);
	background: var(--Primary-Accent-Wisp-Pink-1, #FFF3F3);
}
`

const SubscriptionCardHeader = styled.div`
display: flex;
align-items: flex-start;
gap: 24px;
align-self: stretch;
`
const SubscriptionCardHeaderTitleWrapper = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 8px;
flex: 1 0 0;
`

const SubscriptionCardHeaderTitle = styled.div`
align-self: stretch;

color: #313131;
font-family: Inter;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: normal;

&.active {
	color: var(--Primary-Red-Orange-1, #FB2F2F);
}
`

const SubscriptionCardHeaderPrice = styled.div`
align-self: stretch;

color: #757575;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 24px */
&.active {
	color: var(--Primary-Red-Orange-1, #FB2F2F);
}
`

const SubscriptionCardDescription = styled.p`
color: var(--Secondary-Smokey-Grey-1, #6B7270);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 16px; /* 114.286% */
&.active {
	color: var(--Primary-Red-Orange-1, #FB2F2F);
}
`

@inject("store") @observer
class RestaurantFormSetupClass extends MobxComponent<Props, State> {

	prePopulated: boolean = false;

	constructor(props: Props) {
		super(props);
		this.state = {
			error: null,
			card_name: "",
			card_error: "",
			show_modal: false,
			openingHour: defaultOpeningHour,
			openingHourIndex: -1,
			edit: false,
		};
		//
		const reseller = this.injected.store.reseller;
		if (reseller && reseller.billing && reseller.billing.reseller_billing_google_api_key && reseller.billing.reseller_billing_google_api_key.length > 0) {
			GoogleService = GoogleCreateService(); //(reseller.billing.reseller_billing_google_api_key)
		} else {
			GoogleService = GoogleCreateService(); //(config.services.google.api_key)
		}
		//
	}

	onSubmit = async (values: FormValues, form: FormikActions<FormValues>) => {

		const { service, reseller } = this.injected.store;

		const stop = () => {
			form.setSubmitting(false);
		};

		try {

			this.setState({ error: null });

			form.setSubmitting(true);

			if (reseller!.chargebee && reseller!.chargebee.subscription.plan_structure === "multi") {
				if (values.subscription_plan.indexOf("free") === -1) {
					const stripeData = await window.stripe!.createToken(window.stripeCard!, { name: this.state.card_name });
					if (stripeData.error || !stripeData.token) {
						const defaultErrorMessage = "Credit card error, try again or another method";
						const error = stripeData.error ? (stripeData.error.message || defaultErrorMessage) : defaultErrorMessage;
						this.setState({ error });
						form.setSubmitting(false);
						return;
					}
					values.stripe_token = stripeData.token.id;
				}
			}

			console.log(`FORM VALUES: ${JSON.stringify(values)}`);

			const res = await this.injected.store.api.restaurant_create(values);

			stop();

			if (res.outcome) {
				this.setState({ error: res.message });
				return;
			}

			UI.notification.success("Restaurant created");

			this.props.back!();

			service.track("Restaurant: Created", {
				name: values.name,
				timestamp: Date.now(),
				reseller_id: reseller!._id,
			});

		}
		catch (e) {
			logger.captureException(e);
			this.setState({ error: "Error creating restaurant, please try again or contact us" });
			stop();
		}

	}

	validate = (values: FormValues) => {
		const { errors, isError } = FormHelpers.validate<FormValues>(values, this.validators);
		if (this.state.error && !isError) {
			this.setState({ error: "" });
		}
		else if (!this.state.error && isError) {
			this.setState({ error: "There are errors in your form that need correcting, please scroll up and check other tabs if needed" });
		}

		return errors;
	}

	validators: FormFieldValidators<FormValues> = {
		name: (values) => {
			if (!values.name)
				return { name: "Required - Enter a name for your store location" };
			return undefined;
		},
		address: (values) => {
			const md = values.map_data;
			if (md.type === "google_maps" || md.type === "osm") {
				if (!values.address || !md.components || !md.lat || !md.lng) {
					return { address: "Required - search and select your store address" };
				}
			}
			else if (!values.address) {
				return { address: "Required - enter your store address" };
			}
			return undefined;
		},
		opening_hours: (values) => {
			const { error } = validators.business_hours(values.opening_hours);
			if (error) {
				return { opening_hours: error };
			}
			return undefined;
		},
		locale: (values) => {
			if (!values.locale)
				return { locale: "Required - search and select your locale" };
			return undefined;
		},
		timezone: (values) => {
			if (!values.timezone)
				return { timezone: "Required - search and select your timezone" };
			return undefined;
		},
		"formats.date": (values) => {
			const dateFormatRegex: any = /^(D{1,2}|M{1,4})[-\/](D{1,2}|M{1,4})[-\/](Y{1,4})$/;
			
			const multiDateValidator = (value: string) => {
				return dateFormatRegex.test(value) && moment().format(value);
			};
			
			const isValidFormat = multiDateValidator(values.formats.date.toLocaleUpperCase());
			if (!isValidFormat) {
				return { "formats.date": "Invalid date format" };	
			}
			return undefined;
		},
		currency: (values) => {
			if (!values.currency)
				return { currency: "Required - search and select your currency" };
			return undefined;
		},
		taxes: (values) => {
			if (values.taxes) {
				for (const tax of values.taxes) {
					if (!tax.rate || !tax.name) {
						return { taxes: `One of your taxes is missing a name or tax rate. Please ensure all the fields are completed` };
					}
				}
			}
			return undefined;
		},
		subscription_plan: (values) => {
			const res = this.injected.store.reseller!;
			if (res.chargebee && res.chargebee.subscription.plan_structure === "multi" && !values.subscription_plan) {
				return { subscription_plan: "Required - Select a plan for this restaurant" };
			}
			return undefined;
		},
	};

	getFieldError = (form: FormikProps<FormValues>, field: keyof FormValues | string) => {
		return FormHelpers.error(form, field);
	}

	prePopulate = async (updateForm: (data: { locale: string, timezone: string }) => void) => {
		try {
			if (!this.prePopulated) {
				this.prePopulated = true;

				let locale = "";
				let timezone = "";

				const languages = navigator.languages || [navigator.language];
				for (const l of languages) {
					const found = DataLocales.find((v) => v.value.toLowerCase() === l.toLowerCase());
					if (found) {
						locale = found.value;
						break;
					}
				}

				const { location } = await GoogleService.cords_get_current();
				const data = await GoogleService.timezone_from_cords(location.lat, location.lng);
				if (timezoneList.indexOf(data.timeZoneId) !== -1) {
					timezone = data.timeZoneId;
				}

				updateForm({ locale, timezone });
			}
		}
		catch (e) {
			logger.captureException(e);
		}
	}

	render() {
		const { isMainReseller, setActiveMobileMenu, activeMobileMenu } = this.injected.store;
		const res = this.injected.store.reseller!;
		const plans = ChargebeeSubscriptionPlans[res._id];
		const { edit, show_modal, openingHour, openingHourIndex, error} = this.state;
		return (
			<Formik
				initialValues={initialValues}
				validate={this.validate}
				onSubmit={this.onSubmit}>
				{(form ) => {
					const { isSubmitting, setFieldValue, setFieldTouched, setFieldError, submitCount, errors, } = form;
					this.prePopulate(({ locale, timezone }) => {
						if (locale) setFieldValue("locale", locale);
						if (timezone) setFieldValue("timezone", timezone);
					});
					const mapType = form.values.map_data.type;
					if(isSubmitting) runOnceNotification(error!);
					return (
						<div style={{background: '#fefefe', paddingBottom: 81}}>
							
							<Form>
							<SettingsFormGroup>
								<SettingsFormGroupInput
									error={this.getFieldError(form, 'name')}>
									<SettingsFormLabelContainer>
										<SettingsFormLabelLeftContainer>Name</SettingsFormLabelLeftContainer>
										<SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
									</SettingsFormLabelContainer>
									<FastField
										name="name"
										render={({ field }: any) => (
											<SettingsFormInputContainer>
												<input type="text" {...field} required={true} />
											</SettingsFormInputContainer>
										)}
									/>
								</SettingsFormGroupInput>
								<SettingsFormGroupInput>
									<SettingsFormLabelContainer>
										<SettingsFormLabelLeftContainer>Sub-domain</SettingsFormLabelLeftContainer>
										<SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
									</SettingsFormLabelContainer>
									<FastField
										name="subdomain"
										render={({ field }: any) => (
												<FieldSubdomain
													restaurant_id=""
													website_id=""
													base_domain={res.store_host}
													value={field.value}
													checkDomain={this.injected.store.api.proxy_subdomain_check}
													onChange={(subdomain) => setFieldValue("subdomain", subdomain, false)}
													onCheckCallback={(e) => setFieldError("subdomain", e)} />
											
										)}
									/>
								</SettingsFormGroupInput>
								<SettingsFormGroupInput className='flex-full'>
                                <SettingsFormLabelContainer>
                                    <SettingsFormLabelLeftContainer>Map Data Source</SettingsFormLabelLeftContainer>
                                    <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                </SettingsFormLabelContainer>
                                <SettingsCustomOptionsContainer style={{flexDirection: 'column'}}>
                                    <FastField
                                        name="map_data.type"
                                        render={({ field }: any) => (
                                            <>
                                                {[
                                                    { value: "osm", name: "Open Street Maps" },
                                                    { value: "google_maps", name: "Google Maps" },
                                                    { value: "custom", name: "None" },
                                                ].map((opt, index) => (
                                                    <SettingsCustomOption
                                                        key={index}
                                                        onClick={() => {
                                                            setFieldValue("address", "");
                                                            setFieldValue("map_data", { type: opt.value });
                                                        }}
                                                        className={field.value === opt.value ? 'active' : ''}
														style={{fontWeight: 600}}>
                                                        {opt.name}
                                                        {field.value === opt.value && <IconCheck />}
                                                    </SettingsCustomOption>
                                                ))}
                                            </>
                                        )} />
                                </SettingsCustomOptionsContainer>
								<p>We recommend using Open Street Maps. If you cannot find you store address, try use Google Maps instead. Selecting 'None' will allow you to enter any address but certain delivery related features will be disabled.</p>
                            </SettingsFormGroupInput>
								<SettingsFormGroupInput
                                error={this.getFieldError(form,'address')}
                                className='flex-full'>
                                <SettingsFormLabelContainer>
                                    <SettingsFormLabelLeftContainer>Store Address</SettingsFormLabelLeftContainer>
                                    <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                </SettingsFormLabelContainer>
                                <SettingsFormInputContainer id="map-input">
                                    {(mapType === "google_maps" || mapType === "osm") && (
                                        <InputAddress
                                            type={mapType}
                                            gm={GoogleService}
                                            mb={MapboxAPI}
                                            value={form.values.address}
                                            onChange={(address, map_data) => {
                                                setFieldValue("address", address);
                                                setFieldValue("map_data", map_data);
                                            }}
                                            onError={() => { }}
											//@ts-ignore
											location={ form.values.map_data?.lat ?
												new google.maps.LatLng(form.values.map_data.lat, form.values.map_data.lng)
											: undefined }
											countryCode={DataCountries.find( c => c.label === form.values.map_data?.components?.country )
												?.value}
                                        />
                                    )}
                                    {(mapType === "custom") && (
                                        <FastField
                                            name="address"
                                            render={({ field }: any) => (
                                                <input {...field} required={true} />
                                            )}
                                        />
                                    )}
                                </SettingsFormInputContainer>
                            </SettingsFormGroupInput>
								 <SettingsFormGroupInput className='flex-full'>
                                <SettingsFormLabelContainer>
                                    <SettingsFormLabelLeftContainer>Phone Number (optional)</SettingsFormLabelLeftContainer>
                                    <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                </SettingsFormLabelContainer>
                                <SettingsFormInputContainer>
                                    <FastField
                                        name="phone"
                                        render={({ field }: any) => (
                                            <input {...field}
                                                type="tel"
                                                inputMode="numeric"
                                                onChange={(e) => {
                                                    const numericValue = e.target.value.replace(/\D/g, '');
                                                    form.setFieldValue('phone', numericValue);
                                                }}
                                                value={field.value} />
                                        )}
                                    />
                                </SettingsFormInputContainer>
                            </SettingsFormGroupInput>

							<SettingsFormGroupInput
                                error={this.getFieldError(form,'opening_hours')}
                                className='flex-full'>
                                <SettingsFormLabelContainer>
                                    <SettingsFormLabelLeftContainer>Opening Hours</SettingsFormLabelLeftContainer>
                                    <SalesTaxesAddButton type='button' onClick={() => this.setState({show_modal: true})}>
                                        <IconPlusRed /> Add Time Slot
                                    </SalesTaxesAddButton>
                                </SettingsFormLabelContainer>
                                <OpeningHoursContainer>
                                    <p>Enter time in 24H format, e.g. 21:00 for 9:00pm. Ensure time slots do not overlap or close before they open</p>
                                    {form.values.opening_hours.map((times, index) => {
                                        return (
                                            <OpeningHoursCard onClick={() => {
                                                this.setState({
													show_modal: true,
													edit: true,
													openingHour: times,
													openingHourIndex: index
												})
                                            }}
                                                key={index}>
                                                <OpeningHoursCardWrapper>
                                                    {times.day}
                                                    <OpeningHoursCardScheduleWrapper style={{ flex: 1 }}>
                                                        <IconHour />
                                                        {times.h24 ? '24 Hours' :
                                                            `${times.open} - ${times.close}`}
                                                    </OpeningHoursCardScheduleWrapper>
                                                </OpeningHoursCardWrapper>
                                            </OpeningHoursCard>
                                        )
                                    })}
                                </OpeningHoursContainer>
                            </SettingsFormGroupInput>
							<SettingsFormGroupInput
								error={this.getFieldError(form,'locale')}>
								<SettingsFormLabelContainer>
									<SettingsFormLabelLeftContainer>System Locale</SettingsFormLabelLeftContainer>
									<SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
								</SettingsFormLabelContainer>
								<SettingsFormInputContainer>
									<FastField
										name="locale"
										render={({ field }: any) => (
											<SelectAdv
												type="single"
												options={DataLocales}
												value={field.value}
												onChange={(option: string) => setFieldValue("locale", option)}
											/>
										)}
									/>
								</SettingsFormInputContainer>
							</SettingsFormGroupInput>
					<SettingsFormGroupInput
                    error={this.getFieldError(form,'kmmile')}>
                    <SettingsFormLabelContainer>
                        <SettingsFormLabelLeftContainer>Kilometers / Miles</SettingsFormLabelLeftContainer>
                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                    </SettingsFormLabelContainer>
                    <SettingsFormInputContainer>
                    <FastField
                        name="kmmile"
                        render={({ field }: any) => (
                                <SelectAdv
                                    type="single"
                                    options={DistanceMeasures}
                                    value={field.value}
                                    onChange={(option: string) => setFieldValue("kmmile", option)}
                                />
                        )}
                    />
                    </SettingsFormInputContainer>
                </SettingsFormGroupInput>

			<SettingsFormGroupInput
                    error={this.getFieldError(form,'timezone')}>
                    <SettingsFormLabelContainer>
                        <SettingsFormLabelLeftContainer>Timezone</SettingsFormLabelLeftContainer>
                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                    </SettingsFormLabelContainer>
                    <SettingsFormInputContainer>
                        <FastField
                            name="timezone"
                            render={({ field }: any) => (
                             <SelectAdv
                                type="single"
                                options={timezoneList.map((n) => ({ label: n, value: n }))}
                                value={field.value}
                                onChange={(option: string) => setFieldValue("timezone", option)}
                            />
                            )}
                        />
                    </SettingsFormInputContainer>
                </SettingsFormGroupInput>
                <SettingsFormGroupInput>
                    <SettingsFormLabelContainer>
                        <SettingsFormLabelLeftContainer>Time Formatting</SettingsFormLabelLeftContainer>
                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                    </SettingsFormLabelContainer>
                    <SettingsCustomOptionsContainer style={{flexDirection: 'column'}}>
                    <FastField
                        name="formats.time"
                        render={({ field }: any) => (
                        [
                            { value: "h:mm a", name: "12 Hour (9:00pm)" },
                            { value: "HH:mm", name: "24 Hour (21:00)" },
                        ].map((f, index) => {
                            return (
                            <SettingsCustomOption
                                key={index}
                                onClick={() => {
                                    setFieldValue("formats.time", f.value)
                                }}
                                className={field.value === f.value ? 'active': ''}>
                                {f.name}
                                {field.value === f.value  && <IconCheck /> }
                            </SettingsCustomOption>
                            )
                        })
                        )} />
                    </SettingsCustomOptionsContainer>
                </SettingsFormGroupInput>
				<SettingsFormGroupInput
                    error={this.getFieldError(form, 'formats.date')}>
                    <SettingsFormLabelContainer>
                        <SettingsFormLabelLeftContainer>Date Formatting</SettingsFormLabelLeftContainer>
                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                    </SettingsFormLabelContainer>
                    <SettingsFormInputContainer>
                    <FastField
                        name="formats.date"
                        render={({ field }: any) => (
                            <input
                                type="text"
                                placeholder="DD-MM-YYYY or MM/DD/YYYY"
                                {...field}
                            />
                        )} />
                    </SettingsFormInputContainer>
                </SettingsFormGroupInput>
                <SettingsFormGroupInput
				error={this.getFieldError(form,'currency')}>
                    <SettingsFormLabelContainer>
                        <SettingsFormLabelLeftContainer>Currency</SettingsFormLabelLeftContainer>
                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                    </SettingsFormLabelContainer>
                    <SettingsFormInputContainer>
                    <FastField
                        name="currency"
                        render={({ field }: any) => (
                        
                            <SelectAdv
                                type="single"
                                options={DataCurrenciesList}
                                value={field.value ? field.value.code : null}
                                onChange={(option: string) => {
                                    const currency = DataCurrencies[option as keyof typeof DataCurrencies];
                                    setFieldValue("currency", {
                                        code: currency.code,
                                        symbol: currency.symbol_native,
                                        precision: currency.decimal_digits,
                                    });
                                }}
                            />
                        )}
                    />
                    </SettingsFormInputContainer>
                </SettingsFormGroupInput>

							<SettingsSwitchContainer>
								<SettingsFormLabelLeftContainer>
									Tax in Prices
									<IconHelpV2 />
								</SettingsFormLabelLeftContainer>
								<FastField
									name="tax_in_prices"
									render={({ field }: any) => (
									<Switch
											id="tax_in_prices"
											checked={field.value}
											onChange={(e) => setFieldValue("tax_in_prices", e.target.checked)} />    
									)}
								/>
							</SettingsSwitchContainer>
									
							<SettingsFormGroupInput>
								<SettingsFormLabelContainer>
									<SettingsFormLabelLeftContainer>Subscription Plan</SettingsFormLabelLeftContainer>
								</SettingsFormLabelContainer>
									{(!!plans) && (

										<FastField
											name="subscription_plan"
											render={({ field }: any) => (

												<>
													{plans.map((plan, index) => {
														return (
															<SubscriptionCard
																key={index}
																onClick={() => setFieldValue("subscription_plan", plan.id)} 
																className={plan.id == field.value ? 'active': ''}>
																<SubscriptionCardHeader>
																	<SubscriptionCardHeaderTitleWrapper>
																		<SubscriptionCardHeaderTitle className={plan.id == field.value ? 'active': ''}>{plan.name}</SubscriptionCardHeaderTitle>
																		<SubscriptionCardHeaderPrice className={plan.id == field.value ? 'active': ''}>{plan.price === "0" ? "FREE" : `$${plan.price}.00 per ${plan.period}`}</SubscriptionCardHeaderPrice>
																	</SubscriptionCardHeaderTitleWrapper>
																	{ plan.id == field.value ? <IconSelectRadioActive />: <IconSelectRadio /> }
																</SubscriptionCardHeader>
																<SubscriptionCardDescription style={{fontSize: 14}} className={plan.id == field.value ? 'active': ''}>{plan.description}</SubscriptionCardDescription>
															</SubscriptionCard>
														)
													})}
											
											
												</>
											)}
										/>
									)}
								
								</SettingsFormGroupInput>
								{(!!form.values.subscription_plan && form.values.subscription_plan.indexOf("free") === -1) && (
									<SettingsFormGroupInput>
									<SettingsFormLabelContainer>
										<SettingsFormLabelLeftContainer>Credit Card Details</SettingsFormLabelLeftContainer>
									</SettingsFormLabelContainer>
									<SettingsFormInputContainer>
										<Input
													type="text"
													placeholder="Name On Card"
													value={this.state.card_name}
													onChange={(e) => this.setState({ card_name: e.target.value })}
													required={true}
												/>
									</SettingsFormInputContainer>
									<SettingsFormInputContainer>
										<StripeElementCard
													onError={(e) => this.setState({ card_error: e })}
												/>
									</SettingsFormInputContainer>
							
									<p style={{fontSize: 12}}>{res._id === "airwaiters" ? "You will only be billed after 30 days" : "You will only be billed after 24 hours"}</p>
									</SettingsFormGroupInput>
								)}
								

								{(submitCount > 0 && this.state.error) && <FormGroup error={this.state.error} />}
	
								</SettingsFormGroup>
								<SettingsActionButtonContainer className="no-sidebar">
									<SettingsActionButtonWrapper style={{left: 0}}>
										<button type="button" 
											className="default btn-cancel" 
											onClick={ () =>{ setActiveMobileMenu(
												activeMobileMenu.referrer.pop()!,
												Array.from(new Set(activeMobileMenu.referrer))
											); 
											}}>
												Cancel 
										</button>
										<button type="submit" disabled={isSubmitting}>
											{isSubmitting && <RotateLoader size={2} color="white" />}
											{!isSubmitting && "Save"}
										</button>
									</SettingsActionButtonWrapper>
								</SettingsActionButtonContainer>
							</Form>

							{show_modal && <MobileModal>
                            <MobileModalContent>
                                <MobileModalHeader>
                                    {edit ? 'Edit' : 'Add'} Opening Hours
                                    <span onClick={() => {
                                        this.setState({
											show_modal: false,
											edit: false
										})
                                    }}>
                                        <IconClose />
                                    </span>
                                </MobileModalHeader>
                                <MobileModalBody>
                                    <SettingsFormGroupInput>
                                            <SettingsFormLabelContainer>
                                                <SettingsFormLabelLeftContainer>Day</SettingsFormLabelLeftContainer>
                                            </SettingsFormLabelContainer>
                                            <SettingsFormInputContainer>
                                                <select
                                                    value={openingHour.day}
                                                    onChange={(e) => {
                                                        this.setState({
															openingHour: {
																...openingHour,
																day: e.target.value
															}
                                                        })
                                                    }}>
                                                    <option>Monday</option>
                                                    <option>Tuesday</option>
                                                    <option>Wednesday</option>
                                                    <option>Thursday</option>
                                                    <option>Friday</option>
                                                    <option>Saturday</option>
                                                    <option>Sunday</option>
                                                </select>
                                            </SettingsFormInputContainer>
                                        </SettingsFormGroupInput>
                                    <SettingsSwitchContainer>
                                        <SettingsFormLabelLeftContainer>
                                            Open 24 Hours
                                        </SettingsFormLabelLeftContainer>
                                        <Switch
                                            id="h24"
                                            checked={openingHour.h24}
                                            onChange={(e) => {
                                                
												this.setState({
													openingHour: {
														...openingHour,
														h24: e.target.checked
													}
												})
                                            }}
                                            variant="black"
                                        />
                                    </SettingsSwitchContainer>
                                    <SettingsFormGroupInput>
                                        <SettingsFormLabelContainer>
                                            <SettingsFormLabelLeftContainer>Open</SettingsFormLabelLeftContainer>
                                        </SettingsFormLabelContainer>
                                        <SettingsFormInputContainer className={openingHour.h24 ? 'disabled' : ''}>
										<InputMask mask="99:99" value={openingHour.open} onChange={(e) => this.setState({
														openingHour: {
															...openingHour,
															open: e.target.value
														}
													})} disabled={openingHour.h24}>
											{(inputProps: any) => <Input type="text" className="no-round" style={{ width: 100 }} placeholder="00:00" {...inputProps} disabled={openingHour.h24} />}
										</InputMask>
										
                                        </SettingsFormInputContainer>
                                    </SettingsFormGroupInput>
                                    <SettingsFormGroupInput>
                                        <SettingsFormLabelContainer>
                                            <SettingsFormLabelLeftContainer>Close</SettingsFormLabelLeftContainer>
                                        </SettingsFormLabelContainer>
                                        <SettingsFormInputContainer className={openingHour.h24 ? 'disabled' : ''}>
										<InputMask mask="99:99" value={openingHour.close} onChange={(e) => this.setState({
														openingHour: {
															...openingHour,
															close: e.target.value
														}
													})} disabled={openingHour.h24}>
											{(inputProps: any) => <Input type="text" className="no-round" style={{ width: 100 }} placeholder="00:00" {...inputProps} disabled={openingHour.h24} />}
										</InputMask>
											
                                        </SettingsFormInputContainer>
                                    </SettingsFormGroupInput>
                                </MobileModalBody>
                                <MobileModalFooter>
                                    {edit ?
                                        <>
                                            <SettingsActionButtonWrapper position='relative' noborder nopadding>
                                                <button
                                                    type='button'
                                                    onClick={() => {
                                                        const copyOpeningHours = form.values.opening_hours
                                                        //@ts-ignore
                                                        copyOpeningHours[openingHourIndex] = openingHour
                                                        this.setState({
															show_modal: false,
															edit: false
														}, () => {
															setFieldValue('opening_hours', copyOpeningHours)
														})
                                                      

                                                    }}
                                                > Update</button>
                                            </SettingsActionButtonWrapper>
                                            <SettingsActionButtonWrapper variant="white" position='relative' noborder nopadding>
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        const copyOpeningHour = form.values.opening_hours[openingHourIndex]
														this.setState({
															show_modal: false,
															edit: false
														}, () => {
                                                        setFieldValue('opening_hours', form.values.opening_hours.concat(copyOpeningHour as T.Core.Business.BusinessHoursSlot))
														})
                                                    }}
                                                > Duplicate</button>
                                            </SettingsActionButtonWrapper>
                                            <SettingsActionButtonWrapper variant="default" position='relative' noborder nopadding>
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        const copyOpeningHours = form.values.opening_hours
                                                        copyOpeningHours.splice(openingHourIndex, 1)
														this.setState({
															show_modal: false,
															edit: false
														}, () => {
                                                 
                                                        setFieldValue('opening_hours', copyOpeningHours)
														})

                                                    }}> Delete</button>
                                            </SettingsActionButtonWrapper>
                                        </>
                                        : <SettingsActionButtonWrapper
                                            position='relative' noborder nopadding>
                                            <button type="button" onClick={() => {
                                                 this.setState({
													show_modal: false,
													edit: false
												}, () => {
                                                setFieldValue('opening_hours', form.values.opening_hours.concat(openingHour as T.Core.Business.BusinessHoursSlot))
												})
                                            }}> Save</button>
                                        </SettingsActionButtonWrapper>}
                                </MobileModalFooter>
                            </MobileModalContent>
                        </MobileModal>}
						</div>
					);
				}}
			</Formik>
		);
	}

}

export const RestaurantFormSetupV2 = withTheme(RestaurantFormSetupClass);
