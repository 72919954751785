import * as React from "react";
const autobind = require("class-autobind").default;
import styled, { css } from "../../../styled/index";
import lighten from "polished/lib/color/lighten";
import { CS } from "../../../styles/index";
import { BaseInputCSS } from "../input/index";
import { FaTimes, FaChevronDown } from "react-icons/fa";
import Fuse from "fuse.js";
import { Tag, TagTimes } from "../input.tags/index";
import shortid from "shortid";
const ClickOutComponent = require("react-onclickout");

interface WrapperProps {
	shadow?: "one" | "two" | "three";
	focused: boolean;
}

export const Wrapper = styled("div") <WrapperProps>`
  ${BaseInputCSS};
  ${(props) => props.shadow ? CS.shadow.light[props.shadow] : ""};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: auto;
  border-color: ${({ focused, theme }) => focused ? theme.colors.primary : theme.input.border};
  padding: 0;
`;

export const WrapperInner = styled("div") <{ type: "single" | "multi"; }>`
  display: flex;
  flex-grow: 1;
  height: auto;
  padding: 3px;
  ${({ type }) => type === "single" ? css`
    align-items: center;
    flex-wrap: nowrap;
  ` : css`
    flex-wrap: wrap;
  `};
  > * {
    margin: 3px;
  }
`;

export const InputField = styled("input")`
  ${BaseInputCSS};
  cursor: default;
  border: none;
  width: auto;
  flex-grow: 1;
  height: 24px;
  line-height: normal;
  padding: 0 5px;
  &:focus {
    border: none !important;
  }
`;

export const InputIcon = styled("div")`
  height: 22px;
  min-width: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid ${({ theme }) => theme.input.border};
  color: ${({ theme }) => theme.input.border};
  font-size: 12px;
`;

export const Dropdown = styled("div") <{ active: boolean }>`
  ${CS.shadow.light.two};
  z-index: 3;
  position: absolute;
  top: 120%;
  width: 100%;
  height: auto;
  max-height: 200px;
  overflow-y: auto;

  display: ${({ active }) => active ? "block" : "none"};

  border-radius: 3px;
  background: ${({ theme }) => theme.input.background};
  color: ${({ theme }) => theme.input.text};
  border: 1px solid ${({ theme }) => theme.input.border};
`;

export const DropdownOption = styled("div") <{ active: boolean }>`
  cursor: pointer;
  width: 100%;
  padding: 8px 12px;
  ${({ active }) => active ? css`
    background: ${({ theme }) => lighten(0.07, theme.colors.primary)};
    color: ${({ theme }) => theme.colors.primary_text};
  ` : ""};
  &:hover {
    background: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.primary_text};
  }
`;

interface Option {
	label: string;
	value: string;
}
interface State {
	input: string;
	inputFocused: boolean;
}

interface BaseProps {
	id?: string;
	options: Option[];
	placeholder?: string;
	shadow?: "one" | "two" | "three";
	searchOnly?: boolean;
}
interface SingleProps extends BaseProps {
	type: "single";
	value: string | null;
	onChange: (option: string) => void;
}
interface MultiProps extends BaseProps {
	type: "multi";
	value: string[] | null;
	onChange: (selected: string[]) => void;
}
type Props = SingleProps | MultiProps;

export class SelectAdv extends React.PureComponent<Props, State> {

	inputId: string;
	lastOpened: number | null = null;
	fuse: Fuse<Option>;

	constructor(props: Props) {
		super(props);
		autobind(this);
		this.state = {
			input: "",
			inputFocused: false,
		};
		this.fuse = new Fuse(props.options, {
			shouldSort: true,
			keys: ["value", "label"],
			threshold: 0.3,
		});
		this.inputId = props.id || shortid.generate();
	}

	componentDidUpdate(prevProps: Props) {
		this.lastOpened = this.state.inputFocused ? Date.now() : null;
		if (prevProps.options.length !== this.props.options.length) {
			this.fuse = new Fuse(this.props.options, {
				shouldSort: true,
				keys: ["value", "label"],
				threshold: 0.3,
			});
		}
	}

	onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
		if (this.state.inputFocused) {
			this.setState({ input: e.target.value });
		}
	}

	onChangeSingle(o: Option) {
		if (this.props.type === "single") {
			this.props.onChange(o.value);
			this.toggleDropdown(false);
		}
	}

	onChangeMulti(o: Option) {
		const props = this.props as MultiProps;
		if (props.value) {
			const selected = props.value ? [...props.value] : [];
			const foundIndex = selected.indexOf(o.value);
			if (foundIndex !== -1) {
				selected.splice(foundIndex, 1);
			}
			else {
				selected.push(o.value);
			}
			props.onChange(selected);
			// this.toggleDropdown(false);
		}
	}

	onClickOut() {
		if (this.lastOpened && ((Date.now() - this.lastOpened) > 100)) {
			this.toggleDropdown(false);
		}
	}

	toggleDropdown(open: boolean) {
		const el = document.getElementById(this.inputId);
		if (open) {
			this.setState({ inputFocused: true });
			if (el) {
				el.focus();
			}
		}
		else {
			this.setState({ inputFocused: false, input: "" });
			if (el) {
				el.blur();
			}
		}
	}

	renderMulti(props: MultiProps) {

		const value = props.value || [];
		const { shadow, placeholder, options, searchOnly } = props;
		const { input, inputFocused } = this.state;

		const finalPlaceholder = placeholder || (searchOnly ? "Type to search..." : "Select from the dropdown or type to search...");

		const selected: Option[] = value
			.map((v) => options.find((o) => o.value === v))
			.filter((v) => !!v) as Option[];

		const allSelected = selected.length === options.length;

		let dropdownActive = inputFocused;
		let results = searchOnly ? [] : options.filter((o) => value.indexOf(o.value) === -1);
		let searched = false;
		let inputFieldValue = "";
		if (inputFocused) {
			inputFieldValue = input;
			if (inputFieldValue) {
				results = this.fuse.search(inputFieldValue).slice(0, 25);
				searched = true;
			}
		}

		if (dropdownActive && searchOnly && !input) {
			dropdownActive = false;
		}
		else if (dropdownActive && allSelected) {
			dropdownActive = false;
		}

		return (
			<Wrapper
				shadow={shadow}
				focused={inputFocused}
				onClick={() => this.toggleDropdown(!inputFocused)}>
				<WrapperInner type={this.props.type}>
					{selected.map((option, i) => (
						<Tag key={i}>
							{option.label}
							<TagTimes onClick={() => this.onChangeMulti(option)}>
								<FaTimes />
							</TagTimes>
						</Tag>
					))}
					{(!allSelected || options.length === 0) &&
						<InputField
							form="doesnt-exists"
							placeholder={(
								options.length === 0 ? "No items found" :
									selected.length > 0 ? "" :
										finalPlaceholder
							)}
							value={inputFieldValue}
							onChange={this.onInputChange}
							id={this.inputId}
						/>
					}
				</WrapperInner>

				{(!allSelected || options.length === 0) &&
					<InputIcon>
						<FaChevronDown />
					</InputIcon>
				}

				<ClickOutComponent onClickOut={this.onClickOut}>
					<Dropdown
						active={dropdownActive}
						onClick={(e) => e.stopPropagation()}>
						{results.map((o, i) => (
							<DropdownOption key={i} onClick={() => this.onChangeMulti(o)} active={value.indexOf(o.value) !== -1}>
								{o.label}
							</DropdownOption>
						))}
						{results.length === 0 && (
							<div className="text-center p-tb-3">
								{(searchOnly && !searched) ? "Type to start searching" : "No options found"}
							</div>
						)}
					</Dropdown>
				</ClickOutComponent>
			</Wrapper>
		);

	}

	renderSingle(props: SingleProps) {
		const { value, shadow, placeholder, options, searchOnly } = props;
		const { input, inputFocused } = this.state;

		const finalPlaceholder = placeholder || (searchOnly ? "Type to search..." : "Select from the dropdown or type to search...");

		let dropdownActive = inputFocused;
		let results = searchOnly ? [] : options;
		let searched = false;
		let inputFieldValue = "";

		if (inputFocused) {
			inputFieldValue = input;
			if (inputFieldValue) {
				results = this.fuse.search(inputFieldValue).slice(0, 25);
				searched = true;
			}
		}
		else if (value) {
			const option = options.find((o) => o.value === value);
			if (option) {
				inputFieldValue = option.label;
			}
		}

		if (dropdownActive && searchOnly && !input) {
			dropdownActive = false;
		}

		return (
			<Wrapper
				shadow={shadow}
				focused={inputFocused}
				onClick={() => this.toggleDropdown(!inputFocused)}>
				<WrapperInner type={this.props.type}>
					<InputField
						id={this.inputId}
						form="doesnt-exists"
						placeholder={finalPlaceholder}
						value={inputFieldValue}
						onChange={this.onInputChange} />
				</WrapperInner>
				<InputIcon>
					<FaChevronDown />
				</InputIcon>
				<ClickOutComponent onClickOut={this.onClickOut}>
					<Dropdown
						active={dropdownActive}
						onClick={(e) => e.stopPropagation()}>
						{results.map((o, i) => (
							<DropdownOption key={i} onClick={() => this.onChangeSingle(o)} active={value === o.value}>
								{o.label}
							</DropdownOption>
						))}
						{results.length === 0 && (
							<div className="text-center p-tb-3">
								{(searchOnly && !searched) ? "Type to start searching" : "No options found"}
							</div>
						)}
					</Dropdown>
				</ClickOutComponent>
			</Wrapper>
		);
	}

	render() {
		if (this.props.type === "multi") {
			return this.renderMulti(this.props);
		}
		return this.renderSingle(this.props);
	}

}
