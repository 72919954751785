import React, { useState } from 'react'
import { WebsiteForm } from '../../../../../mobx/components/website-form';
import { useStores } from '../../../../../mobx/useStores';
import { UI } from '../../../../../core/ui';
import { FormGroup, Input, RotateLoader, Switch } from '@lib/components';
import { FastField } from 'formik';
import { SettingsActionButtonContainer, SettingsActionButtonWrapper } from '../../../restaurant/views/settings-v2';
import { CookieColorsMobile, convertColortoHex } from '../website/color_picker_mobile';
import { ImageUpload } from '../website/upload_image';
type FormValues = T.Schema.Website.WebsiteSchema["sections"]["footer"];

export const FooterV2 = () => {
    const store = useStores();
    const w = store.website!;
    const [colors, setColors] = useState({
        backgroundColor: '#292929',
        textColor: '#ffffff',
        backgroundTintColor: '#ffffff'
    });
    let initialValues = w.sections.footer;
    return (
        <WebsiteForm<FormValues>
					submit={async (w, values) => {
					w.sections.footer = values;
					const update = { $set: { "sections.footer": values } };
					return { w, update };
				}}
				validators={{}}
				initialValues={initialValues}
				onSuccess={() => { }}
				onError={() => UI.notification.error("An error occurred")}
				onSuccessMessage="Settings Updated"
				onErrorMessage="">
				{({ form, error }) => {
					const { isSubmitting, setFieldValue } = form;
					return (
						<div className="p-4" style={{ height: '90vh', overflowX: 'auto' }}>

							<FastField
								name="hidden"
								render={({ field }: any) => (
									<FormGroup title="">
										<div style={{ display: 'flex', justifyContent: 'space-between'}}>
											<div style={{ fontSize: '18px', lineHeight: '24px', fontWeight: 600 }}>Show Footer</div>
											<div>
											<Switch
												id="hidden"
												checked={field.value}
												onChange={(e) => setFieldValue("hidden", e.target.checked)} />
											</div>
										</div>
									</FormGroup>
								)}
							/>

							<FastField
								name="color_background"
								style={{ marginBottom: '30px'}}
								render={({ field }: any) => (
									<FormGroup title="">
										<CookieColorsMobile 
											color={field.value && convertColortoHex(field.value) || colors.backgroundColor}
											onColorChange={(color: any) => {
												setColors({ ...colors, backgroundColor: color });
												field.onChange({ target: { name: 'color_background', value: color } });
											}}
											colorField="Background Color"
										/>
									</FormGroup>
								)}
							/>

							<FastField
								name="color_text"
								render={({ field }: any) => (
									<FormGroup title="">
										<CookieColorsMobile 
											color={field.value && convertColortoHex(field.value) || colors.textColor}
											onColorChange={(color: any) => {
												setColors({ ...colors, textColor: color });
												field.onChange({ target: { name: 'textColor', value: color } });
											}}
											colorField="Text Color"
										/>
									</FormGroup>
								)}
							/>

							<FastField
								name="image_background"
								render={({ field }: any) => (
									<FormGroup
										title="Background Image"
										help="File name can't contain special charaters"
									>
										<ImageUpload 
											value={[field.value]} 
											onChange={(files: any[]) => setFieldValue('image_background', files[0])} onRemove={() => setFieldValue('image_background', null)}/>
									</FormGroup>
								)}
							/>

							<FastField
								name="image_background_tint"
								render={({ field }: any) => (
									<FormGroup title="">
										<CookieColorsMobile 
											color={field.value && convertColortoHex(field.value) || colors.backgroundTintColor}
											onColorChange={(color: any) => {
												setColors({ ...colors, backgroundTintColor: color });
												field.onChange({ target: { name: 'image_background_tint', value: color } });
											}}
											colorField="Background Image Tint"
										/>
									</FormGroup>
								)}
							/>

							<FastField
								name="terms_text"
								render={({ field }: any) => (
									<FormGroup title="Terms & Conditions Text">
										<Input type="text" {...field} />
									</FormGroup>
								)}
							/>

							<FastField
								name="terms_url"
								render={({ field }: any) => (
									<FormGroup title="Terms & Conditions URL">
										<Input type="url" {...field} />
									</FormGroup>
								)}
							/>

							<FastField
								name="social_links.facebook"
								render={({ field }: any) => (
									<FormGroup title="Facebook Link">
										<Input type="text" {...field} />
									</FormGroup>
								)}
							/>

							<FastField
								name="social_links.twitter"
								render={({ field }: any) => (
									<FormGroup title="Twitter Link">
										<Input type="text" {...field} />
									</FormGroup>
								)}
							/>

							<FastField
								name="social_links.instagram"
								render={({ field }: any) => (
									<FormGroup title="Instagram Link">
										<Input type="text" {...field} />
									</FormGroup>
								)}
							/>

							<FastField
								name="social_links.pinterest"
								render={({ field }: any) => (
									<FormGroup title="Pinterest Link">
										<Input type="text" {...field} />
									</FormGroup>
								)}
							/>

							<FastField
								name="social_links.tiktok"
								render={({ field }: any) => (
									<FormGroup title="Tiktok Link">
										<Input type="text" {...field} />
									</FormGroup>
								)}
							/>

							{error && <FormGroup error={error} />}
                            <SettingsActionButtonContainer className='no-sidebar'>
                        <SettingsActionButtonWrapper style={{left: 0}}>
                            <button 
                                type="button" 
                                className='btn-cancel'
                                onClick={ () => store.setActiveMobileMenu(
                                    store.activeMobileMenu.referrer.pop()!,
                                    Array.from(new Set(store.activeMobileMenu.referrer))
                                )}>
                                Cancel
                            </button>
                            <button  type="submit" disabled={isSubmitting}>
                            {isSubmitting && <RotateLoader size={2} color="white" />}
                            {!isSubmitting && "Save"}
                            </button>
                        </SettingsActionButtonWrapper>
                    </SettingsActionButtonContainer>

						</div>
					);
				}}
			</WebsiteForm>
    )
}