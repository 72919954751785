import React from 'react';
import { CommonText, Input } from '@lib/components';
import styled, { css } from 'styled-components';
import { SearchIcon } from '../../../../components/v2/icons';
import { InputComponent, InputWrapper } from '../mobileControls/inputWithIcon';

const ItemWrapper = styled.div<{ isActive?: boolean; }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    border-radius: 4px;
    border: ${({ isActive }) => isActive ? '1px solid #FB2F2F' : '1px solid transparent'};
    background: ${({ isActive }) => isActive ? '#FFF3F3' : '#F5F5F5'};
    div {
        color: ${({ isActive }) => isActive ? '#FB2F2F' : '#4B4F5E'};
    }
`;

const ContentWrapper = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
`;

const Tick = () => (
    <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.9998 6.5998L9.8998 0.699804C10.0831 0.516471 10.3165 0.424805 10.5998 0.424805C10.8831 0.424805 11.1165 0.516471 11.2998 0.699804C11.4831 0.883138 11.5748 1.11647 11.5748 1.3998C11.5748 1.68314 11.4831 1.91647 11.2998 2.0998L4.6998 8.6998C4.4998 8.8998 4.26647 8.9998 3.9998 8.9998C3.73314 8.9998 3.4998 8.8998 3.2998 8.6998L0.699804 6.0998C0.516471 5.91647 0.424805 5.68314 0.424805 5.3998C0.424805 5.11647 0.516471 4.88314 0.699804 4.6998C0.883138 4.51647 1.11647 4.4248 1.3998 4.4248C1.68314 4.4248 1.91647 4.51647 2.0998 4.6998L3.9998 6.5998Z" fill="#FB2F2F" />
    </svg>
);

const GroupItemsWrapper = styled.div<{ type?: number }>`
    ${({ type }) => type === 2 ? css`
        display: flex;
        gap: 16px;
        align-items: center;
        flex-wrap: wrap;
        & > div {
            width: calc(50% - 8px);
        }
    ` : css`
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 16px;
    `}
    
`;

type GroupItemsTickProps = {
    options: any[];
    activeId: string | number;
    type?: number;
    enableSearch?: boolean;
    searchPlaceholder?: string;
    selectItem: (item: any) => void;
}
export const GroupItemsTick: React.FC<GroupItemsTickProps> = ({ options, activeId, selectItem, type = 1, enableSearch = false, searchPlaceholder }) => {
    return (
        <GroupItemsWrapper type={type}>
            {
                options.map(item => (
                    <ItemWrapper key={item.id} onClick={() => {
                        if (item.id !== activeId) {
                            selectItem(item)
                        } else {
                            selectItem('')
                        }
                    }} isActive={item.id === activeId}>
                        <ContentWrapper>
                            {item.icon && item.icon}
                            <CommonText size="semiMedium" weight={500} color='#4B4F5E'>
                                {item.label}
                            </CommonText>
                        </ContentWrapper>

                        {activeId === item.id && <Tick />}
                    </ItemWrapper>
                ))
            }
            {enableSearch &&
                <InputWrapper>
                    <SearchIcon />
                    <InputComponent
                        placeholder={searchPlaceholder}
                        onChange={(e) => { selectItem({id : Number(e.target.value)})}}
                        value={activeId}
                        type='number'
                    />
                </InputWrapper>}
        </GroupItemsWrapper>
    )
}