import React, { useEffect, useState } from "react";
import { Button, Checkbox, CommonText, FooterSection, Radio, styled } from "@lib/components";
import { BackIcon, IconSettings } from "../../../../../../../components/v2/icons";
import { MobXProviderContext, observer } from "mobx-react";
import moment from "moment-timezone";
import { CustomerModalWrapper, FilterHeader, SortByWrapper } from "../style";
import { GroupItemsTick } from "../../../../../components/groupItemsTick";
import { ModalBody } from "../../../../bookings/mobileBookings/modals/settings/bookingSettingConstants";
import { InputWithIcon } from "../../../../../components/mobileControls/inputWithIcon";
import BottomModal from "../../../../../components/BottomModal";

const Section = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 16px;
`;

const SearchWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 16px;
    @media (max-width: 767px) {
        padding: 0 16px;
    }
`;

const FilterOptionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #FFF;
    padding: 16px;
    gap: 16px;
`;
const Option = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 12px;
    width: 50%;
`;
export const Footer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 12px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .mobile-view {
        display: none;
    }
    .desktop-view {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
    }
    @media (max-width: 767px) {
        .mobile-view {
            width: 100%;
            display: block;
        }
        .desktop-view {
            display: none;
        }
    }
`;
export const DesktopBottomControl = styled.div`
    position: fixed;
    bottom: 0;
    width: calc(100vw - 57px);
    right: 0;
    background: #FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    padding: 16px;
`;
export const DesktopBottomFooter = styled.div`
    max-width: 865px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    width: 100%;
    padding: 0 16px;
`;

export const AscendingIcon = ({ color = '#1C1B1F' }) => (
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.25004 12.6538V3.10763L1.40002 6.95763L0.346191 5.90383L6.00002 0.25L11.6538 5.90383L10.6 6.95763L6.74999 3.10763V12.6538H5.25004Z" fill={color} />
    </svg>
);

export const DescendingIcon = ({ color = '#1C1B1F' }) => (
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.00002 12.6538L0.346191 6.99998L1.40002 5.94615L5.25004 9.79618V0.25H6.74999V9.79618L10.6 5.94615L11.6538 6.99998L6.00002 12.6538Z" fill={color} />
    </svg>
);

type MobileFilterProps = {
    isOpen: boolean;
}
type statusOrderProps = {
    'unconfirmed': number,
    'confirmed': number,
    'cancelled': number,
    'complete': number,
}

type filterInfoProps = {
    displayResult: number,
    sortBy: string,
    direction: string,
    type: string[]
}

const statusOrder: statusOrderProps = {
    'unconfirmed': 1,
    'confirmed': 2,
    'cancelled': 3,
    'complete': 4
}
const defaultFilter = {
    displayResult: 10,
    sortBy: 'created',
    direction: 'asc',
    type: [],
    searchInfo: ''
}

export const CustomerFilter: React.FC<MobileFilterProps> = observer(({ isOpen }) => {
    const { store } = React.useContext(MobXProviderContext);
    const { updateCustomerSearchInfo } = store;
    const filterInfo = store.customerSearchInfo;
    useEffect(() => {
        if (store.restaurant._id) {
            store.handleSearchCustomer(defaultFilter);
            store.updateCustomerSearchInfo({...defaultFilter});
        }
    }, [])

    if (!isOpen) {
        return <></>;
    }

    const handleResetFilter = () => {
        store.updateCustomerSearchInfo({...defaultFilter});
    }

    const handleSearchCustomer = () => {
        store.handleSearchCustomer(store.customerSearchInfo);
        store.updateCustomerControl({ openFilterModal: false })
    }
    const optionsDirection = [
        {
            id: 'asc',
            label: 'Ascending',
            value: 'asc',
            icon: <AscendingIcon color={filterInfo?.direction === 'asc' ? '#FB2F2F' : undefined} />,
        },
        {
            id: 'des',
            label: 'Descending',
            value: 'des',
            icon: <DescendingIcon color={filterInfo?.direction === 'des' ? '#FB2F2F' : undefined} />,
        }
    ];

    const optionsResult = [
        {
            id: 10,
            label: '10 Results',
        },
        {
            label: '25 Results',
            id: 25,
        },
        {
            label: '50 Results',
            id: 50,
        },

    ];

    const optionsSortBy = [
        {
            id: 'created',
            label: 'Created',
            value: 'created',
        },
        {
            id: 'orders',
            label: 'Orders',
            value: 'stats.orders',
        },
        {
            id: 'last_seen',
            label: 'Last Seen',
            value: 'meta.last_seen',
        },
        {
            id: 'sales',
            label: 'Sales',
            value: 'stats.sales',
        },
        {
            id: 'Name',
            label: 'Name',
            value: 'details.name',
        },
        {
            id: 'unverified',
            label: 'Unverified',
            value: 'verified',
        }
    ];

    const optionsType = [
        {
            id: 'guest',
            label: 'Guest',
            value: 'guest',
        },
        {
            id: 'facebook',
            label: 'Facebook',
            value: 'facebook',
        },
        {
            id: 'email',
            label: 'E-mail',
            value: 'email',
        },
        {
            id: 'google',
            label: 'Google',
            value: 'google',
        },
    ];
    const handleSelectDisplayResult = (item: any) => {
        updateCustomerSearchInfo({ displayResult: item.id });
    };
    const handleTypeChange = (type: string) => {
        const updatedStatus = filterInfo.type.includes(type)
            ? filterInfo.type.filter((s: string) => s !== type)
            : [...filterInfo.type, type];
        updateCustomerSearchInfo({ type: updatedStatus });
    };

    return (
        <>
            <ModalBody style={{ maxWidth: '900px' }}>
                <SearchWrapper>
                    <InputWithIcon />
                </SearchWrapper>
                <FilterOptionWrapper>
                    <Section>
                        <CommonText size="medium" weight={600} color='#4B4F5E'>
                            Display Results
                        </CommonText>
                        <GroupItemsTick options={optionsResult} activeId={filterInfo.displayResult} type={2} selectItem={handleSelectDisplayResult} enableSearch={true} searchPlaceholder={'Enter number of results'} />
                    </Section>

                    <Section>
                        <CommonText size="medium" weight={600} color='#4B4F5E'>
                            Sort By
                        </CommonText>
                        <SortByWrapper>
                            {optionsSortBy.map(option => {
                                return (
                                    <Option key={option.id} onClick={() => { updateCustomerSearchInfo({ sortBy: option.value }) }}>
                                        <Radio
                                            version="v2"
                                            id={option.id}
                                            checked={
                                                option.value === filterInfo.sortBy
                                            }
                                            value={option.value}
                                            onChange={() => { }}
                                        />
                                        <CommonText size="semiMedium" weight={500} color='#4B4F5E'>
                                            {option.label}
                                        </CommonText>
                                    </Option>
                                )
                            })}
                        </SortByWrapper>
                    </Section>
                    <Section>
                        <CommonText size="medium" weight={600} color='#4B4F5E'>
                            Sort Direction
                        </CommonText>
                        <GroupItemsTick options={optionsDirection} activeId={filterInfo.direction} type={2} selectItem={(item) => { updateCustomerSearchInfo({ direction: item.id }) }} />
                    </Section>
                    <Section>
                        <CommonText size="medium" weight={600} color='#4B4F5E'>
                            Type
                        </CommonText>
                        <SortByWrapper>
                            {optionsType.map(option => {
                                return (
                                    <Option key={option.id} >
                                        <Checkbox
                                            id={`checkbox-${option.id}`}
                                            checked={filterInfo.type.includes(option.value)}
                                            onChange={() => { handleTypeChange(option.value) }}
                                            version='v2' />
                                        <CommonText size="semiMedium" weight={500} color='#4B4F5E'>
                                            {option.label}
                                        </CommonText>
                                    </Option>
                                )
                            })}
                        </SortByWrapper>
                    </Section>
                </FilterOptionWrapper>
            </ModalBody>
            <Footer>
                <div className="mobile-view">
                    <FooterSection>
                        <Button size="md" full={true} type="button" color="primary" onClick={() => handleSearchCustomer()}>
                            Search
                        </Button>
                        <Button size="md" full={true} type="button" onClick={() => handleResetFilter()}>
                            Reset
                        </Button>
                    </FooterSection>
                </div>
                <div className="desktop-view">
                    <DesktopBottomControl>
                        <DesktopBottomFooter>
                            <Button color="primary" onClick={() => handleSearchCustomer()}>Search</Button>
                            <Button type="button" overrideColor={'#F1F4F7'} customColor={'#4B4F5E'} onClick={() => handleResetFilter()} >
                                Reset
                            </Button>
                        </DesktopBottomFooter>
                    </DesktopBottomControl>
                </div>
            </Footer>

        </>
    )
})