import React from "react";
import styled from "styled-components";
import { MobileModalBody, MobileModalContent, MobileModalHeader } from "../../views/settings-v2";
import { CommonText } from "@lib/components";
import { IconClose } from "../../../../components/v2/icons";

export const BottomModalFooterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;
`
const DesktopBottomFooter = styled.div`
    max-width: 865px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    width: 100%;
    padding: 0 16px;
`;
const DesktopBottomControl = styled.div`
    position: fixed;
    bottom: 0;
    width: calc(100vw - 57px);
    right: 0;
`;

const BottomModal = (props: any) => {
    const {open, title, onClose, footer, children } = props;
    return (
        <DesktopBottomControl>
            {open && <MobileModalContent style={{ maxWidth: "100%" }}>
                {title && <MobileModalHeader>
                    <CommonText size="medium" color="#4B4F5E">{title}</CommonText>
                    <span onClick={onClose}>
                        <IconClose />
                    </span>
                </MobileModalHeader>}
                <MobileModalBody>
                    {children}
                </MobileModalBody>
                <BottomModalFooterWrapper>
                    <DesktopBottomFooter>
                        {footer}
                    </DesktopBottomFooter>
                </BottomModalFooterWrapper>
            </MobileModalContent>}
        </DesktopBottomControl>
    )

}

export default BottomModal;