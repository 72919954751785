import { Button, Checkbox, FooterSection, Radio, Sidebar } from '@lib/components';
import { MobXProviderContext } from 'mobx-react';
import React, { useState } from 'react';
import {
  OptionFilterWrapper,
  OptionHeading,
  OptionPart,
  OptionStyle,
  ShowResults,
  SidebarFilterBody,
  SoidLine,
  SortDirectionStyle,
  seenOptions,
  serviceTypeOptions,
  sortDirectionConstant,
  sortOptions,
  statusOptions,
} from './filter-list-constant';
import { FaCheck } from 'react-icons/fa';

type FilterListViewV2Props = {
  isOpen: boolean;
  handleClose: () => void;
};
type SortOptions = 'created' | 'due' | 'name' | 'total';
export const FilterListViewV2: React.FC<FilterListViewV2Props> = ({ isOpen, handleClose }) => {
  const [sortBy, setSortBy] = useState<SortOptions>('created');
  const [sortDirection, setSortDirection] = useState(-1);
  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
  const [selectedServiceType, setSelectedServiceType] = useState<string[]>([]);
  const [selectedSeen, setSelectedSeen] = useState<boolean[]>([]);
  const { store } = React.useContext(MobXProviderContext);
  const restaurantId = store.restaurant._id;
  const handleSortChange = (value: SortOptions) => {
    setSortBy(value);
  };
  const handleSortDirectionChange = (value: number) => {
    setSortDirection(value);
  };
  const handleStatusChange = (status: string) => {
    const updatedStatus = selectedStatus.includes(status)
      ? selectedStatus.filter((s: string) => s !== status)
      : [...selectedStatus, status];
    setSelectedStatus(updatedStatus);
  };
  const handleServiceTypeChange = (serviceType: any) => {
    const updatedServiceType = selectedServiceType.includes(serviceType)
      ? selectedServiceType.filter(s => s !== serviceType)
      : [...selectedServiceType, serviceType];
    setSelectedServiceType(updatedServiceType);
  };
  const handleSeen = (seen: boolean) => {
    const updatedSeen = selectedSeen.includes(seen) ? selectedSeen.filter(s => s !== seen) : [...selectedSeen, seen];
    setSelectedSeen(updatedSeen);
  };
  const updateOrdersStore = (
    store: any,
    restaurantId: string,
    status: any,
    seen: any,
    serviceType: any,
    sortDirectionValue: number
  ) => {
    const sortMapping = {
      created: {
        created: sortDirectionValue,
      },
      due: {
        'ready_in.timestamp': sortDirectionValue,
      },
      name: {
        'customer.name': sortDirectionValue,
      },
      total: {
        'bill.total': sortDirectionValue,
      },
    };
    const sortValue = sortMapping[sortBy] || {};
    const query = {
      restaurant_id: restaurantId,
      ...(serviceType && serviceType.length > 0 && { 'config.service': { $in: serviceType } }),
      ...(status && status.length > 0 && { status: { $in: status } }),
      ...(seen && seen.length > 0 && { status: { $in: seen } }),
    };
    return store.api.orders_find({
      page: 1,
      limit: 25,
      sort: sortValue,
      query: query,
    });
  };

  const handleFilter = async () => {
    const response = await updateOrdersStore(
      store,
      restaurantId,
      selectedStatus,
      selectedSeen,
      selectedServiceType,
      sortDirection
    );
    if (response.outcome) throw new Error(response.message);
    store.updateOrders({
      items: response.items,
      count: response.count,
      page: 1,
    });
    handleClose();
  };
  const handleReset = async () => {
    handleClose();
    setSortBy('created');
    setSortDirection(-1);
    setSelectedStatus([]);
    setSelectedServiceType([]);
    setSelectedSeen([]);
    const response = await updateOrdersStore(store, restaurantId, [], [], [], -1);
    if (response.outcome) throw new Error(response.message);
    store.updateOrders({
      items: response.items,
      count: response.count,
      page: 1,
    });
  };

  return (
    <Sidebar isOpen={isOpen} headerTitle="Filter Orders" onClose={handleClose}>
      <SidebarFilterBody style={{ padding: '16px' }}>
        <OptionFilterWrapper>
          <ShowResults>Showing 25 Results</ShowResults>
          <OptionPart>
            <OptionHeading>Sort By:</OptionHeading>
            <OptionStyle>
              {sortOptions.map((sort, i) => {
                return (
                  <div key={i} className="p-tb-1">
                    <Radio
                      id={`sort-${sort.value}`}
                      name={`sort-key`}
                      checked={sortBy === sort.value}
                      title={sort.displayText}
                      value={sort.value}
                      onChange={() => handleSortChange(sort.value as SortOptions)}
                    />
                  </div>
                );
              })}
            </OptionStyle>
          </OptionPart>
          <SoidLine />
          <OptionPart>
            <OptionHeading>Sort Direction</OptionHeading>
            <SortDirectionStyle>
              {sortDirectionConstant.map(direction => {
                const isActive = sortDirection === direction.value;

                return (
                  <div
                    key={direction.id}
                    className={`sort-option${isActive ? ' active' : ''}`}
                    onClick={() => handleSortDirectionChange(direction.value)}
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <div>{direction.text}</div>
                    {isActive && (
                      <div>
                        <FaCheck />
                      </div>
                    )}
                  </div>
                );
              })}
            </SortDirectionStyle>
          </OptionPart>
          <SoidLine />
          <OptionPart>
            <OptionHeading>Status</OptionHeading>
            <OptionStyle>
              {statusOptions.map((status, i) => (
                <div key={i} style={{ display: 'flex', margin: '8px 0', height: '22px' }}>
                  <Checkbox
                    id={`status-${status.value}`}
                    checked={selectedStatus.includes(status.value)}
                    className="m-r-1"
                    onChange={() => handleStatusChange(status.value)}
                  />
                  {status.displayText}
                </div>
              ))}
            </OptionStyle>
          </OptionPart>
          <SoidLine />
          <OptionPart>
            <OptionHeading>Service Type</OptionHeading>
            <OptionStyle>
              {serviceTypeOptions.map((service, i) => (
                <div key={i} style={{ display: 'flex', margin: '8px 0', height: '22px' }}>
                  <Checkbox
                    id={`service-${service.value}`}
                    checked={selectedServiceType.includes(service.value)}
                    className="m-r-1"
                    onChange={() => handleServiceTypeChange(service.value)}
                  />
                  {service.displayText}
                </div>
              ))}
            </OptionStyle>
          </OptionPart>
          <SoidLine />
          <OptionPart>
            <OptionHeading>Seen</OptionHeading>
            <OptionStyle>
              {seenOptions.map((seen, i) => (
                <div key={i} style={{ display: 'flex', height: '22px' }}>
                  <Checkbox
                    id={`seen-${seen.value}`}
                    checked={selectedSeen.includes(seen.value)}
                    className="m-r-1"
                    onChange={() => handleSeen(seen.value)}
                  />
                  {seen.displayText}
                </div>
              ))}
            </OptionStyle>
          </OptionPart>
        </OptionFilterWrapper>
      </SidebarFilterBody>
      <FooterSection>
        <Button full={true} type="button" color="primary" onClick={handleFilter} disabled={false}>
          Apply filter
        </Button>
        <Button full={true} type="button" style={{ backgroundColor: '#F1F4F7' }} onClick={handleReset} disabled={false}>
          Reset
        </Button>
      </FooterSection>
    </Sidebar>
  );
};
