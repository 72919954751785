import * as React from "react";
import { FastField } from "formik";
import { FieldOpeningHoursV2, FormGroup, Switch } from "@lib/components";
import { Input } from "@lib/components";
import { Button } from "@lib/components";
import { RotateLoader } from "@lib/components";
import { inject, observer } from "mobx-react";
import { ModalContent } from "@lib/components";
import { Modal } from "@lib/components";
import { TabSelect, SelectAdv, FieldOpeningHours } from "@lib/components";
import { DataLocales, ListOrderTimesOptions, ListServicesOptionsNoB } from "@lib/common";
import _cloneDeep from "lodash/cloneDeep";
import { MobxComponent } from "../../../../../mobx/components";
import { RestaurantForm } from "../../../../../mobx/components/restaurant-form";
import { MenuItemText } from "../menus/layout/floating-button";
import { IconHelp } from "../../../../components/v2/icons";
import { SettingsFormGroup, SettingsFormGroupInput, SettingsFormLabelContainer, SettingsFormLabelLeftContainer, SettingsFormLabelRightContainer, SettingsFormInputContainer, SettingsSwitchContainer, SettingsActionButtonContainer, SettingsActionButtonWrapper } from "../settings-v2";
import { TabGroup, TabItem } from "../settings-v2/receipt-printing";

type FormValues = T.Schema.Restaurant.Menu.RestaurantCategory;

interface Props {
	type: "edit" | "create";
	menuIndex: number;
	initialValues: FormValues | null;
	close: () => void;
}

interface State {
	tab: string;
}

@inject("store") @observer
export class RestaurantFormCategoryV2 extends MobxComponent<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			tab: "0",
		};
	}

	setTab = (tab: string) => {
		this.setState({ tab });
	};

	componentDidUpdate(prevProps: Props) {
		if (this.props.initialValues && !prevProps.initialValues) {
			this.setTab("0");
		}
	}

	render() {
		const { type, close } = this.props;
		const { tab } = this.state;
		const { store } = this.injected;
		const { activeMobileMenu } = store;

		const initialValues = _cloneDeep(this.props.initialValues);

		if (initialValues && !initialValues.conditions) {
			initialValues.conditions = {
				hide_unavailable_category: false,
				services: [],
				hours: [],
			};
		}

		let openItems = false;
		let openItemsList: any = [];
		const r = this.injected.store.restaurant;
		// console.log(1)
		if (r && initialValues) {
			// console.log(2)
			// console.log({ initialValues });
			// const filteredMenus = r.menus.filter((menu: any) => menu._id === initialValues.menu_id);
			// if (filteredMenus.length > 0) {
			if (
				// filteredMenus[0]
				// && typeof filteredMenus[0].abacus_open_items === "boolean"
				// && filteredMenus[0].abacus_open_items === true
				r.abacus
				&& typeof r.abacus.abacus_open_items === "boolean"
				&& r.abacus.abacus_open_items === true
			) {
				// console.log(3)
				openItems = true;
				if (
					// filteredMenus[0]
					// && filteredMenus[0].abacus_open_item_list
					r.abacus.abacus_open_item_list
					&& r.abacus.abacus_open_item_list.length > 0
				) {
					// console.log(4)
					const filteredList = r.abacus.abacus_open_item_list.filter(openItem => !['Online Promos', 'Online Fees', 'Online Tips'].includes(openItem.productName))
					openItemsList = filteredList.map(openItemListItem => {
						return {
							value: openItemListItem.productId,
							label: openItemListItem.productName
						}
					});
				}
			}
			// }
		}
		// console.log({ openItems });
		//
		const menuTabs = openItems
			? [
				{ label: "General", value: "0" },
				{ label: "Conditions", value: "1" },
				{ label: "Open Items", value: "2" },
			]
			: (r?.wpos?.wpos_status ? [
				{ label: "General", value: "0" },
				{ label: "Conditions", value: "1" },
				{ label: "POS", value: "3" },
			] : [
				{ label: "General", value: "0" },
				{ label: "Conditions", value: "1" },
			]);
		//
		return (
					<>
					{(activeMobileMenu.name === 'create-category' || activeMobileMenu.name === 'edit-category') &&
					 <TabGroup className='menus options-set-header' style={{ overflow: 'unset', padding: '16px'}}>
						
						<TabItem
							active={this.state.tab === '0'}
							onClick={() => this.setState({ tab: '0' })}
							style={{ fontSize: '12px' }}
						>
							General
						</TabItem>
						<TabItem
							active={this.state.tab === '1'}
							onClick={() => this.setState({ tab: '1' })}
							style={{ fontSize: '12px' }}
						>
							Conditions
						</TabItem>
						<TabItem
							active={this.state.tab === '2'}
							onClick={() => this.setState({ tab: '2' })}
							style={{ fontSize: '12px' }}
						>
							Open Items
						</TabItem>
					</TabGroup>}
					<RestaurantForm<FormValues>
				submit={async (r: any, values: FormValues): Promise<{ r: any; update: { $set: { menus: any[] } } }> => {
					const { menuIndex } = this.props;
				
					r.menus = r.menus || [];
					r.menus[menuIndex] = r.menus[menuIndex] || { categories: [] };
					r.menus[menuIndex].categories = r.menus[menuIndex].categories || [];
				
					const existingIndex = r.menus[menuIndex].categories.findIndex((p: FormValues) => p._id === values._id);
					if (existingIndex === -1) {
						r.menus[menuIndex].categories.push(values);
					} else {
						r.menus[menuIndex].categories[existingIndex] = values;
					}
				
					const update = { $set: { menus: r.menus } };
					return { r, update };
				}}
				validators={{
					name: (values) => {
						if (!values.name) return { name: "This field is required" };
					},
				}}
				initialValues={initialValues}
				onSuccess={close}
				onSuccessMessage={type === "edit" ? "Category edited" : "Category created"}
			>
					{({ form, error, getFieldError }) => {
						const { isSubmitting, setFieldValue } = form;
						const { store } = this.injected;
						return (
							<div>
								{this.state.tab === '0' && <>
									<SettingsFormGroup>
										<SettingsFormGroupInput>
											<SettingsFormLabelContainer>
												<SettingsFormLabelLeftContainer>Name</SettingsFormLabelLeftContainer>
												<SettingsFormLabelRightContainer>
													<IconHelp />
												</SettingsFormLabelRightContainer>
											</SettingsFormLabelContainer>
											<SettingsFormInputContainer>
												<FastField
													name="name"
													render={({ field }: any) => (
														<input
															type="text"
															{...field} />
													)} />
											</SettingsFormInputContainer>
										</SettingsFormGroupInput>
										<SettingsFormGroupInput>
											<SettingsFormLabelContainer>
												<SettingsFormLabelLeftContainer>Display Name</SettingsFormLabelLeftContainer>
												<SettingsFormLabelRightContainer>
													<IconHelp />
												</SettingsFormLabelRightContainer>
											</SettingsFormLabelContainer>
											<SettingsFormInputContainer>
												<FastField
													name="display_name"
													render={({ field }: any) => (
														<input
															type="text"
															{...field} />
													)} />
											</SettingsFormInputContainer>
										</SettingsFormGroupInput>
										<SettingsFormGroupInput>
											<SettingsFormLabelContainer>
												<SettingsFormLabelLeftContainer>Description</SettingsFormLabelLeftContainer>
												<SettingsFormLabelRightContainer>
													<IconHelp />
												</SettingsFormLabelRightContainer>
											</SettingsFormLabelContainer>
											<SettingsFormInputContainer>
												<FastField
													name="description"
													render={({ field }: any) => (
														<input
															type="text"
															{...field} />
													)} />
											</SettingsFormInputContainer>
										</SettingsFormGroupInput>
									</SettingsFormGroup>
								</>}
								{this.state.tab === '1' && <>
									<SettingsFormGroup>
										<SettingsSwitchContainer>
											<SettingsFormLabelLeftContainer>
												Hide Unavailable Menu
											</SettingsFormLabelLeftContainer>
											<FastField
												name="conditions.hide_unavailable_menu"
												render={({ field }: any) => (
													<Switch
														id="conditions.hide_unavailable_menu"
														checked={field.value || false}
														onChange={(e) => setFieldValue(
															"conditions.hide_unavailable_menu",
															e.target
																.checked
														)} />
												)} />
										</SettingsSwitchContainer>

										<SettingsFormGroupInput
											error={(error && getFieldError(form, 'conditions.services')) || null}>
											<SettingsFormLabelContainer>
												<SettingsFormLabelLeftContainer>Services</SettingsFormLabelLeftContainer>
												<SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
											</SettingsFormLabelContainer>
											<SettingsFormInputContainer>
												<FastField
													name="conditions.services"
													render={({ field }: any) => (
														<SelectAdv
															type="single"
															options={ListServicesOptionsNoB}
															value={field.value}
															onChange={(option: string) => setFieldValue("conditions.services", option)} />
													)} />
											</SettingsFormInputContainer>
										</SettingsFormGroupInput>
										<SettingsFormGroupInput
											error={(error && getFieldError(form, 'hours'))|| null}>
											<SettingsFormLabelContainer>
												<SettingsFormLabelLeftContainer>Applicable Hours</SettingsFormLabelLeftContainer>
												<SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
											</SettingsFormLabelContainer>
											<FastField
												name="conditions.hours"
												render={({ field }: any) => (
													<FieldOpeningHoursV2
													allowNone={true}
													hours={field.value}
													onChange={(opening_hours) => {
														setFieldValue("conditions", {
															...form.values.conditions,
															hours: opening_hours,
														});
													}}
/>
												)} />
										</SettingsFormGroupInput>
									</SettingsFormGroup>

								</>}

								{this.state.tab === '2' &&
									<div style={{padding: 16}}>
										<SettingsFormGroupInput>
											<SettingsFormLabelContainer>
												<SettingsFormLabelLeftContainer>Abacus Open Item</SettingsFormLabelLeftContainer>
												<SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
											</SettingsFormLabelContainer>
											<SettingsFormInputContainer>
												<FastField
													name="abacus_open_item"
													render={({ field }: any) => (
														<SelectAdv
															type="single"
															options={ListOrderTimesOptions}
															value={field.value}
															onChange={(option: string) => setFieldValue("abacus_open_item", option)} />
													)} />
											</SettingsFormInputContainer>
										</SettingsFormGroupInput>
									</div>}
								<SettingsActionButtonContainer>
									<SettingsActionButtonWrapper  style={{ left: 0}}>
										<button type="button"
											className="default btn-cancel"
											onClick={() => {
												store.setActiveMobileMenu(
													store.activeMobileMenu.referrer.pop()!,
													Array.from(new Set(store.activeMobileMenu.referrer))
												);
											} }>
											Cancel
										</button>
										<button type="submit" disabled={isSubmitting}>
											{isSubmitting && <RotateLoader size={2} color="white" />}
											{!isSubmitting && "Save"}
										</button>
									</SettingsActionButtonWrapper>
								</SettingsActionButtonContainer>
							</div>
						);
					} }
				</RestaurantForm>
				</>
		);

	}

}
