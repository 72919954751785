import React, { useState } from 'react'
import { WebsiteForm } from '../../../../../mobx/components/website-form';
import { cloneDeep } from 'lodash';
import { UI } from '../../../../../core/ui';
import { logger } from '@lib/common';
import { useStores } from '../../../../../mobx/useStores';
import { BlockError, BlockSuccess, Button, FieldDomain, FieldSubdomain, FormGroup, LinkTag, RotateLoader } from '@lib/components';
import { FastField } from 'formik';
import { SettingsActionButtonContainer, SettingsActionButtonWrapper, SettingsFormGroup, SettingsFormGroupInput, SettingsFormLabelContainer, SettingsFormLabelLeftContainer, SettingsFormLabelRightContainer } from '../../../restaurant/views/settings-v2';
import { IconHelp } from '../../../../components/v2/icons';

interface Props {
	type: string
}

interface FormValuesSubdomain {
	subdomain: string;
}

interface FormValuesDomain {
	domain: string | null;
}


export const DomainsV2 = (props: Props) => {
	let initialValuesSubdomain: FormValuesSubdomain; 
	let initialValuesDomain: FormValuesDomain;
	const { type } = props;
	const store = useStores();
	const reseller = store.reseller!;
	const website = store.website!;

	const [certLoading, setCertLoading] = useState(false);
	const [certExists, setCertExists] = useState(false);
	const [certError, setCertError] = useState(false);
    const checkCertificate = async () => {
		try {

			const w = store.website!;

			if (!w.domain) return;

			setCertLoading(true)
			setCertExists(false)
			setCertError(false)

			const response = await store.api.proxy_domain_cert_check({
				domain: w.domain,
			});

			if (response.outcome) {
				UI.notification.error(response.message, { timeout: 6000 });

				setCertLoading(false)
				setCertExists(false)
				setCertError(true)
			}
			else {
				setCertLoading(false)
				setCertExists(response.exists)
				setCertError(false)
			}

		}
		catch (e) {
			logger.captureException(e);
			UI.notification.error("Something went wrong, loading your domain status");
			setCertLoading(false)
			setCertExists(false)
			setCertError(true)
		}
	}
	const issueCertificate = async () => {
		try {

			const w = store.website!;

			if (!w.domain) return;

			setCertLoading(true)
			setCertExists(false)
			setCertError(false)

			const response = await store.api.proxy_domain_cert_issue({
				restaurant_id: "",
				website_id: w._id,
			});

			if (response.outcome) {
				UI.notification.error(response.message, { timeout: 9000 });

				setCertLoading(false)
				setCertExists(false)
				setCertError(false)
			}
			else {

				setCertLoading(false)
				setCertExists(true)
				setCertError(false)
			}

		}
		catch (e) {
			logger.captureException(e);
			UI.notification.error("Something went wrong, try again or contact us");
			setCertLoading(false)
			setCertExists(false)
			setCertError(true)
		}
	}
	const validate = (subdomain: string) => {
		if (
			!subdomain.match(/^[a-z0-9](?:[a-z0-9-]{2,61}[a-z0-9])?$/) ||
			subdomain.length <= 3 ||
			subdomain.indexOf(".") !== -1
		) {

			return false;
		}
		return true;
	}

    initialValuesSubdomain = {
        subdomain: website.subdomain,
    };

	initialValuesDomain = {
		domain: website.domain,
	};

	if(type === 'custom-domain') {
		return (
			<WebsiteForm<FormValuesDomain>
			customSubmit={async (values, form) => {
			  try {
				form.setSubmitting(true);
				const { domain } = values;
				const response = await store.api.proxy_domain_update({
				  restaurant_id: '',
				  website_id: website._id,
				  domain: domain || null,
				});
				if (response.outcome) {
				  UI.notification.error(response.message, { timeout: 6000 });
				} else {
				  UI.notification.success('Domain updated');
				  const w = cloneDeep(website);
				  w.domain = domain || null;
				  store.setWebsite(w);
				  setCertLoading(false)
				  setCertExists(response.cert_exists)
				  setCertError(false)
				}
			  } catch (e) {
				logger.captureException(e);
				UI.notification.error('Something went wrong, try again or contact us');
			  } finally {
				form.setSubmitting(false);
			  }
			}}
			validators={{}}
			initialValues={initialValuesDomain}
			onSuccess={() => {}}
			onError={() => UI.notification.error('An error occurred')}
			onSuccessMessage="Settings Updated"
			onErrorMessage=""
		  >
			{({ form, error }) => {
			  const { isSubmitting, setFieldValue, setFieldError } = form;
			  return (
				<div className="p-4">
				  <FormGroup title="How It Works">
					<p className="lhp">
					  Start by entering your domain name below and save the form. To connect your custom domain name to
					  your website, you will need to add an "A record" to your domains DNS settings. The "A record"
					  should match your chosen domain name and have a value of "35.238.2.132". If you need help with
					  this step, please contact us.
					</p>
					<p className="lhp m-t-2">
					  Once the domain record is added, give it a few minutes to take effect and press the "Generate SSL
					  Certificate" button below. If it fails, check your DNS record and allow more time for it to take
					  effect, then try again
					</p>
				  </FormGroup>

				  <FastField
					name="domain"
					render={({ field }: any) => (
					  <FormGroup
						title="Custom Domain"
						help="Enter the sub-domain name where your website be located. This can be changed any time and you can set a custom domain later"
						error={form.errors.domain}
					  >
						<FieldDomain
						  website_id={website._id}
						  restaurant_id=""
						  value={field.value}
						  check={store.api.proxy_domain_check}
						  onChange={value => setFieldValue('domain', value, false)}
						  onError={e => setFieldError('domain', e)}
						/>
					  </FormGroup>
					)}
				  />

				  {website.domain && (
					<FormGroup title="Domain Status">
					  {certError && (
						<p>
						  Failed to load domain status, <LinkTag onClick={checkCertificate}>try again</LinkTag>
						</p>
					  )}
					  {!certError && certLoading && <RotateLoader size={2} />}
					  {!certError && !certLoading && (
						<div>
						  {!certExists && (
							<div>
							  <BlockError
								text="No SSL certificate found, your site won't work till you generate one"
								className="inline-block"
							  />
							  <div />
							  <Button
								size="xs"
								color="primary-inverse"
								type="button"
								className="m-t-3"
								onClick={issueCertificate}
							  >
								Generate SSL Certificate
							  </Button>
							</div>
						  )}
						  {certExists && (
							<BlockSuccess
							  text="SSL certificate generated, your domain is live"
							  className="inline-block"
							/>
						  )}
						</div>
					  )}
					</FormGroup>
				  )}

				  {error && <FormGroup error={error} />}

				  
				  <SettingsActionButtonContainer className='no-sidebar'>
					<SettingsActionButtonWrapper style={{left: 0}}>
						<button 
                        type="button" 
                        className='btn-cancel'
                        onClick={ () => store.setActiveMobileMenu(
                            store.activeMobileMenu.referrer.pop()!,
                            Array.from(new Set(store.activeMobileMenu.referrer))
                        )}>
                        Cancel
                    </button>
						<button type="submit" disabled={isSubmitting}>
							{isSubmitting && <RotateLoader size={2} color="white" />}
							{!isSubmitting && 'Save'}
						</button>
					</SettingsActionButtonWrapper>
				</SettingsActionButtonContainer>
				</div>
			  );
			}}
		  </WebsiteForm>
		)
	}
    return(
    <WebsiteForm<FormValuesSubdomain>
    customSubmit={async (values, form) => {
    try {
        form.setSubmitting(true);
        const { subdomain } = values;
        const check_subdomain = validate(subdomain);
        if (check_subdomain) {
        const response = await store.api.proxy_subdomain_update({
            subdomain: subdomain.toLowerCase(),
            restaurant_id: '',
            website_id: website._id,
        });
        if (response.outcome) {
            UI.notification.error(response.message, { timeout: 6000 });
        } else {
            UI.notification.success('Sub-domain updated');
            const w = cloneDeep(website);
            w.subdomain = subdomain.toLowerCase();
            store.setWebsite(w);
        }
        } else {
        UI.notification.error('Invalid sub-domain name, try again !');
        }
    } catch (e) {
        logger.captureException(e);
        UI.notification.error('Something went wrong, try again or contact us');
    } finally {
        form.setSubmitting(false);
    }
    }}
    validators={{}}
    initialValues={initialValuesSubdomain}
    onSuccess={() => {}}
    onError={() => UI.notification.error('An error occurred')}
    onSuccessMessage="Settings Updated"
    onErrorMessage=""
    >
    {({ form, error }) => {
    const { isSubmitting, setFieldValue, setFieldError } = form;
    return (
        <SettingsFormGroup>
            <SettingsFormGroupInput>
                <SettingsFormLabelContainer>
                    <SettingsFormLabelLeftContainer>Sub-domain</SettingsFormLabelLeftContainer>
                    <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                </SettingsFormLabelContainer>
                <FastField
                    name="subdomain"
                    render={({ field }: any) => (
                
                        <FieldSubdomain
                        restaurant_id=""
                        website_id={website._id}
                        base_domain={reseller.store_host}
                        value={field.value}
                        checkDomain={store.api.proxy_subdomain_check}
                        onChange={subdomain => setFieldValue('subdomain', subdomain, false)}
                        onCheckCallback={e => setFieldError('subdomain', e)}
                        />
                    )}
                />
            </SettingsFormGroupInput>

            {error && <FormGroup error={error} />}

            <SettingsActionButtonContainer className='no-sidebar'>
                <SettingsActionButtonWrapper style={{left: 0}}>
					<button 
                        type="button" 
                        className='btn-cancel'
                        onClick={ () => store.setActiveMobileMenu(
                            store.activeMobileMenu.referrer.pop()!,
                            Array.from(new Set(store.activeMobileMenu.referrer))
                        )}>
                        Cancel
                    </button>
                    <button type="submit" disabled={form.errors.subdomain !== ''}>
                        {isSubmitting && <RotateLoader size={2} color="white" />}
                        {!isSubmitting && 'Save'}
                    </button>
                </SettingsActionButtonWrapper>
            </SettingsActionButtonContainer>
        </SettingsFormGroup>
    );
    }}
    </WebsiteForm>)
}
