import * as React from "react";
import { MobxComponent } from "../../../mobx/components/index";
import { Switch, TopNav } from "@lib/components";
import { LayoutDashboardComponents } from "./layout.c";
import { inject, observer } from "mobx-react";
import { Tooltip } from "@lib/components";
import { FaLifeRing, FaPowerOff, FaRocket } from "react-icons/fa";
import { withTranslation, WithTranslation } from "react-i18next";
import { IconSettings, IconClose, IconHamburger, IconPlus, IconLogout, IconRoadmap, IconDevelopers, IconDocument, IconStaff, IconLandingPage, IconStore, IconBack, IconPowerOff, IconGlobe, IconMoreOptions } from "../../components/v2/icons";
import { SidebarLogoWrapper, HeaderTitleWrapper, MenuItem, QuickSettingsContainer, QuickSettingsHeaderWrapper, QuickSettingsBodyWrapper, QuickSettingsFooterWrapper, QuickSettingsButton, QuickSettingsItemsContainer, QuickSettingsItemsWrapper, QuickSettingsItemWrapper, HeaderMobile, HeaderMobileTitleWrapper, HeaderMobileTitle, HeaderMobileMenuContainer, HeaderMobileMenuUpperWrapper, HeaderMobileMenuWrapper, HeaderMobileMenuTitleWrapper, HeaderV2, HeaderV2LeftContainer, HeaderV2RightContainer, HeaderV2MenuGroup, HeaderV2MenuLabel, HeaderV2MenuItems } from "../../components/v2/layout.c";
import { QuickSettings } from "../../components/v2/quicksettings";
import { SidebarAPIComponent } from "./views/api/sidebar-api";
import { SettingsHeader, SettingsHeaderTextBreadcrumbs, SettingsHeaderTextContainer, SettingsHeaderTextTitle, SettingsHeaderTextWrapper } from "../restaurant/views/settings-v2";

interface Props extends WithTranslation {
	children: React.ReactNode;
}
interface State {
	quickSettings: boolean;
	activeMobileMenu: boolean;
	createApiMobile: boolean;
	toggleLocalize: boolean;
}

@inject("store") @observer
class LayoutDashboardClass extends MobxComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			quickSettings: false,
			activeMobileMenu: false,
			createApiMobile: false,
			toggleLocalize: false
		};
	}

	componentDidMount() {
		const script = document.createElement("script");
		script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyAc_6JJnBdHbKjXGeoKIznTE22-YaRRuSA&libraries=geometry,places";
		document.body.appendChild(script);

		const { store } = this.injected;
		const { organisation } = store;
		//@ts-ignore
        if(document && Localize && Localize.loaded) {
			var lw = document.getElementById('localize-widget');
			if(lw) {
				store.setLocalizeWidget(lw);
				lw.remove();
			//@ts-ignore
				Localize.hideWidget();
				//@ts-ignore
				Localize.on("setLanguage", (data) => {
					store.setCwLang(data.to)
					localStorage.setItem('cw-lang', data.to)
				});
			}
        }
	}

	componentWillUnmount(): void {
		const { store } = this.injected;
		store.setLocalizeRef(React.createRef());
	}

	toggleQuickSettings() {
		this.setState({
			quickSettings: !this.state.quickSettings
		})
	}

	toggleMobileMenu() {
        this.setState({
            activeMobileMenu: !this.state.activeMobileMenu,
        })
    }

	getPathnameText(pathname: string){
		if(pathname === '/website') return 'Landing Page'
		if(pathname === '/staff') return 'Staff'
		if(pathname === '/developer') return 'Developers'
		
		return 'Restaurants'
	}
	
	render() {
		const { store, t } = this.injected;
		const reseller = store.reseller!;
		const pathname = store.router.s.path;
		const { trialExpiry, trialExpired, restrictions, isStaff, isMainReseller, organisation } = store;
		const { children } = this.props;
		const { logo_icon } = reseller.admin;
		const links = [];
		const { toggleLocalize } = this.state;

		const handleAdding = () => {
			switch(pathname){
				case '/developer':
					this.setState({createApiMobile: true})
					break;
				case '/staff':
					store.setOpenModalStaff(true)
					break;
				case '/':
					store.setOpenCreateRestaurant(true)
					break;
				default:
					break;
			}
		}

		if (
			restrictions.restaurant.create ||
			restrictions.restaurant.delete ||
			restrictions.restaurant.billing ||
			restrictions._.restaurantView
		) {
			links.push({
				text: "Restaurants",
				onClick: () => store.router.push("/"),
				active: pathname === "/",
			});
		}

		if (restrictions.website.create || restrictions.website.edit) {
			links.push({
				text: "Landing Page",
				onClick: () => store.router.push("/website"),
				active: pathname === "/website",
			});
		}

		if (restrictions.staff.create || restrictions.staff.edit || restrictions.staff.delete) {
			links.push({
				text: "Staff",
				onClick: () => {store.router.push("/staff"); store.setRouterStaff(''); store.setOpenModalStaff(false)},
				active: pathname === "/staff",
			});
		}

		if (restrictions.api && (restrictions.api.create || restrictions.api.edit || restrictions.api.delete)) {
			links.push({
				text: "Developers",
				onClick: () => store.router.push("/developer"),
				active: pathname === "/developer",
			});
		}

		
		return (
			<div>
				
				{organisation && organisation.dashboard === 'v2.1' ? <>
				<HeaderV2>
					<HeaderV2LeftContainer>
						<img src={logo_icon} />
						<HeaderV2MenuGroup>
							{links.map( (link, index) => (
								<HeaderV2MenuLabel
									key={index}
									active={link.active}
									onClick={link.onClick}>
									{link.text}
								</HeaderV2MenuLabel>
							))}
						</HeaderV2MenuGroup>
					</HeaderV2LeftContainer>
					<HeaderV2RightContainer>
						<HeaderV2MenuItems
							 style={{position: 'relative'}}
							 onClick={() => {
								 if(!toggleLocalize) {
									if(store.localizeRef.current?.children.length == 0
										&& store.localizeWidget) {
										 store.localizeWidget.style.cssText = `
										 	position: absolute;
											top: 32px;
											right: 0px;
											left: unset;
										 `
										 store.localizeRef.current?.appendChild(store.localizeWidget!)
									 } else store.localizeRef.current?.appendChild(store.localizeWidget!)
									 //@ts-ignore
									 Localize.showWidget()
								 } else {
									  //@ts-ignore
									 Localize.hideWidget()
								 }
								 this.setState({
									 toggleLocalize: !toggleLocalize
								 })
							  }}>
							<IconGlobe /> <span className="lang-text" style={{textTransform: 'uppercase'}}>{store.cwLang}</span>
							<div ref={store.localizeRef} style={{
								position: 'absolute',
								height: 200,
								top: 0,
								left: 130
							}}></div>
						</HeaderV2MenuItems>
						<HeaderV2MenuItems
							onClick={() => { 
								store.updateView({quicksettings_active: true})
							}}>
							<IconSettings />
						</HeaderV2MenuItems>
						<HeaderV2MenuItems
							onClick={() => window.open('https://support.cloudwaitress.com', '_blank')}>
							<IconDocument />
						</HeaderV2MenuItems>
						<HeaderV2MenuItems
							onClick={() => window.open('https://feedback.cloudwaitress.com/roadmap', '_blank')}>
							<IconRoadmap />
						</HeaderV2MenuItems>
						<HeaderV2MenuItems
							onClick={() => store.service.logout()}>
							<IconPowerOff />
						</HeaderV2MenuItems>
					</HeaderV2RightContainer>
				</HeaderV2>
				{ pathname === '/staff' && 
					<SettingsHeader>
						<SettingsHeaderTextContainer>
							<SettingsHeaderTextWrapper>
								<SettingsHeaderTextTitle>{store.staffRouter ? store.staffRouter : 'Staff List'}</SettingsHeaderTextTitle>
								
								<SettingsHeaderTextBreadcrumbs style={{flexDirection: 'row', justifyContent: 'start', gap: 4}}>
									<div style={{cursor: 'pointer'}} onClick={ () => {store.router.push("/"); store.setRouterStaff(''); store.setOpenModalStaff(false)}}>Home</div> / 
									<div style={{cursor: 'pointer'}} onClick={() => {store.router.push("/staff"); store.setRouterStaff(''); store.setOpenModalStaff(false)}}>Staff</div>
									{store.staffRouter && <div> / Staff List </div>}
								</SettingsHeaderTextBreadcrumbs>
							</SettingsHeaderTextWrapper>
						</SettingsHeaderTextContainer>
					</SettingsHeader>
				}

				<HeaderMobile>
					{store.activeMobileMenu.referrer.length > 0 ?
					<MenuItem
						onClick={() => {
							store.setActiveMobileMenu(
								store.activeMobileMenu.referrer.pop()!,
								Array.from(new Set(store.activeMobileMenu.referrer))
							)
						}}>
						<IconBack />
					</MenuItem>
					:<MenuItem
						onClick={() => this.toggleMobileMenu()}>
						<IconHamburger />
                    </MenuItem> }
					<HeaderMobileTitleWrapper isDevelopers={pathname === "/developer"}>
						<HeaderMobileTitle>
							{this.getPathnameText(pathname)}
						</HeaderMobileTitle>
					</HeaderMobileTitleWrapper>
					{ (pathname === "/developer" || pathname === "/staff") && (<MenuItem>
						<span style={{height: 24}} onClick={() => {
							if(pathname.includes('developer')) {
								store.setActiveMobileMenu('add-key', 'developers')
							}
							else handleAdding()
						}}>
						<IconPlus />
						</span>
					</MenuItem>)}

					{pathname === '/' && store.activeMobileMenu.referrer.length == 0 && 
					<>
					<MenuItem
						onClick={() => {
							store.setActiveMobileMenu('add-restaurant', 'restaurants')
	
						}}>
						<IconPlus />
					</MenuItem>
					<MenuItem
						onClick={() => store.setActiveMobileModal('more-actions')}>
						<IconMoreOptions />
					</MenuItem>
					</>}

				</HeaderMobile>
				<HeaderMobileMenuContainer  className={`${this.state.activeMobileMenu ? 'active' : ''}`}>
					<HeaderMobileMenuUpperWrapper>
						<HeaderMobileMenuTitleWrapper style={{borderBottom: '1px solid #dfdfdf'}}>
							<MenuItem
								onClick={() => this.toggleMobileMenu()}>
									<IconBack />
							</MenuItem>
							<HeaderMobileTitle>
								Menu
							</HeaderMobileTitle>
							<div></div>
						</HeaderMobileMenuTitleWrapper>
						<HeaderMobileMenuWrapper  style={{borderBottom: '1px solid #dfdfdf'}}>
							<MenuItem
								active = { pathname === '/'}
								onClick={() => {
									this.toggleMobileMenu()
									store.router.push('/')
									}}>
								<IconStore /> Restaurants
							</MenuItem>
							<MenuItem
								active = { pathname === '/website'}
								onClick={() => {
									this.toggleMobileMenu()
									store.router.push('/website')
									}}>
								<IconLandingPage /> Landing Page
							</MenuItem>
							<MenuItem
								active = { pathname === '/staff'}
								onClick={() => {
									this.toggleMobileMenu()
									store.router.push('/staff')
									store.setOpenModalStaff(false)
									store.setRouterStaff('')
									}}>
								<IconStaff /> Staff
							</MenuItem>
							<MenuItem
								active = { pathname === '/developer'}
								onClick={() => {
									this.toggleMobileMenu()
									store.router.push('/developer')
									}}>
								<IconDevelopers />Developers
							</MenuItem>
						</HeaderMobileMenuWrapper>
						<HeaderMobileMenuWrapper>
							<MenuItem
								onClick={() => { 
									this.toggleMobileMenu()
									store.updateView({quicksettings_active: true})
								}}>
								<IconSettings />Dashboard Switch
							</MenuItem>
							<MenuItem
								onClick={() => window.open('https://support.cloudwaitress.com', '_blank')}>
								<IconDocument /> Documentation
							</MenuItem>
							<MenuItem
								onClick={() => window.open('https://feedback.cloudwaitress.com/roadmap', '_blank')}>
								<IconRoadmap /> Roadmap and Feature request
							</MenuItem>
							<MenuItem onClick={() => store.service.logout()}>
							<IconLogout/>
								Log out
							</MenuItem>
						</HeaderMobileMenuWrapper>
					</HeaderMobileMenuUpperWrapper>
				</HeaderMobileMenuContainer>
				</>
				: <TopNav.Wrapper>
				<TopNav.Content width="lg" align="space-between">

					<div className="flex-line centered">

						<TopNav.Logo className="m-r-4">
							<img src={logo_icon} />
						</TopNav.Logo>

						<TopNav.Menu
							align="left"
							items={links}
						/>

					</div>

					<div className="flex-line centered child-mr-7">
					
						<Tooltip
							text="Dashboard Switch"
							width={120}
							position="bottom"
							offset={5}>
							<TopNav.Icon onClick={() => store.updateView({quicksettings_active: true})}>
								<IconSettings />
							</TopNav.Icon>
						</Tooltip>
						
						{(!trialExpired && !isStaff) && (
							<Tooltip
								width={200}
								position="bottom"
								offset={10}
								text={<p className="lhp p-1">Ensure all your restaurants have a payment method to prevent issues</p>}>
								<p className="">
									Trial ends {t("dateFromTimestamp", { value: trialExpiry })}
								</p>
							</Tooltip>
						)}
						{isMainReseller && (
							<Tooltip
								text="Documentation"
								width={120}
								position="bottom"
								offset={5}>
								<a href="https://support.cloudwaitress.com" target="_blank">
									<TopNav.Icon>
										<FaLifeRing />
									</TopNav.Icon>
								</a>
							</Tooltip>
						)}
						{isMainReseller && (
							<Tooltip
								text={<span className="inline-block lhp p-lr-1">Roadmap & feature requests</span>}
								width={120}
								position="bottom"
								offset={5}>
								<a href="https://feedback.cloudwaitress.com/roadmap" target="_blank">
									<TopNav.Icon>
										<FaRocket />
									</TopNav.Icon>
								</a>
							</Tooltip>
						)}
						<Tooltip text="Logout" width={60} position="bottom" offset={5}>
							<TopNav.Icon onClick={() => store.service.logout()}>
								<FaPowerOff />
							</TopNav.Icon>
						</Tooltip>
					</div>

				</TopNav.Content>
			</TopNav.Wrapper>}
				<LayoutDashboardComponents.Content
					pathname={pathname}
					isStaffMobile={pathname === '/staff' || pathname === '/' && 
					store.organisation?.dashboard === 'v2.1'} className={(store.organisation && store.organisation.dashboard) || ''}>
					{children}
				</LayoutDashboardComponents.Content>
				{store.view.quicksettings_active  && <QuickSettings />  }
				<SidebarAPIComponent active={this.state.createApiMobile} index={-1} items={undefined} restaurants={store.restaurants.items} handleClose={() => {this.setState({createApiMobile: false})}} />
			</div>
		);
	}

}

export const LayoutDashboard = withTranslation()(LayoutDashboardClass);
