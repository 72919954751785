import React from 'react'
import { WebsiteForm } from '../../../../../mobx/components/website-form';
import { useStores } from '../../../../../mobx/useStores';
import { UI } from '../../../../../core/ui';
import { FormGroup, Input, RotateLoader } from '@lib/components';
import { FastField } from 'formik';
import { SettingsActionButtonContainer, SettingsActionButtonWrapper } from '../../../restaurant/views/settings-v2';
import _ from 'lodash'
type FormValues = T.Schema.Website.WebsiteSchema["seo"];

export const SEOV2 = () => {
    const store = useStores();
    const w = store.website!;
    let initialValues = w.seo;

    return (
        <WebsiteForm<FormValues>
        submit={async (w, values) => {
            w.seo = values;
            const update = { $set: { "seo": {
                title: _.escape(w.seo.title),
                meta: _.escape(w.seo.meta)
            } } };
            return { w, update };
        }}
        validators={{}}
        initialValues={initialValues}
        onSuccess={() => { }}
        onError={() => UI.notification.error("An error occurred")}
        onSuccessMessage="Settings Updated"
        onErrorMessage="">
        {({ form, error }) => {
            const { isSubmitting, setFieldValue, setFieldTouched } = form;
            return (
                <div className="p-4">

                    <FastField
                        name="title"
                        render={({ field }: any) => (
                                <FormGroup
                                    title="Website Title"
                                    help="The document title for your website">
                                    
                                    <Input title="Google typically displays the first 50-60 characters" {...field} />
                                </FormGroup>
                        )}
                    />

                    <FastField
                        name="meta"
                        render={({ field }: any) => (
                                <FormGroup
                                    title="Website Meta Description"
                                    help="The page description / meta description for your website">
                                    <Input title="Google typically displays the first 50-60 characters" {...field} />
                                </FormGroup>
                        )}
                    />

<SettingsActionButtonContainer className='no-sidebar'>
                        <SettingsActionButtonWrapper style={{left: 0}}>
                            <button 
                                type="button" 
                                className='btn-cancel'
                                onClick={ () => store.setActiveMobileMenu(
                                    store.activeMobileMenu.referrer.pop()!,
                                    Array.from(new Set(store.activeMobileMenu.referrer))
                                )}>
                                Cancel
                            </button>
                            <button  type="submit" disabled={isSubmitting}>
                            {isSubmitting && <RotateLoader size={2} color="white" />}
                            {!isSubmitting && "Save"}
                            </button>
                        </SettingsActionButtonWrapper>
                    </SettingsActionButtonContainer>
                    

                </div>
            );
        }}
    </WebsiteForm>
    )
}