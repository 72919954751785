import React, { useEffect, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import styled from 'styled-components';
import { Card } from '../customerCard';
import { Button, CommonText, Sidebar } from '@lib/components';
import { CreateCustomerDesktopWrapper, CustomerBodyWrapper, CustomerModalWrapper, DesktopExportWrapper, FilterHeader, ListContainer } from './style';
import { CustomerDetail } from './customerDetails';
import { CustomerFilter } from './customerFilter';
import { BackIcon, IconSettings } from '../../../../../../components/v2/icons';
import { MobileModal } from '../../../settings-v2';
import { Header, ModalWrapper } from '../../../bookings/mobileBookings/modals/bookingDetail/bookingDetail.component';
import { CustomerCSVExport } from './customerExportData';
import { CreateCustomerModal } from './createCustomerModal';

const CustomerBody = observer(() => {
    const { store } = React.useContext(MobXProviderContext);
    const customers = store.customers;
    const { openExportModal, currentCustomer, openFilterModal, openCreateCustomer } = store.customerControl;
    const r = store.restaurant!;
    const openCustomerList = !currentCustomer && !openFilterModal && !openExportModal && !openCreateCustomer;
    
    const handleItemClick = (item: any) => {
        store.updateCustomerControl({ currentCustomer: item })
    };
    const handleClose = () => {
        store.updateCustomerControl({ currentCustomer: null })
    }

    const handleGetCustomerData = async () => {
        store.handleSearchCustomer(store.customerSearchInfo, store.customers.page + 1);
    }

    return (
        <CustomerBodyWrapper>
            <div className='mobile-view'>
                {
                    openCustomerList && customers.items?.map((customerInfo: any) => <Card key={Math.random()} handleOpen={() => handleItemClick(customerInfo)} customerInfo={customerInfo} />)
                }

                {openCustomerList && customers.items.length >= (10 * store.customers.page) && <Button overrideColor={'#F1F4F7'} customColor='#FB2F2F' full={true} border={true} onClick={() => handleGetCustomerData()}>
                    Show more</Button>
                }
                <Sidebar isOpen={!!currentCustomer} headerTitle="Customer - Details" onClose={handleClose} isMobile={true} version='v2'>
                    {currentCustomer && <CustomerDetail />}
                </Sidebar>
                {openFilterModal &&
                    <CustomerModalWrapper bg='#FEFEFE'>
                        <FilterHeader style={{
                            borderBottom: '1px solid #DFDFDF'
                        }}>
                            <BackIcon onClick={() => store.updateCustomerControl({ openFilterModal: false })} />
                            <CommonText size="medium" weight={600} color='#0C202A'>
                                Customer Filters
                            </CommonText>
                            <div style={{
                                opacity: '0'
                            }}>
                                <IconSettings />
                            </div>
                        </FilterHeader>
                        <CustomerFilter isOpen={openFilterModal}/>
                    </CustomerModalWrapper>
                }
                {openExportModal && <MobileModal>
                    <ModalWrapper bg='#F1F4F7'>
                        <Header style={{
                            borderBottom: '1px solid #DFDFDF'
                        }}>
                            <BackIcon onClick={() => store.updateCustomerControl({ openExportModal: false })} />
                            <CommonText size="medium" weight={600} color='#0C202A'>
                                Customer - Export
                            </CommonText>
                            <div style={{
                                opacity: '0'
                            }}>
                                <IconSettings />
                            </div>
                        </Header>
                        <DesktopExportWrapper>
                            <CommonText size="medium" weight={600} color='#4B4F5E' style={{margin: '16px 16px 0'}}>
                                CSV Data Export
                            </CommonText>
                            <CustomerCSVExport />
                        </DesktopExportWrapper>
                    </ModalWrapper>
                </MobileModal>
                }

            </div>
            <div className='desktop-view'>
                {
                    openCustomerList && customers.items?.map((customerInfo: any) => <Card key={Math.random()} customerInfo={customerInfo} handleOpen={() => handleItemClick(customerInfo)} />)
                }

                {openCustomerList && customers.items.length >= (10 * store.customers.page) && <Button overrideColor={'#F1F4F7'} customColor='#FB2F2F' border={true} onClick={() => handleGetCustomerData()}>
                    Show more</Button>
                }
                {currentCustomer && <CustomerDetail />}
                {openFilterModal && <CustomerFilter isOpen={openFilterModal}
                />}
                {openExportModal &&
                    <DesktopExportWrapper>
                        <CommonText size="medium" weight={600} color='#4B4F5E' style={{marginLeft: '16px'}}>
                            CSV Data Export
                        </CommonText>
                        <CustomerCSVExport />
                    </DesktopExportWrapper>
                }
                {openCreateCustomer &&
                    <CreateCustomerDesktopWrapper>
                        <CreateCustomerModal/>
                    </CreateCustomerDesktopWrapper>
                }
            </div>

        </CustomerBodyWrapper>
    );
});

export default CustomerBody;