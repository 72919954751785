import React from 'react'
import { WebsiteForm } from '../../../../../mobx/components/website-form';
import { useStores } from '../../../../../mobx/useStores';
import { UI } from '../../../../../core/ui';
import { FormGroup, Input, RotateLoader, Switch } from '@lib/components';
import { FastField } from 'formik';
import { SettingsActionButtonContainer, SettingsActionButtonWrapper } from '../../../restaurant/views/settings-v2';
import { toJS } from 'mobx';

type FormValues = T.Schema.Website.WebsiteSchema["sections"]["contact_form"];

export const ContactFormV2 = () => {
    const store = useStores();
    const w = store.website!;
    let initialValues = toJS(w.sections.contact_form);
    return (
        <WebsiteForm<FormValues>
				submit={async (w, values) => {

					w.sections.contact_form = values;

					const update = { $set: { "sections.contact_form": values } };

					return { w, update };

				}}
				validators={{}}
				initialValues={initialValues}
				onSuccess={() => { }}
				onError={() => UI.notification.error("An error occurred")}
				onSuccessMessage="Settings Updated"
				onErrorMessage="">
				{({ form, error }) => {
					const { isSubmitting, setFieldValue, setFieldTouched } = form;
					return (
						<div className="p-4">

							<FastField
								name="hidden"
								render={({ field }: any) => (
									<FormGroup title="">
										<div style={{ display: 'flex', justifyContent: 'space-between'}}>
											<div style={{ fontSize: '18px', lineHeight: '24px', fontWeight: 600 }}>Hide Contact Form</div>
											<div>
											<Switch
												id="hidden"
												checked={field.value}
												onChange={(e) => setFieldValue("hidden", e.target.checked)} />
											</div>
										</div>
										
									</FormGroup>
								)}
							/>

							<FastField
								name="email"
								render={({ field }: any) => (
									<FormGroup title="Contact Form E-Mail" help="This is required for the contact form to be displayed">
										<Input type="email" {...field} />
									</FormGroup>
								)}
							/>

							{error && <FormGroup error={error} />}
                            <SettingsActionButtonContainer className='no-sidebar'>
                        <SettingsActionButtonWrapper style={{left: 0}}>
                            <button 
                                type="button" 
                                className='btn-cancel'
                                onClick={ () => store.setActiveMobileMenu(
                                    store.activeMobileMenu.referrer.pop()!,
                                    Array.from(new Set(store.activeMobileMenu.referrer))
                                )}>
                                Cancel
                            </button>
                            <button  type="submit" disabled={isSubmitting}>
                            {isSubmitting && <RotateLoader size={2} color="white" />}
                            {!isSubmitting && "Save"}
                            </button>
                        </SettingsActionButtonWrapper>
                    </SettingsActionButtonContainer>
							

						</div>
					);
				}}
			</WebsiteForm>
    )
}