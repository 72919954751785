import React from 'react';
import { RotateLoader, Switch } from '@lib/components';
import { IconHelpV2, IconHelp } from '../../../../../components/v2/icons';
import {
  SettingsFormGroup,
  SettingsSwitchContainer,
  SettingsFormLabelLeftContainer,
  SettingsFormGroupInput,
  SettingsFormLabelContainer,
  SettingsFormLabelRightContainer,
  SettingsFormInputContainer,
  SettingsActionButtonContainer,
  SettingsActionButtonWrapper,
} from '..';
import { RestaurantForm } from '../../../../../../mobx/components/restaurant-form';
import { useStores } from '../../../../../../mobx/useStores';
import { updateRestaurantNotificationOptions } from '../../../../../../core/libs/receipt';
import { UI } from '../../../../../../core/ui';
import { FastField } from 'formik';
import styled from 'styled-components';

type FormValues = T.Schema.Restaurant.RestaurantSchema["settings"]["services"]

export const WaitTimesAutoStatusServices = (props: any) => {
  const store = useStores();
  const r = store.restaurant!;
  const { injected, referrer } = props

 
  let service:  T.Schema.Restaurant.Services.RestaurantServiceTypes = 'pickup';
  if(referrer.includes('deliveries')) {
    service = 'delivery';
  } else if(referrer.includes('dine-ins')) {
    service = 'dine_in';
  } else if(referrer.includes('table-booking')) {
    service = 'table_booking';
  }
	let initialValues = r.settings.services[service];

  return (
    <div style={{paddingTop: 16, paddingBottom: 83}}>
    
    <RestaurantForm<FormValues>
      submit={async (r, values) => {
         {/*@ts-ignore*/}
        r.settings.services[service] = values;
        const update = { $set: { [`settings.services.${service}`]: r.settings.services[service] } };
        return { r, update };
      }}
      //@ts-ignore
      initialValues={initialValues}
      onSuccess={async () => await updateRestaurantNotificationOptions(injected.store)}
      onError={() => UI.notification.error("An error occurred")}
      onSuccessMessage="Settings Updated"
      width='100%'
    >
      {({ form, error, getFieldError }) => {
        const { isSubmitting, setFieldValue } = form;
        return (
          <>
            <SettingsFormGroup>
              <p style={{ fontSize: '12px' }}>
                The following settings are used to automatically update order statuses and calculate order wait times. The
                customer wait time is calculated by adding the time till confirm & ready values together
              </p>
              <SettingsSwitchContainer>
                <SettingsFormLabelLeftContainer>
                  Enable Automated Order Statuses
                  <IconHelpV2 />
                </SettingsFormLabelLeftContainer>
              </SettingsSwitchContainer>
              <SettingsSwitchContainer>
                <SettingsFormLabelLeftContainer>Confirm</SettingsFormLabelLeftContainer>
                <FastField
                  name="status.auto.confirmed"
                  render={({ field, form }: any) => (
                    <Switch
                        id="enable-auto-confirmed-switch"
                        checked={field.value}
                        onChange={(e) => form.setFieldValue("status.auto.confirmed", e.target.checked)} />
                  )} />
              </SettingsSwitchContainer>
              {props.service !== "table_booking" && ( <SettingsSwitchContainer>
                <SettingsFormLabelLeftContainer>Ready</SettingsFormLabelLeftContainer>
                <FastField
                    name="status.auto.ready"
                    render={({ field, form }: any) => (
                      <Switch
                          id="enable-auto-ready-switch"
                          checked={field.value}
                          onChange={(e) => form.setFieldValue("status.auto.ready", e.target.checked)} />
                    )}
                  />
              </SettingsSwitchContainer>
              )}
              {service === "delivery" && (
                <SettingsSwitchContainer>
                  <SettingsFormLabelLeftContainer>On Route</SettingsFormLabelLeftContainer>
                  <FastField
                    name="status.auto.on_route"
                    render={({ field, form }: any) => (
                      <Switch
                          id="enable-auto-on_route-switch"
                          checked={field.value}
                          onChange={(e) => form.setFieldValue("status.auto.on_route", e.target.checked)} />
                    )}
                  />
                </SettingsSwitchContainer>
                )}
              <SettingsSwitchContainer>
                <SettingsFormLabelLeftContainer>Complete</SettingsFormLabelLeftContainer>
                <FastField
                  name="status.auto.complete"
                  render={({ field, form }: any) => (
                    <Switch
                        id="enable-auto-complete-switch"
                        checked={field.value}
                        onChange={(e) => form.setFieldValue("status.auto.complete", e.target.checked)} />
                  )}
                />
              </SettingsSwitchContainer>
              <p style={{ fontSize: '12px' }}>
                Automatically change order statuses based on timings set below. Only works for orders due immediately
              </p>
              <SettingsFormGroupInput>
                <SettingsFormLabelContainer>
                  <SettingsFormLabelLeftContainer>Time Till Confirm (minutes)</SettingsFormLabelLeftContainer>
                  <SettingsFormLabelRightContainer>
                    <IconHelp />
                  </SettingsFormLabelRightContainer>
                </SettingsFormLabelContainer>
                <SettingsFormInputContainer>
                  <FastField
                    name="status.times.confirmed"
                    render={({ field }: any) => (
                       <input type="number" step={1} min={0} {...field}/> 
                    )} />
                </SettingsFormInputContainer>
                <p style={{ fontSize: '12px' }}>
                  Time in minutes from un-confirmed to confirmed. Set 0 for instant confirmation
                </p>
              </SettingsFormGroupInput>
              <SettingsFormGroupInput>
                <SettingsFormLabelContainer>
                  <SettingsFormLabelLeftContainer>Time Till Ready (minutes)</SettingsFormLabelLeftContainer>
                  <SettingsFormLabelRightContainer>
                    <IconHelp />
                  </SettingsFormLabelRightContainer>
                </SettingsFormLabelContainer>
                <SettingsFormInputContainer>
                  <FastField
                    name="status.times.ready"
                    render={({ field }: any) => (
                      <input type="number" step={1} min={0} {...field}/>
                    
                    )} />
                </SettingsFormInputContainer>
                <p style={{ fontSize: '12px' }}>
                  Time in minutes from confirmed to ready. Set 0 for instant change, leaving empty will have no effect
                </p>
              </SettingsFormGroupInput>
              {service === 'delivery' && <SettingsFormGroupInput>
                <SettingsFormLabelContainer>
                  <SettingsFormLabelLeftContainer>Time Till On Route (minutes)</SettingsFormLabelLeftContainer>
                  <SettingsFormLabelRightContainer>
                    <IconHelp />
                  </SettingsFormLabelRightContainer>
                </SettingsFormLabelContainer>
                <SettingsFormInputContainer>
                <FastField
                  name="status.times.on_route"
                  render={({ field }: any) => (
                    <input type="number" step={1} min={0} {...field}/>
                  )} />
                </SettingsFormInputContainer>
                <p style={{ fontSize: '12px' }}>
                Time in minutes from ready to on route, delivery orders only. Set 0 for instant change, leaving empty will have no effect
                </p>
              </SettingsFormGroupInput> }
              <SettingsFormGroupInput>
                <SettingsFormLabelContainer>
                  <SettingsFormLabelLeftContainer>Time Till Complete (minutes)</SettingsFormLabelLeftContainer>
                  <SettingsFormLabelRightContainer>
                    <IconHelp />
                  </SettingsFormLabelRightContainer>
                </SettingsFormLabelContainer>
                <SettingsFormInputContainer>
                <FastField
                  name="status.times.complete"
                  render={({ field }: any) => (
                      <input type="number" step={1} min={0} {...field}/>
                  
                  )} />
                </SettingsFormInputContainer>
                <p style={{ fontSize: '12px' }}>
                  Time in minutes from the previous status to complete. Set 0 for instant change, leaving empty will have no
                  effect
                </p>
              </SettingsFormGroupInput>
              <SettingsFormGroupInput>
                <SettingsFormLabelContainer>
                  <SettingsFormLabelLeftContainer>Order Rush Management</SettingsFormLabelLeftContainer>
                </SettingsFormLabelContainer>
              </SettingsFormGroupInput>
              <div
                style={{
                  fontSize: '14px',
                  fontWeight: 400,
                  color: '#6B7270',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '8px',
                }}
              >
                <div>
                  The settings below help you increase the estimated wait time of a customer's order during busy periods. It
                  follows a simple structure. For every X oustanding orders, increase the estimated wait time by Y minutes
                </div>
                <div>
                  An outstanding order is any order that is currently un-confirmed or confirmed. For example, if your wait
                  time is 20 minutes, and you set 'for every 2 outstanding orders, increase the wait time by 10 minutes', this
                  is how it would play out:
                </div>
                <ul>
                  <li>0 outstanding orders - 20 minute wait time</li>
                  <li>1 outstanding orders - 20 minute wait time</li>
                  <li>2 outstanding orders - 30 minute wait time</li>
                  <li>3 outstanding orders - 30 minute wait time</li>
                  <li>4 outstanding orders - 40 minute wait time</li>
                </ul>
              </div>
              <SettingsFormGroupInput>
                <SettingsFormLabelContainer>
                  <SettingsFormLabelLeftContainer>For Every X Outstanding Orders</SettingsFormLabelLeftContainer>
                  <SettingsFormLabelRightContainer>
                    <IconHelp />
                  </SettingsFormLabelRightContainer>
                </SettingsFormLabelContainer>
                <SettingsFormInputContainer>
                  <FastField
                    name="status.rush.linear_order_threshold"
                    render={({ field }: any) => (
                      <input {...field} value={field.value || ""} type="number" step={1} min={0} />
                    )}
                  />
                </SettingsFormInputContainer>
                <p style={{ fontSize: '12px' }}>
                  The number of outstanding orders before an increase in wait time is applied
                </p>
              </SettingsFormGroupInput>
              <SettingsFormGroupInput>
                <SettingsFormLabelContainer>
                  <SettingsFormLabelLeftContainer>Increase Wait Time By Y Minutes</SettingsFormLabelLeftContainer>
                  <SettingsFormLabelRightContainer>
                    <IconHelp />
                  </SettingsFormLabelRightContainer>
                </SettingsFormLabelContainer>
                <SettingsFormInputContainer>
                  <FastField
                    name="status.rush.linear_time_increment"
                    render={({ field }: any) => (
                      <input {...field} value={field.value || ""} type="number" step={1} min={0} />
                    )}
                  />
                </SettingsFormInputContainer>
                <p style={{ fontSize: '12px' }}>
                  The amount of time to add onto the customers wait time if the order count above is exceeded
                </p>
              </SettingsFormGroupInput>
            </SettingsFormGroup>
            <SettingsActionButtonContainer>
                <SettingsActionButtonWrapper>
                    <button type="button" 
                        className="default btn-cancel" 
                        onClick={ () => store.setActiveMobileMenu(
                            store.activeMobileMenu.referrer.pop()!,
                            Array.from(new Set(store.activeMobileMenu.referrer))
                        )}>
                            Cancel 
                    </button>
                    <button type="submit" disabled={isSubmitting}>
                        {isSubmitting && <RotateLoader size={2} color="white" />}
                        {!isSubmitting && "Save"}
                    </button>
                </SettingsActionButtonWrapper>
            </SettingsActionButtonContainer>
          </>
        )}}
    </RestaurantForm>
    </div>
  );
};
