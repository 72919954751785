import React from 'react';
import { MobxComponent } from "../../../mobx/components";
import { inject, observer } from "mobx-react";
import { withTranslation, WithTranslation } from "react-i18next";
import { IconSettings, IconClose } from "../../components/v2/icons";
import { Switch } from "@lib/components";
import { HeaderTitleWrapper, MenuItem, QuickSettingsContainer, QuickSettingsHeaderWrapper, QuickSettingsBodyWrapper, QuickSettingsFooterWrapper, QuickSettingsButton, QuickSettingsItemsContainer, QuickSettingsItemsWrapper, QuickSettingsItemWrapper } from "./layout.c";
import { UI } from '../../../core/ui';
import { Select } from '@lib/components/src';


interface Props extends WithTranslation {}
interface State {
    toggle: boolean;
    version?: string;
}

@inject("store") @observer
class QuickSettingsClass extends MobxComponent<Props, State> {
    constructor(props: Props) {
		super(props);
        this.state = {
            toggle: false,
            version: 'v1'
        };
	}

    componentDidMount(): void {
        const { organisation } = this.injected.store
        this.setState({
            toggle: (organisation!.dashboard == 'v2'),
            version: organisation!.dashboard
        })
    }

    render() {
        const { store, t} = this.injected;
        const { organisation } = store;
        if(!organisation) return
        return (
        <QuickSettingsContainer className={store.view.quicksettings_active ? 'active': ''}>
            <QuickSettingsHeaderWrapper>
                <HeaderTitleWrapper style={{justifyContent: 'start'}}>
                    <IconSettings />
                    <div>Quick Settings</div>
                </HeaderTitleWrapper>
                <MenuItem onClick={() => store.updateView({quicksettings_active: false})}><IconClose /></MenuItem>
            </QuickSettingsHeaderWrapper>
            <QuickSettingsBodyWrapper>
                <QuickSettingsItemsContainer>
                    <QuickSettingsItemsWrapper>
                        <QuickSettingsItemWrapper style={{flexDirection: 'column', alignItems: 'start'}}>
                            <span>Choose Layout</span>

                            <Select
                                value={this.state.version}
                                options={[
                                    {label: 'Classic Version', value: 'v1'},
                                    {label: 'Modern Version', value: 'v2'},
                                    {label: 'Full Modern Version', value: 'v2.1'},
                                ]}
                                onChange={(e) => {
                                    this.setState({
                                        version: e.target.value
                                    })
                                }}/> 
                        </QuickSettingsItemWrapper>
                        {organisation && (organisation.dashboard === undefined || organisation.dashboard == 'v1') &&
                        <p>Ordering dashboard is currently using the classic layout.</p>}
                    </QuickSettingsItemsWrapper>
                </QuickSettingsItemsContainer>
            </QuickSettingsBodyWrapper>
            <QuickSettingsFooterWrapper>
                <QuickSettingsButton 
                    onClick={() => {
                        store.updateView({quicksettings_active: false})
                        store.updateOrganisation({
                            dashboard: this.state.version
                        })
                        store.api.organisation_update({
                            _id: organisation._id,
                            update: {
                                $set: {
                                    dashboard: organisation.dashboard
                                }
                            }
                        }).then(() =>  UI.notification.success("Layout updated") )
                    }}>
                    Save Settings
                </QuickSettingsButton>
            </QuickSettingsFooterWrapper>
        </QuickSettingsContainer>
        )
    }
}
export const QuickSettings = withTranslation()(QuickSettingsClass);
