import React from 'react';
import {
  SettingsFormGroup,
  SettingsFormLabelLeftContainer,
  SettingsFormGroupInput,
  SettingsFormLabelContainer,
  SettingsFormLabelRightContainer,
  SettingsFormInputContainer,
  SettingsActionButtonContainer,
  SettingsActionButtonWrapper,
} from '../..';
import { useStores } from '../../../../../../../mobx/useStores';
import { IconHelp } from '../../../../../../components/v2/icons';
import { RestaurantForm } from '../../../../../../../mobx/components/restaurant-form';
import { UI } from '../../../../../../../core/ui';
import { updateRestaurantNotificationOptions } from '../../../../../../../core/libs/receipt';
import { FastField } from 'formik';
import { RotateLoader } from '@lib/components';
type FormValues = T.Schema.Restaurant.RestaurantSchema["settings"]["services"]["table_booking"];

export const BookingTimingServices = (props: any) => {
  const store = useStores();
  const r = store.restaurant!;
  const { injected } = props
	let initialValues = r.settings.services.table_booking;
  return (
    <div style={{paddingTop: 16, paddingBottom: 83}}>
      <RestaurantForm<FormValues>
        submit={async (r, values) => {
          r.settings.services.table_booking = values;
          const update = { $set: { "settings.services.table_booking": r.settings.services.table_booking } };
          return { r, update };
        }}
        initialValues={initialValues}
        onSuccess={async () => await updateRestaurantNotificationOptions(injected.store)}
        onError={() => UI.notification.error("An error occurred")}
        onSuccessMessage="Settings Updated"
        width='100%'
      >
        {({ form, error, getFieldError }) => {
          const { isSubmitting, setFieldValue } = form;
          return (
          <>
            <SettingsFormGroup>
              <SettingsFormGroupInput>
                <SettingsFormLabelContainer>
                  <SettingsFormLabelLeftContainer>Maximum Days Ahead</SettingsFormLabelLeftContainer>
                  <SettingsFormLabelRightContainer>
                    <IconHelp />
                  </SettingsFormLabelRightContainer>
                </SettingsFormLabelContainer>
                <SettingsFormInputContainer>
                <FastField
                name="times.conditions.max_days"
                render={({ field }: any) => (
                  <input type="number" step={1} min={0} required={true} {...field} />
                )} />
                </SettingsFormInputContainer>
                <p style={{ fontSize: '12px' }}>
                  Maximum number of people allowed for a booking request. Leave blank for no limit
                </p>
              </SettingsFormGroupInput>
              <SettingsFormGroupInput>
                <SettingsFormLabelContainer>
                  <SettingsFormLabelLeftContainer>Time Interval (minutes)</SettingsFormLabelLeftContainer>
                  <SettingsFormLabelRightContainer>
                    <IconHelp />
                  </SettingsFormLabelRightContainer>
                </SettingsFormLabelContainer>
                <SettingsFormInputContainer>
                <FastField
                    name="times.conditions.time_interval"
                    render={({ field }: any) => (
                      <input type="number" step={1} min={1} required={true} {...field} />
                    )} />
                </SettingsFormInputContainer>
                <p style={{ fontSize: '12px' }}>
                  Minimum number of people allowed for a booking request. Leave blank for no minimum
                </p>
              </SettingsFormGroupInput>
              <SettingsFormGroupInput>
                <SettingsFormLabelContainer>
                  <SettingsFormLabelLeftContainer>Order Offset (minutes)</SettingsFormLabelLeftContainer>
                  <SettingsFormLabelRightContainer>
                    <IconHelp />
                  </SettingsFormLabelRightContainer>
                </SettingsFormLabelContainer>
                <SettingsFormInputContainer>
                <FastField
                name="times.conditions.order_offset"
                render={({ field }: any) => (
                  <input type="number" step={1} min={0} required={true} {...field} />
                )} />
                </SettingsFormInputContainer>
                <p style={{ fontSize: '12px' }}>
                  The minimum amount of time from now that an order for a later time must be placed
                </p>
              </SettingsFormGroupInput>
            </SettingsFormGroup>
            <SettingsActionButtonContainer>
               <SettingsActionButtonWrapper>
                    <button type="button" 
                        className="default btn-cancel" 
                        onClick={ () => store.setActiveMobileMenu(
                            store.activeMobileMenu.referrer.pop()!,
                            Array.from(new Set(store.activeMobileMenu.referrer))
                        )}>
                            Cancel 
                    </button>
                    <button type="submit" disabled={isSubmitting}>
                        {isSubmitting && <RotateLoader size={2} color="white" />}
                        {!isSubmitting && "Save"}
                    </button>
                </SettingsActionButtonWrapper>
            </SettingsActionButtonContainer>
          </>)}}
      </RestaurantForm>
    </div>
  );
};
