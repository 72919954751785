import React, { useState } from "react";
import { SettingsWebsiteWrapper } from "./themes";
import { OptionalText, SettingsActionButtonContainer, SettingsActionButtonWrapper, SettingsFormGroup, SettingsFormGroupInput, SettingsFormInputContainer, SettingsFormLabelContainer, SettingsFormLabelLeftContainer, SettingsFormLabelRightContainer, SettingsSwitchContainer } from "..";
import { CookieColorPickerContent, CookieColorPickerWrapper, CookieColorsContainer, CookieColorsRow } from "../policies-agreements";
import { ColorPicker, FormUpload, RotateLoader, Switch } from "@lib/components";
import { CoreUtils } from "@lib/common";
import { IconHelp, IconUploadImage } from "../../../../../components/v2/icons";
import { ImageLogoContainer } from "../email-notification";
import { RestaurantForm } from "../../../../../../mobx/components/restaurant-form";
import { UI } from "../../../../../../core/ui";
import { useStores } from "../../../../../../mobx/useStores";
import { FastField } from "formik";

interface Props {
    activeDesignMode?: boolean
}
type FormValues = T.Schema.Restaurant.RestaurantSchema["website"]["sections"]["header"];

export const SettingsWebsiteHeader = (props: Props) => {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const { activeDesignMode } = props
    const store = useStores()
    const r = store.restaurant!;
    let initialValues = r.website.sections.header;
    return (
        <RestaurantForm<FormValues>
        submit={async (r, values) => {
            r.website.sections.header = values;
            const update = { $set: { "website.sections.header": values } };
            return { r, update };
        }}
        initialValues={initialValues}
        onError={() => UI.notification.error("An error occurred")}
        onSuccessMessage="Settings Updated"
        width="100%"
    >
        {({ form, error }) => {
            const { isSubmitting, setFieldValue } = form;
            return (
        <SettingsWebsiteWrapper style={{ paddingBottom: '58px'}}>
            <SettingsFormGroup>
            <SettingsFormGroupInput>
                    <SettingsSwitchContainer>
                        <SettingsFormLabelLeftContainer>
                           Show Header
                        </SettingsFormLabelLeftContainer>
                        <FastField
								name="images.show"
								render={({ field }: any) => (
								    <Switch
											id="switch"
											checked={field.value === undefined ? true : field.value}
											onChange={(e) => setFieldValue("images.show", e.target.checked)}
										/>
								)}
							/>
                    </SettingsSwitchContainer>
                </SettingsFormGroupInput>
                <SettingsFormGroupInput style={{gap: 16, borderBottom: '1px dashed var(--Secondary-Light-Grey-1, #DFDFDF)', paddingBottom: 16}}>
                    <CookieColorsContainer>
                        <CookieColorsRow>
                            <p>Background</p>
                            <CookieColorPickerWrapper>
                            <FastField
									name="colors.background"
									render={({ field }: any) => (
                                <CookieColorPickerContent>
                                        <ColorPicker
                                            color={field.value}
                                            onChange={(color) => setFieldValue('colors.background', color)}
                                            />
                                                {'#'+CoreUtils.colors.rgbToHex(field.value)}
                                        </CookieColorPickerContent>
                                    )} />
                            </CookieColorPickerWrapper>
                        </CookieColorsRow>
                    </CookieColorsContainer>
                </SettingsFormGroupInput>
                <SettingsFormGroupInput style={{gap: 16, borderBottom: '1px dashed var(--Secondary-Light-Grey-1, #DFDFDF)', paddingBottom: 16}}>
                    <CookieColorsContainer>
                        <CookieColorsRow>
                            <p>Text</p>
                            <CookieColorPickerWrapper>
                            <FastField
									name="colors.text"
									render={({ field }: any) => (
                                <CookieColorPickerContent>
                                        <ColorPicker
                                            color={field.value}
                                            onChange={(color) => setFieldValue('colors.text', color)}
                                            />
                                          
                                                {'#'+CoreUtils.colors.rgbToHex(field.value)}
                                        </CookieColorPickerContent>
                                    )} />
                            </CookieColorPickerWrapper>
                        </CookieColorsRow>
                    </CookieColorsContainer>
                </SettingsFormGroupInput>
                <SettingsFormGroupInput>
                    <SettingsFormLabelContainer>
                        <SettingsFormLabelLeftContainer>
                            Header Background Image <OptionalText>(Optional)</OptionalText>
                        </SettingsFormLabelLeftContainer>
                        <SettingsFormLabelRightContainer>
                        <IconHelp />
                        </SettingsFormLabelRightContainer>
                    </SettingsFormLabelContainer>
                    <FastField
                        name="images.background"
                        render={({ field }: any) => (
                    <SettingsFormInputContainer>
                        <ImageLogoContainer>
                            {field.value ? 
                            field.value.not_uc ? 
                                <a>
                                    <img src={field.value} height={75} />
                                </a>
                            :<a href={CoreUtils.image.uc(field.value)} target="_blank">
                                {field.value}
                                    {/* <img src={CoreUtils.image.uc(field.value)} height={75}/> */}
                                </a>
                            : <IconUploadImage />}
                            <FormUpload
                                        maxSize={100}
                                        multi={false}
                                        imagesOnly={true}
                                        stockImages
                                        value={[field.value]}
                                        onRemove={() => {
                                           setFieldValue('images.background', null)
                                        }}
                                        onChange={(files) =>{
                                            setFieldValue('images.background', files[0])
                                        }}
                                        stockImageQuery="?fit=min&w=1920&h=800&q=60"
                                    />
                                    
                              
                        </ImageLogoContainer>
                    </SettingsFormInputContainer>
                    )} />
                </SettingsFormGroupInput>
                <SettingsFormGroupInput style={{gap: 16, borderBottom: '1px dashed var(--Secondary-Light-Grey-1, #DFDFDF)', paddingBottom: 16}}>
                    <CookieColorsContainer>
                        <CookieColorsRow>
                            <p>Header Background Image Tint</p>
                            <CookieColorPickerWrapper>
                            <FastField
								name="colors.tint"
								render={({ field }: any) => (
                                <CookieColorPickerContent>
                                        <ColorPicker
                                            color={field.value}
                                            onChange={(color) => setFieldValue('colors.tint', color)}
                                            />
                                            {'#'+CoreUtils.colors.rgbToHex(field.value)}
                                        </CookieColorPickerContent>
                                )} />
                            </CookieColorPickerWrapper>
                        </CookieColorsRow>
                    </CookieColorsContainer>
                </SettingsFormGroupInput>
                <SettingsFormGroupInput>
                    <SettingsFormLabelContainer>
                        <SettingsFormLabelLeftContainer>Header Title <OptionalText>(Optional)</OptionalText></SettingsFormLabelLeftContainer>
                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                    </SettingsFormLabelContainer>
                    <SettingsFormInputContainer>
                        <FastField
								name="text.title"
								render={({ field }: any) => (
									<input
											type="text"
											{...field}
										/>
								)}
							/>
                    </SettingsFormInputContainer>
                </SettingsFormGroupInput>
                <SettingsFormGroupInput>
                    <SettingsFormLabelContainer>
                        <SettingsFormLabelLeftContainer>Header Subtitle <OptionalText>(Optional)</OptionalText></SettingsFormLabelLeftContainer>
                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                    </SettingsFormLabelContainer>
                    <SettingsFormInputContainer>
      
                            <FastField
								name="text.subtitle"
								render={({ field }: any) => (
								    <input
											type="text"
											{...field}
										/>
								)}
							/>
                    </SettingsFormInputContainer>
                </SettingsFormGroupInput>
                <SettingsFormGroupInput>
                    <SettingsFormLabelContainer>
                        <SettingsFormLabelLeftContainer>
                            Header Logo Image <OptionalText>(Optional)</OptionalText>
                        </SettingsFormLabelLeftContainer>
                        <SettingsFormLabelRightContainer>
                        <IconHelp />
                        </SettingsFormLabelRightContainer>
                    </SettingsFormLabelContainer>
                    <FastField
								name="images.logo"
								render={({ field }: any) => (
                    <SettingsFormInputContainer>
                        <ImageLogoContainer>
                        {field.value ? 
                            field.value.not_uc ? 
                                <a>
                                    <img src={field.value._id} height={75} />
                                </a>
                            :<a href={CoreUtils.image.uc(field.value)} target="_blank">
                               
                                    {<img src={CoreUtils.image.uc(field.value)} height={75}/>}
                                </a>
                            : <IconUploadImage />}
                            <FormUpload
                                        maxSize={100}
                                        multi={false}
                                        imagesOnly={true}
                                        value={[]}
                                        onRemove={() => {
                                            setFieldValue('images.logo', null)
                                        }}
                                        onChange={(files) =>{
                                            setFieldValue('images.logo', files[0])
                                        }}
                                    />
                                    
                              
                        </ImageLogoContainer>
                    </SettingsFormInputContainer>
                                )} />
                </SettingsFormGroupInput>
                <p style={{width: '100%'}}>Or Select a custom url image to use.</p>
  
                <SettingsFormGroupInput>
                    <SettingsFormLabelContainer>
                        <SettingsFormLabelLeftContainer>Header Logo Link <OptionalText>(Optional)</OptionalText></SettingsFormLabelLeftContainer>
                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                    </SettingsFormLabelContainer>
                    <SettingsFormInputContainer>
                        <FastField
								name="images.logo_link"
								render={({ field }: any) => (
									<input
											type="url"
											{...field}
										/>
								)}
							/>
                    </SettingsFormInputContainer>
                </SettingsFormGroupInput>
            </SettingsFormGroup>
            <SettingsActionButtonContainer activeDesignMode={activeDesignMode}>
                <SettingsActionButtonWrapper>
                    <button type="submit" disabled={isSubmitting}>
                        {isSubmitting && <RotateLoader size={2} color="white" />}
                        {!isSubmitting && "Save"}
                    </button>
                </SettingsActionButtonWrapper>
            </SettingsActionButtonContainer>
        </SettingsWebsiteWrapper>
            )}}
        </RestaurantForm>
    )
}
