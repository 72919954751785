import * as React from "react";
import styled, { css } from "../../../styled/index";
import lighten from "polished/lib/color/lighten";
import darken from "polished/lib/color/darken";
import { CS } from "../../../styles/index";

export const BaseInputCSS = css`
  width: 100%;
  outline: 0;
  background: ${({ theme }) => theme.input.background};
  color: ${({ theme }) => theme.input.text};
  border: 1px solid ${({ theme }) => theme.input.border};
  font-size: ${({ theme }) => theme.input.fontSize}px;
  height: ${({ theme }) => theme.input.height}px;
  line-height: normal;
  padding: 0 10px;
  border-radius: 3px;
  box-sizing: border-box;
  &:-moz-placeholder, &:-ms-input-placeholder, &::-moz-placeholder, &::-webkit-input-placeholder {
    color: ${({ theme }) => lighten(0.25, theme.input.text)};
  }
  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }
  &:disabled, &.disabled {
    background-color: ${({ theme }) => darken(0.10, theme.input.background)};
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

const InputComponent = styled("input") <T.Lib.Form.Input.InputProps>`
  ${BaseInputCSS};
  ${(props) => props.shadow ? CS.shadow.light[props.shadow] : ""};
`;

export const Input = (props: T.Lib.Form.Input.InputProps) => <InputComponent {...props} />;


export class InputNumber extends React.PureComponent<T.Lib.Form.Input.InputNumberProps> {

	onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (this.props.change)
			this.props.change(parseFloat(e.target.value) || "");
	}

	render() {
		return <InputComponent {...this.props} onChange={this.onChange} />;
	}

}