import React, { useState } from 'react';
import { InputWithIcon } from './inputWithIcon';
import { styled } from '@lib/components';
import { MobileFilter } from './modals/filter';
import { CSVExport } from './modals/csvExport';
import { FilterIcon, ExportIcon, RefreshIcon } from '../../../../../components/v2/icons';
import { MobXProviderContext } from 'mobx-react';

const BookingSearchWrapper = styled.div`
    display: flex;
    gap: 8px;
`;

export const ButtonWrapper = styled.div`
    background: #F1F4F7;
    border-radius: 4px;
    width: 40px;
    height: 40px;
    position: relative;
    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;

type BookingSearchProps = {
}


export const BookingSearch: React.FC<BookingSearchProps> = ({ }) => {
    const { store } = React.useContext(MobXProviderContext);
    const {searchKey} = store.bookingControl;
    return (
            <BookingSearchWrapper>
                <InputWithIcon />
                {!searchKey && <>
                    <ButtonWrapper>
                        <FilterIcon onClick={() => store.updateBookingControl({ openFilterModal: true })} />
                    </ButtonWrapper>
                    <ButtonWrapper>
                        <RefreshIcon onClick={() => {store.service.booking.handle_get_booking()}} />
                    </ButtonWrapper>
                    <ButtonWrapper>
                        <ExportIcon onClick={() => store.updateBookingControl({ openExportModal: true })} />
                    </ButtonWrapper>
                </>}

            </BookingSearchWrapper>
    );
};
