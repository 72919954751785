import React from 'react'
import { WebsiteForm } from '../../../../../mobx/components/website-form';
import { useStores } from '../../../../../mobx/useStores';
import { UI } from '../../../../../core/ui';
import { FormGroup, RotateLoader, Textarea } from '@lib/components';
import { FastField } from 'formik';
import { SettingsActionButtonContainer, SettingsActionButtonWrapper } from '../../../restaurant/views/settings-v2';
type FormValues = T.Schema.Restaurant.RestaurantSchema["website"]["inject"];

export const CustomV2 = () => {
    const store = useStores();
    const w = store.website!;
    let initialValues = w.sections.inject || {
        html_head: "",
        html_body: "",
    };
    return (
        <WebsiteForm<FormValues>
				submit={async (website, values) => {
					website.sections.inject = values;
					const update = { $set: { "sections.inject": values } };
					return { w: website, update };
				}}
				validators={{}}
				initialValues={initialValues}
				onSuccess={() => { }}
				onError={() => UI.notification.error("An error occurred")}
				onSuccessMessage="Settings Updated"
				onErrorMessage="">
				{({ form, error }) => {
					const { isSubmitting, setFieldValue } = form;
					return (
						<div className="p-4">

							<FormGroup>
								<p className="lhp">Use this section to add custom HTML to your website. Custom CSS should be added to the HTML head</p>
							</FormGroup>

							<FastField
								name="html_head"
								render={({ field }: any) => (
									<FormGroup optional={true} title="HTML Head Code" help="Code that will be inserted at the end of the HEAD tag">
										<Textarea
											onChange={(e) => setFieldValue("html_head", e.target.value)}
											value={field.value || ""}
										/>
									</FormGroup>
								)}
							/>

							<FastField
								name="html_body"
								render={({ field }: any) => (
									<FormGroup optional={true} title="HTML Body Code" help="Code that will be inserted at the end of the BODY tag">
										<Textarea
											onChange={(e) => setFieldValue("html_body", e.target.value)}
											value={field.value || ""}
										/>
									</FormGroup>
								)}
							/>

							{error && <FormGroup error={error} />}
                            <SettingsActionButtonContainer className='no-sidebar'>
                        <SettingsActionButtonWrapper style={{left: 0}}>
                            <button 
                                type="button" 
                                className='btn-cancel'
                                onClick={ () => store.setActiveMobileMenu(
                                    store.activeMobileMenu.referrer.pop()!,
                                    Array.from(new Set(store.activeMobileMenu.referrer))
                                )}>
                                Cancel
                            </button>
                            <button  type="submit" disabled={isSubmitting}>
                            {isSubmitting && <RotateLoader size={2} color="white" />}
                            {!isSubmitting && "Save"}
                            </button>
                        </SettingsActionButtonWrapper>
                    </SettingsActionButtonContainer>
						</div>
					);
				}}
			</WebsiteForm>
    )
}