import { BumpCard } from '@lib/components';
import { PaginationBumpItems } from '@lib/components';
import { MobXProviderContext } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import localStorage from 'store';
import { nanoid } from 'nanoid';
import { UI } from '../../../../../../core/ui';
import { Untrusive, logger } from '@lib/common';
import { TStatusItem } from './bump';
interface PropsBumpItem extends WithTranslation {
  listOrder: T.Schema.Order.OrderSchema[];
  orderStatusData: TStatusItem[];
  totalResponse: number;
  currentPage: number;
  setCurrentPage: (value: number) => void;
}

const BumpCardWrapper = styled.div<{ numberCards: number; rows: number }>`
  height: 96%;
  display: grid;
  grid-template-columns: ${({ numberCards }) => `repeat(${numberCards}, 1fr)`};
  ${({ rows }) => {
    if (rows === 1) {
      return css`
        grid-template-rows: 1fr;
      `;
    }
    if (rows === 2) {
      return css`
        grid-template-rows: 1fr 1fr;
      `;
    }
  }}
  gap: 24px;
  align-content: space-between;
  padding: 24px;
  background-color: #f1f4f7;
`;

const PaginationWapper = styled.div`
  display: flex;
  position: fixed;
  bottom: 0;
  height: 72px;
  width: 100%;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
  padding: 3px;
  background-color: #fff;
`;
const DEFAULT_SERVICE = ['pickup', 'delivery', 'dine_in'];

const updateOrdersStore = (store: any, restaurantId: string, page: number) => {
  const savedSettingBumpView = localStorage.get('settingBumpView');
  let savedActiveCard = savedSettingBumpView ? JSON.parse(savedSettingBumpView).card : 4;
  const serviceToFilter = localStorage.get('serviceToFilter');
  const orderServiceDisplay = serviceToFilter ? serviceToFilter : DEFAULT_SERVICE;
  return store.api.orders_bump_find({
    page: page,
    limit: savedActiveCard,
    sort: {
      created: -1,
    },
    query: {
      'config.service': {
        $in: orderServiceDisplay,
      },
      status: {
        $in: ['confirmed'],
      },
      restaurant_id: restaurantId,
    },
  });
};

const updateStatusOderDish = (
  store: any,
  orderId: string,
  status: string,
  orderDishLineId?: string,
  orderDishLineLocator?: any
) => {
  return store.service.order.update_dish_status(orderId, status, orderDishLineId, orderDishLineLocator);
};

const BumpItems: React.FC<PropsBumpItem> = ({
  listOrder,
  orderStatusData,
  totalResponse,
  currentPage,
  setCurrentPage,
}) => {
  const { store } = React.useContext(MobXProviderContext);
  const { restrictions } = store;

  const [dataBump, setDataBump] = useState(listOrder);
  const [isDisableModal, setIsDisableModal] = useState(false);

  const restaurantId = store.restaurant._id;
  const handlePageChange = async (page: number) => {
    setCurrentPage(page);
    const response = await updateOrdersStore(store, restaurantId, page);    
    store.updateOrders({
      items: response.items,
      count: response.count,
      page: currentPage,
    });
  };
  const settingBumpView = localStorage.get('settingBumpView');
  const settingPerPage = settingBumpView ? JSON.parse(settingBumpView) : { row: 1, card: 4 };
  const itemsPerPage = settingPerPage.card;
  const totalPages = Math.ceil(dataBump.length / itemsPerPage);
  const restaurant = store.restaurant;
  const printerNames = restaurant.settings?.printers;
  const updateStatus = async (o: T.Schema.Order.OrderSchema, status: T.Schema.Order.OrderStatuses) => {
    try {
      await store.service.order.update_status(o._id, status);
      setDataBump(dataBump.filter(el => el._id !== o._id));
      
    } catch (error) {}
  };

  const updateEstimatedTime = async (o: T.Schema.Order.OrderSchema, time: string) => {
    try {
      await store.service.order.update_ready_time(o._id, parseInt(time));
      setIsDisableModal(true);
    } catch (error) {}
  };
  const updatePrinterOrder = async (o: T.Schema.Order.OrderSchema, _id: string) => {
    try {
      const r = store.restaurant!;
      const type = 'order';
      const restaurant_id = r._id;
      const printer_id = _id;
      const type_id = o._id;
      const job_id = nanoid();
      await store.api.print_receipt({
        type,
        restaurant_id,
        printer_id,
        type_id,
        job_id,
      });
      UI.notification.success('Order sent to printer');
    } catch (error) {}
  };

  const handleDeleteOrder = async (o: T.Schema.Order.OrderSchema) => {
    try {
      const proceed = confirm('Once deleted, it cannot be recovered. Delete the order?');
      if (!proceed) {
        return;
      }
      Untrusive.start();
      const { _id } = o;
      const restaurant_id = store.restaurant!._id;
      const response = await store.api.order_delete({
        _id,
        restaurant_id,
      });
      if (response.outcome) {
        UI.notification.error(response.message, { timeout: 6000 });
      } else {
        store.removeOrder(_id);
        UI.notification.success('Order deleted');
        setDataBump(dataBump.filter(el => el._id !== o._id));
      }
    } catch (e) {
      logger.captureException(e);
      UI.notification.error('An error occurred, try again soon or contact us', { timeout: 6000 });
    } finally {
      Untrusive.stop();
    }
  };
  const handleUpdateOrderDish = (
    orderId: string,
    status: string,
    orderDishLineId?: string,
    orderDishLineLocator?: any
  ) => {
    updateStatusOderDish(store, orderId, status, orderDishLineId, orderDishLineLocator);
  };
  useEffect(() => {
    if (currentPage > totalPages || dataBump.length === 0) {
      setCurrentPage(totalPages);
    }
  }, [totalPages, dataBump]);

  return (
    <>
      <BumpCardWrapper numberCards={itemsPerPage / settingPerPage.row} rows={settingPerPage.row}>
        {listOrder.map(order => {
          return (
            <React.Fragment key={order._id}>
              <BumpCard
                order={order}
                handleReady={updateStatus}
                handleSelectOption={updateEstimatedTime}
                handleSelectPrinter={updatePrinterOrder}
                isDisableModal={isDisableModal}
                setIsDisableModal={setIsDisableModal}
                onDeleteOrder={handleDeleteOrder}
                orderStatusData={orderStatusData}
                printerNames={printerNames}
                settingCard={settingPerPage}
                onUpdateOrderDish={handleUpdateOrderDish}
                permissionDelete={restrictions.restaurant.orders_delete}
              />
            </React.Fragment>
          );
        })}
      </BumpCardWrapper>
      {!!dataBump.length && (
        <PaginationWapper>
          <PaginationBumpItems
            totalItems={totalResponse}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </PaginationWapper>
      )}
    </>
  );
};

export default withTranslation()(BumpItems);
