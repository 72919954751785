import React, { useState } from 'react'
import { SettingsHeader, SettingsHeaderMenuContainer, SettingsHeaderMenuItem, SettingsHeaderTextContainer, SettingsHeaderTextTitle, SettingsHeaderTextWrapper, SettingsMenuContainer, SettingsMenuRow, SettingsMenuRowHeader, SettingsMenuRowItem, SettingsMenuRowWrapper } from '..'
import { IconAlert, IconArrowForward, IconBack, IconColors, IconCustomers, IconDevelopers, IconFonts, IconFooter, IconHeader, IconItems, IconLandingPage, IconLogout, IconMenuNavBar, IconMobile, IconSystemMisc, IconThemes, IconTopNavBar, IconUploadImage, IconWebsite, IconWrapperRounded } from '../../../../../components/v2/icons'
import { useStores } from '../../../../../../mobx/useStores'
import { DesignModeIframe, DesignModeWrapper } from '../../settings/sections/website'
import { toJS } from 'mobx'
import ObjectHash from "node-object-hash";
import { SettingsWebsiteTheme } from './themes'
import { capitalize, isEmpty } from 'lodash'
import { SettingsWebsiteColors } from './colors'
import { SettingsWebsiteFonts } from './fonts'
import { SettingsWebsiteTopNavBar } from './topnavbar'
import { SettingsWebsiteMenuNavBar } from './menunavbar'
import { SettingsWebsiteHeader } from './header'
import { SettingsWebsiteItems } from './items'
import { SettingsWebsiteFooter } from './footer'
import { SettingsWebsiteMobile } from './mobile'
import { SettingsWebsiteAlert } from './alert'
import { SettingsWebsiteDomain } from './domain'
import { SettingsWebsiteFavicon } from './favicon'
import { SettingsWebsiteSEO } from './seo'
import { SettingsWebsiteSocial } from './social'
import { SettingsWebsiteCustom } from './custom'
import { SettingsWebsiteMisc } from './misc'
import { SettingsWebsiteItemLayout } from './itemlayout'
const DesignSettings = (props: any) => {
    const { activeDesignMode, setActiveDesignMode, setActiveSetting } = props
    const store = useStores()
    const menuTemplate = store.restaurant!.website.sections.menu_nav_bar
    ?.menu_template || "v1";
    return (<div className="hide-overflow-scrollbar" style={{paddingBottom: activeDesignMode ? 0 : 83, width: '100%', overflowY: 'scroll', maxWidth: activeDesignMode ? 460: '100%'}}>
    <SettingsMenuContainer className="border-bottom-mobile">
        { activeDesignMode && <SettingsHeader style={{padding: 16}}>
            <SettingsHeaderTextContainer>
                <SettingsHeaderTextWrapper>
                    <SettingsHeaderTextTitle>Website</SettingsHeaderTextTitle>
                </SettingsHeaderTextWrapper>
            </SettingsHeaderTextContainer>
            <SettingsHeaderMenuContainer>
                <SettingsHeaderMenuItem
                    onClick={() => setActiveDesignMode(false)}>
                    <IconLogout /> Exit Design Mode
                </SettingsHeaderMenuItem>
            </SettingsHeaderMenuContainer>
        </SettingsHeader>}
        <SettingsMenuRowHeader>Design</SettingsMenuRowHeader>
        <SettingsMenuRow onClick={() => {
            if(activeDesignMode) {
                setActiveSetting('themes')
                setActiveDesignMode(activeDesignMode)
            }
            else store.setActiveMobileMenu('themes', 'website')}
            }>
            <SettingsMenuRowWrapper>
                <SettingsMenuRowItem>
                    <IconWrapperRounded className='icon-wrapper-rounded'>
                        <IconThemes />
                    </IconWrapperRounded>
                    <p>Themes</p>
                    <IconArrowForward />
                </SettingsMenuRowItem>
            </SettingsMenuRowWrapper>
        </SettingsMenuRow>
        <SettingsMenuRow onClick={() => {
            if(activeDesignMode) {
                setActiveSetting('colors')
                setActiveDesignMode(activeDesignMode)
            }
            else store.setActiveMobileMenu('colors', 'website')}}
            >
            <SettingsMenuRowWrapper>
                <SettingsMenuRowItem>
                    <IconWrapperRounded className='icon-wrapper-rounded'>
                        <IconColors />
                    </IconWrapperRounded>
                    <p>Colors</p>
                    <IconArrowForward />
                </SettingsMenuRowItem>
            </SettingsMenuRowWrapper>
        </SettingsMenuRow>
        <SettingsMenuRow onClick={() => {
            if(activeDesignMode) {
                setActiveSetting('fonts')
                setActiveDesignMode(activeDesignMode)
            }
            else store.setActiveMobileMenu('fonts', 'website')}
            }>
            <SettingsMenuRowWrapper>
                <SettingsMenuRowItem>
                    <IconWrapperRounded className='icon-wrapper-rounded'>
                        <IconFonts />
                    </IconWrapperRounded>
                    <p>Fonts</p>
                    <IconArrowForward />
                </SettingsMenuRowItem>
            </SettingsMenuRowWrapper>
        </SettingsMenuRow>
        <SettingsMenuRow onClick={() => {
            if(activeDesignMode) {
                setActiveSetting('top-nav-bar')
                setActiveDesignMode(activeDesignMode)
            }
            else store.setActiveMobileMenu('top-nav-bar', 'website')
            }}>
            <SettingsMenuRowWrapper>
                <SettingsMenuRowItem>
                    <IconWrapperRounded className='icon-wrapper-rounded'>
                        <IconTopNavBar/>
                    </IconWrapperRounded>
                    <p>Top Nav Bar</p>
                    <IconArrowForward />
                </SettingsMenuRowItem>
            </SettingsMenuRowWrapper>
        </SettingsMenuRow>
        <SettingsMenuRow onClick={() => {
            if(activeDesignMode) {
                setActiveSetting('menu-nav-bar')
                setActiveDesignMode(activeDesignMode)
            } else store.setActiveMobileMenu('menu-nav-bar', 'website')}}>
            <SettingsMenuRowWrapper>
                <SettingsMenuRowItem>
                    <IconWrapperRounded className='icon-wrapper-rounded'>
                        <IconMenuNavBar />
                    </IconWrapperRounded>
                    <p>Menu Nav Bar</p>
                    <IconArrowForward />
                </SettingsMenuRowItem>
            </SettingsMenuRowWrapper>
        </SettingsMenuRow>
        <SettingsMenuRow onClick={() => {
            if(activeDesignMode) {
                setActiveSetting('header')
                setActiveDesignMode(activeDesignMode)
            } else store.setActiveMobileMenu('header', 'website')}}>
            <SettingsMenuRowWrapper>
                <SettingsMenuRowItem>
                    <IconWrapperRounded className='icon-wrapper-rounded'>
                        <IconHeader />
                    </IconWrapperRounded>
                    <p>Header</p>
                    <IconArrowForward />
                </SettingsMenuRowItem>
            </SettingsMenuRowWrapper>
        </SettingsMenuRow>
        {menuTemplate == 'v2' &&<SettingsMenuRow onClick={() => {
            if(activeDesignMode) {
                setActiveSetting('item-layout')
                setActiveDesignMode(activeDesignMode)
            } else store.setActiveMobileMenu('item-layout', 'website')}}>
            <SettingsMenuRowWrapper>
                <SettingsMenuRowItem>
                    <IconWrapperRounded className='icon-wrapper-rounded'>
                        <IconItems />
                    </IconWrapperRounded>
                    <p>Item Layout</p>
                    <IconArrowForward />
                </SettingsMenuRowItem>
            </SettingsMenuRowWrapper>
        </SettingsMenuRow>}
        <SettingsMenuRow onClick={() => {
            if(activeDesignMode) {
                setActiveSetting('items')
                setActiveDesignMode(activeDesignMode)
            } else store.setActiveMobileMenu('items', 'website')}}>
            <SettingsMenuRowWrapper>
                <SettingsMenuRowItem>
                    <IconWrapperRounded className='icon-wrapper-rounded'>
                        <IconItems />
                    </IconWrapperRounded>
                    <p>Items</p>
                    <IconArrowForward />
                </SettingsMenuRowItem>
            </SettingsMenuRowWrapper>
        </SettingsMenuRow>
        <SettingsMenuRow onClick={() => {
            if(activeDesignMode) {
                setActiveSetting('footer')
                setActiveDesignMode(activeDesignMode)
            } else store.setActiveMobileMenu('footer', 'website')}}>
            <SettingsMenuRowWrapper>
                <SettingsMenuRowItem>
                    <IconWrapperRounded className='icon-wrapper-rounded'>
                        <IconFooter />
                    </IconWrapperRounded>
                    <p>Footer</p>
                    <IconArrowForward />
                </SettingsMenuRowItem>
            </SettingsMenuRowWrapper>
        </SettingsMenuRow>
        <SettingsMenuRow onClick={() => {
            if(activeDesignMode) {
                setActiveSetting('mobile')
                setActiveDesignMode(activeDesignMode)
            } else store.setActiveMobileMenu('mobile', 'website')}}>
            <SettingsMenuRowWrapper>
                <SettingsMenuRowItem>
                    <IconWrapperRounded className='icon-wrapper-rounded'>
                        <IconMobile />
                    </IconWrapperRounded>
                    <p>Mobile</p>
                    <IconArrowForward />
                </SettingsMenuRowItem>
            </SettingsMenuRowWrapper>
        </SettingsMenuRow>
        <SettingsMenuRow onClick={() => {
            if(activeDesignMode) {
                setActiveSetting('alert')
                setActiveDesignMode(activeDesignMode)
            } else store.setActiveMobileMenu('alert', 'website')}}>
            <SettingsMenuRowWrapper>
                <SettingsMenuRowItem>
                    <IconWrapperRounded className='icon-wrapper-rounded'>
                        <IconAlert />
                    </IconWrapperRounded>
                    <p>Alert</p>
                    <IconArrowForward />
                </SettingsMenuRowItem>
            </SettingsMenuRowWrapper>
        </SettingsMenuRow>
    </SettingsMenuContainer>
    <SettingsMenuContainer>
        <SettingsMenuRowHeader>Domain</SettingsMenuRowHeader>
        <SettingsMenuRow onClick={() => {
            if(activeDesignMode) {
                setActiveSetting('domain')
                setActiveDesignMode(activeDesignMode)
            } else store.setActiveMobileMenu('domain', 'website')}}>
            <SettingsMenuRowWrapper>
                <SettingsMenuRowItem>
                    <IconWrapperRounded className='icon-wrapper-rounded'>
                        <IconWebsite />
                    </IconWrapperRounded>
                    <p>Domain</p>
                    <IconArrowForward />
                </SettingsMenuRowItem>
            </SettingsMenuRowWrapper>
        </SettingsMenuRow>
        <SettingsMenuRow onClick={() => {
            if(activeDesignMode) {
                setActiveSetting('favicon')
                setActiveDesignMode(activeDesignMode)
            } else store.setActiveMobileMenu('favicon', 'website')}}>
            <SettingsMenuRowWrapper>
                <SettingsMenuRowItem>
                    <IconWrapperRounded className='icon-wrapper-rounded'>
                        <IconTopNavBar />
                    </IconWrapperRounded>
                    <p>Favicon</p>
                    <IconArrowForward />
                </SettingsMenuRowItem>
            </SettingsMenuRowWrapper>
        </SettingsMenuRow>
        <SettingsMenuRow onClick={() => {
            if(activeDesignMode) {
                setActiveSetting('search-engine-optimization')
                setActiveDesignMode(activeDesignMode)
            } else store.setActiveMobileMenu('search-engine-optimization', 'website')}}>
            <SettingsMenuRowWrapper>
                <SettingsMenuRowItem>
                    <IconWrapperRounded className='icon-wrapper-rounded'>
                        <IconWebsite />
                    </IconWrapperRounded>
                    <p>Search Engine Optimization</p>
                    <IconArrowForward />
                </SettingsMenuRowItem>
            </SettingsMenuRowWrapper>
        </SettingsMenuRow>
        <SettingsMenuRow onClick={() => {
            if(activeDesignMode) {
                setActiveSetting('social-media-links')
                setActiveDesignMode(activeDesignMode)
            } else store.setActiveMobileMenu('social-media-links', 'website')}}>
            <SettingsMenuRowWrapper>
                <SettingsMenuRowItem>
                    {<IconWrapperRounded className='icon-wrapper-rounded'>
                        <IconCustomers />
                    </IconWrapperRounded>}
                    <p>Social Media Links</p>
                    <IconArrowForward />
                </SettingsMenuRowItem>
            </SettingsMenuRowWrapper>
        </SettingsMenuRow>
        <SettingsMenuRow onClick={() => {
            if(activeDesignMode) {
                setActiveSetting('custom-css-&-javascript')
                setActiveDesignMode(activeDesignMode)
            } else store.setActiveMobileMenu('custom-css-&-javascript', 'website')}}>
            <SettingsMenuRowWrapper>
                <SettingsMenuRowItem>
                    <IconWrapperRounded className='icon-wrapper-rounded'>
                        <IconDevelopers />
                    </IconWrapperRounded>
                    <p>Custom CSS & Javascript</p>
                    <IconArrowForward />
                </SettingsMenuRowItem>
            </SettingsMenuRowWrapper>
        </SettingsMenuRow>
        <SettingsMenuRow onClick={() => {
            if(activeDesignMode) {
                setActiveSetting('misc')
                setActiveDesignMode(activeDesignMode)
            } else store.setActiveMobileMenu('misc', 'website')}}>
            <SettingsMenuRowWrapper>
                <SettingsMenuRowItem>
                    <IconWrapperRounded className='icon-wrapper-rounded'>
                        <IconSystemMisc />
                    </IconWrapperRounded>
                    <p>Misc.</p>
                    <IconArrowForward />
                </SettingsMenuRowItem>
            </SettingsMenuRowWrapper>
        </SettingsMenuRow>
    </SettingsMenuContainer>
</div> )

}
const hasher = ObjectHash();
export const SettingsWebsite = (props: any) => {
    const [activeSetting, setActiveSetting] = useState('')
    const store = useStores()
    const { activeDesignMode, setActiveDesignMode, saveRestaurant, injected, restaurant } = props
    const r = restaurant;
    const website = {
        subdomain: toJS(r.subdomain),
        domain: toJS(r.domain),
        colors: toJS(r.website.colors),
        fonts: toJS(r.website.fonts),
        sections: toJS(r.website.sections),
        inject: toJS(r.website.inject),
        social_links: toJS(r.website.social_links),
        map_zoom: toJS(r.website.map_zoom),
        bottom_cart_button: toJS(r.website.bottom_cart_button),
        contact_form_email: toJS(r.website.contact_form_email),
        menu_selector_only: toJS(r.website.experimental_ui_1),
    };

    const hash = hasher.hash(website);
   return (
      
        <>
            {activeDesignMode ?
                <DesignModeWrapper style={{left: 60}}>
                    {isEmpty(activeSetting) ?
                    <>
                    <DesignSettings 
                        activeDesignMode={activeDesignMode}
                        setActiveDesignMode={setActiveDesignMode}
                        setActiveSetting={setActiveSetting}/>
                    </>
                    :<div style={{maxWidth: 460, width: '100%', background: '#fefefe'}}>
                           { activeDesignMode && <SettingsHeader style={{padding: 16}}>
                            <SettingsHeaderTextContainer>
                                <SettingsHeaderTextWrapper>
                                   <SettingsHeaderTextTitle 
                                        style={{cursor: 'pointer', display: 'flex', gap: 16, alignItems: 'center'}}
                                        onClick={() => setActiveSetting('')}><IconBack /> {activeSetting.replace(/-/g, ' ')}</SettingsHeaderTextTitle>
                                </SettingsHeaderTextWrapper>
                            </SettingsHeaderTextContainer>
                            <SettingsHeaderMenuContainer>
                                <SettingsHeaderMenuItem
                                    onClick={() => setActiveDesignMode(false)}>
                                    <IconLogout /> Exit Design Mode
                                </SettingsHeaderMenuItem>
                            </SettingsHeaderMenuContainer>
                        </SettingsHeader>}
                        {activeSetting == 'themes' && 
                            <SettingsWebsiteTheme
                                saveRestaurant={saveRestaurant}
                                injected={injected}
                                activeDesignMode={activeDesignMode}
                                restaurant={store.restaurant!} />}
                        {activeSetting == 'colors' && <SettingsWebsiteColors  activeDesignMode={activeDesignMode}/>}
                        {activeSetting == 'fonts' && <SettingsWebsiteFonts  activeDesignMode={activeDesignMode}/>}
                        {activeSetting == 'top-nav-bar' && <SettingsWebsiteTopNavBar  activeDesignMode={activeDesignMode}/>}
                        {activeSetting == 'menu-nav-bar' && <SettingsWebsiteMenuNavBar activeDesignMode={activeDesignMode}/>}
                        {activeSetting == 'header' && <SettingsWebsiteHeader activeDesignMode={activeDesignMode}/>}
                        {activeSetting == 'items' && 
                            <SettingsWebsiteItems
                                restaurant={store.restaurant!}
                                activeDesignMode={activeDesignMode}/>}
                        {activeSetting == 'item-layout' && 
                            <SettingsWebsiteItemLayout
                                restaurant={store.restaurant!}
                                activeDesignMode={activeDesignMode}/>}
                        {activeSetting == 'footer' && <SettingsWebsiteFooter activeDesignMode={activeDesignMode}/>}
                        {activeSetting == 'mobile' && <SettingsWebsiteMobile activeDesignMode={activeDesignMode}/>}
                        {activeSetting == 'alert' && <SettingsWebsiteAlert activeDesignMode={activeDesignMode}/>}
                        {activeSetting == 'domain' && 
                            <SettingsWebsiteDomain
                            injected={injected}
                            restaurant={store.restaurant!}
                            activeDesignMode={activeDesignMode}/>}
                        {activeSetting == 'favicon' && <SettingsWebsiteFavicon activeDesignMode={activeDesignMode}/>}
                        {activeSetting == 'search-engine-optimization' && <SettingsWebsiteSEO activeDesignMode={activeDesignMode}/>}
                        {activeSetting == 'social-media-links' && <SettingsWebsiteSocial activeDesignMode={activeDesignMode}/>}
                        {activeSetting == 'custom-css-&-javascript' && <SettingsWebsiteCustom activeDesignMode={activeDesignMode}/>}
                        {activeSetting == 'misc' && <SettingsWebsiteMisc activeDesignMode={activeDesignMode}/>}
                    </div> }
                    <DesignModeIframe style={{backgroundColor: '#f1f4f7'}}>
                            <iframe
								onLoadStart={() => console.log("load start")}
								onLoad={() => console.log("load end")}
								className="bsm-2"
								src={`${store.storeURL}?h=${hash}`}
								width="100%"
								height="100%"
							/>
                    </DesignModeIframe>
                </DesignModeWrapper>
            : <DesignSettings activeDesignMode={activeDesignMode} />}
        </>
     
    )
}

