import React from 'react'
import { WebsiteForm } from '../../../../../mobx/components/website-form';
import { useStores } from '../../../../../mobx/useStores';
import { UI } from '../../../../../core/ui';
import { FormGroup, RotateLoader, Switch } from '@lib/components';
import { FastField } from 'formik';
import { SettingsActionButtonContainer, SettingsActionButtonWrapper } from '../../../restaurant/views/settings-v2';
import { ImageUpload } from '../website/upload_image';
import { toJS } from 'mobx';
type FormValues = T.Schema.Website.WebsiteSchema["sections"]["gallery"];

export const GalleryV2 = () => {
    const store = useStores()
    const w = store.website!;
    let initialValues = toJS(w.sections.gallery);
    return (
        <WebsiteForm<FormValues>
        submit={async (w, values) => {

            w.sections.gallery = values;

            const update = { $set: { "sections.gallery": values } };

            return { w, update };

        }}
        validators={{}}
        initialValues={initialValues}
        onSuccess={() => { }}
        onError={() => UI.notification.error("An error occurred")}
        onSuccessMessage="Settings Updated"
        onErrorMessage="">
        {({ form, error }) => {
            const { isSubmitting, setFieldValue } = form;
            return (
                <div className="p-4">

                    <FastField
                        name="hidden"
                        render={({ field }: any) => (
                            <FormGroup title="">
                                <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                                    <div style={{ fontSize: '18px', lineHeight: '24px', fontWeight: 600 }}>Hide Gallery</div>
                                    <div>
                                    <Switch
                                        id="section.hidden"
                                        checked={field.value}
                                        onChange={(e) => setFieldValue("hidden", e.target.checked)} />
                                    </div>
                                </div>
                                
                            </FormGroup>
                        )}
                    />

                    <FastField
                        name="images"
                        render={({ field }: any) => (
                            <FormGroup
                                title="Gallery Images"
                                help="Upload up to 6 images for your gallery. Files name can't contain special charaters">
                                <ImageUpload 
                                    value={[field.value]} 
                                    onChange={(files: any[]) => setFieldValue('images', files[0])} onRemove={() => setFieldValue('images', null)}/>
                            </FormGroup>
                        )}
                    />

                    {error && <FormGroup error={error} />}
                    
                    <SettingsActionButtonContainer className='no-sidebar'>
                        <SettingsActionButtonWrapper style={{left: 0}}>
                            <button 
                                type="button" 
                                className='btn-cancel'
                                onClick={ () => store.setActiveMobileMenu(
                                    store.activeMobileMenu.referrer.pop()!,
                                    Array.from(new Set(store.activeMobileMenu.referrer))
                                )}>
                                Cancel
                            </button>
                            <button  type="submit" disabled={isSubmitting}>
                            {isSubmitting && <RotateLoader size={2} color="white" />}
                            {!isSubmitting && "Save"}
                            </button>
                        </SettingsActionButtonWrapper>
                    </SettingsActionButtonContainer>
                    

                </div>
            );
        }}
    </WebsiteForm>
    )
}