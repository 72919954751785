import { Button, CommonText, FooterSection, GroupOptionItems, Input, RotateLoader, Select, Switch } from "@lib/components";
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import 'react-day-picker/lib/style.css';
import { updateOptionsUtil } from "../../../../utils/pause_services";
import { FastField, Field, Form, Formik } from "formik";
import { InputTitle, serviceOptionsConstant, storeOptionsConstant } from "./special-hours-constants";
import { DateRangePickerV2 } from "./date-range-picker-V2";
import InputMask from "react-input-mask";
import {  QuestionIcon } from "../../../../components/v2/icons";
import { MobXProviderContext } from "mobx-react";
import { IoMdArrowBack } from "react-icons/io";
import { UI } from "../../../../../core/ui";
import { validators } from "@lib/common";
import { ErrorMessage } from "../bookings/mobileBookings/modals/settings/bookingSettingConstants";
import moment from "moment-timezone";
import { MobileModal, MobileModalBody, MobileModalContent, MobileModalHeader, SettingsActionButtonWrapper } from "../settings-v2";
import { SettingServiceWrapper } from "../orders-v2/quick-setting-mobile";

interface Props {
    show?: boolean;
    setShow: () => void;
    selectedSpecialHour?: specialHourProps;
    selectedIndex?: number;
    modalType: string;
}

interface rangeProps {
    from: Date | undefined;
    to: Date | undefined;
}
const ModalContainer = styled.div`
    position: fixed;
    top: 0px;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: 100;
    background: rgba(0,0,0,0.25);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    @media (min-width: 768px) {
        width: 420px;
    }
`

const ModalWrapper = styled.div`
    display: flex;
    max-width: 100%;
    height: 100%;
    margin: 0;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    background: #FFF;
  
`
const ModalHeader = styled.div`
    display: flex;
    padding: 16px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    margin-bottom: 12px;
    border-bottom: 1px solid #DFDFDF;
`

const BackIconWrapper = styled.span`
  position: absolute;
  left: 5%;
  cursor: pointer;
`;
const TitleWithSecondaryText = styled.div`
    display: flex;
    align-items: center;
    align-self: stretch;
    gap: 5px;
`

const ModalFooter = styled.div`
display: flex;
padding: 12px 24px 24px 24px;
align-items: center;
gap: 8px;
align-self: stretch;
background: var(--Neutral-White, #FEFEFE);
`

const ModalBody = styled.div`
    display: flex;
    padding: 0px 16px 16px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    flex: 1 0 0;
    align-self: stretch;
    overflow-y: auto;
    background: white;
    margin-bottom: 20px;
    @media (min-width: 768px) {
        width: 420px;
    }
`

export const OptionItemWrapper = styled.div`
display: flex;
align-items: flex-start;
gap: 16px;
align-self: stretch;
flex-wrap: wrap;
border-bottom: 1px dashed #DFDFDF;
width: 100%;

 p.w-100 {
    width: 100%;
 }

 &.no-border {
    border-bottom: unset;
    padding-bottom: 0px;
 }
 & select {
    display: flex;
    padding: 8px 12px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid var(--Secondary-Light-Grey-1, #DFDFDF);
    background: var(--Neutral-White, #FEFEFE);
    width: 100%;
 }
`

export const TimeWrapper = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 4px;
align-self: stretch;
width: 100%;
`

const TimeWrapperInputWrapper = styled.div`
display: flex;
align-items: flex-start;
gap: 10px;
align-self: stretch;
width: 100%;
label {
    display: flex;
    gap: 8px;
    border-radius: 4px;
    border: 1px solid var(--Secondary-Light-Grey-1, #DFDFDF);
    padding: 8px 12px;
    flex: 1;
    color: var(--Secondary-Dark-Grey-1, #4B4F5E);

line-height: 24px; /* 171.429% */
height: 100%;
    input, select {
        border: none;
        width: 100%;
        padding: 0px;

font-size: 14px;
font-style: normal;
font-weight: 500;
    }
}
`

export interface specialHourProps {
    opened: boolean;
    h24: boolean;
    open: string;
    close: string;
    services: never[];
    fee: string;
    discount: string;
    fromDate?: Date | string;
    toDate?: Date | string;
    rangeDate?: {
        from: Date;
        to: Date;
    };
}
interface serviceDataProps {
    id: string;
    text: string;
    value: string;
    isActive: boolean;
}

export const handlePromotionInfo = (data: any) => {
    if (!data) {
        return ''
    }
    if (data.type === "free_item") {
        return ''
    } else if (data.type === "conventional_discount") {
        return `(${data.condition.discount_value}${data.condition.type === "percentage" && '%'})`
    } else if (data.percent_discount && data.fixed_discount) {
        return `(${data.percent_discount && data.percent_discount + '%'}${(data.fixed_discount && data.fixed_discount !== 0) ? " ," + data.fixed_discount : ''})`
    } else {
        return `(${data.percent_discount && data.percent_discount + '%'}${(data.fixed_discount && data.fixed_discount !== 0) ? data.fixed_discount : ''})`
    }
}

export const handleFeeInfo = (data: any) => {
    if (!data) {
        return ''
    }
    return `(${data.percent_value && data.percent_value + '%'}${(data.fixed_value && data.fixed_value !== 0) ? data.fixed_value : ''})`
}
interface modalTypeDisplayProps {
    add: string,
    edit: string,
    duplicate: string,
}

const modalTypeDisplay: modalTypeDisplayProps = {
    'add': "Add",
    'edit': "Edit",
    'duplicate': "Duplicate",
}

export const SpecialHourModal = ({ show, setShow, selectedSpecialHour, selectedIndex, modalType }: Props) => {
    const { store } = React.useContext(MobXProviderContext);
    const restaurant = store.restaurant!;
    const availableServiceOption = serviceOptionsConstant.filter(service => restaurant.settings.services[service.value].enabled)

    const [serviceOption, setServiceOption] = useState<serviceDataProps[]>([...availableServiceOption])
    const today = new Date();

    useEffect(() => {
        if (selectedSpecialHour) {
            const newServiceOption = serviceOption.map(service => {
                let checkService = false;
                if(!selectedSpecialHour.services){
                    checkService = true;
                } else {
                    checkService = !!selectedSpecialHour.services?.find((e: string) => e === service.id);
                }
                if (checkService) {
                    return {
                        ...service,
                        isActive: true,
                        disabled: (service.value === 'dine_in' && !selectedSpecialHour.opened) ? true : undefined
                    }
                }
                return { ...service, isActive: false, disabled: (service.value === 'dine_in' && !selectedSpecialHour.opened) ? true : undefined }
            })
            setServiceOption(newServiceOption)
        } else {
            setServiceOption(availableServiceOption)
        }
    }, [selectedSpecialHour, modalType])

    useEffect(() => {
        const adjustButtonPosition = () => {
          const button = document.querySelector('.confirm-button');
          if (button) {
            const viewportHeight = window.innerHeight;
            const clientHeight = document.documentElement.clientHeight;
            (button as HTMLElement).style.bottom = `${viewportHeight - clientHeight}px`;
          }
        };
    
        window.addEventListener('resize', adjustButtonPosition);
        adjustButtonPosition();
    
        return () => {
          window.removeEventListener('resize', adjustButtonPosition);
        };
      }, []);

    const promotionList = [
        ...(restaurant.promos || []),
        ...(restaurant.free_item_promos || []),
        ...(restaurant.conventional_discount_promos || []),
    ].map(promoData => {
        return {
            label: `${promoData.name} - ${promoData.code} ${handlePromotionInfo(promoData)}`,
            value: promoData._id
        }
    })

    const feeList = [
        ...(restaurant.settings.fees || []),
    ].map(feeData => {
        return {
            label: `${feeData.name} ${handleFeeInfo(feeData)}`,
            value: feeData._id
        }
    })

    if (!show) return null

    const updateStoreOptions = (id: string, field: any, values: specialHourProps, currentService: serviceDataProps[]) => {
        updateOptionsUtil(id, [...storeOptionsConstant], (options) => {
            field.onChange({ target: { name: field.name, value: options[0].isActive } });
            // disable Dine Ins service when choose close
            const formattedService = currentService.map((service: serviceDataProps) => {
                return {
                    ...service,
                    isActive: (service.value === 'dine_in' && !options[0].isActive) ? false : service.isActive,
                    disabled: (service.value === 'dine_in' && !options[0].isActive) ? true : undefined
                }
            })

            setServiceOption(
                formattedService
            )
            field.onChange({ target: { name: 'services', value: [...values.services].filter(value => value !== 'dine_in') } });


        }, false);
    }


    const updateServiceOptions = (id: string, field: any) => {
        updateOptionsUtil(id, [...serviceOption], (options) => {
            const serviceSelect = options.reduce((result: string[], cur) => {
                if (cur.isActive) {
                    return [...result, cur.value]
                }
                return result
            }, [])
            field.onChange({ target: { name: field.name, value: serviceSelect } });
            setServiceOption(options)
        }, true);
    }

    const customSubmit = async (value: any, form: any) => {
        try {
            form.setSubmitting(true);
            const newFromDate = moment.tz(value.rangeDate.from, moment.tz.guess()).format('YYYY-MM-DD');
            const newToDate = moment.tz(value.rangeDate.to, moment.tz.guess()).format('YYYY-MM-DD');
            const newSpecialHour = { ...value, date: newFromDate, fromDate: newFromDate, toDate: newToDate, rangeDate: undefined }
            if (modalType === 'add') {
                const response = await store.api.add_special_hour(newSpecialHour);
                if (response.outcome !== 0) {
                    UI.notification.error(response.message, { timeout: 6000 });
                } else {
                    UI.notification.success("Special Hours successfully added!");
                    store.addSpecialHour(newSpecialHour)
                    //reset
                    form.resetForm();
                    setServiceOption(availableServiceOption)
                    setShow()
                }
            } else if (modalType === 'edit') {
                const response = await store.api.edit_special_hour(newSpecialHour, selectedIndex);

                if (response.outcome !== 0) {
                    UI.notification.error(response.message, { timeout: 6000 });
                } else {
                    UI.notification.success("Special Hours successfully edited!");
                    store.editSpecialHour(newSpecialHour, selectedIndex)
                    //reset
                    form.resetForm();
                    setServiceOption(availableServiceOption)
                    setShow()
                }
            } else if (modalType === 'duplicate') {
                const response = await store.api.duplicate_special_hour(newSpecialHour, selectedIndex);
                if (response.outcome !== 0) {
                    UI.notification.error(response.message, { timeout: 6000 });
                }
                else {
                    UI.notification.success("Special hour duplicated!");
                    store.duplicateSpecialHour(newSpecialHour, selectedIndex)
                    //reset
                    form.resetForm();
                    setServiceOption(availableServiceOption)
                    setShow()
                }

            }
        }
        catch (e) {
            if (e!.message === 'special hours must not overlap') {
                UI.notification.error("Sorry, Date is already taken.");
            } else {
                UI.notification.error("Something went wrong, try again or contact us");
            }
        }
        finally {
            form.setSubmitting(false);
        }
    }
    const handleSelectDate = (range: rangeProps, field: any) => {
        field.onChange({ target: { name: field.name, value: range } });
    }

    const initialData = {
        // "fromDate": new Date(),
        // "toDate": new Date(),
        "opened": true,
        "h24": false,
        "open": "08:00",
        "close": "15:00",
        "services": [],
        "fee": "",
        "discount": "",
        "rangeDate": {
            from: new Date(),
            to: new Date()
        }
    }

    const validate = (values: specialHourProps) => {
        const errors = {} as any;
        if (!validators.validateTime(values.open)) {
            errors.open = "Start hour has an invalid time. Ensure your hour are set in a valid format between 00:00 and 23:59"
        } else if (!validators.validateTime(values.close)) {
            errors.close = "End hour has an invalid time. Ensure your hour are set in a valid format between 00:00 and 23:59"
        } else if (validators.getTimeAsNumberOfMinutes(values.open) > validators.getTimeAsNumberOfMinutes(values.close)) {
            errors.open = "Please ensure open time are smaller than close time"
        } else if (values?.services.length === 0) {
            errors.services = "Please choose a service"
        }
        return errors
    }

    return (
        <MobileModal  className='dashboard-filter-modal'>
            <MobileModalContent style={{height: '100%', justifyContent: 'stretch'}}>
                <MobileModalHeader style={{justifyContent: 'start'}}>
                    <BackIconWrapper style={{position: 'relative', left: 'unset'}}>
                        <IoMdArrowBack size={24} onClick={() => {
                            setShow()
                        }} />
                    </BackIconWrapper>
                    <CommonText size="large" weight={600} color="#4B4F5E">
                        {modalTypeDisplay[modalType as keyof modalTypeDisplayProps]} Special Hours
                    </CommonText>
                </MobileModalHeader>
                <MobileModalBody style={{paddingTop: 0}}>
                <Formik
                    initialValues={selectedSpecialHour ? {
                        ...selectedSpecialHour, rangeDate: {
                            from: new Date(selectedSpecialHour.fromDate ? selectedSpecialHour.fromDate : ''),
                            to: new Date(selectedSpecialHour.toDate ? selectedSpecialHour.toDate : ''),
                        }
                    } : initialData}
                    validate={validate}
                    onSubmit={customSubmit}>
                    {(form) => {
                        const { submitCount, setFieldValue, values, errors, isSubmitting } = form;
                        const showFormError = !!(submitCount > 0 && errors);
                        return (
                            <Form>
                                <SettingServiceWrapper>
                                    <OptionItemWrapper>
                                        <InputTitle>
                                            <CommonText size="semiMedium" weight={600} color='#4B4F5E'>
                                                Date
                                            </CommonText>
                                            <QuestionIcon />
                                        </InputTitle>
                                        <FastField
                                            name='rangeDate'
                                            render={({ field }: any) => (
                                                <DateRangePickerV2 rangeDate={field.value} onSelect={(range: rangeProps) => handleSelectDate(range, field)} numberOfMonths={2} 
                                                // disabled={{ before: today }} 
                                                />
                                            )}
                                        />
                                        <InputTitle>
                                            <CommonText size="semiMedium" weight={600} color='#4B4F5E'>
                                                Option
                                            </CommonText>
                                            <QuestionIcon />
                                        </InputTitle>
                                        <Field
                                            name="opened"
                                            render={({ field }: any) => {
                                                const baseStoreValue = storeOptionsConstant.map(e => {
                                                    if (e.id === 'open') {
                                                        return { ...e, isActive: field.value }
                                                    } else if (e.id === 'close') {
                                                        return { ...e, isActive: !field.value }
                                                    }
                                                    return e
                                                })
                                                return (
                                                    <GroupOptionItems style={{marginTop: 0}} version={'v2'} optionItems={baseStoreValue} updateOptions={(id) => updateStoreOptions(id, field, values, serviceOption)} />
                                                )
                                            }}
                                        />
                                        <InputTitle style={{marginBottom: '16px'}}>
                                            <CommonText size="medium" weight={500} color='#4B4F5E'>
                                                24 Hours
                                            </CommonText>
                                            <FastField
                                                name='h24'
                                                render={({ field }: any) => (
                                                    <Switch
                                                        id={`h24`}
                                                        checked={field.value}
                                                        labelColor='#4B4F5E'
                                                        onChange={(e) => setFieldValue('h24', e.target.checked)} />

                                                )}
                                            />
                                        </InputTitle>
                                    </OptionItemWrapper>
                                    <OptionItemWrapper>
                                        
                                        <InputTitle>
                                            <CommonText size="semiMedium" weight={600} color='#4B4F5E'>
                                                Service
                                            </CommonText>
                                            <QuestionIcon />
                                        </InputTitle>
                                        <Field
                                            name="services"
                                            render={({ field }: any) => {

                                                return (
                                                    <GroupOptionItems style={{marginTop: 0}} version={'v2'} optionItems={serviceOption} updateOptions={(id) => updateServiceOptions(id, field)} />
                                                )
                                            }}
                                        />
                                        {showFormError && errors.services && <ErrorMessage>{errors.services}</ErrorMessage>}
                                    </OptionItemWrapper>

                                    <OptionItemWrapper className="datepicker">    
                                        <TimeWrapper>
                                            <InputTitle>
                                                <CommonText size="semiMedium" weight={600} color='#4B4F5E'>
                                                    Open
                                                </CommonText>
                                                <QuestionIcon />
                                            </InputTitle>
                                            <Field
                                                name='open'
                                                render={({ field }: any) => (
                                                    <InputMask mask="99:99" required={true} disabled={values.h24} value={field.value} onChange={(e) => setFieldValue('open', e.target.value)}>
                                                        {(inputProps: any) => <Input type="text" disabled={values.h24} placeholder="00:00" {...inputProps} />}
                                                    </InputMask>
                                                )}
                                            />
                                            {showFormError && errors.open && <ErrorMessage>{errors.close}</ErrorMessage>}

                                        </TimeWrapper>
                                        <TimeWrapper style={{marginBottom: '16px'}}>
                                            <InputTitle>
                                                <CommonText size="semiMedium" weight={600} color='#4B4F5E'>
                                                    Close
                                                </CommonText>
                                                <QuestionIcon />
                                            </InputTitle>
                                            <Field
                                                name='close'
                                                render={({ field }: any) => (
                                                    <InputMask mask="99:99" required={true} value={field.value} disabled={values.h24} onChange={(e) => setFieldValue('close', e.target.value)}>
                                                        {(inputProps: any) => <Input type="text" disabled={values.h24} placeholder="21:00" {...inputProps} />}
                                                    </InputMask>
                                                )}
                                            />
                                            {showFormError && errors.close && <ErrorMessage>{errors.close}</ErrorMessage>}
                                        </TimeWrapper>
                                    </OptionItemWrapper>
                                    <OptionItemWrapper className="no-border">
                                        <InputTitle>
                                            <TitleWithSecondaryText>
                                                <CommonText size="semiMedium" weight={600} color='#4B4F5E'>
                                                    Surcharge:
                                                </CommonText>
                                                <CommonText size="semiMedium" weight={400} color="#4B4F5E">
                                                    (Optional)
                                                </CommonText>
                                            </TitleWithSecondaryText>
                                            <QuestionIcon />
                                        </InputTitle>
                                        <FastField
                                            name='fee'
                                            render={({ field }: any) => (
                                                <Select options={feeList}
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                    version='v2'
                                                    value={field.value}
                                                    placeholder="Select surcharge"
                                                    onChange={(e) => setFieldValue('fee', e.target.value)} />
                                            )}
                                        />

                                    </OptionItemWrapper>
                                    <OptionItemWrapper className="no-border" style={{ marginBottom: '30px' }}>
                                        <InputTitle>
                                            <TitleWithSecondaryText>
                                                <CommonText size="semiMedium" weight={600} color='#4B4F5E'>
                                                    Promotion:
                                                </CommonText>
                                                <CommonText size="semiMedium" weight={400} color="#4B4F5E">
                                                    (Optional)
                                                </CommonText>
                                            </TitleWithSecondaryText>
                                            <QuestionIcon />
                                        </InputTitle>
                                        <FastField
                                            name='discount'
                                            render={({ field }: any) => (
                                                <Select options={promotionList}
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                    version='v2'
                                                    placeholder="Select promotion"
                                                    value={field.value}
                                                    onChange={(e) => setFieldValue('discount', e.target.value)} />
                                            )}
                                        />
                                    </OptionItemWrapper>
                                </SettingServiceWrapper>
                                <SettingsActionButtonWrapper className='mx-420'>
                                    <button type="submit" disabled={isSubmitting}> 
                                    {isSubmitting && <RotateLoader size={2} color="white" />}
                                    {!isSubmitting && "Confirm"}
                                    </button>
                                    </SettingsActionButtonWrapper> 
                                    
                            </Form>
                        );
                    }}
                </Formik>
                </MobileModalBody>

            </MobileModalContent>
        </MobileModal>
    )
}
