import React, { useState } from "react";
import { 
    SettingsFormGroup,
    SettingsFormGroupInput,
    SettingsFormLabelContainer,
    SettingsFormLabelLeftContainer,
    SettingsFormLabelRightContainer,
    SettingsFormInputContainer,
    SettingsSwitchContainer,
    OptionalText,
    SettingsActionButtonWrapper,
    SettingsActionButtonContainer
} from ".";
import { 
    IconHelp
} from "../../../../components/v2/icons";
import { RotateLoader, Switch } from "@lib/components";
import { useStores } from "../../../../../mobx/useStores";
import { 
    PrinterCard,
    PrinterCardContainer,
    PrinterCardHeader,
    PrinterCardContent,
    PrinterCardBody,
    PrinterCardBodyDetails,
    TabGroup,
    TabItem
} from "./receipt-printing";
import { RestaurantForm } from "../../../../../mobx/components/restaurant-form";
import { UI } from "../../../../../core/ui";
import { FastField } from "formik";
import { RestaurantUtils } from "@lib/common";
import { cloneDeep } from "lodash";

type FormValues = T.Schema.Restaurant.RestaurantSchema["settings"]["accounts"];


export const SettingsCustomerAccounts = (props: any) => {
    const [toggleSwitch, setToggleSwitch] = useState<boolean>(false)
    const store = useStores()
    const { activeMobileMenu } = props
    const { setActiveMobileMenu, setActiveMobileModal } = store
    const r = store.restaurant!
    let initialValues = cloneDeep(r.settings.accounts);
    const [activeTab, setActiveTab] = useState<string>('guest')
    const guest_match_keys = [
        { key: "details.name", name: "Name" },
        { key: "details.phone", name: "Phone" },
        { key: "details.email", name: "E-mail" },
    ];
    return (
        <div style={{paddingTop: 16}}> 
          
            <TabGroup style={{ overflow: 'unset', padding: '16px' }}>
                <TabItem
                    onClick={() => setActiveTab('guest')}
                    active={activeTab === 'guest'}
                    style={{flex: 1}}>Guest</TabItem>
                <TabItem
                    onClick={() => setActiveTab('facebook')}
                    active={activeTab === 'facebook'}
                    style={{flex: 1}}>Facebook</TabItem>
                <TabItem
                    onClick={() => setActiveTab('google')}
                    active={activeTab === 'google'}
                    style={{flex: 1}}>Google</TabItem>
            </TabGroup>
            <RestaurantForm<FormValues>
                submit={async (r, values) => {
                    r.settings.accounts = values;
                    if (!values.guest) {
                        values.guest = { match_keys: RestaurantUtils.settings.defaultGuestMatchKeys() };
                    }
                    else if (!values.guest.match_keys) {
                        values.guest.match_keys = RestaurantUtils.settings.defaultGuestMatchKeys();
                    }
                    const update = { $set: { "settings.accounts": values } };
                    return { r, update };
                }}
                initialValues={initialValues}
                onError={() => UI.notification.error("An error occurred")}
                onSuccessMessage="Settings Updated">
                {({ form, error }) => {
                    const { isSubmitting, setFieldValue } = form;
                return (
                <>
                    <SettingsFormGroup style={{gap: 16, paddingBottom: 32}}>
                    
                        {activeTab === 'guest' && <>
                        <SettingsFormGroupInput>
                            <SettingsFormLabelContainer>
                                <SettingsFormLabelLeftContainer>Guest Promo Match Fields</SettingsFormLabelLeftContainer>
                            </SettingsFormLabelContainer>
                        </SettingsFormGroupInput>
                        <p>Guest accounts are tracked in the system by a customer's details. You can customize what details are used to match and track guest customers. For example, disabling 'Name' means that guests will only be matched across orders if their "Phone" and "E-Mail" are identical</p>
                        <FastField
                                name="guest.match_keys"
                                render={({ field }: any) => ( 
                            guest_match_keys.map((mk, index) => {
                                return (
                                <SettingsSwitchContainer key={index} style={{gap: 16, paddingTop: 8, paddingBottom: 8}}>
                                    <SettingsFormLabelLeftContainer style={{fontWeight: 500}}>
                                        {mk.name}
                                    </SettingsFormLabelLeftContainer>
                                        <Switch
                                            id={mk.key}
                                            checked={field.value ? field.value.includes(mk.key): false}
                                            onChange={(e) => {
                                                const value = field.value ? [...field.value] : RestaurantUtils.settings.defaultGuestMatchKeys();					
                                                if(e.target.checked) {
                                                    value.push(mk.key)
                                                    setFieldValue("guest.match_keys", value)
                                                } else {
                                                    setFieldValue("guest.match_keys", value.filter(v => v !== mk.key))
                                                }   
                                            }}
                                            variant="black"
                                        />
                                </SettingsSwitchContainer>
                                )
                            })
                   
                        )}/>
                        </>}
                        {activeTab === 'facebook' && <>
                        <SettingsFormGroupInput>
                            <SettingsFormLabelContainer>
                                <SettingsFormLabelLeftContainer>Facebook Developer App ID <OptionalText>(Optional)</OptionalText></SettingsFormLabelLeftContainer>
                                <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                            </SettingsFormLabelContainer>
                            <SettingsFormInputContainer>
                            <FastField
                                name="facebook.app_id"
                                render={({ field }: any) => (
                                <input type="text" {...field} />
                            )}/>
                            </SettingsFormInputContainer>
                        </SettingsFormGroupInput>
                        <p>Enter your app ID to allow customers to login via Facebook. You must have the login addon enabled on your Facebook app</p>
                        </>}
                        {activeTab === 'google' && <>
                        <SettingsFormGroupInput>
                            <SettingsFormLabelContainer>
                                <SettingsFormLabelLeftContainer>Google OAuth Client ID <OptionalText>(Optional)</OptionalText></SettingsFormLabelLeftContainer>
                                <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                            </SettingsFormLabelContainer>
                            <SettingsFormInputContainer>
                                <FastField
                                    name="google.api_client_id"
                                    render={({ field }: any) => (
                                    <input type="text" {...field} />
                                )}/>
                            </SettingsFormInputContainer>
                        </SettingsFormGroupInput>
                        <p>Enter your Google OAuth client ID to allow customers to login via their Google account</p>
                        </>}
                    </SettingsFormGroup>
                    <SettingsActionButtonContainer>
                        <SettingsActionButtonWrapper>
                            <button type="button" 
                                className="default btn-cancel" 
                                onClick={ () => store.setActiveMobileMenu(
                                    store.activeMobileMenu.referrer.pop()!,
                                    Array.from(new Set(store.activeMobileMenu.referrer))
                                )}>
                                    Cancel 
                            </button>
                            <button type="submit" disabled={isSubmitting}>
                                {isSubmitting && <RotateLoader size={2} color="white" />}
                                {!isSubmitting && "Save"}
                            </button>
                        </SettingsActionButtonWrapper>
                    </SettingsActionButtonContainer>
                </>
                )}}
            </RestaurantForm>
        </div>
    )
}