import React, { useState } from "react";
import { SettingsWebsiteWrapper } from "./themes";
import { SettingsActionButtonContainer, SettingsActionButtonWrapper, SettingsFormGroup, SettingsFormGroupInput } from "..";
import { CookieColorPickerContent, CookieColorPickerWrapper, CookieColorsContainer, CookieColorsRow } from "../policies-agreements";
import { ColorPicker, RotateLoader } from "@lib/components";
import { CoreUtils } from "@lib/common";
import { RestaurantForm } from "../../../../../../mobx/components/restaurant-form";
import { useStores } from "../../../../../../mobx/useStores";
import { UI } from "../../../../../../core/ui";
import { FastField } from "formik";
interface Props {
    activeDesignMode?: boolean
}
type FormValues = T.Schema.Restaurant.RestaurantSchema["website"]["colors"];

export const SettingsWebsiteColors = (props: Props) => {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const { activeDesignMode } = props
    const store = useStores();
    const r = store.restaurant!;

    let initialValues = r.website.colors
    return (
        <RestaurantForm<FormValues>
        submit={async (r, values) => {
            r.website.colors = values;
            const update = { $set: { "website.colors": values } };
            return { r, update };
        }}
        initialValues={initialValues}
        onError={() => UI.notification.error("An error occurred")}
        onSuccessMessage="Settings Updated"
        width="100%"
    >
        {({ form, error }) => {
            const { isSubmitting, setFieldValue, setFieldTouched } = form;
            return (
        <SettingsWebsiteWrapper>
            <SettingsFormGroup>
                <SettingsFormGroupInput style={{gap: 16, borderBottom: '1px dashed var(--Secondary-Light-Grey-1, #DFDFDF)', paddingBottom: 16}}>
                    <CookieColorsContainer>
                        <CookieColorsRow>
                            <p>Primary</p>
                            <CookieColorPickerWrapper>
                            <FastField
								name="primary"
								render={({ field }: any) => (
                                <CookieColorPickerContent>
                                    <ColorPicker
                                    color={field.value}
                                    onChange={(color) => setFieldValue('primary', color )}
                                    />
                                        {'#'+CoreUtils.colors.rgbToHex(field.value)}
                                </CookieColorPickerContent>
                                )} />
                            </CookieColorPickerWrapper>
                        </CookieColorsRow>
                    </CookieColorsContainer>
                    <p className="desc">Used for all buttons, icons and other highlighted points. Ideally your main brand color</p>         
                </SettingsFormGroupInput>
                <SettingsFormGroupInput style={{gap: 16, borderBottom: '1px dashed var(--Secondary-Light-Grey-1, #DFDFDF)', paddingBottom: 16}}>
                    <CookieColorsContainer>
                        <CookieColorsRow>
                            <p>Primary Text</p>
                            <CookieColorPickerWrapper>
                            <FastField
								name="primary_text"
								render={({ field }: any) => (
                                <CookieColorPickerContent>
                                        <ColorPicker
                                            color={field.value}
                                            onChange={(color) => setFieldValue('primary_text', color)}
                                            />
                                                {'#'+CoreUtils.colors.rgbToHex(field.value)}
                                        </CookieColorPickerContent>
                                )} />
                            </CookieColorPickerWrapper>
                        </CookieColorsRow>
                    </CookieColorsContainer>
                    <p className="desc">The color of any text or icons when used on top of your primary color. Ideally white or black</p>         
                </SettingsFormGroupInput>
                <SettingsFormGroupInput style={{gap: 16, borderBottom: '1px dashed var(--Secondary-Light-Grey-1, #DFDFDF)', paddingBottom: 16}}>
                    <CookieColorsContainer>
                        <CookieColorsRow>
                            <p>Background</p>
                            <CookieColorPickerWrapper>
                            <FastField
								name="background"
								render={({ field }: any) => (
                                <CookieColorPickerContent>
                                        <ColorPicker
                                            color={field.value}
                                            onChange={(color) => setFieldValue('background', color)}
                                            />
                                                {'#'+CoreUtils.colors.rgbToHex(field.value)}
                                        </CookieColorPickerContent>
                                )} />
                            </CookieColorPickerWrapper>
                        </CookieColorsRow>
                    </CookieColorsContainer>
                    <p className="desc">This is the overall background color of the page and your menus</p>         
                </SettingsFormGroupInput>
                <SettingsFormGroupInput style={{gap: 16, borderBottom: '1px dashed var(--Secondary-Light-Grey-1, #DFDFDF)', paddingBottom: 16}}>
                    <CookieColorsContainer>
                        <CookieColorsRow>
                            <p>Text</p>
                            <CookieColorPickerWrapper>
                            <FastField
								name="text"
								render={({ field }: any) => (
                                <CookieColorPickerContent>
                                        <ColorPicker
                                            color={field.value}
                                            onChange={(color) => setFieldValue('text', color)}
                                            />
                                                {'#'+CoreUtils.colors.rgbToHex(field.value)}
                                        </CookieColorPickerContent>
                                )} />
                            </CookieColorPickerWrapper>
                        </CookieColorsRow>
                    </CookieColorsContainer>
                    <p className="desc">The color of any text used on your background</p>         
                </SettingsFormGroupInput>
                <SettingsFormGroupInput style={{gap: 16, borderBottom: '1px dashed var(--Secondary-Light-Grey-1, #DFDFDF)', paddingBottom: 16}}>
                    <CookieColorsContainer>
                        <CookieColorsRow>
                            <p>Box & Popup Background</p>
                            <CookieColorPickerWrapper>
                            <FastField
								name="box"
								render={({ field }: any) => (
                                <CookieColorPickerContent>
                                        <ColorPicker
                                            color={field.value}
                                            onChange={(color) => setFieldValue('box', color)}
                                            />
                                                {'#'+CoreUtils.colors.rgbToHex(field.value)}
                                        </CookieColorPickerContent>
                                )} />
                            </CookieColorPickerWrapper>
                        </CookieColorsRow>
                    </CookieColorsContainer>
                    <p className="desc">The color of any boxes or popup backgrounds. Includes your menu items</p>         
                </SettingsFormGroupInput>
                <SettingsFormGroupInput style={{gap: 16, borderBottom: '1px dashed var(--Secondary-Light-Grey-1, #DFDFDF)', paddingBottom: 16}}>
                    <CookieColorsContainer>
                        <CookieColorsRow>
                            <p>Box & Popup Text</p>
                            <CookieColorPickerWrapper>
                            <FastField
								name="box_text"
								render={({ field }: any) => (
                                <CookieColorPickerContent>
                                        <ColorPicker
                                            color={field.value}
                                            onChange={(color) => setFieldValue('box_text', color)}
                                            />
                                                {'#'+CoreUtils.colors.rgbToHex(field.value)}
                                        </CookieColorPickerContent>
                                )} />
                            </CookieColorPickerWrapper>
                        </CookieColorsRow>
                    </CookieColorsContainer>
                    <p className="desc">The color of any text or icons in your boxes or popups. Includes menu items</p>         
                </SettingsFormGroupInput>
            </SettingsFormGroup>      
            <SettingsActionButtonContainer activeDesignMode={activeDesignMode}>
                <SettingsActionButtonWrapper>
                    <button type="submit" disabled={isSubmitting}>
                        {isSubmitting && <RotateLoader size={2} color="white" />}
                        {!isSubmitting && "Save"}
                    </button>
                </SettingsActionButtonWrapper>
            </SettingsActionButtonContainer>
        </SettingsWebsiteWrapper>
        )}}
        </RestaurantForm>
    )
}
