import * as React from "react";
import { FastField, Field } from "formik";
import { ButtonGroup, FooterSection, FormGroup, FormUpload, Sidebar } from "@lib/components";
import { Button } from "@lib/components";
import { RotateLoader } from "@lib/components";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../../../../mobx/components/index";
import { WebsiteForm } from "../../../../../../mobx/components/website-form";
import { UI } from "../../../../../../core/ui";
import { Input } from "@lib/components";
import styled from "styled-components";
import { FaCheck } from "react-icons/fa";
import { ImageUpload } from "../upload_image";

export const CardHeaderItem = styled.div`
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  display: flex;
  padding: 12px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 4px;
  background: #F5F5F5;
  justify-content: space-between;
`;
export const Wrapper = styled.div`
  .card-header-active {
    border: 1px solid #FB2F2F;
    background: #FFF3F3;
  }
  .icon-active {
    color: #FB2F2F;
  }
`
interface Props {
  isOpen?: any;
	handleClose?: any;
	isMobile?: boolean;
 }
interface State { }
type FormValues = T.Schema.Website.WebsiteSchema["sections"]["locations"];

@inject("store") @observer
export class WebsiteFormLocations extends MobxComponent<Props, State> {

	initialValues: FormValues;

	constructor(props: Props) {
		super(props);
		const w = this.injected.store.website!;
		this.state = {};
		this.initialValues = w.sections.locations || {
			map_zoom: 12,
			card_type: 'map'
		};
	}

	render() {
    const { isMobile, isOpen, handleClose } = this.props;
    const CardHeaderItems = [
      { type: 'map', label: 'Map' },
      { type: 'image', label: 'Image' },
      { type: 'none', label: 'None' }
    ];
    const CardBehaviorItems = [
      { type: 'interactive', label: 'Interactive' },
      { type: 'static', label: 'Static Image' }
    ];
		return (
			!isMobile ? <WebsiteForm<FormValues>
				submit={async (w, values) => {
					w.sections.locations = values;
					const update = { $set: { "sections.locations": values } };
					return { w, update };
				}}
				validators={{}}
				initialValues={this.initialValues}
				onSuccess={() => { }}
				onError={() => UI.notification.error("An error occurred")}
				onSuccessMessage="Settings Updated"
				onErrorMessage="">
				{({ form, error }) => {
					const { isSubmitting, setFieldValue, setFieldTouched } = form;
					return (
						<div className="p-4">

							<Field
                name="card_type"
                render={({ field }: any) => (
                  <FormGroup
                    title="Card Header"
                    help={
                      [undefined, 'map'].includes(form.values?.card_type)
                        ? `Show a map widget with a marker on the store's address.`
                        : form.values?.card_type === 'image'
                        ? `Upload an image to show on this store's location card.`
                        : `Don't show any image or map`
                    }
                  >
                    <ButtonGroup
                      size={'xs'}
                      selected={field.value || 'map'}
                      options={[
                        {
                          value: 'map',
                          name: 'Map',
                        },
                        {
                          value: 'image',
                          name: 'Image',
                        },
                        {
                          value: 'none',
                          name: 'None',
                        },
                      ]}
                      buttonClassName="p-lr-2"
                      onSelect={v => {
                        setFieldValue('card_type', v.value)
                      }}
                      width="auto"
                    />
                  </FormGroup>
                )}
              />

              {form.values && [undefined, 'map'].includes(form.values.card_type) && (
                <Field
                  name="map_type"
                  render={({ field }: any) => (
                    <FormGroup
                      title="Card Header"
                      help="Interactive maps can be zoomed in and out; and panned. Static image is a snapshot of the same map without the controls."
                    >
                      <ButtonGroup
                        size={'xs'}
                        selected={field.value || 'interactive'}
                        options={[
                          {
                            value: 'interactive',
                            name: 'Interactive',
                          },
                          {
                            value: 'static',
                            name: 'Static Image',
                          },
                        ]}
                        buttonClassName="p-lr-2"
                        onSelect={v => setFieldValue('map_type', v.value)}
                        width="auto"
                      />
                    </FormGroup>
                  )}
                />
              )}

              { form.values && form.values.card_type === 'image' && (
                <FastField
                  name="image"
                  render={({ field }: any) => (
                    <FormGroup help="Recomended size is 550x200 pixels. PNG or JPG only">
                      <FormUpload
                        maxSize={550}
                        imagesOnly={true}
                        inputAcceptTypes="image/png/jpg"
                        value={[field.value]}
                        onRemove={() => setFieldValue('image', null)}
                        onChange={files => setFieldValue('image', files[0])}
                      />
                    </FormGroup>
                  )}
                />
              )}

              {form.values && [undefined, 'map'].includes(form.values.card_type) && (
                <FastField
                  name="map_zoom"
                  render={({ field }: any) => (
                    <FormGroup title="Map Zoom" help="The zoom level of your embedded store map. Higher value is more zoomed in. Default is 12 if left empty">
                      <Input type="number" {...field} />
                    </FormGroup>
                  )}
                />
              )}

							{error && <FormGroup error={error} />}

							<Button full={true} color="primary" type="submit" disabled={isSubmitting}>
								{isSubmitting && <RotateLoader size={2} color="white" />}
								{!isSubmitting && "Save"}
							</Button>

						</div>
					);
				}}
			</WebsiteForm>
      : <Sidebar isOpen={isOpen} headerTitle={"Locations"} onClose={handleClose} width={window.innerWidth} isLandingPageMobile>
        <WebsiteForm<FormValues>
				submit={async (w, values) => {
					w.sections.locations = values;
					const update = { $set: { "sections.locations": values } };
					return { w, update };
				}}
				validators={{}}
				initialValues={this.initialValues}
				onSuccess={() => { }}
				onError={() => UI.notification.error("An error occurred")}
				onSuccessMessage="Settings Updated"
				onErrorMessage="">
				{({ form, error }) => {
					const { isSubmitting, setFieldValue, setFieldTouched } = form;
					return (
						<div className="p-4">

							<Field
                name="card_type"
                render={({ field }: any) => (
                  <FormGroup
                    title="Card Header"
                    help={
                      [undefined, 'map'].includes(form.values?.card_type)
                        ? `Show a map widget with a marker on the store's address.`
                        : form.values?.card_type === 'image'
                        ? `Upload an image to show on this store's location card.`
                        : `Don't show any image or map`
                    }
                  >
                    <Wrapper style={{ display: 'flex', flexDirection: 'column', gap: '8px'}}>
                    {CardHeaderItems.map((item) => (
                      <CardHeaderItem
                        key={item.type}
                        className={form.values?.card_type === item.type ? 'card-header-active' : ''}
                        onClick={() => setFieldValue('card_type', item.type)}
                      >
                        {item.label}
                        {form.values?.card_type === item.type && <FaCheck className="icon-active"/>}
                      </CardHeaderItem>
                    ))}
                  </Wrapper>
                  </FormGroup>
                )}
              />

              {form.values && [undefined, 'map'].includes(form.values.card_type) && (
                <Field
                  name="map_type"
                  render={({ field }: any) => (
                    <FormGroup
                      title="Card Header"
                      help="Interactive maps can be zoomed in and out; and panned. Static image is a snapshot of the same map without the controls."
                    >
                     <Wrapper style={{ display: 'flex', flexDirection: 'column', gap: '8px'}}>
                        {CardBehaviorItems.map((item) => (
                          <CardHeaderItem
                            key={item.type}
                            className={form.values?.map_type === item.type ? 'card-header-active' : ''}
                            onClick={() => setFieldValue('map_type', item.type)}
                          >
                            {item.label}
                            {form.values?.map_type === item.type && <FaCheck className="icon-active"/>}
                          </CardHeaderItem>
                        ))}
                    </Wrapper>
                    </FormGroup>
                  )}
                />
              )}

              { form.values && form.values.card_type === 'image' && (
                <FastField
                  name="image"
                  render={({ field }: any) => (
                    <FormGroup help="Recomended size is 550x200 pixels. PNG or JPG only">
                      {/* <FormUpload
                        maxSize={550}
                        imagesOnly={true}
                        inputAcceptTypes="image/png/jpg"
                        value={[field.value]}
                        onRemove={() => setFieldValue('image', null)}
                        onChange={files => setFieldValue('image', files[0])}
                      /> */}
                      <ImageUpload value={[field.value]} onChange={(files: any[]) => setFieldValue('image', files[0])} onRemove={() => setFieldValue('image', null)}/>
                    </FormGroup>
                  )}
                />
              )}

              {form.values && [undefined, 'map'].includes(form.values.card_type) && (
                <FastField
                  name="map_zoom"
                  render={({ field }: any) => (
                    <FormGroup title="Map Zoom" help="The zoom level of your embedded store map. Higher value is more zoomed in. Default is 12 if left empty">
                      <Input type="number" {...field} />
                    </FormGroup>
                  )}
                />
              )}

							{error && <FormGroup error={error} />}
							<FooterSection style={{ padding: "8px 16px", left: 0 }} width={window.innerWidth}>
                <Button full={true} color="primary" type="submit" disabled={isSubmitting}>
                  {isSubmitting && <RotateLoader size={2} color="white" />}
                  {!isSubmitting && "Save"}
                </Button>
              </FooterSection>
							

						</div>
					);
				}}
			</WebsiteForm>
      </Sidebar>
		);
	}
}
