import React from 'react';
import { RotateLoader, Switch } from '@lib/components';
import {
  SettingsFormGroup,
  SettingsSwitchContainer,
  SettingsFormLabelLeftContainer,
  SettingsFormGroupInput,
  SettingsFormLabelContainer,
  SettingsFormLabelRightContainer,
  SettingsFormInputContainer,
  SettingsActionButtonContainer,
  SettingsActionButtonWrapper,
} from '../..';
import { IconHelpV2, IconHelp } from '../../../../../../components/v2/icons';
import { useStores } from '../../../../../../../mobx/useStores';
import { RestaurantForm } from '../../../../../../../mobx/components/restaurant-form';
import { UI } from '../../../../../../../core/ui';
import { updateRestaurantNotificationOptions } from '../../../../../../../core/libs/receipt';
import { FastField } from 'formik';
type FormValues = T.Schema.Restaurant.RestaurantSchema["settings"]["services"]["table_booking"];

export const GeneralTableBookingServices = (props: any) => {
  const store = useStores();
  const r = store.restaurant!;
  const { injected } = props
	let initialValues = r.settings.services.table_booking;
  return (
    <div style={{paddingTop: 16, paddingBottom: 83}}>
      <RestaurantForm<FormValues>
        submit={async (r, values) => {
          r.settings.services.table_booking = values;
          const update = { $set: { "settings.services.table_booking": r.settings.services.table_booking } };
          return { r, update };
        }}
        initialValues={initialValues}
        onSuccess={async () => await updateRestaurantNotificationOptions(injected.store)}
        onError={() => UI.notification.error("An error occurred")}
        onSuccessMessage="Settings Updated"
        width='100%'
      >
        {({ form, error, getFieldError }) => {
          const { isSubmitting, setFieldValue } = form;
          return (
            <>
              <SettingsFormGroup>
                <SettingsSwitchContainer>
                  <SettingsFormLabelLeftContainer>
                    Enabled
                    <IconHelpV2 />
                  </SettingsFormLabelLeftContainer>
                  <FastField
												name="enabled"
												render={({ field }: any) => (
												<Switch
															id="enable-pickup-switch"
															checked={field.value}
															onChange={(e) => setFieldValue("enabled", e.target.checked)} />
												
												)}
											/>
                </SettingsSwitchContainer>
                <p style={{ fontSize: '12px', width: '100%' }}>Allows customers to place orders due immediately</p>
                <SettingsFormGroupInput>
                  <SettingsFormLabelContainer>
                    <SettingsFormLabelLeftContainer>Display Name</SettingsFormLabelLeftContainer>
                    <SettingsFormLabelRightContainer>
                      <IconHelp />
                    </SettingsFormLabelRightContainer>
                  </SettingsFormLabelContainer>
                  <SettingsFormInputContainer>
									<FastField
												name="display_name"
												render={({ field }: any) => (
													
														<input
															type="text"
															{...field}
														/>
												)}
											/>
                  </SettingsFormInputContainer>
                  <p style={{ fontSize: '12px' }}>
                    Override the service name. This will override the translation as well. Defaults to Pickup.
                  </p>
                </SettingsFormGroupInput>
                <SettingsFormGroupInput>
                  <SettingsFormLabelContainer>
                    <SettingsFormLabelLeftContainer>Table Booking Notes</SettingsFormLabelLeftContainer>
                    <SettingsFormLabelRightContainer>
                      <IconHelp />
                    </SettingsFormLabelRightContainer>
                  </SettingsFormLabelContainer>
                  <SettingsFormInputContainer>
                  <FastField
												name="notes"
												render={({ field }: any) => (
												<input type="text" {...field} />
													
												)}
											/>
                  </SettingsFormInputContainer>
                  <p style={{ fontSize: '12px' }}>This will be shown to customers if they choose this order method</p>
                </SettingsFormGroupInput>
                <SettingsFormGroupInput>
                  <SettingsFormLabelContainer>
                    <SettingsFormLabelLeftContainer>Maximum Number Of People</SettingsFormLabelLeftContainer>
                    <SettingsFormLabelRightContainer>
                      <IconHelp />
                    </SettingsFormLabelRightContainer>
                  </SettingsFormLabelContainer>
                  <SettingsFormInputContainer>
                  <FastField
												name="max_people"
												render={({ field }: any) => (
									      	<input
															type="number"
															min={1}
															step={1}
															value={field.value || form.values.limit_number_of_people || ""}
															{...field}
														/>
												)}
											/>
                  </SettingsFormInputContainer>
                  <p style={{ fontSize: '12px' }}>
                    Maximum number of people allowed for a booking request. Leave blank for no limit
                  </p>
                </SettingsFormGroupInput>
                <SettingsFormGroupInput>
                  <SettingsFormLabelContainer>
                    <SettingsFormLabelLeftContainer>Minimum Number Of People</SettingsFormLabelLeftContainer>
                    <SettingsFormLabelRightContainer>
                      <IconHelp />
                    </SettingsFormLabelRightContainer>
                  </SettingsFormLabelContainer>
                  <SettingsFormInputContainer>
                  <FastField
												name="min_people"
												render={({ field }: any) => (
										  	  <input 
                              type="number"
															min={1}
															step={1}
															value={field.value || ""}
															{...field}
														/>
												)}
											/>
                  </SettingsFormInputContainer>
                  <p style={{ fontSize: '12px' }}>
                    Minimum number of people allowed for a booking request. Leave blank for no minimum
                  </p>
                </SettingsFormGroupInput>
              </SettingsFormGroup>
              <SettingsActionButtonContainer>
                <SettingsActionButtonWrapper>
                    <button type="button" 
                        className="default btn-cancel" 
                        onClick={ () => store.setActiveMobileMenu(
                            store.activeMobileMenu.referrer.pop()!,
                            Array.from(new Set(store.activeMobileMenu.referrer))
                        )}>
                            Cancel 
                    </button>
                    <button type="submit" disabled={isSubmitting}>
                        {isSubmitting && <RotateLoader size={2} color="white" />}
                        {!isSubmitting && "Save"}
                    </button>
                </SettingsActionButtonWrapper>
            </SettingsActionButtonContainer>
            </>
          )}}
        </RestaurantForm>
      </div>
  );
};
