import React, { useState } from "react";
import { 
    SettingsFormGroup,
    SettingsFormGroupInput,
    SettingsFormLabelContainer,
    SettingsFormLabelLeftContainer,
    SettingsFormInputContainer,
    SettingsSwitchContainer,
    OptionalText,
    SettingsFormLabelRightContainer,
    SettingsActionButtonWrapper,
    SettingsActionButtonContainer
} from ".";

import { RotateLoader, SelectAdv, Switch } from "@lib/components";
import { IconHelp } from "../../../../components/v2/icons";
import { RestaurantForm } from "../../../../../mobx/components/restaurant-form";
import { UI } from "../../../../../core/ui";
import { FastField } from "formik";
import { RestaurantUtils } from "@lib/common";
import { cloneDeep } from "lodash";
import { useStores } from "../../../../../mobx/useStores";

type FormValues = T.Schema.Restaurant.RestaurantSettingsNotificationsAudio;

export const SettingsAudioNotification = () => {
    const [options, setOptions] = useState<string[]>([])
    const [toggleSwitch, setToggleSwitch] = useState<boolean>(false)
    const store = useStores()
    const r = store.restaurant!
    let initialValues = cloneDeep(r.settings.notifications.audio) || RestaurantUtils.settings.defaultAudioNotifications();
    return (
    <div style={{paddingTop: 16, width: '100%'}}>
        <RestaurantForm<FormValues>
        submit={async (r, v) => {
            r.settings.notifications.audio = v;
            const update = { $set: { "settings.notifications.audio": v } };
            return { r, update };
        }}
        initialValues={initialValues}
        onError={() => UI.notification.error("An error occurred")}
        onSuccessMessage="Settings Updated"
        width="100%"
    >
        {({ form, error }) => {
            const { isSubmitting, setFieldValue } = form;
            return (
        <>
            <SettingsFormGroup style={{gap: 16, paddingBottom: 32}}>
                <SettingsFormGroupInput>
                    <SettingsFormLabelContainer>
                        <SettingsFormLabelLeftContainer>Sound</SettingsFormLabelLeftContainer>
                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                    </SettingsFormLabelContainer>
                    <SettingsFormInputContainer>
                    <FastField
                        name="sound"
                        render={({ field }: any) => (
                            <SelectAdv
                                    type="single"
                                    options={[
                                        { value: "", label: "None" },
                                        { value: "job-done.ogg", label: "Default" },
                                        { value: "filling-your-inbox.ogg", label: "Filling Your Inbox" },
                                        { value: "hand-bell.ogg", label: "Hand Bell" },
                                        { value: "tiny-bell.ogg", label: "Tiny Bell" },
                                        { value: "plucky.ogg", label: "Plucky" },
                                        { value: "oringz-w429.ogg", label: "O-Ringz" },
                                    ]}
                                    value={field.value}
                                    onChange={(option: string) => setFieldValue("sound", option)}
                                />
                        )}
                    />
                    </SettingsFormInputContainer>
                </SettingsFormGroupInput>
                <SettingsFormGroupInput>
                    <SettingsFormLabelContainer>
                        <SettingsFormLabelLeftContainer>Repeat Count</SettingsFormLabelLeftContainer>
                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                    </SettingsFormLabelContainer>
                    <SettingsFormInputContainer>
                    <FastField
                        name="repeat_count"
                        render={({ field }: any) => (
                            <input type="number" min="1" step="1" {...field} />
                        )} />
                    </SettingsFormInputContainer>
                </SettingsFormGroupInput>
                <SettingsSwitchContainer style={{gap: 16, paddingTop: 8, paddingBottom: 8}}>
                    <SettingsFormLabelLeftContainer style={{fontWeight: 500}}>
                        Stop On Click
                    </SettingsFormLabelLeftContainer>
                    <FastField 
                            name="stop_on_click"
                            render={({ field }: any) =>
                            <Switch
                                id="stop_on_click"
                                checked={field.value || false}
                                onChange={(e) =>  setFieldValue("stop_on_click", e.target.checked)}
                                variant="black"
                                />
                            }
                        />
                </SettingsSwitchContainer>
                <SettingsSwitchContainer style={{gap: 16, paddingTop: 8, paddingBottom: 8}}>
                    <SettingsFormLabelLeftContainer style={{fontWeight: 500}}>
                        Play On - New Order
                    </SettingsFormLabelLeftContainer>
                    <FastField 
                            name="play_on.order_new"
                            render={({ field }: any) =>
                            <Switch
                                id="play_on.order_new"
                                checked={field.value || false}
                                onChange={(e) =>  setFieldValue("play_on.order_new", e.target.checked)}
                                variant="black"
                                />
                            }
                        />
                </SettingsSwitchContainer>
                <SettingsSwitchContainer style={{gap: 16, paddingTop: 8, paddingBottom: 8}}>
                    <SettingsFormLabelLeftContainer style={{fontWeight: 500}}>
                        Play On - New Booking
                    </SettingsFormLabelLeftContainer>
                    <FastField 
                            name="play_on.booking_new"
                            render={({ field }: any) =>
                            <Switch
                                id="play_on.booking_new"
                                checked={field.value || false}
                                onChange={(e) =>  setFieldValue("play_on.booking_new", e.target.checked)}
                                variant="black"
                                />
                            }
                        />
                </SettingsSwitchContainer>
                <SettingsSwitchContainer style={{gap: 16, paddingTop: 8, paddingBottom: 8}}>
                    <SettingsFormLabelLeftContainer style={{fontWeight: 500}}>
                        Play On - Customer Age Verification Request
                    </SettingsFormLabelLeftContainer>
                        <FastField 
                        name="play_on.customer_age_verification"
                        render={({ field }: any) =>
                        <Switch
                            id="play_on.customer_age_verification"
                            checked={field.value || false}
                            onChange={(e) =>  setFieldValue("play_on.customer_age_verification", e.target.checked)}
                            variant="black"
                            />
                        }
                    />
                </SettingsSwitchContainer>
            </SettingsFormGroup>
            <SettingsActionButtonContainer>
                <SettingsActionButtonWrapper>
                    <button type="button" 
                        className="default btn-cancel" 
                        onClick={ () => store.setActiveMobileMenu(
                            store.activeMobileMenu.referrer.pop()!,
                            Array.from(new Set(store.activeMobileMenu.referrer))
                        )}>
                            Cancel 
                    </button>
                    <button type="submit" disabled={isSubmitting}>
                        {isSubmitting && <RotateLoader size={2} color="white" />}
                        {!isSubmitting && "Save"}
                    </button>
                </SettingsActionButtonWrapper>
            </SettingsActionButtonContainer>
        </>
            )}}
        </RestaurantForm>
    </div>
    )
}