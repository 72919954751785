import React, { useState } from 'react'
import { WebsiteForm } from '../../../../../mobx/components/website-form';
import { useStores } from '../../../../../mobx/useStores';
import { UI } from '../../../../../core/ui';
import { FormGroup, Input, RotateLoader, Switch } from '@lib/components';
import { FastField } from 'formik';
import { SettingsActionButtonContainer, SettingsActionButtonWrapper } from '../../../restaurant/views/settings-v2';
import { ImageUpload } from '../website/upload_image';
import { WebsiteTopNavLinks } from '../website/fields/top-nav-links';
import { WebsiteUtils } from '@lib/common';
import { CookieColorsMobile } from '../website/color_picker_mobile';
type FormValues = T.Schema.Website.WebsiteSchema["sections"]["top_nav"];

export const NavbarV2 = () => {
    const store = useStores()
    const w = store.website!;
    let initialValues = w.sections.top_nav;
    const [colors, setColors] = useState(
        {
			backgroundColor: '#000000',
			colorText: '#000000'
		}
    )

    return (
        <WebsiteForm<FormValues>
        submit={async (w, values) => {
            w.sections.top_nav = values;
            const update = { $set: { "sections.top_nav": values } };
            return { w, update };
        }}
        validators={{}}
        initialValues={initialValues}
        onSuccess={() => { }}
        onError={() => UI.notification.error("An error occurred")}
        onSuccessMessage="Settings Updated"
        onErrorMessage="">
        {({ form, error }) => {
            const { isSubmitting, setFieldValue } = form;
            return (
                <div className="p-4"  style={{ height: '93vh', overflowY: 'scroll', overflowX: 'hidden', marginBottom: '20px'}}>
                    <FastField
                        name="hidden"
                        render={({ field }: any) => (
                            <div style={{marginBottom: '16px'}}>
                                <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                                    <div style={{ fontSize: '18px', lineHeight: '24px', fontWeight: 600}}>Hide Nav Bar</div>
                                    <Switch
                                        id="hidden"
                                        checked={field.value}
                                        onChange={(e) => setFieldValue("hidden", e.target.checked)} />
                                </div>
                                <div style={{ fontSize: '12px', lineHeight: '16px', fontWeight: 500, color: '#6B7270', marginTop: '16px'}}>Remove this section from your website</div>
                            </div>
                        )}
                    />

                    <FastField
                        name="image_logo"
                        render={({ field }: any) => (
                            <FormGroup
                                title="Logo Image"
                                help="Upload your business logo. It will be displayed at the resolution you upload it at. File name can't contain special charaters"
                                optional={true}>
                                 <ImageUpload 
                                    value={[field.value]} 
                                    onChange={(files: any[]) => setFieldValue('image_logo', files[0])} onRemove={() => setFieldValue('image_logo', null)}/>
                            </FormGroup>
                        )}
                    />

                    <FastField
                        name="image_logo_url"
                        render={({ field }: any) => (
                            <FormGroup
                                optional={true}
                                title="Logo Image URL"
                                help="Link to an external website when someone clicks your logo. Leaving this empty will take the user to the top of your page when they click your logo">
                                <Input type="url" {...field} />
                            </FormGroup>
                        )}
                    />

                    <FastField
                        name="image_logo_text"
                        render={({ field }: any) => (
                            <FormGroup
                                optional={true}
                                title="Logo Text"
                                help="You can use this as an alternate to uploading an image logo. If used together with a logo image, the text will be displayed to the right of your logo image">
                                <Input type="text" {...field} />
                            </FormGroup>
                        )}
                    />

                    <FastField
                        name="image_logo_center"
                        render={({ field }: any) => (
                            <div style={{marginBottom: '16px'}}>
                                <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                                    <div style={{ fontSize: '18px', lineHeight: '24px', fontWeight: 600}}>Center Content</div>
                                    <Switch
                                    id="image_logo_center"
                                    checked={field.value}
                                    onChange={(e) => setFieldValue("image_logo_center", e.target.checked)} />
                                </div>
                                <div style={{ fontSize: '12px', lineHeight: '16px', fontWeight: 500, color: '#6B7270', marginTop: '16px'}}>Center all the content in the top nav bar including your logo and links</div>

                            </div>
                        )}
                    />

                    <FastField
                        name="links"
                        render={({ field }: any) => (
                            <FormGroup
                                title="Links"
                                help="These are the links of your top nav menu. Remove all links to hide the menu">
                                <WebsiteTopNavLinks
                                    values={field.value || WebsiteUtils.settings.defaultTopNavLinks()}
                                    onChange={(links) => setFieldValue("links", links)}
                                />
                            </FormGroup>
                        )}
                    />

                    <FastField
                        name="color_background"
                        style={{ marginBottom: '30px'}}
                        render={({ field }: any) => (
                            <FormGroup title="">
                                <CookieColorsMobile
                                    color={field.value || colors.backgroundColor}
                                    onColorChange={(color: any) => {
                                        setColors({...colors, backgroundColor: color });
                                        field.onChange({ target: { name: 'color_background', value: color } });
                                    }}
                                    colorField="Background Color"
                                />
                            </FormGroup>
                        )}
                    />

                    <FastField
                        name="color_text"
                        render={({ field }: any) => (
                            <FormGroup title="">
                                <CookieColorsMobile 
                                    color={field.value || colors.colorText}
                                    onColorChange={(color: any) => {
                                        setColors({...colors, colorText: color });
                                        field.onChange({ target: { name: 'color_text', value: color } });
                                    }}
                                    colorField="Text Color"
                                />
                            </FormGroup>
                        )}
                    />

                    {error && <FormGroup error={error} />}

                    <SettingsActionButtonContainer className='no-sidebar'>
                        <SettingsActionButtonWrapper style={{left: 0}}>
                            <button 
                                type="button" 
                                className='btn-cancel'
                                onClick={ () => store.setActiveMobileMenu(
                                    store.activeMobileMenu.referrer.pop()!,
                                    Array.from(new Set(store.activeMobileMenu.referrer))
                                )}>
                                Cancel
                            </button>
                            <button  type="submit" disabled={isSubmitting}>
                            {isSubmitting && <RotateLoader size={2} color="white" />}
                            {!isSubmitting && "Save"}
                            </button>
                        </SettingsActionButtonWrapper>
                    </SettingsActionButtonContainer>

                </div>
            );
        }}
    </WebsiteForm>
    )
}