import React from "react";
import { 
    SettingsFormGroup,
    SettingsFormGroupInput,
    SettingsFormLabelContainer,
    SettingsFormLabelLeftContainer,
    SettingsFormLabelRightContainer,
    SettingsFormInputContainer,
    SettingsSwitchContainer,
    SettingsActionButtonWrapper,
    SettingsActionButtonContainer
} from ".";
import { 
    IconHelp,
} from "../../../../components/v2/icons";
import { RotateLoader, Switch } from "@lib/components";
import { RestaurantForm } from "../../../../../mobx/components/restaurant-form";
import { UI } from "../../../../../core/ui";
import { useStores } from "../../../../../mobx/useStores";
import { FastField } from "formik";
interface FormValues {
	business: T.Schema.Restaurant.RestaurantSchema["settings"]["business"];
}
export const SettingsMiscellaneous = () => {
    const store = useStores()
    const r = store.restaurant!;
    let initialValues: FormValues  = {
        business: r.settings.business
    }
    const validateRegularExpression = (value: string) => {
		let error;
		try {
			new RegExp(value);
		} catch (e) {
			error = 'Invalid Phone Number RegExp.';
		}
		return error;
	}
    return (
        <div style={{paddingTop: 16, width: '100%'}}>
        <RestaurantForm<FormValues>
        submit={async (r, values) => {
            r.settings.business = values.business;
            const update = { $set: { "settings.business": values.business } };
            return { r, update };
        }}
        initialValues={initialValues}
        onError={() => UI.notification.error("An error occurred")}
        onSuccessMessage="Settings Updated"
        width="100%"
        >
        {({ form, error }) => {
        const { isSubmitting, setFieldValue, errors } = form;
        return (
            <>
            <SettingsFormGroup style={{ gap: 16, paddingBottom: 32}}>
                <SettingsFormGroupInput>
                    <SettingsFormLabelContainer>
                        <SettingsFormLabelLeftContainer>Phone Number Regexp</SettingsFormLabelLeftContainer>
                        <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                    </SettingsFormLabelContainer>
                    <SettingsFormInputContainer>
                        <FastField 
                            name="business.validation.phone_regex"
                            validate={validateRegularExpression}
                            render={({ field }: any) =>
                            <input 
                            placeholder='XX XXXX XXXX'
                            type="text"
                            {...field} />
                            }
                        />
                    </SettingsFormInputContainer>
                </SettingsFormGroupInput>
                <SettingsSwitchContainer style={{gap: 16, paddingTop: 8, paddingBottom: 8}}>
                    <SettingsFormLabelLeftContainer style={{fontWeight: 500}}>
                        Logged In Only
                    </SettingsFormLabelLeftContainer>
                    <FastField 
                        name="business.logged_in_only"
                        render={({ field }: any) =>
                        <Switch
                            id="logged_in_only"
                            checked={field.value || false}
                            onChange={(e) =>  setFieldValue("business.logged_in_only", e.target.checked)}
                            variant="black"
                            />
                        }
                    />
                </SettingsSwitchContainer>
                <SettingsSwitchContainer style={{gap: 16, paddingTop: 8, paddingBottom: 8}}>
                    <SettingsFormLabelLeftContainer style={{fontWeight: 500}}>
                        Disable Signup
                    </SettingsFormLabelLeftContainer>
                    <FastField 
                        name="business.disable_signup"
                        render={({ field }: any) =>
                        <Switch
                            id="disable_signup"
                            checked={field.value || false}
                            onChange={(e) =>  setFieldValue("business.disable_signup", e.target.checked)}
                            variant="black"
                            />
                        }
                    />
                </SettingsSwitchContainer>
                <SettingsSwitchContainer style={{gap: 16, paddingTop: 8, paddingBottom: 8}}>
                    <SettingsFormLabelLeftContainer style={{fontWeight: 500}}>
                        Service Pop-up Required
                    </SettingsFormLabelLeftContainer>
                    <FastField 
                        name="business.service_required"
                        render={({ field }: any) =>
                        <Switch
                            id="service_required"
                            checked={field.value || false}
                            onChange={(e) =>  setFieldValue("business.service_required", e.target.checked)}
                            variant="black"
                            />
                        }
                    />
                </SettingsSwitchContainer>
            </SettingsFormGroup>
            <SettingsActionButtonContainer>
                <SettingsActionButtonWrapper>
                    <button type="button" 
                        className="default btn-cancel" 
                        onClick={ () => store.setActiveMobileMenu(
                            store.activeMobileMenu.referrer.pop()!,
                            Array.from(new Set(store.activeMobileMenu.referrer))
                        )}>
                            Cancel 
                    </button>
                    <button type="submit" disabled={isSubmitting}>
                        {isSubmitting && <RotateLoader size={2} color="white" />}
                        {!isSubmitting && "Save"}
                    </button>
                </SettingsActionButtonWrapper>
            </SettingsActionButtonContainer>
            </>
        )}}
        </RestaurantForm>
        </div>
    )
}
