import React from 'react';
import { Sidebar, SidebarBodySection } from '../../../sidebar';
import { OrderStatusItem, TStatusItem } from '../../../bumpViews/modals/changeStatus';
import styled from 'styled-components';
import { CommonText } from '../../../bumpViews/commonText';
import { OrderTotals } from '../../../order/order-totals';

type ChangeOrderStatusSidebarProps = {
  isOpen: boolean;
  isMobile?: boolean;
  handleClose: () => void;
  selectedOrders?: number;
  onChangeStatusOrder: (status: string) => void;
  totalOrderStatus?: { [key: string]: number; due_soon?: any };
};

export const MOCK_ORDER_STATUS_DATA: TStatusItem[] = [
  { id: '1', text: 'Unconfirmed', type: 'unconfirmed' },
  { id: '2', text: 'Confirmed', type: 'confirmed' },
  { id: '3', text: 'Ready', type: 'ready' },
  { id: '4', text: 'On-route', type: 'on_route' },
  { id: '5', text: 'Completed', type: 'complete' },
  { id: '6', text: 'Cancelled', type: 'cancelled' },
];

const TextItemWrapper = styled.div`
  margin-bottom: 16px;
  font-family: Inter;
`;

const SelectedText = styled.div`
  color: #6B7270;
  display: flex;
  padding: 8px 16px;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`;

const TotalOrderStatus = styled.div`
  display: flex;
  width: 40px;
  padding: 18px;
  justify-content: center;
  align-items: center;
  border-radius: 99px;
  background: rgba(12, 32, 42, 0.20);
  height: 24px;
`;

export const ChangeOrderStatusSidebar: React.FC<ChangeOrderStatusSidebarProps> = ({
  isOpen,
  handleClose,
  isMobile = false,
  onChangeStatusOrder,
  selectedOrders = 0,
  totalOrderStatus = {},
}) => {
  const handleSelectStatus = (status: string) => {
    onChangeStatusOrder(status);
  };

  return (
    <Sidebar isOpen={isOpen} headerTitle="Change Order Status" onClose={handleClose} isMobile={isMobile} isNoMargin>
      <div style={{ padding: '16px 0 0 0' }}>
        <SelectedText>Selected {selectedOrders} orders</SelectedText>
      </div>
      <SidebarBodySection style={{ padding: '16px' }}>
        {MOCK_ORDER_STATUS_DATA.map(item => (
          <TextItemWrapper key={item.id}>
            <OrderStatusItem
              onClick={() => handleSelectStatus(item.type)}
              style={{ padding: '12px 16px' }}
              type={item.text}
            >
              <CommonText size="medium" weight={600} color="#F5F5F5" style={{ height: '40px', display: 'flex', alignItems: 'center' }}>
                {item.text}
              </CommonText>
              <TotalOrderStatus>
                {item.type === 'confirmed' ? (totalOrderStatus.due_soon || 0) : (totalOrderStatus[item.type] || 0)}
              </TotalOrderStatus>
            </OrderStatusItem>
          </TextItemWrapper>
        ))}
      </SidebarBodySection>
    </Sidebar>
  );
};
