import * as React from "react";
import { Button } from "@lib/components";
import { UI } from "../../../../../core/ui/index";
import { logger } from "@lib/common";
import { Input } from "@lib/components";
import { FormGroup } from "@lib/components";
import { Formik, Form, FastField, FormikProps, FormikActions } from "formik";
import { RotateLoader } from "@lib/components";
import { SelectAdv } from "@lib/components";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../../../mobx/components/index";
import { FormHelpers } from "../../../../../core/form";
import { withTranslation, WithTranslation } from "react-i18next";
import { Tag } from "@lib/components";
import { SettingsActionButtonContainer, SettingsActionButtonWrapper, SettingsFormGroup, SettingsFormGroupInput, SettingsFormInputContainer, SettingsFormLabelContainer, SettingsFormLabelLeftContainer, SettingsFormLabelRightContainer } from "../../../restaurant/views/settings-v2";
import { IconHelp } from "../../../../components/v2/icons";
import styled from "styled-components";
type FormValues = T.Schema.API.APICESchema;

interface Props extends WithTranslation {
	restaurants: Array<{ value: string; label: string; }>;
	initialValues: FormValues;
	edit_id: string;
	back: () => void;
}

const SelectedRestaurantsContainer = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 16px;
align-self: stretch;
`

const SelectedRestaurantsCard = styled.div`
display: flex;
padding: 16px;
align-items: center;
gap: 16px;
align-self: stretch;

border-radius: 4px;
border: 1px solid var(--Primary-Light-Alabaster-1, #F5F5F5);
background: var(--Neutral-White, #FEFEFE);`

const SelectedRestaurantsCardWrapper = styled.div`
display: flex;
align-items: flex-start;
gap: 16px;
flex: 1 0 0;
flex-direction: column;
@media (min-width: 768px) {
	flex-direction: row;
	align-items: center;
}
`

const SelectedRestaurantsCardName = styled.div`
flex: 1 0 0;
color: var(--Secondary-Dark-Grey-1, #4B4F5E);
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 150% */
`

const SelectedRestaurantsCardId = styled.div`
display: flex;
padding: 4px 8px;
align-items: center;
gap: 4px;

border-radius: 99px;
background: #F1F1F1;
color: #313131;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 16px; /* 114.286% */
`

const StyledForm = styled(Form)`
	width: 100%;
	padding-bottom: 83px;
	min-height: calc(100vh - 140px);

	@media(min-width: 768px) {
		min-height: auto;
	}
`

@inject("store") @observer
class APIFormClass extends MobxComponent<Props, { error: string }> {

	constructor(props: Props) {
		super(props);
		this.state = {
			error: "",
		};
	}

	onSubmit = async (values: FormValues, form: FormikActions<FormValues>) => {
		const { edit_id, back } = this.props;
		const { store } = this.injected;
		const isCreate = !edit_id;
		try {
			this.setState({ error: "" });

			const res = isCreate ?
				await this.injected.store.api.api_create({ api: values }) :
				await this.injected.store.api.api_update({ _id: edit_id, api: values });

			form.setSubmitting(false);

			if (res.outcome) {
				this.setState({ error: res.message });
				return;
			}

			const { item } = res;
			const items = [...this.injected.store.apis.items];
			if (isCreate) {
				items.push(item);
				form.resetForm();
			}
			else {
				const index = items.findIndex((s) => s._id === item._id);
				items[index] = item;
			}
			store.updateApis({ items });
			UI.notification.success("API saved");
			back();
		}
		catch (e) {
			logger.captureException(e);
			this.setState({ error: "Error saving api, please try again or contact us" });
			form.setSubmitting(false);
		}
	}

	validate = (values: FormValues) => {
		const { errors, isError } = FormHelpers.validate<FormValues>(values, {});
		if (this.state.error && !isError) {
			this.setState({ error: "" });
		}
		else if (!this.state.error && isError) {
			this.setState({ error: "There are errors in your form that need correcting, please scroll up" });
		}
		return errors;
	}

	getFieldError = (form: FormikProps<FormValues>, field: keyof FormValues) => {
		return FormHelpers.error(form, field);
	}

	render() {
		const { store } = this.injected;
		const { initialValues, restaurants } = this.props;
		const isV2 = store.organisation && store.organisation.dashboard === 'v2';
		return (
			<Formik
				initialValues={initialValues}
				validate={this.validate}
				onSubmit={this.onSubmit}
				enableReinitialize={true}>
				{(form) => {
					const { isSubmitting, setFieldValue, submitCount } = form;
					return (<>
					
						<StyledForm>
						<SettingsFormGroup>
							<SettingsFormGroupInput>
								<SettingsFormLabelContainer>
									<SettingsFormLabelLeftContainer>Name</SettingsFormLabelLeftContainer>
									<SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
								</SettingsFormLabelContainer>
								<SettingsFormInputContainer>
									<FastField
									name="name"
									render={({ field }: any) => (
									<input type="text" {...field} required={true} />	
									)}/>
								</SettingsFormInputContainer>
							</SettingsFormGroupInput>
							<SettingsFormGroupInput>
								<SettingsFormLabelContainer>
									<SettingsFormLabelLeftContainer>Restaurant Access</SettingsFormLabelLeftContainer>
									<SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
								</SettingsFormLabelContainer>
								
								<FastField
									name="restaurants"
									render={({ field }: any) => (
										<div>
											<SettingsFormInputContainer>
											<SelectAdv
												type="multi"
												value={field.value || []}
												options={restaurants}
												onChange={(selectedValues: string[]) => {
													setFieldValue("restaurants", selectedValues);
												}}
											/>
											</SettingsFormInputContainer>
											<p className="lhp m-t-2">Restrict your key access to particular restaurants. Leaving this empty will disable access to any restaurants</p>
											
											{(field.value || []).length > 0 && (
												<div className="m-t-2">
													<SettingsFormLabelContainer style={{marginBottom: 8}}>
														<SettingsFormLabelLeftContainer>Selected restaurant ID's</SettingsFormLabelLeftContainer>
														<SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
													</SettingsFormLabelContainer>
													<SelectedRestaurantsContainer>
													{field.value.map((id: string) => {
														const r = restaurants.find((v) => v.value === id);
														if (!r) return null;
														return (
															<SelectedRestaurantsCard>
																<SelectedRestaurantsCardWrapper>
																	<SelectedRestaurantsCardName>{r.label}</SelectedRestaurantsCardName>
																	<SelectedRestaurantsCardId>{id}</SelectedRestaurantsCardId>
																</SelectedRestaurantsCardWrapper>
															{/* <p key={id} className="m-t-2 lhp">{r.label} - <Tag>{id}</Tag></p> */}
															</SelectedRestaurantsCard>
														);
													})}
													</SelectedRestaurantsContainer>
												</div>
											)}
										</div>
									)}
								/>
							</SettingsFormGroupInput>
						</SettingsFormGroup>
						<SettingsActionButtonContainer className="no-sidebar">
							<SettingsActionButtonWrapper style={{left: 0}}>
								<button type="button" 
									className="default btn-cancel" 
									onClick={ () =>{ store.setActiveMobileMenu(
										store.activeMobileMenu.referrer.pop()!,
										Array.from(new Set(store.activeMobileMenu.referrer))
									); 
									}}>
										Cancel 
								</button>
								<button  type="submit" disabled={isSubmitting} >
									{isSubmitting && <RotateLoader size={2} color="white" />}
									{!isSubmitting && "Save"}
								</button>
							</SettingsActionButtonWrapper>
						</SettingsActionButtonContainer>
						</StyledForm>
						</>
					);
				}}
			</Formik>
		);
	}

}

export const APIFormV2 = withTranslation()(APIFormClass);
