import * as React from "react";
import { Button, styled } from "@lib/components";
import { logger } from "@lib/common";
import { UI } from "../../../../../core/ui";
import { RotateLoader } from "@lib/components";
import { RestaurantFormSetup } from "./form.setup";
import { Modal } from "@lib/components";
import { ModalTitle, ModalContent } from "@lib/components";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../../../mobx/components";
import { withTranslation, WithTranslation } from "react-i18next";
import { Untrusive } from "@lib/common";
import { DashboardRestaurantsList } from "./list";
import { DashboardRestaurantsListV2 } from "./list-v2";
import { FaPlus } from "react-icons/fa";
import { MobileModal, MobileModalBody, MobileModalContent, MobileModalFooter, MobileModalHeader, SalesTaxesAddButton, SettingsActionButtonWrapper, SettingsCustomOption, SettingsCustomOptionsContainer, SettingsFormGroupInput, SettingsFormInputContainer, SettingsFormLabelContainer, SettingsFormLabelLeftContainer, SettingsFormLabelRightContainer } from "../../../restaurant/views/settings-v2";
import { IconCalendar, IconCheck, IconClose, IconDelete, IconGridView, IconHelp, IconListView, IconMoreOptions, IconPayment, IconPaymentAlt, IconPlusRed, IconRearrange, IconSettingsAlt, IconStore, IconStoreAlt } from "../../../../components/v2/icons";
import { orderBy, sortBy } from "lodash";
import { ViewIcon } from "../../../restaurant/views/orders/bump-icon";

export const AddRestaurantButton = styled.div`
	display: flex;
	padding: 8px 16px;
	align-items: center;
	gap: 4px;
	align-self: stretch;
	background-color: #FB2F2F;
	color: #FEFEFE;
	border-radius: 4px;
	cursor: pointer;
	height: 52px;
	 @media(max-width: 768px) {
	display: none!important;
 }
`;
const SearchRestaurantWrapper = styled.input`
	width: 312px;
	@media(max-width: 768px) {
	width: 100%;
 }
`

export const AddRestaurantWrapper = styled.div<{ isStaffV2?: boolean}>`
 margin-bottom: 24px;
display: none;
 @media(min-width: 768px) {
	display: flex;
	width: 100%;
	justify-content: space-between;
 }
`;

interface Props extends WithTranslation { }
interface State {
	create_modal_active: boolean;
	delete_modal_active: number | null;
	cb_loaded: boolean;
	cb_error: boolean;
	key_word: string;
	sort_active: string;
	view: string;
	selected_restaurant: any;
}

@inject("store") @observer
class DashboardRestaurantsClass extends MobxComponent<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			create_modal_active: false,
			delete_modal_active: null,
			cb_error: false,
			cb_loaded: false,
			key_word: '',
			sort_active: '',
			view: 'list',
			selected_restaurant: null
		};
		this.handleManage = this.handleManage.bind(this)
	}

	componentDidMount() {
		this.get();
	}

	onChange = <T extends keyof State>(e: React.ChangeEvent<HTMLInputElement>) => {
		const newState = UI.helpers.handleChange(e);
		this.setState(newState as { [P in T]: State[P]; });
	}

	loading = () => {
		return (
			<div className="m-t-12">
				<RotateLoader size={3} />
			</div>
		);
	}
	start = () => {
		const { store } = this.injected;
		const { restrictions } = store;
		if (!restrictions.restaurant.create) {
			return null;
		}
		return (
			<div className="m-t-12 text-center">
				<h1 className="m-b-4">Create A Restaurant To Get Started</h1>
				<p className="big m-b-6">Every restaurant has it's own store, menus, settings, orders and bookings</p>
				<Button className="width200" color="primary" round={true} onClick={() => store.setOpenCreateRestaurant(true)}>Create New Restaurant</Button>
			</div>
		);
	}
	restaurants = () => {
		const { store } = this.injected;
		const { restrictions } = store;
		const { restaurantListViewActive, restaurantListSortActive} = store;
		let filteredName = store.restaurants.items.filter(item => 
			item.name.toLowerCase().includes(this.state.key_word.toLocaleLowerCase())
		).map(r => {
			const cb = r.billing.cb;
			return {
				...r,
				status: r.hasOwnProperty('publish_status') ? r.publish_status: 1 
			}
		} );
		
		const { sort_active } = this.state

		
		if(['created', 'status'].includes(sort_active)) {
			filteredName = orderBy(filteredName, sort_active, (sort_active  == 'status') ? 'desc': 'asc')
		} else {
			filteredName = orderBy(filteredName, 'position')
		}

		return (
			<div>
				{store.organisation && store.organisation.dashboard === 'v2.1' 
				? 
				<> { store.activeMobileMenu.name !== 'add-restaurant' &&
				<AddRestaurantWrapper
					style={{width: restaurantListViewActive === 'list' ?  720 :'100%', marginLeft: 'auto', marginRight: 'auto'}} 
					isStaffV2={store.organisation.dashboard === 'v2.1'}>
					<SettingsFormInputContainer style={{padding: '6px 12px'}}>
                            <SearchRestaurantWrapper
                                style={{width: 312}}
                                type="text"
                                onChange={(e) => this.setState({ key_word: e.target.value })}
                                placeholder="Search Restaurant" />
                    </SettingsFormInputContainer>
					{restrictions.staff.create && (
						<div style={{display: 'flex', gap: 16}}>
						<SalesTaxesAddButton 
							variant="red"
							type='button' 
							style={{padding: '11px 16px', fontSize: 12}}
							onClick={() => store.setActiveMobileMenu('add-restaurant', 'restaurants')}>
							<IconPlusRed /> Add Restaurant
						</SalesTaxesAddButton>
						<span style={{
								padding: '7px 12px',
								cursor: 'pointer',
    							background: '#fefefe',
    							borderRadius: 4}}
								onClick={() =>  {
									this.get(false)
									store.setActiveMobileModal('more-actions')
								}}>
							<IconMoreOptions />
						</span>
						</div>
					)}
				</AddRestaurantWrapper>  }
				</>
				: <div className="flex-l-r-center m-b-3">
					<h2>Restaurants</h2>
					{restrictions.restaurant.create && (
						<Button
							size="xs"
							round={true}
							color="primary"
							onClick={() => store.setOpenCreateRestaurant(true)}>
							New Restaurant
						</Button>
					)}
				</div>
				}

				<div className={(store.organisation && store.organisation.dashboard !== 'v2.1') ? "m-t-10": ""}>
					{ (store.organisation && store.organisation.dashboard !== 'v2.1') ? 
						<DashboardRestaurantsList /> : 
						<DashboardRestaurantsListV2 
							restaurantName={filteredName}
							view={restaurantListViewActive}
							rearrange={restaurantListSortActive === 'rearrange'}
							handleManage={this.handleManage}
							/>}
				</div>

			</div>
		);
	}

	modal_setup = () => {

		const { store, t } = this.injected;
		const { trialExpiry, trialExpired } = store;
		const { restrictions } = store;

		if (!restrictions.restaurant.create) {
			return null;
		}

		const res = store.reseller!;

		return (
			<Modal
				width={620}
				active={store.openCreateRestaurant}
				close={() => store.setOpenCreateRestaurant(false)}
				wrapperStyle={{ marginTop: '50px'}}>

				<ModalTitle className="round-top">
					<h3>New Restaurant Setup</h3>
					{(res.chargebee && !!res.chargebee.subscription.trial_period_days) && (
						<div className="m-t-1">
							{trialExpired && <p className="lhp">You subscription for this restaurant will commence immediately and you will be billed according to the plan you select below</p>}
							{!trialExpired && <p className="lhp">This restaurant will start with a trial subscription after which your billing will commence according to the plan you select below. Your trail expires at {t("dateFromTimestamp", { value: trialExpiry })}</p>}
						</div>
					)}
				</ModalTitle>

				<ModalContent>
					<RestaurantFormSetup
						back={() => {
							store.setOpenCreateRestaurant(false)
							this.get();
						}} />
				</ModalContent>

			</Modal>
		);

	}
	modal_delete = () => {

		const { delete_modal_active } = this.state;
		const { store } = this.injected;
		const { restrictions } = store;
		const restaurants = store.restaurants.items;

		if (!restrictions.restaurant.delete) {
			return null;
		}

		let item;
		if (delete_modal_active !== null && restaurants[delete_modal_active]) {
			item = restaurants[delete_modal_active];
		}

		return (
			<Modal
				width="sm"
				close={() => this.setState({ delete_modal_active: null })}
				active={delete_modal_active !== null}>
				<ModalContent>
					<h4 className="m-b-1">Delete Restaurant</h4>
					<p className="underline">{item ? item.name : ""}</p>
				</ModalContent>
				<ModalContent>
					<p className="lhp big">Are you sure you want to delete this restaurant. Once deleted, none of it's data can be recovered. Proceed with caution</p>
				</ModalContent>
				<ModalContent className="text-right">
					<Button className="m-r-2 width100 max128" onClick={() => this.setState({ delete_modal_active: null })}>Cancel</Button>
					<Button color="primary" onClick={() => this.delete(delete_modal_active)}>Delete</Button>
				</ModalContent>
			</Modal>
		);

	}

	get = async (flag: boolean = true) => {
		const { store } = this.injected;
		try {
			store.updateRestaurants({ loading: flag, error: "" });
			const data = await this.injected.store.api.restaurants();
			if (data.outcome) {
				store.updateRestaurants({
					loading: false,
					error: data.message,
					items: [],
				});
			}
			else {
				const { items } = data;
				store.updateRestaurants({
					loading: false,
					error: "",
					items: items,
				});
				if (items.length === 1) {
					setTimeout(() => this.injected.store.service.tour_new_restaurant(), 500);
				}
			}
		}
		catch (e) {
			logger.captureException(e);
			store.updateRestaurants({
				loading: false,
				error: "",
				items: [],
			});
			UI.notification.error("Error loading restaurants, retrying in 5 seconds", { timeout: 5000 });
			setTimeout(this.get, 5000);
		}
		return null;
	}

	view = async (index: number | null) => {
		if (index === null) return;
		const { store } = this.injected;
		const { restrictions } = store;
		const rr = restrictions.restaurant;
		const r = store.restaurants.items[index];
		let path = `/restaurant/${r._id}`;
		
		if (rr.dashboard) {
			path = `/restaurant/${r._id}`;
		}
		else if (restrictions._.restaurantOrderViews.length > 0 && restrictions.restaurant.orders_board) {
			path = `/restaurant/${r._id}/orders`;
		}
		else if (restrictions._.restaurantOrderViews.length > 0 && restrictions.restaurant.orders_list) {
			path = `/restaurant/${r._id}/orders/list`;
		}
		else if (restrictions._.restaurantOrderViews.length > 0 && restrictions.restaurant.orders_bump) {
			path = `/restaurant/${r._id}/orders/bump`;
		}
		else if (rr.bookings) {
			path = `/restaurant/${r._id}/bookings`;
		}
		else if (rr.customers) {
			path = `/restaurant/${r._id}/customers`;
		}
		else if (rr.menus) {
			path = `/restaurant/${r._id}/menus`;
		}
		else if (restrictions._.restaurantSettingsEnabled) {
			path = `/restaurant/${r._id}/settings`;
		}

		store.router.push(path);
	}
	delete = async (index: number | null) => {
		if (index === null) return;
		const { store } = this.injected;
		try {

			const p = prompt("Are you sure you want to delete this restaurant. Once deleted, none of it's data can be recovered. Enter 'delete' into the field below to proceed with deletion");

			if (p === null) { return; }

			if (p.toLowerCase() !== "delete") {
				UI.notification.error("Enter 'delete' to delete the restaurant");
				return;
			}

			Untrusive.start();

			const r = store.restaurants.items[index];
			const res = await this.injected.store.api.restaurant_delete({ _id: r._id });

			Untrusive.stop();

			if (res.outcome) {
				UI.notification.error(res.message);
				return;
			}

			const restaurants = [...store.restaurants.items];
			restaurants.splice(index, 1);

			this.setState({ delete_modal_active: null });
			store.updateRestaurants({ items: restaurants });

			UI.notification.success("Restaurant deleted");

		}
		catch (e) {
			logger.captureException(e);
			UI.notification.error("Something went wrong, try again or contact us");
			Untrusive.stop();
		}
	}

	toggleSortStatus(status: string) {
		const { store } = this.injected
		if(store.restaurantListSortActive !== status) {
			store.setRestaurantListSortActive(status)
		} else {
			store.setRestaurantListSortActive('')
		}
	}

	toggleView(view: string) {
		const { store } = this.injected
		if(store.restaurantListViewActive !== view) {
			store.setRestaurantListViewActive(view) 
		}
	}

	handleManage(r: any) {
		this.setState({
			selected_restaurant: r
		})
	}

	render() {
		if (!this.state) return null;
		const { store } = this.injected;
		const { loading, items } = store.restaurants;
		const { selected_restaurant } = this.state;
		const { 
			restaurantListSortActive,
			restaurantListViewActive
		} = store;

		return (
			<div>
				{loading && this.loading()}

				{(!loading && items.length === 0) && this.start()}

				{(!loading && items.length !== 0) && this.restaurants()}

				{this.modal_setup()}

				{this.modal_delete()}

				{store.activeMobileModal === 'more-actions' && <MobileModal className="dashboard-filter-modal full-mobile">
					<MobileModalContent>
						<MobileModalHeader>
							More Action
							<span
								onClick={() => store.setActiveMobileModal('')}>
								<IconClose />
							</span>
						</MobileModalHeader>
						<MobileModalBody>
							<p>Select action to be applied on Restaurant Lists. </p>
							<SettingsFormGroupInput style={{gap: 16}} className="desktop-only">
								<SettingsFormLabelContainer>
									<SettingsFormLabelLeftContainer> View</SettingsFormLabelLeftContainer>
								</SettingsFormLabelContainer>
								<SettingsCustomOptionsContainer>
									<SettingsCustomOption
											className={restaurantListViewActive == 'list' ? 'active' : ''}
											onClick={() => this.toggleView('list') }>
											<span style={{display: 'flex', alignItems: 'center', gap: 8}}><IconListView />
											List</span>
											{restaurantListViewActive == 'list' && <IconCheck /> }
									</SettingsCustomOption>
									<SettingsCustomOption
											className={restaurantListViewActive == 'grid' ? 'active' : ''}
											onClick={() => this.toggleView('grid') }>
											<span style={{display: 'flex', alignItems: 'center', gap: 8}}><IconGridView />
											Grid </span>
											{restaurantListViewActive == 'grid' && <IconCheck /> }
									</SettingsCustomOption>
								</SettingsCustomOptionsContainer>
							</SettingsFormGroupInput>
							<SettingsFormGroupInput style={{gap: 16}}>
								<SettingsFormLabelContainer>
									<SettingsFormLabelLeftContainer> Sort</SettingsFormLabelLeftContainer>
								</SettingsFormLabelContainer>
								<SettingsCustomOptionsContainer>
									<SettingsCustomOption
											className={restaurantListSortActive == 'created' ? 'active' : ''}
											onClick={() => this.toggleSortStatus('created') }>
											<span style={{display: 'flex', alignItems: 'center', gap: 8}}><IconCalendar />
											by Date Created</span>
											{restaurantListSortActive == 'created' && <IconCheck /> }
									</SettingsCustomOption>
									<SettingsCustomOption
											className={restaurantListSortActive == 'status' ? 'active' : ''}
											onClick={() => this.toggleSortStatus('status') }>
											<span style={{display: 'flex', alignItems: 'center', gap: 8}}><IconHelp />
											by Status </span>
											{restaurantListSortActive == 'status' && <IconCheck /> }
									</SettingsCustomOption>
									<SettingsCustomOption
											className={restaurantListSortActive == 'rearrange' ? 'active' : ''}
											onClick={() => this.toggleSortStatus('rearrange') }>
											<span style={{display: 'flex', alignItems: 'center', gap: 8}}><IconRearrange />
											Custom Re-arrange </span>
											{restaurantListSortActive == 'rearrange' && <IconCheck /> }
									</SettingsCustomOption>
								</SettingsCustomOptionsContainer>
							</SettingsFormGroupInput>
								
						</MobileModalBody>
						<MobileModalFooter>
						<SettingsActionButtonWrapper
                                position='relative' noborder nopadding>
                                <button type="button" onClick={() => {
                                   store.setActiveMobileModal('')
                                 }}> Save</button>
                            </SettingsActionButtonWrapper> 
						</MobileModalFooter>
					</MobileModalContent>
				</MobileModal> }
				{selected_restaurant && <MobileModal className="dashboard-filter-modal full-mobile">
					<MobileModalContent>
						<MobileModalHeader>
							<span style={{textTransform: 'capitalize'}}>{selected_restaurant.name}</span>
							<span
								onClick={() => this.setState({
									selected_restaurant: null
								})}>
								<IconClose />
							</span>
						</MobileModalHeader>
						<MobileModalBody>
							<p>Select action to be applied on {selected_restaurant.name}. </p>
							<SettingsFormGroupInput style={{gap: 16}}>
								<SettingsFormLabelContainer>
									<SettingsFormLabelLeftContainer> Store Status</SettingsFormLabelLeftContainer>
								</SettingsFormLabelContainer>
								<SettingsCustomOptionsContainer>
									<SettingsCustomOption
											onClick={() => {
												store.api.publishRestaurant({
													restaurant_id: selected_restaurant._id,
													publish_status: 1
												}).then(() => {
													UI.notification.success(selected_restaurant.name + ' has been published.')
													this.get()
												})
												this.setState({
													selected_restaurant: null
												},)
												
											} }>
											<span style={{display: 'flex', alignItems: 'center', gap: 8}}><IconListView />
											Publish</span>
									</SettingsCustomOption>
									<SettingsCustomOption
											onClick={() => {
												store.api.publishRestaurant({
													restaurant_id: selected_restaurant._id,
													publish_status: 0
												}).then(() => {
													UI.notification.success(selected_restaurant.name + ' has been unpublished.')
													this.get()
												})
												this.setState({
													selected_restaurant: null
												},)
												
											} }>
											<span style={{display: 'flex', alignItems: 'center', gap: 8}}><IconGridView />
											Unpublish </span>
									</SettingsCustomOption>
								</SettingsCustomOptionsContainer>
							</SettingsFormGroupInput>
							<SettingsFormGroupInput style={{gap: 16}}>
								<SettingsFormLabelContainer>
									<SettingsFormLabelLeftContainer> Sort</SettingsFormLabelLeftContainer>
								</SettingsFormLabelContainer>
								<SettingsCustomOptionsContainer>
									<SettingsCustomOption
											onClick={() =>{
												this.view(store.restaurants.items.findIndex(r => r._id === selected_restaurant._id))
											}}>
											<span  className='icon-w-24' style={{display: 'flex', alignItems: 'center', gap: 8}}><IconSettingsAlt />
											Manage</span>
									</SettingsCustomOption>
									<SettingsCustomOption
											onClick={() =>{
												const r = selected_restaurant
												const store_url = r.domain ? `https://${r.domain}` : `https://${r.subdomain}.${store.reseller!.store_host}`;
												window.open(store_url)
											}}>
											<span  className='icon-w-24' style={{display: 'flex', alignItems: 'center', gap: 8}}><IconStoreAlt />
											View Store</span>
									</SettingsCustomOption>
									<SettingsCustomOption
											onClick={() => store.billing.initChargebeeSession(selected_restaurant._id) }>
											<span  className='icon-w-24' style={{display: 'flex', alignItems: 'center', gap: 8}}><IconPaymentAlt />
											Billing</span>
									</SettingsCustomOption>
									<SettingsCustomOption
											className={restaurantListSortActive == 'delete' ? 'active' : ''}
											onClick={ () => this.delete(store.restaurants.items.findIndex(r => r._id === selected_restaurant._id)) }>
											<span  className='icon-w-24' style={{display: 'flex', alignItems: 'center', gap: 8}}><IconDelete />
											Delete </span>
									</SettingsCustomOption>
								</SettingsCustomOptionsContainer>
							</SettingsFormGroupInput>
								
						</MobileModalBody>
						<MobileModalFooter>
						<SettingsActionButtonWrapper
                                position='relative' noborder nopadding>
                                <button type="button" onClick={() => {
                                   this.setState({
									selected_restaurant: null
								   })
                                 }}> Save</button>
                            </SettingsActionButtonWrapper> 
						</MobileModalFooter>
					</MobileModalContent>
				</MobileModal> }
				

			</div>
		);
	}

}

export const DashboardRestaurants = withTranslation()(DashboardRestaurantsClass);
