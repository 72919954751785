import React, { useState } from 'react';
import { styled } from '@lib/components';
import { ExportIcon, IconPlus, IconQuestionCircle, RefreshIcon } from '../../../../../../components/v2/icons';
import { MobXProviderContext } from 'mobx-react';
import { InputWithIcon } from '../../../../components/mobileControls/inputWithIcon';

export const ButtonWrapper = styled.div<{ bg?: string }>`
    background: ${({ bg }) => bg ? bg : '#F1F4F7'};
    border-radius: 4px;
    width: 40px;
    height: 40px;
    position: relative;
    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;

const CustomerToolHeaderWrapper = styled.div`
    display: flex;
    gap: 8px;
    max-width: 856px;
    width: 100%;
    justify-content: center;
    .desktop-view {
        display: flex;
        gap: 8px;
    }
	.mobile-view {
    display: none;
  }
  @media (max-width: 767px) {
    .desktop-view {
      display: none;
    }
    .mobile-view {
      display: flex;
      gap: 8px;
	  padding: none;
    }
  }
`

type CustomerToolHeaderProps = {
}

export const CustomerToolHeader: React.FC<CustomerToolHeaderProps> = ({  }) => {
    const { store } = React.useContext(MobXProviderContext);

    const handleGetCustomerData = async () => {
        const data = {
            "page": 1,
            "limit": 10,
            "sort": {
                "created": -1
            },
            "query": {
                "restaurants": store.restaurant!._id
            }
        }
        const response = await store.api.customers_find(data);
        if (response.outcome)
            throw new Error(response.message);
        store.updateCustomers({
            items: response.items,
            count: response.count,
            page: 1
        });
    }
    return (
        <CustomerToolHeaderWrapper>
            <InputWithIcon />
            {<>
                <div className='desktop-view'>
                    <ButtonWrapper bg={'#fefefe'} onClick={() => store.updateCustomerControl({ openCreateCustomer: true })}>
                        <IconPlus />
                    </ButtonWrapper>
                    <ButtonWrapper bg={'#fefefe'} onClick={() => handleGetCustomerData()}>
                        <RefreshIcon />
                    </ButtonWrapper>
                    <ButtonWrapper bg={'#fefefe'} onClick={() => store.updateCustomerControl({ openExportModal: true })} >
                        <ExportIcon />
                    </ButtonWrapper>
                    <a target="_blank" href="https://support.cloudwaitress.com/platform-information/customer-accounts" className="">
                        <ButtonWrapper bg={'#fefefe'} onClick={() => { }}>
                            <IconQuestionCircle />
                        </ButtonWrapper>
                    </a>
                </div>
                <div className='mobile-view'>
                    <ButtonWrapper>
                        <RefreshIcon onClick={() => handleGetCustomerData()} />
                    </ButtonWrapper>
                    <ButtonWrapper onClick={() => store.updateCustomerControl({ openExportModal: true })}>
                        <ExportIcon />
                    </ButtonWrapper>
                </div>

            </>}
        </CustomerToolHeaderWrapper>
    );
};
