import React from 'react'
import { WebsiteForm } from '../../../../../mobx/components/website-form';
import { useStores } from '../../../../../mobx/useStores';
import { UI } from '../../../../../core/ui';
import { FormGroup, RotateLoader, Switch, Textarea } from '@lib/components';
import { FastField } from 'formik';
import { SettingsActionButtonContainer, SettingsActionButtonWrapper } from '../../../restaurant/views/settings-v2';
type FormValues = T.Schema.Website.WebsiteSchema["sections"]["about_us"] & { features_text: string; };

export const AboutUsV2 = () => {
    const store = useStores()
    const w = store.website!;
    let initialValues = {
        ...w.sections.about_us,
        features_text: w.sections.about_us.features.reduce((t, a, i) => {
            if (i === 0) return t + a;
            return `${t}\n${a}`
        }, ""),
    };
    
    return (
        <WebsiteForm<FormValues>
        submit={async (w, values) => {

            values.features = (values.features_text || "").split("\n")

            if (!values.features[0]) {
                values.features = [];
            }

            w.sections.about_us = values;

            // @ts-ignore
            delete w.sections.about_us.features_text;

            const update = { $set: { "sections.about_us": values } };

            return { w, update };

        }}
        validators={{}}
        initialValues={initialValues}
        onSuccess={() => { }}
        onError={() => UI.notification.error("An error occurred")}
        onSuccessMessage="Settings Updated"
        onErrorMessage="">
        {({ form, error }) => {
            const { isSubmitting, setFieldValue, setFieldTouched } = form;
            return (
                <div className="p-4">

                    <FastField
                        name="hidden"
                        render={({ field }: any) => (
                            <FormGroup title="">
                                <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                                    <div style={{ fontSize: '18px', lineHeight: '24px', fontWeight: 600 }}>Hide About Us</div>
                                    <div>
                                    <Switch
                                        id="hidden"
                                        checked={field.value}
                                        onChange={(e) => setFieldValue("hidden", e.target.checked)}/>
                                    </div>
                                </div>
                            </FormGroup>
                        )}
                    />

                    <FastField
                        name="side_by_side"
                        render={({ field }: any) => (
                            <FormGroup title="">
                                <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                                    <div style={{ fontSize: '18px', lineHeight: '24px', fontWeight: 600 }}>Side By Side Layout</div>
                                    <div>
                                    <Switch
                                        id="side_by_side"
                                        checked={field.value}
                                        onChange={(e) => setFieldValue("side_by_side", e.target.checked)} />
                                    </div>
                                </div>
                            </FormGroup>
                        )}
                    />

                    <FastField
                        name="text"
                        render={({ field }: any) => (
                            <FormGroup title="Text">
                                <Textarea {...field} />
                            </FormGroup>
                        )}
                    />

                    <FastField
                        name="features_text"
                        render={({ field }: any) => (
                            <FormGroup title="Feature Points" help="One per line, we recommend you write 6 points">
                                <Textarea {...field} />
                            </FormGroup>
                        )}
                    />

                    {error && <FormGroup error={error} />}
                    
                    <SettingsActionButtonContainer className='no-sidebar'>
                        <SettingsActionButtonWrapper style={{left: 0}}>
                            <button 
                                type="button" 
                                className='btn-cancel'
                                onClick={ () => store.setActiveMobileMenu(
                                    store.activeMobileMenu.referrer.pop()!,
                                    Array.from(new Set(store.activeMobileMenu.referrer))
                                )}>
                                Cancel
                            </button>
                            <button  type="submit" disabled={isSubmitting}>
                            {isSubmitting && <RotateLoader size={2} color="white" />}
                            {!isSubmitting && "Save"}
                            </button>
                        </SettingsActionButtonWrapper>
                    </SettingsActionButtonContainer>

                </div>
            );
        }}
    </WebsiteForm>
    )
}