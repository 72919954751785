import React, { useEffect, useState } from "react";
import { Button, CommonText, FooterSection, Radio, styled } from "@lib/components";
import { IconSettings } from "../../../../../../../components/v2/icons";
import { Header, ModalWrapper } from "../bookingDetail/bookingDetail.component";
import { BackIcon } from "../bookingDetail/bookingDetail.icon";
import { ActiveRadio, ModalBody, RadioIcon } from "../settings/bookingSettingConstants";
import { GroupItems } from "./groupItems";
import { MobXProviderContext } from "mobx-react";
import moment from "moment-timezone";
import { MobileModal } from "../../../../settings-v2";

const Section = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;
const Option = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 12px;
`;
const Footer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

export const AscendingIcon = ({ color = '#1C1B1F' }) => (
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.25004 12.6538V3.10763L1.40002 6.95763L0.346191 5.90383L6.00002 0.25L11.6538 5.90383L10.6 6.95763L6.74999 3.10763V12.6538H5.25004Z" fill={color} />
    </svg>
);

export const DescendingIcon = ({ color = '#1C1B1F' }) => (
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.00002 12.6538L0.346191 6.99998L1.40002 5.94615L5.25004 9.79618V0.25H6.74999V9.79618L10.6 5.94615L11.6538 6.99998L6.00002 12.6538Z" fill={color} />
    </svg>
);

type MobileFilterProps = {
    isOpen: boolean;
    handleClose: () => void;
}
type statusOrderProps = {
    'unconfirmed': number,
    'confirmed': number,
    'cancelled': number,
    'complete': number,
}

const defaultFilter = {
    sortBy: 'status',
    sortOrder: 'asc',
    filterStatus: 'all'
}
const statusOrder: statusOrderProps = {
    'unconfirmed': 1,
    'confirmed': 2,
    'cancelled': 3,
    'complete': 4
}

export const MobileFilter: React.FC<MobileFilterProps> = ({ isOpen, handleClose }) => {
    const [sortOrder, setSortOrder] = useState(defaultFilter.sortOrder);
    const [sortBy, setSortBy] = useState(defaultFilter.sortBy);
    const [filterStatus, setFilterStatus] = useState(defaultFilter.filterStatus);
    const [bookingList, setBookingList] = useState<T.Schema.Booking.BookingSchema[]>([]);
    const { store } = React.useContext(MobXProviderContext);
    const {searchKey, openFilterModal} = store.bookingControl;
    useEffect(() => {
        if (store.restaurant._id) {
            const { sortOrder, sortBy, filterStatus } = store.filteredBookings.filterType;
            setSortOrder(sortOrder)
            setSortBy(sortBy)
            setFilterStatus(filterStatus)
        }

    }, [store.filteredBookings.filterType])

    useEffect(() => {
        if(!searchKey && openFilterModal){
            handleGetBookingList()
        } else if(searchKey && openFilterModal) {
            handleGetBookingListWithSearch();
        }
        return () => {
            setBookingList([])
        }
    }, [openFilterModal])
    
    const handleGetBookingList = async() => {
        const response = await store.api.get_bookings(store.restaurant._id);
        if (response.outcome !== 0)
            throw new Error(response.message);
        let newBookingList = [...response.data]
        setBookingList(newBookingList)
    }
    const handleGetBookingListWithSearch = async() => {
        const response = await store.api.get_search_bookings(store.restaurant._id, searchKey)
        if (response.outcome !== 0)
            throw new Error(response.message);
        let newBookingList = [...response.data]
        setBookingList(newBookingList)
    }

    if (!isOpen) {
        return <></>;
    }

    const handleResetFilter = () => {
        setSortOrder(defaultFilter.sortOrder)
        setSortBy(defaultFilter.sortBy)
        setFilterStatus(defaultFilter.filterStatus)
        store.updateFilteredBookings({
            filterType: {
                sortOrder: defaultFilter.sortOrder, sortBy: defaultFilter.sortBy, filterStatus: defaultFilter.filterStatus
            }
        })
    }

    const compareStatus = (a: T.Schema.Booking.BookingSchema, b: T.Schema.Booking.BookingSchema, currentSort: string): number => {
        if (currentSort === 'asc') {
            return statusOrder[a.status] < statusOrder[b.status] ? -1 : 1;
        } else if (currentSort === 'des') {
            return statusOrder[a.status] > statusOrder[b.status] ? -1 : 1;
        }
        return 0
    }

    const compareCreated = (a: T.Schema.Booking.BookingSchema, b: T.Schema.Booking.BookingSchema, currentSort: string): number => {
        if (currentSort === 'asc') {
            return a.created > b.created ? -1 : 1;
        } else if (currentSort === 'des') {
            return a.created < b.created ? -1 : 1;
        }
        return 0
    }

    const compareBookingDate = (a: T.Schema.Booking.BookingSchema, b: T.Schema.Booking.BookingSchema, currentSort: string): number => {
        const dateA = moment(a.config.date, "YYYY/MM/DD")
        const dateB = moment(b.config.date, "YYYY/MM/DD")
        if (currentSort === 'asc') {
            return dateA < dateB ? -1 : dateA > dateB ? 1 : 0;
        } else if (currentSort === 'des') {
            return dateA > dateB ? -1 : dateA < dateB ? 1 : 0;
        }
        return 0
    }

    const compareName = (a: T.Schema.Booking.BookingSchema, b: T.Schema.Booking.BookingSchema, currentSort: string): number => {
        if (currentSort === 'asc') {
            return a.customer.name.localeCompare(b.customer.name);
        } else if (currentSort === 'des') {
            return b.customer.name.localeCompare(a.customer.name);
        }
        return 0
    }

    const handleFilter = async() => {
        let newBookingList = [...bookingList]
        if (filterStatus !== 'all') {
            newBookingList = newBookingList?.filter((e: T.Schema.Booking.BookingSchema) => e.status === filterStatus)
        }

        if (filterStatus === 'all' && sortBy === 'status') {
            newBookingList.sort((a, b) => compareStatus(a, b, sortOrder))
        }
        if (sortBy === 'created') {
            newBookingList.sort((a, b) => compareCreated(a, b, sortOrder))
        }
        if (sortBy === 'booking date') {
            newBookingList.sort((a, b) => compareBookingDate(a, b, sortOrder))
        }
        if (sortBy === 'name') {
            newBookingList.sort((a, b) => compareName(a, b, sortOrder))
        }

        store.updateBookings({
            items: newBookingList,
        });
        store.updateFilteredBookings({
            filterType: {
                sortOrder, sortBy, filterStatus
            }
        })
        handleClose()
    }

    const options = [
        {
            id: 'asc',
            label: 'Ascending',
            value: 'asc',
            icon: <AscendingIcon color={sortOrder === 'asc' ? '#FB2F2F' : undefined} />,
            onClick: () => setSortOrder('asc')
        },
        {
            id: 'des',
            label: 'Descending',
            value: 'des',
            icon: <DescendingIcon color={sortOrder === 'des' ? '#FB2F2F' : undefined} />,
            onClick: () => setSortOrder('des')
        }
    ];

    const optionsSortBy = [
        {
            id: 'status',
            label: 'Status',
            value: 'status',
        },
        {
            id: 'created',
            label: 'Created',
            value: 'created',
        },
        {
            id: 'booking_date',
            label: 'Booking Date',
            value: 'booking date',
        },
        {
            id: 'Name',
            label: 'Name',
            value: 'name',
        }
    ];
    const optionsStatus = [
        {
            id: 'all',
            label: 'All',
            value: 'all',
        },
        {
            id: 'unconfirmed',
            label: 'Unconfirmed',
            value: 'unconfirmed',
        },
        {
            id: 'confirmed',
            label: 'Confirmed',
            value: 'confirmed',
        },
        {
            id: 'cancelled',
            label: 'Cancelled',
            value: 'cancelled',
        }
    ];
    return (
        <MobileModal>
            <ModalWrapper bg='#FEFEFE'>
                <Header style={{
                    borderBottom: '1px solid #DFDFDF'
                }}>
                    <BackIcon onClick={handleClose} />
                    <CommonText size="medium" weight={600} color='#0C202A'>
                        Filters
                    </CommonText>
                    <div style={{
                        opacity: '0'
                    }}>
                        <IconSettings />
                    </div>
                </Header>
                <ModalBody>
                    <Section>
                        <CommonText size="medium" weight={600} color='#4B4F5E'>
                            Sort By
                        </CommonText>
                        <div>
                            {optionsSortBy.map(option => {
                                return (
                                    <Option key={option.id} onClick={() => { setSortBy(option.value) }}>
                                        <Radio
                                            version="v2"
                                            id={option.id}
                                            checked={
                                                option.value === sortBy
                                            }
                                            value={option.value}
                                            onChange={() => { }}
                                        />
                                        <CommonText size="semiMedium" weight={500} color='#4B4F5E'>
                                            {option.label}
                                        </CommonText>
                                    </Option>
                                )
                            })}
                        </div>
                    </Section>
                    <Section>
                        <CommonText size="medium" weight={600} color='#4B4F5E'>
                            Sort Order
                        </CommonText>
                        <div>
                            <GroupItems options={options} sortOrder={sortOrder} />
                        </div>
                    </Section>

                    <Section>
                        <CommonText size="medium" weight={600} color='#4B4F5E'>
                            Status
                        </CommonText>
                        <div>
                            {optionsStatus.map(option => {
                                return (
                                    <Option key={option.id} onClick={() => { setFilterStatus(option.value) }}>
                                        <Radio
                                            version="v2"
                                            id={option.id}
                                            checked={
                                                option.value === filterStatus
                                            }
                                            value={option.value}
                                            onChange={() => { }}
                                        />
                                        <CommonText size="semiMedium" weight={500} color='#4B4F5E'>
                                            {option.label}
                                        </CommonText>
                                    </Option>
                                )
                            })}
                        </div>
                    </Section>

                </ModalBody>
                <FooterSection width={420}>
                    <Button size="md" full={true} type="button" onClick={() => handleResetFilter()}>
                        Reset
                    </Button>
                    <Button size="md" full={true} type="button" color="primary" onClick={() => handleFilter()}>
                        Filter
                    </Button>

                </FooterSection>
            </ModalWrapper>
        </MobileModal>
    )
}