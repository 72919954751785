import React, { useState } from 'react';
import {
  SettingsFormGroup,
  SettingsFormLabelLeftContainer,
  SettingsFormLabelContainer,
  SettingsFormGroupInput,
  SettingsActionButtonContainer,
  SettingsActionButtonWrapper,
} from '../..';
import { parseString } from "xml2js";
import { Button, FieldDeliveryZones, FormDropzone, FormGroup } from '@lib/components';
import { UI } from '../../../../../../../core/ui';
import { logger } from '@lib/common';
import { useStores } from '../../../../../../../mobx/useStores';
import { RestaurantForm } from '../../../../../../../mobx/components/restaurant-form';
import { FastField, FormikProps } from 'formik';
import { updateRestaurantNotificationOptions } from '../../../../../../../core/libs/receipt';
import { RotateLoader } from '@lib/components';
import shortid from 'shortid';
import S from 'string';
type FormValues = T.Schema.Restaurant.RestaurantSchema["settings"]["services"]["delivery"];

export const ZonesDeliveryServices = (props: any) => {
    const [files, setFiles] = useState([]);
    const [zones, setZones] = useState<any[]>([]);
  
    const onChangeZones = (files: File[], form: FormikProps<FormValues>) => {
        if (files.length === 0) return;
        try {
          const file = files[0];
          const reader = new FileReader();
          reader.onload = () => {
            if (typeof reader.result !== "string") {
              UI.notification.error("Something went wrong reading your file, check the file format and try again", { timeout: 6000 });
              return;
            }
            parseString(reader.result, (err, result) => {
              if (err) {
                UI.notification.error("Something went wrong reading your file, check the file format and try again", { timeout: 6000 });
                logger.captureException(err);
              }
              else {
                onChangeZonesFileReader(result, form);
              }
            });
          };
          reader.readAsText(file);
        }
        catch (e) {
          logger.captureException(e);
          UI.notification.error("5 Something went wrong reading your file, check the file format and try again", { timeout: 6000 });
        }
      }

    const onChangeZonesFileReader = (result: any, form: FormikProps<FormValues>) => {
      try {
        const zones: T.Schema.Restaurant.Services.DeliveryZone[] = result.kml.Document[0].Placemark.map((zone: any) => {
          let coordinates = "";
          let coordinatesSplit;
          if (zone.Polygon !== undefined && zone.Polygon[0] !== undefined) {
            coordinates = S(zone.Polygon[0].outerBoundaryIs[0].LinearRing[0].coordinates[0]).collapseWhitespace().s;
          } else if (zone.Point !== undefined && zone.Point[0] !== undefined && zone.Point[0].coordinates !== undefined) {
            coordinates = S(zone.Point[0].coordinates).collapseWhitespace().s;
          }
          coordinatesSplit = coordinates.split(" ");
          return {
            _id: shortid.generate(),
            name: zone.name[0] ? zone.name[0] : "",
            fee: "",
            disabled: false,
            polygon: coordinatesSplit.map((cords) => {
              const temp = cords.split(",");
              return {
                lat: parseFloat(temp[1]),
                lng: parseFloat(temp[0]),
              };
            }),
          } as T.Schema.Restaurant.Services.DeliveryZone;
        });
        if (zones !== undefined && zones.length > 0) {
          form.setFieldValue("zones", zones);
        } else {
          // No polygon found
          UI.notification.error("Something went wrong reading your file, check the file format and try again", { timeout: 6000 });
        }
      }
      catch (e) {
        UI.notification.error("Something went wrong reading your file, check the file format and try again", { timeout: 6000 });
        logger.captureException(e);
      }
    }
    const { injected } = props;
    const store = useStores();
  
    const onSubmit = async (r: T.Schema.Restaurant.RestaurantSchema, values: T.Schema.Restaurant.Services.RestaurantServiceDelivery): Promise<{ r: T.Schema.Restaurant.RestaurantSchema; update: { $set: { "settings.services.delivery": T.Schema.Restaurant.Services.RestaurantServiceDelivery; }; }; }> => {
      r.settings.services.delivery = values;
      const update = { $set: { "settings.services.delivery": r.settings.services.delivery } };
      return { r, update };
    };
    const r = store.restaurant!;
    let initialValues = r.settings.services.delivery;
    const currency = r.settings.region.currency;
		const currencySymbol = currency.symbol || "$";
		
    return (<div style={{paddingTop: 16, paddingBottom: 83}}>			
    <RestaurantForm<FormValues>
      submit={onSubmit}
      initialValues={initialValues}
      onSuccess={async () => await updateRestaurantNotificationOptions(injected.store)}
      onError={() => UI.notification.error("An error occurred")}
      onSuccessMessage="Settings Updated"
    >
      {({ form, error, getFieldError }) => {
        const { isSubmitting, setFieldValue } = form;
        return (
        <>
          <SettingsFormGroup>
            <SettingsFormGroupInput>
              <SettingsFormLabelContainer>
                <SettingsFormLabelLeftContainer>How Delivery Zones Work</SettingsFormLabelLeftContainer>
              </SettingsFormLabelContainer>
              <div
                style={{
                  fontSize: '14px',
                  fontWeight: 400,
                  color: '#6B7270',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '8px',
                }}
              >
                <div>
                  Use delivery zones to ensure delivery orders are accepted only within set areas. Adding delivery zones
                  will override existing delivery fee and max driving distance settings
                </div>
                <ol>
                  <li>
                    Visit your{' '}
                    <a
                      href="https://www.google.com/maps/d/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: '#FB2F2F', textDecoration: 'underline' }}
                    >
                      custom google maps
                    </a>{' '}
                    and login if needed
                  </li>
                  <li>Press the button that says "Create A New Map"</li>
                  <li>
                    Use the draw line tool to create your various delivery zones making sure to give them meaningful names
                    that you can remember
                  </li>
                  <li>Delivery zones must be closed shapes. Do not create any additional layers</li>
                  <li>
                    Use the "Export to KML/KMZ" option and check the box that says "Export to a .KML file". Press download
                    to get your map file and upload it here
                  </li>
                </ol>
              </div>
            </SettingsFormGroupInput>
            <SettingsFormGroupInput>
              <SettingsFormLabelContainer>
                <SettingsFormLabelLeftContainer>Upload Delivery Zone File (.kml)</SettingsFormLabelLeftContainer>
              </SettingsFormLabelContainer>
              <div style={{ width: "100%" }}>
                  <FormDropzone
                      files={files}
                      accept=".kml"
                      onChange={() => onChangeZones(files, form)}
                  />
              
                  <FastField
                      name="zones"
                      render={({ field }: any) => (
                        <FormGroup hide={(field.value || []).length === 0}>
                          <FieldDeliveryZones
                            values={field.value || []}
                            onChange={(zones) => setFieldValue("zones", zones)}
                            currencySymbol={currencySymbol}
                          />
                          <div className="m-t-4">
                            <Button color="primary-inverse" size="xs" onClick={() => setFieldValue("zones", [])}>
                              Remove All Zones
                            </Button>
                          </div>
                        </FormGroup>
                      )}
                    />
              </div>
            </SettingsFormGroupInput>
          </SettingsFormGroup>
          <SettingsActionButtonContainer>
                <SettingsActionButtonWrapper>
                    <button type="button" 
                        className="default btn-cancel" 
                        onClick={ () => store.setActiveMobileMenu(
                            store.activeMobileMenu.referrer.pop()!,
                            Array.from(new Set(store.activeMobileMenu.referrer))
                        )}>
                            Cancel 
                    </button>
                    <button type="submit" disabled={isSubmitting}>
                        {isSubmitting && <RotateLoader size={2} color="white" />}
                        {!isSubmitting && "Save"}
                    </button>
                </SettingsActionButtonWrapper>
            </SettingsActionButtonContainer>
        </>
        )}}
      </RestaurantForm>
  </div>
) ;
};
