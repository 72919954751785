import React from 'react';
import { IconButton } from '../../../../components/IconButton';
import { Flex } from '../../../../components/Flex';
import { CustomerItemContainer, IconWrapper } from './style';
import { CommonText } from '@lib/components';
import ReactAvatar from 'react-avatar';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { MailIcon, CartIcon, PhoneIcon, CircleDollarIconWhite } from '../../../../../../components/v2/icons';
import { ButtonWrapper } from '../customerToolHeader';

export const Thumbnail = styled("div")`
  border-radius: 50%;
  overflow: hidden;
  > * {
    width: 48px;
    height: 48px;
  }
`;

type CustomerCardProps = {
    handleOpen: () => void;
    customerInfo: T.Schema.Customer.CustomerSchema
}

export const CustomerCardContent = styled.div`
    /* display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 12px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center; */
    .mobile {
        display: none;
    }
    .desktop {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          gap: 8px;
    }
    @media (max-width: 767px) {
        .mobile {
            width: 100%;
            display: block;
        }
        .desktop {
            display: none;
        }
    }
`;

const CustomerCard: React.FC<CustomerCardProps> = (props) => {
    const { t } = useTranslation();
    const {
        customerInfo,
        handleOpen,
    } = props;
    const { details, stats } = customerInfo;

    return <CustomerItemContainer onClick={handleOpen}>
        <Thumbnail>
            <ReactAvatar name={details?.name} email={details.email} size="48" />
        </Thumbnail>
        <Flex f="1" fd='column' jc='start' g='8px'>
            <CommonText size="medium" weight={600} color='#0C202A'>
                {details?.name}
            </CommonText>
            <CustomerCardContent>
                <div className="mobile">
                    <Flex jc='start' g='8px' fd='column' ai='flex-start'>
                        <Flex g='4px'>
                            <IconWrapper>
                                <CircleDollarIconWhite />
                            </IconWrapper>
                            <CommonText size="small" weight={500} color='#4B4F5E' lineHeight='16px' >
                                Sales: {t("currency", { value: stats.sales ? stats.sales : 0 })}
                            </CommonText>
                        </Flex>
                        <Flex g='4px'>
                            <IconWrapper>
                                <CartIcon />
                            </IconWrapper>
                            <CommonText size="small" weight={500} color='#4B4F5E' lineHeight='16px'>
                                Orders: {stats.orders}
                            </CommonText>
                        </Flex>
                    </Flex>
                </div>
                <div className="desktop">
                        <IconWrapper>
                            <CircleDollarIconWhite />
                        </IconWrapper>
                        <CommonText size="small" weight={500} color='#4B4F5E' lineHeight='16px' style={{marginRight: '8px'}}>
                            Sales: {t("currency", { value: stats.sales ? stats.sales : 0 })}
                        </CommonText>
                        <IconWrapper>
                            <CartIcon />
                        </IconWrapper>
                        <CommonText size="small" weight={500} color='#4B4F5E' lineHeight='16px'>
                            Orders: {stats.orders}
                        </CommonText>
                </div>
            </CustomerCardContent>
        </Flex>

        <Flex g="8px">
            <ButtonWrapper onClick={() => window.location.href = `tel:${details?.phone}`}>
                <PhoneIcon />
            </ButtonWrapper>
            <ButtonWrapper onClick={() => window.location.href = `mailto:${details?.email}`}>
                <MailIcon />
            </ButtonWrapper>
        </Flex>
    </CustomerItemContainer>
}

export default CustomerCard;