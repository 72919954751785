import * as React from "react";
import styled, { css } from "../../../styled/index";
const autobind = require("class-autobind").default;
import { ChromePicker } from "react-color";
import { CoreUtils } from "@lib/common";
const ClickOutComponent = require("react-onclickout");

interface Props {
	color: string;
	onChange: (color: string) => void;
}
interface State {
	active: boolean;
}

const Wrapper = styled("div")`
  display: inline-block;
  position: relative;
`;

const SwatchWrapper = styled("div")`
  display: inline-block;
  cursor: pointer;
  background: white;
  box-shadow: 0 0 0 1px rgba(0,0,0,.1);
  padding: 3px;
  border-radius: 3px;
`;

const Swatch = styled("div") <{ color: string }>`
  width: 36px;
  height: 18px;
  border-radius: 3px;
  background: ${({ color }) => color};
`;

const DropWrapper = styled("div") <{ active: boolean }>`
  position: absolute;
  top: 125%;
  left: 0;
  display: ${({ active }) => active ? "block" : "none"};
  z-index: 3;
`;

export class ColorPicker extends React.Component<Props, State> {

	lastOpened: number | null = null;

	constructor(props: Props) {
		super(props);
		autobind(this);
		this.state = { active: false };
		this.lastOpened = null;
	}

	componentDidUpdate() {
		this.lastOpened = this.state.active ? Date.now() : null;
	}

	setActive(active: boolean) {
		this.setState({ active });
	}

	onClickOut() {
		if (this.state.active && this.lastOpened && ((Date.now() - this.lastOpened) > 100)) {
			this.setActive(false);
		}
	}

	render() {
		const { active } = this.state;
		const { color, onChange } = this.props;
		const finalColor = color && color.length > 7 ?
			CoreUtils.colors.rgbaObjectFromString(color) : color;
		return (
			<Wrapper>
				<SwatchWrapper onClick={() => this.setActive(!this.state.active)}>
					<Swatch color={color} />
				</SwatchWrapper>
				<ClickOutComponent onClickOut={this.onClickOut}>
					<DropWrapper active={active}>
						<ChromePicker
							color={finalColor}
							onChangeComplete={(Color) => onChange(CoreUtils.colors.stringFromRgba(Color.rgb))} />
					</DropWrapper>
				</ClickOutComponent>
			</Wrapper>
		);
	}

}
