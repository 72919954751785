import React, { useEffect, useState } from "react";
import { SettingsWebsiteWrapper } from "./themes";
import { SettingsActionButtonContainer, SettingsActionButtonWrapper, SettingsFormGroup, SettingsFormGroupInput, SettingsFormInputContainer, SettingsFormLabelContainer, SettingsFormLabelLeftContainer, SettingsFormLabelRightContainer, SettingsSwitchContainer } from "..";
import { FieldFont, RotateLoader, Select, SelectAdv, Switch } from "@lib/components";
import { GoogleCreateService } from "../../../../../../core/libs/google";
import { IconHelp } from "../../../../../components/v2/icons";
import { RestaurantForm } from "../../../../../../mobx/components/restaurant-form";
import { UI } from "../../../../../../core/ui";
import { useStores } from "../../../../../../mobx/useStores";
import { FastField } from "formik";
interface Props {
    activeDesignMode?: boolean
    restaurant?: T.Schema.Restaurant.RestaurantSchema
}
type FormValues = T.Schema.Restaurant.RestaurantSchema["website"]["sections"]["menus"];

export const SettingsWebsiteItems = (props: Props) => {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const { activeDesignMode, restaurant } = props
    const store = useStores();
    const r = store.restaurant!;
    let initialValues = r.website.sections.menus
    const menuTemplate = r .website.sections.menu_nav_bar
        ?.menu_template || "v1";
    return (
        <RestaurantForm<FormValues>
        submit={async (r, values) => {
            r.website.sections.menus = values;
            const update = {
                $set: {
                    "website.sections.menus": r.website.sections.menus,
                },
            };
            return { r, update };
        }}
        initialValues={initialValues}
        onError={() => UI.notification.error("An error occurred")}
        onSuccessMessage="Settings Updated"
        width="100%"
    >
        {({ form, error }) => {
            const { isSubmitting, setFieldValue } = form;
            return (
        <SettingsWebsiteWrapper>
            <SettingsFormGroup>
                <SettingsFormGroupInput>
                    <SettingsFormLabelContainer>
                        <SettingsFormLabelLeftContainer>
                           Item Style
                        </SettingsFormLabelLeftContainer>
                        <SettingsFormLabelRightContainer>
                            <IconHelp />
                        </SettingsFormLabelRightContainer>
                    </SettingsFormLabelContainer>
                    <SettingsFormInputContainer>
                    <FastField
                        name="dish_style"
                        render={({ field }: any) => (
                        <Select
                                style={{padding: 0, height: 'auto', border: 'none'}}
                                value={field.value}
                                options={[
                                    { value: "list", label: "List" },
                                    { value: "list-rounded", label: "Rounded List" },
                                    { value: "card", label: "Card" },
                                ]}
                                onChange={(e) => {
                                    setFieldValue(
                                        "dish_style",
                                        e.target.value
                                    );
                                }}
                            />
                    )} />
                    </SettingsFormInputContainer>
                </SettingsFormGroupInput>
                <SettingsFormGroupInput>
                    <SettingsFormLabelContainer>
                        <SettingsFormLabelLeftContainer>
                           Item Tag Position
                        </SettingsFormLabelLeftContainer>
                        <SettingsFormLabelRightContainer>
                            <IconHelp />
                        </SettingsFormLabelRightContainer>
                    </SettingsFormLabelContainer>
                    <SettingsFormInputContainer>

                    <FastField
                        name="dish_tag_position"
                        render={({ field }: any) => (
                            <Select
                                style={{padding: 0, height: 'auto', border: 'none'}}
                                value={field.value}
                                options={[
                                    { value: "edge_top", label: "Top Edge" },
                                    { value: "inside_bottom", label: "Inner Bottom" },
                                ]}
                                onChange={(e) => {
                                    setFieldValue(
                                        "dish_tag_position",
                                        e.target.value
                                    );
                                }}
                            />
                    )} />
                    </SettingsFormInputContainer>
                </SettingsFormGroupInput>
                <SettingsFormGroupInput>
                    <SettingsSwitchContainer>
                        <SettingsFormLabelLeftContainer>
                            Hide Item Descriptions
                        </SettingsFormLabelLeftContainer>
                        <FastField
								name="dish_hide_description"
								render={({ field }: any) => (
								
										<Switch
											id="dish_hide_description"
											checked={field.value || false}
											onChange={(e) =>
												setFieldValue(
													"dish_hide_description",
													e.target.checked
												)
											}
										/>
								)}
							/>
                    </SettingsSwitchContainer>
                </SettingsFormGroupInput>
                {menuTemplate == 'v1' && <>
                <SettingsFormGroupInput>
                    <SettingsSwitchContainer>
                        <SettingsFormLabelLeftContainer>
                            Hide `All` Category
                        </SettingsFormLabelLeftContainer>
                        <FastField
									name="dish_hide_all_category"
									render={({ field }: any) => (
								    <Switch
										id="dish_hide_all_category"
										checked={field.value || false}
										onChange={(e) => setFieldValue("dish_hide_all_category", e.target.checked)} />
									
									)}
								/>
                    </SettingsSwitchContainer>
                </SettingsFormGroupInput>
                <SettingsFormGroupInput>
                    <SettingsSwitchContainer>
                        <SettingsFormLabelLeftContainer>
                            Hide Category Descriptions
                        </SettingsFormLabelLeftContainer>
                        
								<FastField
									name="dish_hide_category_description"
									render={({ field }: any) => (
									
											<Switch
												id="dish_hide_category_description"
												checked={field.value || false}
												onChange={(e) =>
													setFieldValue(
														"dish_hide_category_description",
														e.target.checked
													)
												}
											/>
									)}
								/>
                    </SettingsSwitchContainer>
                </SettingsFormGroupInput>
                <SettingsFormGroupInput>
                    <SettingsSwitchContainer>
                        <SettingsFormLabelLeftContainer>
                            Enable Condensed Menu
                        </SettingsFormLabelLeftContainer>
                        <FastField
									name="enable_condensed_menu"
									render={({ field }: any) => (
									
											<Switch
												id="enable_condensed_menu"
												checked={field.value || false}
												onChange={(e) =>
													setFieldValue(
														"enable_condensed_menu",
														e.target.checked
													)
												}
											/>
									)}
								/>
                    </SettingsSwitchContainer>
                </SettingsFormGroupInput></> }
            </SettingsFormGroup>
            <SettingsActionButtonContainer activeDesignMode={activeDesignMode}>
                <SettingsActionButtonWrapper>
                    <button type="submit" disabled={isSubmitting}>
                        {isSubmitting && <RotateLoader size={2} color="white" />}
                        {!isSubmitting && "Save"}
                    </button>
                </SettingsActionButtonWrapper>
            </SettingsActionButtonContainer>
        </SettingsWebsiteWrapper>
            )}}
        </RestaurantForm>
    )
}
