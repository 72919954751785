import React, { useState } from 'react'
import { WebsiteForm } from '../../../../../mobx/components/website-form';
import { useStores } from '../../../../../mobx/useStores';
import { UI } from '../../../../../core/ui';
import { FormGroup, RotateLoader } from '@lib/components';
import { FastField } from 'formik';
import { CookieColorsMobile } from '../website/color_picker_mobile';
import { SettingsActionButtonContainer, SettingsActionButtonWrapper } from '../../../restaurant/views/settings-v2';
type FormValues = T.Schema.Website.WebsiteSchema["colors"];

export const ColorsV2 = () => {
    const store = useStores()
    const w = store.website!;
    let initialValues = w.colors;

    const [colors, setColors ] = useState( {
        backgroundColor: '#000000',
        primaryColor: '#ee5252',
        primaryTextColor: '#383838',
        boxPopupBackgroundColor: '#000000',
        boxPopupTextColor: '#ffffff',
        textColor: '#ffffff'
    });
    
    const col_props = {
        className: "col",
        small_title: true,
        no_border: true,
    };
    return (
        <WebsiteForm<FormValues>
        submit={async (w, values) => {
            w.colors = values;
            const update = { $set: { "colors": values } };
            return { w, update };
        }}
        validators={{}}
        initialValues={initialValues}
        onSuccess={() => { }}
        onError={() => UI.notification.error("An error occurred")}
        onSuccessMessage="Settings Updated"
        onErrorMessage="">
        {({ form, error }) => {
            const { isSubmitting, setFieldValue, setFieldTouched } = form;
            return (
                <div className="p-4">

                    <FormGroup contentClassName="" >
                        <FastField
                            name="primary"
                            render={({ field }: any) => (
                                <FormGroup title="" {...col_props}>
                                    <CookieColorsMobile 
                                        color={field.value || colors.primaryColor}
                                        onColorChange={(color: any) => {
                                            setColors({ 
                                                ...colors,
                                                primaryColor: color
                                            });
                                            field.onChange({ target: { name: 'primary', value: color } });
                                        }}
                                        colorField="Primary"
                                    />
                                </FormGroup>
                            )}
                        />

                        <FastField
                            name="primary_text"
                            render={({ field }: any) => (
                                <FormGroup title="" {...col_props}>
                                    <CookieColorsMobile 
                                        color={field.value || colors.primaryTextColor}
                                        onColorChange={(color: any) => {
                                            setColors({ 
                                                ...colors, primaryTextColor: color });
                                            field.onChange({ target: { name: 'primary_text', value: color } });
                                        }}
                                        colorField="Primary Text"
                                    />
                                </FormGroup>
                            )}
                        />

                        <FastField
                            name="background"
                            onChange={(color: any) =>  setColors({ 
                                ...colors,backgroundColor: color })}
                            render={({ field }: any) => (
                                <FormGroup title="" {...col_props}>
                                    <CookieColorsMobile 
                                        color={field.value || colors.backgroundColor}
                                        onColorChange={(color: any) => {
                                            setColors({ 
                                                ...colors, backgroundColor: color });
                                            field.onChange({ target: { name: 'background', value: color } });
                                        }}
                                        colorField="Background"
                                    />
                                </FormGroup>
                            )}
                        />

                        <FastField
                            name="text"
                            render={({ field }: any) => (
                                <FormGroup title="" {...col_props}>
                                    <CookieColorsMobile 
                                        color={field.value || colors.textColor}
                                        onColorChange={(color: any) => {
                                            setColors({ 
                                                ...colors,textColor: color });
                                            field.onChange({ target: { name: 'text', value: color } });
                                        }}
                                        colorField="Text"
                                    />
                                </FormGroup>
                            )}
                        />

                        <FastField
                            name="box"
                            render={({ field }: any) => (
                                <FormGroup title="" {...col_props}>
                                    <CookieColorsMobile 
                                        color={field.value || colors.boxPopupBackgroundColor}
                                        onColorChange={(color: any) => {
                                            setColors({ 
                                                ...colors,boxPopupBackgroundColor: color });
                                            field.onChange({ target: { name: 'box', value: color } });
                                        }}
                                        colorField="Box & Popup Background"
                                    />
                                </FormGroup>
                            )}
                        />

                        <FastField
                            name="box_text"
                            render={({ field }: any) => (
                                <FormGroup title="" {...col_props}>
                                    <CookieColorsMobile 
                                        color={field.value || colors.boxPopupTextColor}
                                        onColorChange={(color: any) => {
                                            setColors({ 
                                                ...colors, boxPopupTextColor: color });
                                            field.onChange({ target: { name: 'box_text', value: color } });
                                        }}
                                        colorField="Box & Popup Text"
                                    />
                                </FormGroup>
                            )}
                        />

                    </FormGroup>

                    {error && <FormGroup error={error} />}

                    <SettingsActionButtonContainer className='no-sidebar'>
                        <SettingsActionButtonWrapper style={{left: 0}}>
                            <button 
                                type="button" 
                                className='btn-cancel'
                                onClick={ () => store.setActiveMobileMenu(
                                    store.activeMobileMenu.referrer.pop()!,
                                    Array.from(new Set(store.activeMobileMenu.referrer))
                                )}>
                                Cancel
                            </button>
                            <button  type="submit" disabled={isSubmitting}>
                            {isSubmitting && <RotateLoader size={2} color="white" />}
                            {!isSubmitting && "Save"}
                            </button>
                        </SettingsActionButtonWrapper>
                    </SettingsActionButtonContainer>

                </div>
            );
        }}
    </WebsiteForm> 
    )
}