import React from "react";
import { 
    SettingsFormGroup,
    SettingsFormGroupInput,
    SettingsFormLabelContainer,
    SettingsFormLabelLeftContainer,
    SettingsFormInputContainer,
    SettingsSwitchContainer,
    OptionalText,
    SettingsFormLabelRightContainer,
    SettingsActionButtonWrapper,
    SettingsActionButtonContainer
} from ".";

import { RotateLoader, Switch } from "@lib/components";
import { IconHelp } from "../../../../components/v2/icons";
import { RestaurantForm } from "../../../../../mobx/components/restaurant-form";
import { UI } from "../../../../../core/ui";
import { useStores } from "../../../../../mobx/useStores";
import { FastField } from "formik";

type FormValues = T.Schema.Restaurant.RestaurantSchema["settings"]["business"]["age_verification"];

export const SettingsAgeVerification = () => {
    const store = useStores()
    const r = store.restaurant!;
    let initialValues = r.settings.business.age_verification
    return (
    <div style={{paddingTop: 16, width: '100%'}}>
        <RestaurantForm<FormValues>
        submit={async (r, values) => {
            r.settings.business.age_verification = values;
            const update = { $set: { "settings.business.age_verification": values } };
            return { r, update };
        }}
        initialValues={initialValues}
        onError={() => UI.notification.error("An error occurred")}
        onSuccessMessage="Settings Updated">
        {({ form, error }) => {
            const { isSubmitting, setFieldValue } = form;
            return (
            <>
                <SettingsFormGroup style={{gap: 16, paddingBottom: 32}}>
                    <SettingsSwitchContainer style={{gap: 16, paddingTop: 8, paddingBottom: 8}}>
                        <SettingsFormLabelLeftContainer style={{fontWeight: 500}}>
                            Enabled
                        </SettingsFormLabelLeftContainer>
                        <FastField 
                            name="enabled"
                            render={({ field }: any) =>
                            <Switch
                                id="age_verification.enabled"
                                checked={field.value || false}
                                onChange={(e) =>  setFieldValue("enabled", e.target.checked)}
                                variant="black"
                                />
                            }
                        />
                    </SettingsSwitchContainer>
                    <p>Age verification allows customers to upload their legal documents. You can use this to manually verify the identity and age of the customer. Useful for selling age restricted products</p>
                    <SettingsFormGroupInput>
                        <SettingsFormLabelContainer>
                            <SettingsFormLabelLeftContainer>Minimum Age <OptionalText>(Years)</OptionalText></SettingsFormLabelLeftContainer>
                            <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                        </SettingsFormLabelContainer>
                        <SettingsFormInputContainer>
                        <FastField
                                name="min_age"
                                render={({ field }: any) => (
                                    <input type="number" step="1" min="0" max="200" required={true} {...field} />
                                )}
                            />
                        </SettingsFormInputContainer>
                    </SettingsFormGroupInput>
                    <SettingsFormGroupInput>
                        <SettingsFormLabelContainer>
                            <SettingsFormLabelLeftContainer>Estimated Wait Time <OptionalText>(Hours)</OptionalText></SettingsFormLabelLeftContainer>
                            <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                        </SettingsFormLabelContainer>
                        <SettingsFormInputContainer>
                            <FastField
                                name="estimated_wait_time"
                                render={({ field }: any) => (
                                    <input type="number" step="1" min="0" max="200" required={true} {...field} />
                                )}
                            />
                        </SettingsFormInputContainer>
                    </SettingsFormGroupInput>
                    <SettingsSwitchContainer style={{gap: 16, paddingTop: 8, paddingBottom: 8}}>
                        <SettingsFormLabelLeftContainer style={{fontWeight: 500}}>
                            Store Popup Notification
                        </SettingsFormLabelLeftContainer>
                        <FastField 
                            name="store_popup_notification"
                            render={({ field }: any) =>
                            <Switch
                                id="age_verification.store_popup_notification"
                                checked={field.value || false}
                                onChange={(e) =>  setFieldValue("store_popup_notification", e.target.checked)}
                                variant="black"
                                />
                            }
                        />
                    </SettingsSwitchContainer>
                </SettingsFormGroup>
                <SettingsActionButtonContainer>
                    <SettingsActionButtonWrapper>
                        <button type="button" 
                            className="default btn-cancel" 
                            onClick={ () => store.setActiveMobileMenu(
                                store.activeMobileMenu.referrer.pop()!,
                                Array.from(new Set(store.activeMobileMenu.referrer))
                            )}>
                                Cancel 
                        </button>
                        <button type="submit" disabled={isSubmitting}>
                            {isSubmitting && <RotateLoader size={2} color="white" />}
                            {!isSubmitting && "Save"}
                        </button>
                    </SettingsActionButtonWrapper>
                </SettingsActionButtonContainer>
            </>
            )
        }}
        </RestaurantForm>
    </div>
    )
}