import React from 'react';
import { FastField } from 'formik';
import { FormGroup, Button, RotateLoader, Switch, Input, LinkTag } from '@lib/components';
import { MobxComponent } from '../../../../../../../mobx/components/index';
import { RestaurantForm } from '../../../../../../../mobx/components/restaurant-form';
import { UI } from '../../../../../../../core/ui';
import { PaymentBaseFields } from './base';
import { inject, observer } from 'mobx-react';
import { SaveButtonWrapper, SettingsActionButtonContainer, SettingsActionButtonWrapper } from '../../../settings-v2';

interface Props {
  isMobile?: boolean;
}
interface State {}
type FormValues = T.Schema.Restaurant.Payments.RestaurantPaymentStripeBancontact;

@inject('store')
@observer
export class SettingsFormPaymentsStripeBancontact extends MobxComponent<Props, State> {
  initialValues: FormValues;

  constructor(props: Props) {
    super(props);
    this.state = {};
    const r = this.injected.store.restaurant!;
    this.initialValues = r.settings.payments.bancontact || {
      enabled: false,
      label: '',
      services: [],
      connect_account_id: '',
      currency: '',
      disable_email_receipt: false,
      custom_payment_email: '',
      min_order: 0,
    };
  }

  render() {
    const { showMainSupport } = this.injected.store;
    const { isMobile } = this.props;
    const { store } = this.injected;
    const { organisation } = store;
    return (
      <RestaurantForm<FormValues>
        submit={async (r, values) => {
          r.settings.payments.bancontact = values;
          const update = { $set: { 'settings.payments.bancontact': r.settings.payments.bancontact } };
          return { r, update };
        }}
        validators={{
          // @ts-ignore
          connect_account_id: values => {
            if (
              values.connect_account_id &&
              values.connect_account_id.length > 0 &&
              !values.connect_account_id.includes('acct_')
            )
              return { connect_account_id: 'This account ID is in the wrong format' };
          },
          // @ts-ignore
          min_order: values => {
            if (!values.min_order) {
              return { min_order: 'Minimum amount required for this payment method.' };
            }
            if (values.min_order && values.min_order < 0)
              return { min_order: 'Minimum amount need to be greater than 0' };
          },
        }}
        initialValues={this.initialValues}
        onError={() => UI.notification.error('An error occurred')}
        onSuccessMessage="Settings Updated"
      >
        {({ form, error, getFieldError }) => {
          const { isSubmitting, setFieldValue } = form;
          return (
            <>
            <div className="p-4">
              {showMainSupport && (
                <FormGroup>
                  <LinkTag
                    className="block font-semi-bold"
                    target="_blank"
                    href="https://support.cloudwaitress.com/how-to-guides/setup-stripe-payments"
                  >
                    Read the setup guide
                  </LinkTag>
                </FormGroup>
              )}

              <FastField
                name="enabled"
                render={({ field }: any) => (
                  !isMobile ? (
                    <FormGroup title="Enabled" help="Allows customers to pay using this method">
                    <Switch
                      id="enable-switch"
                      checked={field.value}
                      onChange={e => setFieldValue('enabled', e.target.checked)}
                    />
                  </FormGroup>
                  ) : (
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px', paddingBottom: '20px', borderBottom: '1px dashed #DFDFDF'}}>
                    <div style={{ fontSize: '14px', fontWeight: 600 }}>Enabled Payment Method</div>
                    <div>
                    <Switch
                      id="enable-switch"
                      checked={field.value}
                      onChange={e => setFieldValue('enabled', e.target.checked)}
                    />
                    </div>
                  </div>
                  )
                )}
              />

              <FastField
                name="currency"
                render={({ field }: any) => (
                  <FormGroup
                    title="Currency"
                    help="Select the currency you would like to accept Stripe charges in. Make sure you are authorized to accept charges in that currency and that it is supported by Stripe"
                  >
                    <Input
                      type="text"
                      value={field.value}
                      onChange={(code: any) => setFieldValue('currency', code)}
                      disabled
                    />
                  </FormGroup>
                )}
                disable={true}
              />

              <FastField
                name="disable_email_receipt"
                render={({ field }: any) => (
                  <FormGroup
                    title="Disable E-Mail Receipt"
                    help="Toggle this to disable Stripe payment receipts from being sent to your customer's e-mail"
                  >
                    <Switch
                      id="disable_email_receipt-switch"
                      checked={field.value || false}
                      onChange={e => setFieldValue('disable_email_receipt', e.target.checked)}
                    />
                  </FormGroup>
                )}
              />

              <FastField
                name="custom_payment_email"
                render={({ field }: any) => (
                  <FormGroup
                    optional
                    title="Enable Custom Payment Form"
                    help="Entering an e-mail address will enable the custom payment form at your-store-domain.com/online-payment"
                  >
                    <Input
                      {...field}
                      type="email"
                      placeholder="Enter E-Mail Address For Payment Notification"
                      value={field.value || ''}
                    />
                  </FormGroup>
                )}
              />

              <PaymentBaseFields defaultLabel="Credit Card" setFieldValue={setFieldValue} />
              <FastField
                name="min_order"
                render={({ field }: any) => (
                  <FormGroup
                    title="Minimum Order Value ($)"
                    error={getFieldError(form, 'min_order')}
                    help="Only allow payments when order total is greater than or equal to this value "
                  >
                    <Input
                      {...field}
                      type="number"
                      value={field.value || 1}
                      step="0.01"
                      onChange={e => setFieldValue('min_order', e.target.value)}
                    />
                  </FormGroup>
                )}
              />
              <FastField
                name="connect_account_id"
                render={({ field }: any) => (
                  <FormGroup
                    optional
                    title="SC Account ID"
                    error={getFieldError(form, 'connect_account_id')}
                    help="Admin use only"
                  >
                    <Input type="text" {...field} autoSave="false" autoCorrect="false" />
                  </FormGroup>
                )}
              />

              {error && <FormGroup error={error} />}

              {organisation && organisation.dashboard === 'v2.1' ?
                <SaveButtonWrapper>
                  <SettingsActionButtonContainer className="no-sidebar desktop-view">
                  <SettingsActionButtonWrapper style={{left: 0}}>
                    <button  type="submit" disabled={isSubmitting} >
                      {isSubmitting && <RotateLoader size={2} color="white" />}
                      {!isSubmitting && "Save"}
                    </button>
                  </SettingsActionButtonWrapper>
                </SettingsActionButtonContainer>
              </SaveButtonWrapper>
              : <Button full={true} color="primary" type="submit" disabled={isSubmitting}>
                  {isSubmitting && <RotateLoader size={2} color="white" />}
                  {!isSubmitting && 'Save'}
                </Button>
              }
             

            </div>
            {organisation && organisation.dashboard === 'v2.1' && 
            <SaveButtonWrapper>
              <SettingsActionButtonContainer style={{ width: '100%', marginLeft: 0 }} className='mobile-view'>
                <SettingsActionButtonWrapper>
                  <Button full={true} color="primary" type="submit" disabled={isSubmitting}>
                    {isSubmitting && <RotateLoader size={2} color="white" />}
                    {!isSubmitting && 'Save'}
                  </Button>
                </SettingsActionButtonWrapper>
              </SettingsActionButtonContainer>
              </SaveButtonWrapper>
            }
              </>
          );
        }}
      </RestaurantForm>
    );
  }
}