import * as React from "react";
import styled, { css } from "../../../styled/index";
import { BaseInputCSS } from "../input/index";
import { ArrowDownIcon } from "./icon";

const SelectComponent = styled("select") <T.Lib.Form.Select.SelectProps>`
  ${BaseInputCSS};
  option {
    padding: 5px 0;
    /* &:checked{
        background-color: lighten(0.15, #FB2F2F);
      } */
    &:disabled {
      background-color: darken(white, 15%);
    }
  }
  ${(props) => props.version === 'v2' ? css`
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    border: 1px solid #dfdfdf;
    border-radius: 2px;
    margin-right: 2rem;
    padding: 0.5rem;
    padding-right: 2rem;
  `: css``};
  
`;
const SelectWrapper = styled("div")`
    position: relative;
    width: 100%;
`

export const Select = (props: T.Lib.Form.Select.SelectProps) => (
  <SelectWrapper>
    <SelectComponent {...props}>
      {props.placeholder && <option value="">{props.placeholder || ""}</option>}
      {props.options.map((o, i) => {
        return (<option key={i} value={o.value} disabled={o.disabled || false}>{o.label}</option>);
      })}
    </SelectComponent>
    {props.version === 'v2' && <ArrowDownIcon />}
  </SelectWrapper>
);
