import { BumpButton, Button, CommonText } from '@lib/components';
import React from 'react';
import styled from 'styled-components';
import { DetailItem } from '../minimalViewCard/minimalViewCard';
import { EButtonColor } from '@lib/components/dist/esm/components/bumpViews/button';
import { Translation } from 'react-i18next';
import { ActionButton } from '../../modals/bookingDetail/bookingDetail';
import { MobXProviderContext } from 'mobx-react';
import { UsersIcon, CalendarIcon, MailIcon, PhoneIcon } from '../../../../../../../components/v2/icons';
import { UI } from '../../../../../../../../core/ui';

const WithActionBtnsCardWrapper = styled.div`
    width: 100%;
    background: #FEFEFE;
    padding: 12px;
    border-radius: 4px;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    @media (min-width: 768px) {
	    flex-direction: row;
    }
`;

const HeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`;

const CardDetails = styled.div`
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: 50% 50%;
    grid-gap: 12px;
    margin-top: 12px;
    @media (max-width: 385px) {
	    grid-template-rows:  1fr;
        grid-template-columns: 100%;
    }
`;

const CardFooter = styled.div`
    display: flex;
    margin-top: 12px;
    flex-direction: row;
    gap: 8px;
    @media (min-width: 768px) {
	    width: 120px;
        flex-direction: column;
    }
`;

const ContentWrapper = styled.div`
    flex: 1;
`;

type WithActionBtnsCardProps = {
    title: string;
    numberOfPeople: number;
    bookingTime: string | number;
    timeStamp: number;
    onClick: () => void;
    orderStatus: string;
    email: string;
    phone?: string;
    itemId?: string | undefined;
    item:any;
}

export const WithActionBtnsCard: React.FC<WithActionBtnsCardProps> = ({ title, numberOfPeople, bookingTime,timeStamp, onClick, email, phone, orderStatus, itemId = '', item
}) => {
    const { store } = React.useContext(MobXProviderContext);

    const handleStatusSelect = async (item: T.Schema.Booking.BookingSchema, status: T.Schema.Booking.BookingStatuses) => {
        if (!status) return;
        const newItem = { ...item, status }

        store.service.booking.update_status(newItem, status, 'list');
        if (status === 'cancelled') {
            UI.notification.error("This order was cancelled", { timeout: 1000 });
        } else if (status === 'confirmed') {
            UI.notification.success("This order was confirm", { timeout: 1000 });
        } 
    }

    return (
        <Translation>
            {
                t => (
                    <WithActionBtnsCardWrapper >
                        <ContentWrapper>
                            <HeaderWrapper onClick={onClick}>
                                <CommonText size="medium" weight={600} color='#0C202A'>
                                    {title}
                                </CommonText>
                                {/* <InboxIcon /> */}
                            </HeaderWrapper  >
                            <CardDetails onClick={onClick}>
                                <DetailItem>
                                    <UsersIcon />
                                    <CommonText size="small" weight={500} color='#6B7270'>
                                        {numberOfPeople > 1 ? numberOfPeople + ' People' : numberOfPeople + ' Person'} 
                                    </CommonText>
                                </DetailItem>
                                <DetailItem>
                                    <CalendarIcon />
                                    <CommonText size="small" weight={500} color='#6B7270'>
                                        {t("dateFromTimestamp", { value: bookingTime })} - {t("timeFromTimestamp", { value: timeStamp })}
                                    </CommonText>
                                </DetailItem>
                               <DetailItem>
                                    <MailIcon />
                                    <CommonText ellipsis={true} width={'100%'} size="small" weight={500} color='#6B7270'>
                                        {email}
                                    </CommonText>
                                </DetailItem>
                                <DetailItem>
                                    <PhoneIcon />
                                    <CommonText size="small" weight={500} color='#6B7270'>
                                        {phone}
                                    </CommonText>
                                </DetailItem>
                            </CardDetails>
                        </ContentWrapper>
                        <CardFooter>
                            {orderStatus !== 'cancelled' && (
                                <ActionButton status='back' onClick={() => { itemId && handleStatusSelect(item, 'cancelled') }}>Cancel</ActionButton>
                            )}
                            {orderStatus !== 'unconfirmed' && (
                                <ActionButton status='unconfirmed' onClick={() => { itemId && handleStatusSelect(item, 'unconfirmed') }}>Unconfirm</ActionButton>
                            )}
                            {orderStatus !== 'confirmed' && (
                                <ActionButton status='confirmed' onClick={() => {
                                    itemId && handleStatusSelect(item, 'confirmed')
                                }
                                }>Confirm</ActionButton>
                            )}
                        </CardFooter>
                    </WithActionBtnsCardWrapper>
                )
            }
        </Translation>

    );
}