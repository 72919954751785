import * as React from "react";
import InputMask from "react-input-mask";
import { FastField } from "formik";
import { FieldOpeningHoursV2, FormGroup } from "@lib/components";
import { Input } from "@lib/components";
import { Button } from "@lib/components";
import { RotateLoader } from "@lib/components";
import { Switch } from "@lib/components";
import { TabSelect } from "@lib/components";
import { SelectAdv } from "@lib/components";
import { FieldOpeningHours } from "@lib/components";
import { inject, observer } from "mobx-react";
import { Modal } from "@lib/components";
import { ModalContent } from "@lib/components";
import { ListOrderTimesOptions, ListServicesOptionsNoB } from "@lib/common";
import { Tag } from "@lib/components";
import { MobxComponent } from "../../../../../mobx/components";
import { RestaurantForm } from "../../../../../mobx/components/restaurant-form";
import { MenuItemText } from "../menus/layout/floating-button";
import { IconHelp } from "../../../../components/v2/icons";
import { SettingsFormGroup, SettingsFormGroupInput, SettingsFormLabelContainer, SettingsFormLabelLeftContainer, SettingsFormLabelRightContainer, SettingsFormInputContainer, SettingsSwitchContainer, SettingsActionButtonContainer, SettingsActionButtonWrapper } from "../settings-v2";
import { TabGroup, TabItem } from "../settings-v2/receipt-printing";

interface Option {
	label: string;
	value: string;
}
type FormValues = T.Schema.Restaurant.Menu.RestaurantMenu;
interface Props {
	type: "edit" | "create";
	abacus_type?: "edit" | "create";
	initialValues: FormValues | null;
	close: () => void;
}
interface State {
	tab: string;
	companiesFetched: boolean;
	options: Option[];
	fetching: boolean;
	generating: boolean;
	generatingAbacus: boolean;
	generated: boolean;
	generatedAbacus: boolean;
	// menu_id: string;
	company_id: string;
	isChecking: boolean;
	isCheckingAbacus: boolean;
	backgroundStatus: string;
	backgroundStatusAbacus: string;
	disableGenerate: boolean;
	disableAbacusGenerate: boolean;
}

@inject("store")
@observer
export class RestaurantFormMenuV2 extends MobxComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			tab: "0",
			companiesFetched: false,
			options: [],
			fetching: false,
			generating: false,
			generatingAbacus: false,
			generated: false,
			generatedAbacus: false,
			// menu_id: "",
			company_id: "",
			isChecking: false,
			isCheckingAbacus: false,
			backgroundStatus: "Unknown",
			backgroundStatusAbacus: "Unknown",
			disableGenerate: false,
			disableAbacusGenerate: false,
		};
	}

	setTab = (tab: string) => {
		this.setState({ tab });
	};

	componentDidMount = () => {
		this.setState({
			tab: "0",
			companiesFetched: false,
			options: [],
			fetching: false,
			generating: false,
			generated: false,
			// menu_id: ""
		});
	};

	componentDidUpdate(prevProps: Props) {
		if (this.props.initialValues && !prevProps.initialValues) {
			this.setTab("0");
		}
	}

	// fetchSites = async () => {
	// 	//
	// 	this.setState({
	// 		fetching: true
	// 	}, async () => {
	// 		const _id = this.injected.store.restaurant && this.injected.store.restaurant._id ? this.injected.store.restaurant._id : ""
	// 		const data = await this.injected.store.getKountaSites(_id)
	// 		const sites = data.sites
	// 		const company_id = data.company_id
	// 		const options = sites.map((company: any) => {
	// 			return {
	// 				value: company.id.toString(),
	// 				label: company.name,
	// 			}
	// 		})
	// 		//
	// 		this.setState({
	// 			companiesFetched: true,
	// 			options,
	// 			fetching: false,
	// 			company_id: company_id
	// 		})
	// 	})
	// 	//
	// }

	checkStatus = async () => {
		this.setState(
			{
				isChecking: true,
			},
			async () => {
				const _id =
					this.injected.store.restaurant &&
						this.injected.store.restaurant._id
						? this.injected.store.restaurant._id
						: "";
				const result = await this.injected.store.checkStatus(_id);
				if (this.state.backgroundStatus !== result) {
					// If the status changed, refresh the data
					const res = await this.injected.store.api.restaurant({
						_id: _id,
					});
					///@ts-ignore
					const { restaurant } = res;
					this.injected.store.setRestaurant(restaurant);
				}
				this.setState({
					isChecking: false,
					backgroundStatus: result,
				});
			}
		);
	};

	checkAbacusStatus = async () => {
		console.log("Abacus Called");
		this.setState(
			{
				isCheckingAbacus: true,
			},
			async () => {
				const _id =
					this.injected.store.restaurant &&
						this.injected.store.restaurant._id
						? this.injected.store.restaurant._id
						: "";
				const result = await this.injected.store.checkStatusAbacus(_id);
				console.log({ result });
				if (this.state.backgroundStatusAbacus !== result) {
					// If the status changed, refresh the data
					const res = await this.injected.store.api.restaurant({
						_id: _id,
					});
					///@ts-ignore
					const { restaurant } = res;
					this.injected.store.setRestaurant(restaurant);
				}
				this.setState({
					isCheckingAbacus: false,
					backgroundStatusAbacus: result,
				});
			}
		);
	};

	generateMenu = async (menu_id: string) => {
		//
		this.setState({
			generating: true,
			generated: false,
			disableGenerate: true,
		});
		const _id =
			this.injected.store.restaurant && this.injected.store.restaurant._id
				? this.injected.store.restaurant._id
				: "";
		// const site_id = this.in
		const result = await this.injected.store.generateMenu(_id, menu_id);
		this.setState(
			{
				generating: false,
				generated: true,
			},
			async () => {
				// Refresh the data immediately
				const res = await this.injected.store.api.restaurant({
					_id: _id,
				});
				///@ts-ignore
				const { restaurant } = res;
				this.injected.store.setRestaurant(restaurant);
				setTimeout(() => {
					this.setState({
						disableGenerate: false,
					});
				}, 1000);
			}
		);
		//
	};

	generateMenuv2 = async (menu_id: string) => {
		//
		this.setState({
			generating: true,
			generated: false,
			disableGenerate: true,
		});
		const _id =
			this.injected.store.restaurant && this.injected.store.restaurant._id
				? this.injected.store.restaurant._id
				: "";
		// const site_id = this.in
		const result = await this.injected.store.generateMenuv2(_id, menu_id);
		this.setState(
			{
				generating: false,
				generated: true,
			},
			async () => {
				// Refresh the data immediately
				const res = await this.injected.store.api.restaurant({
					_id: _id,
				});
				///@ts-ignore
				const { restaurant } = res;
				this.injected.store.setRestaurant(restaurant);
				setTimeout(() => {
					this.setState({
						disableGenerate: false,
					});
				}, 1000);
			}
		);
		//
	};

	generateAbacusMenu = async (menu_id: string) => {
		//
		this.setState({
			generatingAbacus: true,
			generatedAbacus: false,
			disableAbacusGenerate: true,
		});
		const _id =
			this.injected.store.restaurant && this.injected.store.restaurant._id
				? this.injected.store.restaurant._id
				: "";
		// const site_id = this.in
		const result = await this.injected.store.generateAbacusMenu(
			_id,
			menu_id
		);
		this.setState(
			{
				generating: false,
				generated: true,
			},
			async () => {
				const res = await this.injected.store.api.restaurant({
					_id: _id,
				});
				///@ts-ignore
				const { restaurant } = res;
				this.injected.store.setRestaurant(restaurant);
				setTimeout(() => {
					this.setState({
						disableAbacusGenerate: false,
					});
				}, 1000);
			}
		);
		//
	};

	render() {
		const { type, initialValues, close } = this.props;
		const { tab } = this.state;
		const r = this.injected.store.restaurant;
		const { store } = this.injected;
		const { activeMobileMenu } = store
		//
		
		let openItems = false;
		if (
			initialValues &&
			r &&
			r.abacus &&
			typeof r.abacus.abacus_open_items === "boolean" &&
			r.abacus.abacus_open_items === true
		) {
			openItems = true;
		}
		//
		let menu_options: any = [{}];
		///@ts-ignore
		if (
			r._id === "kFkeSP16a" ||
			///@ts-ignore
			r._id === "58b19c3a1667c9685d0f8c63" ||
			///@ts-ignore
			// || r._id === "RESVqm6Ka2KYjhtWTcJn-iMm"
			///@ts-ignore
			r._id === "RES1T_qjKy9PUdDPiVTsVPcd" ||
			///@ts-ignore
			r.reseller_id === "cloudwaitress-test" ||
			///@ts-ignore
			r.reseller_id === "yoom"
		) {
			if (r?.kounta?.enabled) {
				///@ts-ignore
				menu_options = [
					{ label: "General", value: "0" },
					{ label: "Conditions", value: "1" },
					{ label: "Kounta", value: "2" },
				];
			} if (r?.wpos?.wpos_status) {
				menu_options = [
					{ label: "General", value: "0" },
					{ label: "Conditions", value: "1" },
					{ label: "POS", value: "4" },
				];
			} else {
				menu_options = [
					{ label: "General", value: "0" },
					{ label: "Conditions", value: "1" },
				];
			}
		} else {
			if (
				r &&
				r._id &&
				r._id !== "RESVqm6Ka2KYjhtWTcJn-iMm" &&
				r.abacus &&
				r.abacus.abacus_status === true &&
				openItems === false
			) {
				menu_options = [
					{ label: "General", value: "0" },
					{ label: "Conditions", value: "1" },
					// { label: "Kounta", value: "2" },
					{ label: "Abacus", value: "3" },
				];
			} else if (
				///@ts-ignore
				(r._id === "RESoMB_ZxZYNZaa_anfvZol1" ||
					///@ts-ignore
					r._id === "RESVqm6Ka2KYjhtWTcJn-iMm") &&
				openItems === false
			) {
				console.log("Open Items false");
				if (r?.kounta?.enabled) {
					///@ts-ignore
					menu_options = [
						{ label: "General", value: "0" },
						{ label: "Conditions", value: "1" },
						{ label: "Kounta", value: "2" },
						{ label: "Abacus", value: "3" },
					];
				} else {
					menu_options = [
						{ label: "General", value: "0" },
						{ label: "Conditions", value: "1" },
						{ label: "Abacus", value: "3" },
					];
				}
			} if (r?.wpos?.wpos_status) {
				menu_options = [
					{ label: "General", value: "0" },
					{ label: "Conditions", value: "1" },
					{ label: "POS", value: "4" },
				];
			} else {
				menu_options = [
					{ label: "General", value: "0" },
					{ label: "Conditions", value: "1" },
				];
			}
		}
		let initialKountaValues =
			initialValues && initialValues.kounta_sync
				? initialValues.kounta_sync
				: {};
		if (initialKountaValues === {}) {
			initialKountaValues = {
				kounta_sync_export_enabled: false,
			};
		}
		return (
				
				<>
					{(activeMobileMenu.name ! == 'create-menu' || activeMobileMenu.name ! == 'edit-menu') && <TabGroup className='menus' style={{ overflow: 'unset', padding: '16px'}}>
						<TabItem
							active={this.state.tab === '0'}
							onClick={() => this.setState({ tab: '0'})}
						>
						General
						</TabItem>
						<TabItem
							active={this.state.tab === '1'}
							onClick={() => this.setState({ tab: '1'})}
						>
						Condition
						</TabItem>
					</TabGroup>}

				<RestaurantForm<FormValues>
					submit={async (r, values) => {
						const existing = r.menus.findIndex(
							(p) => p._id === values._id
						);
						if (existing === -1) {
							r.menus.push(values);
						} else {
							r.menus[existing] = values;
						}
						const update = { $set: { menus: r.menus } };
						return { r, update };
					}}
					validators={{
						name: (values) => {
						  if (!values.name)
							return { name: "This field is required" };
						},
						"conditions.pre_order.cutoff_time": (values) => {
							if (!values.conditions.pre_order.cutoff_time) {
								return;
							}
							if (
								!/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(
									values.conditions.pre_order.cutoff_time
								)
							) {
								return {
									"conditions.pre_order.cutoff_time":
										"Invalid time format. Please use 24-hour format (max value is 23.59)",
								};
							}
						}
					}}
					initialValues={initialValues}
					onSuccess={close}
					onSuccessMessage={
						type === "edit" ? "Menu edited" : "Menu created"
					}
				>
					{({ form, error, getFieldError }) => {
						//
						const { isSubmitting, setFieldValue } = form;
						//
						// if (this.state.menu_id === "") {
						// this.setState({
						// 	menu_id: form.values._id
						// })
						// }
						//
						// if (this.state.company_id !== "") {
						// 	setFieldValue("kounta.kounta_company_id", this.state.company_id)
						// }
						//
						// let options: Option[] = []
						// if (
						// 	this.state.companiesFetched === false
						// 	&& form.values.kounta
						// 	// && form.values.kounta.kounta_site_id
						// 	// && form.values.kounta.kounta_site_name
						// 	// && form.values.kounta.kounta_site_id.length > 0
						// 	// && form.values.kounta.kounta_site_name.length > 0
						// ) {
						// 	options = [{
						// 		label: form.values.kounta.kounta_site_name,
						// 		value: form.values.kounta.kounta_site_id,
						// 	}]
						// } else {
						// 	options = this.state.options
						// }
						//
						// const noUsernameOrPassword = !form.values.kounta ||
						// 	!form.values.kounta.kounta_username ||
						// 	!form.values.kounta.kounta_password ||
						// 	form.values.kounta.kounta_username === "" ||
						// 	form.values.kounta.kounta_password === ""
						//
						return (
							<div>
								<div>
									{this.state.tab === '0' && <>
									<SettingsFormGroup>
										<SettingsFormGroupInput>
										<SettingsFormLabelContainer>
											<SettingsFormLabelLeftContainer>Name</SettingsFormLabelLeftContainer>
											<SettingsFormLabelRightContainer>
											<IconHelp />
											</SettingsFormLabelRightContainer>
										</SettingsFormLabelContainer>
										<SettingsFormInputContainer>
											<FastField
												name="name"
												render={({ field }: any) => (
												<input
													type="text"
													{...field}
													/>
												)} />
										</SettingsFormInputContainer>
										</SettingsFormGroupInput>
										<SettingsFormGroupInput>
										<SettingsFormLabelContainer>
											<SettingsFormLabelLeftContainer>Display Name</SettingsFormLabelLeftContainer>
											<SettingsFormLabelRightContainer>
											<IconHelp />
											</SettingsFormLabelRightContainer>
										</SettingsFormLabelContainer>
										<SettingsFormInputContainer>
										<FastField
												name="display_name"
												render={({ field }: any) => (
												<input
													type="text"
													{...field}
													/>
												)} />
										</SettingsFormInputContainer>
										</SettingsFormGroupInput>
										<SettingsFormGroupInput>
										<SettingsFormLabelContainer>
											<SettingsFormLabelLeftContainer>Description</SettingsFormLabelLeftContainer>
											<SettingsFormLabelRightContainer>
											<IconHelp />
											</SettingsFormLabelRightContainer>
										</SettingsFormLabelContainer>
										<SettingsFormInputContainer>
											<FastField
												name="description"
												render={({ field }: any) => (
												<input
													type="text"
													{...field} />
												)} />
										</SettingsFormInputContainer>
										</SettingsFormGroupInput>
									</SettingsFormGroup>
									</>}
									{this.state.tab === '1' && <>
									<SettingsFormGroup>
									<SettingsSwitchContainer>
										<SettingsFormLabelLeftContainer>
											Hide Unavailable Menu
										</SettingsFormLabelLeftContainer>
										<FastField
											name="conditions.hide_unavailable_menu"
											render={({ field }: any) => (
												<Switch
													id="conditions.hide_unavailable_menu"
													checked={field.value || false}
													onChange={(e) =>
													setFieldValue(
														"conditions.hide_unavailable_menu",
														e.target
														.checked
													)	
													}
												/>
											)}
											/>
										</SettingsSwitchContainer>
										<SettingsFormGroupInput
											error={(error && getFieldError(form, 'conditions.times'))|| null}>
											<SettingsFormLabelContainer>
												<SettingsFormLabelLeftContainer>Order Times</SettingsFormLabelLeftContainer>
												<SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
											</SettingsFormLabelContainer>
											<SettingsFormInputContainer>
											<FastField
												name="conditions.times"
												render={({ field }: any) => (
														<SelectAdv
															type="single"
															options={ListOrderTimesOptions}
															value={field.value}
															onChange={(option: string) => setFieldValue("conditions.times", option)}
														/>
												)}
											/>
											</SettingsFormInputContainer>
										</SettingsFormGroupInput>
										<SettingsFormGroupInput
											error={(error && getFieldError(form, 'hours'))|| null}>
											<SettingsFormLabelContainer>
												<SettingsFormLabelLeftContainer>Applicable Hours</SettingsFormLabelLeftContainer>
												<SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
											</SettingsFormLabelContainer>
											<FastField
												name="conditions.hours"
												render={({ field }: any) => (
													<FieldOpeningHoursV2
														allowNone={true}
														hours={field.value}
														onChange={(
															opening_hours
														) => setFieldValue(
															"conditions.hours",
															opening_hours
														)} />
												)} />
										</SettingsFormGroupInput>
										<SettingsFormGroupInput
											error={(error && getFieldError(form, 'conditions.services'))|| null}>
											<SettingsFormLabelContainer>
												<SettingsFormLabelLeftContainer>Services</SettingsFormLabelLeftContainer>
												<SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
											</SettingsFormLabelContainer>
											<SettingsFormInputContainer>
											<FastField
												name="conditions.services"
												render={({ field }: any) => (
														<SelectAdv
															type="single"
															options={ListServicesOptionsNoB}
															value={field.value}
															onChange={(option: string) => setFieldValue("conditions.services", option)}
														/>
												)}
											/>
											</SettingsFormInputContainer>
										</SettingsFormGroupInput>
										<SettingsSwitchContainer>
										<SettingsFormLabelLeftContainer>
											Enable Age Restricted Access
										</SettingsFormLabelLeftContainer>
										<FastField
											name="conditions.age_restricted"
											render={({ field }: any) => (
												<Switch
													id="conditions.age_restricted"
													checked={
													field.value
													}
													onChange={(e) =>
													setFieldValue(
														"conditions.age_restricted",
														e.target
														.checked
													)	
													}
												/>
											)}
											/>
										</SettingsSwitchContainer>
										<SettingsSwitchContainer>
										<SettingsFormLabelLeftContainer>
											Enable Pre-orders Only
										</SettingsFormLabelLeftContainer>
										<FastField
											name="conditions.pre_order.enabled"
											render={({ field }: any) => (
												<Switch
													id="conditions.pre_order.enabled"
													checked={
													field.value
													}
													onChange={(e) =>
													setFieldValue(
														"conditions.pre_order.enabled",
														e.target
														.checked
													)	
													}
												/>
											)}
											/>
										</SettingsSwitchContainer>
										<SettingsFormGroupInput>
										<SettingsFormLabelContainer>
											<SettingsFormLabelLeftContainer>Pre-order Days In Advance</SettingsFormLabelLeftContainer>
											<SettingsFormLabelRightContainer>
											<IconHelp />
											</SettingsFormLabelRightContainer>
										</SettingsFormLabelContainer>
										<SettingsFormInputContainer>
											<FastField
												name="conditions.pre_order.days_in_advance"
												render={({ field }: any) => (
												<input
													type="number"
													{...field}
													/>
												)} />
										</SettingsFormInputContainer>
										</SettingsFormGroupInput>
										<SettingsFormGroupInput>
										<SettingsFormLabelContainer>
											<SettingsFormLabelLeftContainer>Pre-order Cutoff Time (24H Format)</SettingsFormLabelLeftContainer>
											<SettingsFormLabelRightContainer>
											<IconHelp />
											</SettingsFormLabelRightContainer>
										</SettingsFormLabelContainer>
											<FastField
												name="conditions.pre_order.cutoff_time"
												render={({ field }: any) => (
													<InputMask
														mask="99:99"
														{...field}
													>
														{(inputProps: any) => (
															<Input
																type="text"
																placeholder="00:00"
																{...inputProps}
															/>
														)}
													</InputMask>
												)} />
										</SettingsFormGroupInput>
									</SettingsFormGroup>
									
									</>}
									<SettingsActionButtonContainer>
										<SettingsActionButtonWrapper style={{ left: 0}}>
											<button type="button" 
												className="default btn-cancel" 
												onClick={ () => {store.setActiveMobileMenu(
													store.activeMobileMenu.referrer.pop()!,
													Array.from(new Set(store.activeMobileMenu.referrer))
												)}}>
													Cancel 
											</button>
											<button type="submit" disabled={isSubmitting}>
												{isSubmitting && <RotateLoader size={2} color="white" />}
												{!isSubmitting && "Save"}
											</button>
										</SettingsActionButtonWrapper>
									</SettingsActionButtonContainer>
									</div>
							</div>
						);
					}}
				</RestaurantForm>
				</>
		);
	}
}
