import { CommonText, css, styled } from '@lib/components';
import React, { useContext, useState } from 'react';
import { CancelOrderWrapper, ConfirmProgress, ConfirmProgressIcon, ConfirmProgressStatus, DetailInfo, Header, LineWrapper, ModalWrapper, ProgressLine, RowContent, SectionTitle, SectionWrapper, TableContent, TextPrimary, UnconfirmedIconWrapper } from './bookingDetail.component';
import { BackIcon, BookingSuccessImage, CancelOrderImage, CancelledIcon, ConfirmedIcon, EllipsisIcon, UnconfirmedIcon } from './bookingDetail.icon';
import { Translation } from "react-i18next";
import { MobXProviderContext, observer, useLocalStore } from 'mobx-react';
import { UI } from '../../../../../../../../core/ui';
import { ModalBody } from '../settings/bookingSettingConstants';
import { displayId, displayNumberOfPeople, displayPhone } from '../../../bookingdisplayData';
import { MobileModal } from '../../../../settings-v2';

export const ControlsWrapper = styled.div`
  width: 100%;
  max-width: 900px;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;

export const FooterWrapper = styled.div`
  background: #fefefe;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  border-top: 1px solid #dfdfdf;
  padding: 8px 16px;
  z-index: 9;
`;


export const ActionButton = styled.div<{ status: string }>`
    display: flex;
    flex: 1;
    height: 48px;
    color: ${props => {
        if (props.status === "back") {
            return "#4B4F5E";
        }
        else {
            return "#F5F5F5";
        }
    }}; 
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: ${props => {
        if (props.status === "unconfirmed") {
            return "#E5AC00";
        } else if (props.status === "confirmed") {
            return "#A5C100";
        } else if (props.status === "back") {
            return "#F1F4F7";
        } else {
            return "#BC362F";
        }
    }};
    border: 1px solid #dfdfdf;
    cursor: pointer;
    @media (min-width: 768px) {
	    order: ${props => {
        if (props.status === "unconfirmed") {
            return "2";
        } else if (props.status === "confirmed") {
            return "1";
        } else if (props.status === "back") {
            return '3';
        } else {
            return "3";
        }
    }};
    }
`;
const ConfirmIconWrapper = styled.div<{ active?: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
${({ active }) => {
        return active
            ? css`
          svg path {
            fill: #FB2F2F;
          }
        `
            : css`
          svg path {
            color: #DFDFDF;
          }
        `;
    }}
`;

type BookingDetailModalProps = {
    bookingData: T.Schema.Booking.BookingSchema;
    isMobile: boolean;
}

export const BookingDetail: React.FC<BookingDetailModalProps> = observer(({ bookingData, isMobile }) => {
    const { store } = useContext(MobXProviderContext);
    const { bookingDetailLayout } = store;
    const r = store.restaurant!;
    const rid = r._id;

    const handleClose = () => {
        store.router.push(`/restaurant/${rid}/bookings`);
    }

    const handleStatusSelect = async (item: T.Schema.Booking.BookingSchema, status: T.Schema.Booking.BookingStatuses) => {
        if (!status) return;
        const newItem = { ...item, status }

        store.service.booking.update_status(newItem, status);
        if (status === 'cancelled') {
            UI.notification.error("This order was cancelled", { timeout: 1000 });
            store.setBookingDetailLayout(1)
            handleClose();
        } else if (status === 'confirmed') {
            setTimeout(() => {
                store.setBookingDetailLayout(1)
                handleClose();
            }, 1000);

        } else {
            handleClose();
        }
    }

    return (
        <Translation>
            {
                t => (
                    <>
                        <ModalBody style={{ padding: 0 }}>
                            {bookingDetailLayout === 1 &&
                                <DetailInfo>
                                    <SectionWrapper>
                                        <CommonText size="medium" weight={600} color='#4B4F5E'>
                                            We got your order and we’re validating it.
                                        </CommonText>
                                        <ConfirmProgress>
                                            <UnconfirmedIconWrapper>
                                                <UnconfirmedIcon />
                                                <CommonText size="semiMedium" weight={400} color='#FB2F2F'>
                                                    Unconfirmed
                                                </CommonText>
                                            </UnconfirmedIconWrapper>

                                            <LineWrapper>
                                                <ProgressLine unconfirmed={bookingData.status === "unconfirmed"} />
                                            </LineWrapper>
                                            <ConfirmIconWrapper active={bookingData.status === "confirmed" || bookingData.status === "cancelled"}>
                                                {bookingData.status === "cancelled" ? <CancelledIcon /> : <ConfirmedIcon />}
                                                <CommonText size="semiMedium" weight={400} color={bookingData.status === 'unconfirmed' ? '#6B7270' : '#FB2F2F'}>
                                                    {bookingData.status === "cancelled" ? 'Cancelled' : 'Confirmed'}
                                                </CommonText>
                                            </ConfirmIconWrapper>
                                        </ConfirmProgress>

                                    </SectionWrapper>

                                    <SectionWrapper>
                                        <SectionTitle>
                                            <CommonText size="medium" weight={600} color='#4B4F5E'>
                                                Booking Details
                                            </CommonText>
                                        </SectionTitle>
                                        <TableContent>
                                            <RowContent>
                                                <CommonText size="semiMedium" weight={400} color='#6B7270'>
                                                    Booking For:
                                                </CommonText>
                                                <CommonText size="semiMedium" weight={500} color='#4B4F5E' align={'right'}>
                                                    {t("datetimeFromTimestamp", { value: bookingData.config.timestamp })}
                                                </CommonText>
                                            </RowContent>
                                            <RowContent>
                                                <CommonText size="semiMedium" weight={400} color='#6B7270'>
                                                    No. of Persons:
                                                </CommonText>
                                                <CommonText size="semiMedium" weight={500} color='#4B4F5E' align={'right'}>
                                                    {bookingData?.config?.number_of_people > 1 ? bookingData.config.number_of_people + ' People' : bookingData.config.number_of_people + ' Person'} 
                                                </CommonText>
                                            </RowContent>
                                            <RowContent>
                                                <CommonText size="semiMedium" weight={400} color='#6B7270'>
                                                    Placed:
                                                </CommonText>
                                                <CommonText size="semiMedium" weight={500} color='#4B4F5E' align={'right'}>
                                                    {t("datetimeFromTimestamp", { value: bookingData.created })}
                                                </CommonText>
                                            </RowContent>
                                            <RowContent>
                                                <CommonText size="semiMedium" weight={400} color='#6B7270'>
                                                    Notes:
                                                </CommonText>
                                                <CommonText size="semiMedium" weight={500} color='#4B4F5E' align={'right'}>
                                                    {bookingData.notes}
                                                </CommonText>
                                            </RowContent>
                                        </TableContent>
                                    </SectionWrapper>
                                    <SectionWrapper>
                                        <SectionTitle>
                                            <CommonText size="medium" weight={600} color='#4B4F5E'>
                                                Customer Details
                                            </CommonText>
                                        </SectionTitle>
                                        <TableContent>
                                            <RowContent>
                                                <CommonText size="semiMedium" weight={400} color='#6B7270'>
                                                    Name
                                                </CommonText>
                                                <CommonText size="semiMedium" weight={500} color='#4B4F5E' align={'right'}>
                                                    {bookingData.customer.name}
                                                </CommonText>
                                            </RowContent>
                                            <RowContent>
                                                <CommonText size="semiMedium" weight={400} color='#6B7270'>
                                                    Email
                                                </CommonText>
                                                <CommonText size="semiMedium" weight={500} color='#4B4F5E' align={'right'}>
                                                    {bookingData.customer.email}
                                                </CommonText>
                                            </RowContent>
                                            <RowContent>
                                                <CommonText size="semiMedium" weight={400} color='#6B7270'>
                                                    Phone
                                                </CommonText>
                                                <CommonText size="semiMedium" weight={500} color='#4B4F5E' align={'right'}>
                                                    {displayPhone(bookingData)}
                                                </CommonText>
                                            </RowContent>
                                        </TableContent>
                                    </SectionWrapper>

                                </DetailInfo>
                            }

                            {bookingDetailLayout === 2 &&
                                <CancelOrderWrapper isMobile={isMobile}>
                                    <CancelOrderImage />
                                    <TextPrimary size={21} weight={600} color='#0C202A' >
                                        Cancel order?
                                    </TextPrimary>
                                    <TextPrimary size={20} weight={500} color='#4B4F5E' >
                                        Are you sure you want to cancel the order? You might want to call the customer for clarifications.
                                    </TextPrimary>
                                </CancelOrderWrapper>
                            }
                            {bookingDetailLayout === 3 &&
                                <CancelOrderWrapper isMobile={isMobile}>
                                    <BookingSuccessImage />
                                    <TextPrimary size={21} weight={600} color='#0C202A' >
                                        Booking Confirmed!
                                    </TextPrimary>
                                    <TextPrimary size={20} weight={500} color='#4B4F5E' >
                                        Email notification has been sent to the customer.
                                    </TextPrimary>
                                </CancelOrderWrapper>
                            }


                        </ModalBody>
                        <FooterWrapper>
                            <ControlsWrapper>
                                {bookingDetailLayout === 1 &&
                                    <>
                                        {bookingData.status !== 'cancelled' && (
                                            <ActionButton style={{ flex: isMobile ? '1' : '0 0 150px' }} status='back' onClick={() => store.setBookingDetailLayout(2)}>Cancel</ActionButton>
                                        )}
                                        {bookingData.status !== 'unconfirmed' && (
                                            <ActionButton style={{ flex: isMobile ? '1' : '0 0 150px' }} status='unconfirmed' onClick={() => handleStatusSelect(bookingData, 'unconfirmed')}>Unconfirm</ActionButton>
                                        )}
                                        {bookingData.status !== 'confirmed' && (
                                            <ActionButton style={{ flex: isMobile ? '1' : '0 0 150px' }} status='confirmed' onClick={() => {
                                                store.setBookingDetailLayout(3)
                                                handleStatusSelect(bookingData, 'confirmed')
                                            }
                                            }>Confirm</ActionButton>
                                        )}
                                    </>
                                }
                                {bookingDetailLayout === 2 &&
                                    <>
                                        <ActionButton status='back' onClick={() => store.setBookingDetailLayout(1)}>Back</ActionButton>
                                        <ActionButton status='cancelled' onClick={() => {
                                            handleStatusSelect(bookingData, 'cancelled');
                                        }}>Cancel</ActionButton>

                                    </>
                                }
                            </ControlsWrapper>
                        </FooterWrapper>
                    </>
                )
            }
        </Translation>
    )
});
