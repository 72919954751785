import React, { useState } from "react"
import { TimeWrapper } from "../../../../special-hours/special-hour-modal"
import { InputTitle } from "../../../../special-hours/special-hours-constants"
import { Button, CommonText, FooterSection, FormGroup, Input, RotateLoader } from "@lib/components"
import { QuestionIcon } from "../../../../../../../components/v2/icons"
import { Field, Form, Formik, FormikActions } from "formik"

import styled from "styled-components"
import { DesktopBottomControl, DesktopBottomFooter, Footer } from "../customerFilter"
import { MobXProviderContext } from "mobx-react"
import { UI } from "../../../../../../../../core/ui"
import { logger } from "@lib/common"
import { FormHelpers } from "../../../../../../../../core/form"

const ModalBody = styled.div`
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    flex: 1 0 0;
    align-self: stretch;
    background: white;
    margin-bottom: 20px;
`
interface FormValues {
    login: string;
    password: string;
    name: string;
    phone: string;
    password_confirm: string;
}
interface CreateCustomerModalProps {

}
const errorStrings: T.ObjectString = {
    error_invalid_email: "Invalid e-mail address",
    error_exists: "Customer with this e-mail address already exists",
};
const initialValues: FormValues = {
    login: "",
    password: "",
    password_confirm: "",
    name: '',
    phone: ''
};

export const CreateCustomerModal: React.FC<CreateCustomerModalProps> = () => {
    const { store } = React.useContext(MobXProviderContext);
    const restaurant_id = store.restaurant!._id;
    const [state, setState] = useState({ error: '' })
    const submitCustomer = async (values: FormValues, form: FormikActions<FormValues>) => {
        form.setSubmitting(false);
        const { login, password, password_confirm, name, phone } = values;
        try {
            if (password.length < 6 || password.length > 512) {
                setState({ error: "Password must be at least 6 characters long" });
                return;
            }
            else if (password !== password_confirm) {
                setState({ error: "Passwords do not match" });
                return;
            }

            form.setSubmitting(true);

            setState({ error: "" });

            const res = await store.api.customer_create({ login, password, restaurant_id, name, phone });

            if (res.outcome) {
                setState({ error: errorStrings[res.message] || res.message });
                form.setSubmitting(false);
                return;
            }

            const { customer } = res;

            const items = [...store.customers.items];

            items.unshift(customer);

            store.updateCustomers({ items });

            UI.notification.success("Customer created");

            store.updateCustomerControl({ openCreateCustomer: false })

        }
        catch (e) {
            logger.captureException(e);
            setState({ error: "Error creating customer, please try again or contact us" });
        }
        finally {
            form.setSubmitting(false);
        }
    }

    const validate = (values: FormValues) => {
        const { errors, isError } = FormHelpers.validate<FormValues>(values, {});
        if (state.error && !isError) {
            setState({ error: "" });
        }
        else if (!state.error && isError) {
            setState({ error: "There are errors in your form that need correcting, please scroll up" });
        }
        return errors;
    }
    return (
        <Formik
            initialValues={initialValues}
            validate={validate}
            onSubmit={submitCustomer}
        >
            {(form) => {
                const { submitCount, setFieldValue, values, errors, isSubmitting } = form;

                const showFormError = !!(submitCount > 0 && state.error);
                return (
                    <Form style={{ overflowY: 'auto' }}>
                        <ModalBody>
                            <TimeWrapper>
                                <InputTitle>
                                    <CommonText size="semiMedium" weight={600} color='#4B4F5E'>
                                        Name
                                    </CommonText>
                                    <QuestionIcon />
                                </InputTitle>
                                <Field
                                    name='name'
                                    render={({ field }: any) => (
                                        <Input
                                            type="text"
                                            {...field}
                                            placeholder={"Name"}
                                            required={true}
                                            onChange={(e) => setFieldValue('name', e.target.value)}
                                        />
                                    )}
                                />
                            </TimeWrapper>
                            <TimeWrapper>
                                <InputTitle>
                                    <CommonText size="semiMedium" weight={600} color='#4B4F5E'>
                                        Email
                                    </CommonText>
                                    <QuestionIcon />
                                </InputTitle>
                                <Field
                                    name='login'
                                    render={({ field }: any) => (
                                        <Input
                                            type="text"
                                            {...field}
                                            placeholder={"Email"}
                                            required={true}
                                            onChange={(e) => setFieldValue('login', e.target.value)}
                                        />
                                    )}
                                />
                            </TimeWrapper>
                            <TimeWrapper>
                                <InputTitle>
                                    <CommonText size="semiMedium" weight={600} color='#4B4F5E'>
                                        Phone Number
                                    </CommonText>
                                    <QuestionIcon />
                                </InputTitle>
                                <Field
                                    name='phone'
                                    render={({ field }: any) => (
                                        <Input
                                            {...field}
                                            type="tel"
                                            inputMode="numeric"
                                            value={field.value}
                                            placeholder={"Phone Number"}
                                            required={true}
                                            onChange={(e) => {
                                                let value = e.target.value;
                                                if (value.startsWith('+')) {
                                                    value = '+' + value.slice(1).replace(/\D/g, '');
                                                } else {
                                                    value = value.replace(/\D/g, '');
                                                }
                                                form.setFieldValue('phone', value);
                                            }}
                                        />
                                    )}
                                />
                            </TimeWrapper>
                            <TimeWrapper>
                                <InputTitle>
                                    <CommonText size="semiMedium" weight={600} color='#4B4F5E'>
                                        Password
                                    </CommonText>
                                    <QuestionIcon />
                                </InputTitle>
                                <Field
                                    name='password'
                                    render={({ field }: any) => (
                                        <Input
                                            type="text"
                                            {...field}
                                            placeholder={"Password"}
                                            required={true}
                                            onChange={(e) => setFieldValue('password', e.target.value)}
                                        />
                                    )}
                                />
                            </TimeWrapper>
                            <TimeWrapper>
                                <InputTitle>
                                    <CommonText size="semiMedium" weight={600} color='#4B4F5E'>
                                        Confirm Password
                                    </CommonText>
                                    <QuestionIcon />
                                </InputTitle>
                                <Field
                                    name='password_confirm'
                                    render={({ field }: any) => (
                                        <Input
                                            type="text"
                                            {...field}
                                            placeholder={"Confirm Password"}
                                            required={true}
                                            onChange={(e) => setFieldValue('password_confirm', e.target.value)}
                                        />
                                    )}
                                />
                            </TimeWrapper>
                            {showFormError && <FormGroup error={state.error} />}
                        </ModalBody>
                        <Footer>
                            <div className="mobile-view">
                                <FooterSection version="v2">
                                    <Button full={true} type="submit" color="primary" disabled={isSubmitting}>
                                        {isSubmitting && <RotateLoader size={2} color="white" />}
                                        {!isSubmitting && "Create"}
                                    </Button>
                                </FooterSection>
                            </div>
                            <div className="desktop-view">
                                <DesktopBottomControl>
                                    <DesktopBottomFooter>
                                        <Button type="submit" color="primary" disabled={isSubmitting}>
                                            {isSubmitting && <RotateLoader size={2} color="white" />}
                                            {!isSubmitting && "Create"}
                                        </Button>
                                        <Button type="button" overrideColor={'#F1F4F7'} customColor={'#4B4F5E'} onClick={() => { store.updateCustomerControl({ openCreateCustomer: false }) }} >
                                            Cancel
                                        </Button>
                                    </DesktopBottomFooter>
                                </DesktopBottomControl>
                            </div>
                        </Footer>
                    </Form>
                );
            }}
        </Formik >
    )

}