import * as React from 'react';
import { Sidebar, styled, FooterSection, SidebarBodySection, SidebarBodySectionTop, Switch, CommonText, GroupOptionItems } from '@lib/components';
import { Button } from '@lib/components';
import { useState } from 'react';
import { MobXProviderContext } from 'mobx-react';
import { FaCheck } from 'react-icons/fa';


const DisplaySettingsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;
const OrderColumnOptionsWrapper = styled.div`
    display: flex;
    padding: 8px 0px;
    gap: 16px;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: #4B4F5E;
    justify-content: space-between;
`;
const ActionButton = styled.div<{active: boolean}>`
	display: flex;
	padding: 12px;
	align-items: center;
	background: ${({ active }) => active ? "#FFF3F3" : "#F5F5F5" };
	border: ${({ active }) => active ? "1px solid #FB2F2F" : "" };
	color: ${({ active }) => active ? "#FB2F2F" : "#4B4F5E" };
	cursor: pointer;
	border-radius: 4px;
	font-family: Inter;
	font-size: 14px;
	font-weight: 500;
	justify-content: space-between;
  width: 170px;
`

interface BumpViewScreenProps {
  active: boolean;
  close: () => void;
  saveSettings: (settings: any)=> void;
  displaySettings: any
}

export const DisplaySettings: React.FC<BumpViewScreenProps> = ({ active, close, saveSettings, displaySettings }) => {
  const { store } = React.useContext(MobXProviderContext);
  const [showUnconfirmColumn, setShowUnconfirmColumn] = useState(displaySettings?.showUnconfirmedColumn ||false);
  const [isCancelledChecked, setIsCancelledChecked] = useState(displaySettings?.showCancelledColumn || false);
  const [layoutView, setLayoutView] = useState(displaySettings?.layoutView || 'expanded');
  const [cardView, setCardView] = useState(displaySettings?.cardView || 'card')

  const handleCancelledChange = (e: any) => {
    setIsCancelledChecked(e.target.checked);
  };
  const handleShowUnconfirmColumn = (e: any) => {
    setShowUnconfirmColumn(e.target.checked);
  };
  
  return (
    <Sidebar isOpen={active} headerTitle="Display Settings" onClose={close}>
    <SidebarBodySection style={{padding: '16px'}}>
      <DisplaySettingsWrapper>
        <CommonText size="medium" weight={600} color="#0C202A" style={{ marginTop: '16px'}}>Order Columns</CommonText>
        <OrderColumnOptionsWrapper>
            <div>Show Un-Confirmed Orders</div>
            <Switch
                id="showUnconfirmColumnSwitch"
                checked={showUnconfirmColumn}
                onChange={handleShowUnconfirmColumn}
        />
        </OrderColumnOptionsWrapper>
        <OrderColumnOptionsWrapper>
            <div>Show Cancelled Orders</div>
            <Switch
                id="cancelledOrdersSwitch"
                checked={isCancelledChecked}
                onChange={handleCancelledChange}
        />
        </OrderColumnOptionsWrapper>
        <CommonText size="medium" weight={600} color="#0C202A">
            Layout View
          </CommonText>
          <div style={{ display: 'flex', justifyContent: 'space-between'}}>
          <ActionButton
            className="p-lr-2"
            onClick={() => {setLayoutView('expanded')}}
            active={layoutView === 'expanded'}>
            Expanded {layoutView === 'expanded' && <div style={{ color: 'red'}}><FaCheck /></div>}
          </ActionButton>
          <ActionButton
            className="p-lr-2"
            onClick={() => {setLayoutView('compressed')}}
            active={layoutView === 'compressed'}>
            Compressed {layoutView === 'compressed' && <div style={{ color: 'red'}}><FaCheck /></div>}
          </ActionButton>
          </div>
        {/* <CommonText size="medium" weight={600} color="#0C202A">
            Order Card View
          </CommonText>
          <div style={{ display: 'flex', justifyContent: 'space-between'}}>
          <ActionButton
            className="p-lr-2"
            onClick={() => {setCardView('card')}}
            active={cardView === 'card'}>
            Card {cardView === 'card' && <div style={{ color: 'red'}}><FaCheck /></div>}
          </ActionButton>
          <ActionButton
            className="p-lr-2"
            onClick={() => {setCardView('list')}}
            active={cardView === 'list'}>
            List {cardView === 'list' && <div style={{ color: 'red'}}><FaCheck /></div>}
          </ActionButton>
          </div> */}
      </DisplaySettingsWrapper>
      <FooterSection>
        <Button full={true} type="button" color="primary" onClick={() => {saveSettings({showUnconfirmColumn, isCancelledChecked, layoutView, cardView}); close()}}>
          Save Settings
        </Button>
      </FooterSection>
    </SidebarBodySection>
  </Sidebar>
  );
};
