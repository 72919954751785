import * as React from 'react';
import { FastField } from 'formik';
import { FormGroup, Button, RotateLoader, Switch, Input, SelectAdv } from '@lib/components';
import { MobxComponent } from '../../../../../../../mobx/components/index';
import { inject, observer } from 'mobx-react';
import { RestaurantForm } from '../../../../../../../mobx/components/restaurant-form';
import { UI } from '../../../../../../../core/ui';
import { PaymentBaseFields } from './base';
import { LinkTag } from '@lib/components';
import { DataATHMovilCurrencies } from '@lib/common';
import { DataATHMovilLanguages } from '@lib/common';
import { DataATHMovilThemes } from '@lib/common';
import { DataATHMovilEnvironments } from '@lib/common';
import { SaveButtonWrapper, SettingsActionButtonContainer, SettingsActionButtonWrapper } from '../../../settings-v2';

interface Props {
  isMobile?: boolean;
}
interface State {}
type FormValues = T.Schema.Restaurant.Payments.RestaurantPaymentATHMovil;

@inject('store')
@observer
export class SettingsFormPaymentsATHMovil extends MobxComponent<Props, State> {
  initialValues: FormValues;

  constructor(props: Props) {
    super(props);
    this.state = {};
    const r = this.injected.store.restaurant!;
    this.initialValues =
      r.settings.payments.ath_movil ||
      ({
        enabled: false,
        label: '',
        public_token: '',
        private_token: '',
        lang_code: '',
        theme: 'btn',
        timeout: 0,
      } as T.Schema.Restaurant.Payments.RestaurantPaymentATHMovil);
  }

  render() {
    const { showMainSupport } = this.injected.store;
    const { isMobile } = this.props;
    const { store } = this.injected;
    const { organisation } = store;
    return (
      <RestaurantForm<FormValues>
        submit={async (r, values) => {
          r.settings.payments.ath_movil = values;
          const update = {
            $set: {
              'settings.payments.ath_movil': r.settings.payments.ath_movil,
            },
          };
          return { r, update };
        }}
        initialValues={this.initialValues}
        onError={() => UI.notification.error('An error occurred')}
        onSuccessMessage="Settings Updated"
      >
        {({ form, error }) => {
          const { isSubmitting, setFieldValue } = form;
          return (
            <>
            <div className="p-4">
              {showMainSupport && (
                <FormGroup>
                  <LinkTag
                    className="block font-semi-bold"
                    target="_blank"
                    href="https://support.cloudwaitress.com/how-to-guides/setup-ath-movil-payments"
                  >
                    Read the setup guide
                  </LinkTag>
                </FormGroup>
              )}

              <FastField
                name="enabled"
                render={({ field }: any) => (
                  !isMobile ? (
                    <FormGroup title="Enabled" help="Allows customers to pay using this method">
                    <Switch
                      id="enable-switch"
                      checked={field.value}
                      onChange={e => setFieldValue('enabled', e.target.checked)}
                    />
                  </FormGroup>
                  ) : (
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px', paddingBottom: '20px', borderBottom: '1px dashed #DFDFDF'}}>
                    <div style={{ fontSize: '14px', fontWeight: 600 }}>Enabled Payment Method</div>
                    <div>
                    <Switch
                      id="enable-switch"
                      checked={field.value}
                      onChange={e => setFieldValue('enabled', e.target.checked)}
                    />
                    </div>
                  </div>
                  )
                )}
              />

              <FastField
                name="env"
                render={({ field }: any) => (
                  <FormGroup title="Environment" help="Your ATH Móvil environment for payments.">
                    <SelectAdv
                      type="single"
                      options={DataATHMovilEnvironments}
                      value={field.value}
                      onChange={(code: string) => setFieldValue('env', code)}
                    />
                  </FormGroup>
                )}
              />

              <FastField
                name="public_token"
                render={({ field }: any) => (
                  <FormGroup
                    title="Public Token"
                    help="Your ATH Móvil Public Token. You can get this from the mobile app."
                  >
                    <Input type="text" {...field} autoSave="false" autoCorrect="false" />
                  </FormGroup>
                )}
              />

              <FastField
                name="private_token"
                render={({ field }: any) => (
                  <FormGroup title="Prive Token" help="Your ATH Móvil Private Token. Don't share this with anyone.">
                    <Input type="text" {...field} autoSave="false" autoCorrect="false" />
                  </FormGroup>
                )}
              />

              <FastField
                name="lang_code"
                render={({ field }: any) => (
                  <FormGroup title="Language" help="Language of the payment form.">
                    <SelectAdv
                      type="single"
                      options={DataATHMovilLanguages}
                      value={field.value}
                      onChange={(code: string) => setFieldValue('lang_code', code)}
                    />
                  </FormGroup>
                )}
              />

              {/* <FastField
                name="theme"
                render={({ field }: any) => (
                  <FormGroup
                    title="Theme"
                    help="The color theme of the button."
                  >
                    <SelectAdv
                      type="single"
                      options={DataATHMovilThemes}
                      value={field.value}
                      onChange={(code: string) => setFieldValue("theme", code)}
                    />
                  </FormGroup>
                )}
              /> */}

              <FastField
                name="currency"
                render={({ field }: any) => (
                  <FormGroup title="Currency" help="Choose the payment currency.">
                    <SelectAdv
                      type="single"
                      options={DataATHMovilCurrencies}
                      value={field.value}
                      onChange={(code: string) => setFieldValue('currency', code)}
                    />
                  </FormGroup>
                )}
              />

              <FastField
                name="timeout"
                render={({ field }: any) => (
                  <FormGroup
                    title="Timeout"
                    help="How long should an ATH Movil processing time wait? Value must be in seconds. Put 0 to wait indefinitely."
                  >
                    <Input type="number" {...field} autoSave="false" autoCorrect="false" />
                  </FormGroup>
                )}
              />

              <PaymentBaseFields defaultLabel="ATH Móvil" setFieldValue={setFieldValue} />

              {error && <FormGroup error={error} />}

              {organisation && organisation.dashboard === 'v2.1' ?
                <SaveButtonWrapper>
                  <SettingsActionButtonContainer className="no-sidebar desktop-view">
                  <SettingsActionButtonWrapper style={{left: 0}}>
                    <button  type="submit" disabled={isSubmitting} >
                      {isSubmitting && <RotateLoader size={2} color="white" />}
                      {!isSubmitting && "Save"}
                    </button>
                  </SettingsActionButtonWrapper>
                </SettingsActionButtonContainer>
              </SaveButtonWrapper>
              : <Button full={true} color="primary" type="submit" disabled={isSubmitting}>
                  {isSubmitting && <RotateLoader size={2} color="white" />}
                  {!isSubmitting && 'Save'}
                </Button>
              }
             

            </div>
            {organisation && organisation.dashboard === 'v2.1' && 
            <SaveButtonWrapper>
              <SettingsActionButtonContainer style={{ width: '100%', marginLeft: 0 }} className='mobile-view'>
                <SettingsActionButtonWrapper>
                  <Button full={true} color="primary" type="submit" disabled={isSubmitting}>
                    {isSubmitting && <RotateLoader size={2} color="white" />}
                    {!isSubmitting && 'Save'}
                  </Button>
                </SettingsActionButtonWrapper>
              </SettingsActionButtonContainer>
              </SaveButtonWrapper>
            }
              </>
          );
        }}
      </RestaurantForm>
    );
  }
}
