import * as React from "react";
import { styled, css } from "@lib/components";

const Content = styled("div")<{ isStaffMobile?: boolean, pathname?: string}>`
  padding: 20px;
  margin: 0 auto;
  margin-top: ${({ theme }) => theme.top_nav.height + 30}px;
  margin-bottom: ${({ theme }) => theme.top_nav.height + 5}px;
  width: 100%;
  max-width: ${({ theme }) => theme.content_width.md}px;

  ${({pathname, theme}) => pathname === '/' ?
  `@media (min-width: 992px) {
    &[class*="v2.1"]{
    max-width: ${theme.content_width.lg}px;
    }
  }`
  : null }
  @media (max-width: 768px) {
    margin-top: ${({ isStaffMobile, theme }) => isStaffMobile ? 0  : theme.top_nav.height + 30};
    padding: ${({ isStaffMobile }) => isStaffMobile ? 0 : '20px'};
  }
`;

export const LayoutDashboardComponents = {
	Content,
};
