import React from 'react'
import { WebsiteForm } from '../../../../../mobx/components/website-form';
import { useStores } from '../../../../../mobx/useStores';
import { UI } from '../../../../../core/ui';
import { FormGroup, Input, RotateLoader } from '@lib/components';
import { FastField, Field } from 'formik';
import { SettingsActionButtonContainer, SettingsActionButtonWrapper } from '../../../restaurant/views/settings-v2';
import { ImageUpload } from '../website/upload_image';
import { CardHeaderItem, Wrapper } from '../website/sections/locations';
import { FaCheck } from 'react-icons/fa';
type FormValues = T.Schema.Website.WebsiteSchema["sections"]["locations"];

const CardHeaderItems = [
    { type: 'map', label: 'Map' },
    { type: 'image', label: 'Image' },
    { type: 'none', label: 'None' }
  ];
const CardBehaviorItems = [
    { type: 'interactive', label: 'Interactive' },
    { type: 'static', label: 'Static Image' }
  ];

export const LocationsV2 = () => {
    const store = useStores();
    const w = store.website!;
    let initialValues = w.sections.locations || {
        map_zoom: 12,
        card_type: 'map'
    };
    return (
        <WebsiteForm<FormValues>
        submit={async (w, values) => {
            w.sections.locations = values;
            const update = { $set: { "sections.locations": values } };
            return { w, update };
        }}
        validators={{}}
        initialValues={initialValues}
        onSuccess={() => { }}
        onError={() => UI.notification.error("An error occurred")}
        onSuccessMessage="Settings Updated"
        onErrorMessage="">
        {({ form, error }) => {
            const { isSubmitting, setFieldValue, setFieldTouched } = form;
            return (
                <div className="p-4">

                    <Field
        name="card_type"
        render={({ field }: any) => (
          <FormGroup
            title="Card Header"
            help={
              [undefined, 'map'].includes(form.values?.card_type)
                ? `Show a map widget with a marker on the store's address.`
                : form.values?.card_type === 'image'
                ? `Upload an image to show on this store's location card.`
                : `Don't show any image or map`
            }
          >
            <Wrapper style={{ display: 'flex', flexDirection: 'column', gap: '8px'}}>
            {CardHeaderItems.map((item) => (
              <CardHeaderItem
                key={item.type}
                className={form.values?.card_type === item.type ? 'card-header-active' : ''}
                onClick={() => setFieldValue('card_type', item.type)}
              >
                {item.label}
                {form.values?.card_type === item.type && <FaCheck className="icon-active"/>}
              </CardHeaderItem>
            ))}
          </Wrapper>
          </FormGroup>
        )}
      />

      {form.values && [undefined, 'map'].includes(form.values.card_type) && (
        <Field
          name="map_type"
          render={({ field }: any) => (
            <FormGroup
              title="Card Header"
              help="Interactive maps can be zoomed in and out; and panned. Static image is a snapshot of the same map without the controls."
            >
             <Wrapper style={{ display: 'flex', flexDirection: 'column', gap: '8px'}}>
                {CardBehaviorItems.map((item) => (
                  <CardHeaderItem
                    key={item.type}
                    className={form.values?.map_type === item.type ? 'card-header-active' : ''}
                    onClick={() => setFieldValue('map_type', item.type)}
                  >
                    {item.label}
                    {form.values?.map_type === item.type && <FaCheck className="icon-active"/>}
                  </CardHeaderItem>
                ))}
            </Wrapper>
            </FormGroup>
          )}
        />
      )}

      { form.values && form.values.card_type === 'image' && (
        <FastField
          name="image"
          render={({ field }: any) => (
            <FormGroup help="Recomended size is 550x200 pixels. PNG or JPG only">
              {/* <FormUpload
                maxSize={550}
                imagesOnly={true}
                inputAcceptTypes="image/png/jpg"
                value={[field.value]}
                onRemove={() => setFieldValue('image', null)}
                onChange={files => setFieldValue('image', files[0])}
              /> */}
              <ImageUpload value={[field.value]} onChange={(files: any[]) => setFieldValue('image', files[0])} onRemove={() => setFieldValue('image', null)}/>
            </FormGroup>
          )}
        />
      )}

      {form.values && [undefined, 'map'].includes(form.values.card_type) && (
        <FastField
          name="map_zoom"
          render={({ field }: any) => (
            <FormGroup title="Map Zoom" help="The zoom level of your embedded store map. Higher value is more zoomed in. Default is 12 if left empty">
              <Input type="number" {...field} />
            </FormGroup>
          )}
        />
      )}

                    {error && <FormGroup error={error} />}
                    <SettingsActionButtonContainer className='no-sidebar'>
                        <SettingsActionButtonWrapper style={{left: 0}}>
                            <button 
                                type="button" 
                                className='btn-cancel'
                                onClick={ () => store.setActiveMobileMenu(
                                    store.activeMobileMenu.referrer.pop()!,
                                    Array.from(new Set(store.activeMobileMenu.referrer))
                                )}>
                                Cancel
                            </button>
                            <button  type="submit" disabled={isSubmitting}>
                            {isSubmitting && <RotateLoader size={2} color="white" />}
                            {!isSubmitting && "Save"}
                            </button>
                        </SettingsActionButtonWrapper>
                    </SettingsActionButtonContainer>
                    

                </div>
            );
        }}
    </WebsiteForm>
    )
}