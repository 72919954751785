import * as React from "react";
const autobind = require("class-autobind").default;
import { logger, GoogleFont, GoogleServiceType, HostedFonts } from '@lib/common';
import { SelectAdv } from '../../form/select.adv/index';

interface Props {
  value: string;
  onChange: (font: T.Core.Font.DesignFont) => void;
  google: GoogleServiceType;
  self_hosted: boolean;
}
interface State {
  loading: boolean;
  fonts: GoogleFont[];
  tempFonts: GoogleFont[];
}

export class FieldFont extends React.Component<Props, State> {
  constructor(props: Props) {
      super(props);
      autobind(this);
      this.state = {
        loading: false,
        fonts: [],
        tempFonts: [],
      };
  }

  async componentDidMount() {
    try {
      const fonts = await this.props.google.fonts.list();
      if (this.props.self_hosted) {
        this.setState({
          tempFonts: fonts,
          fonts: fonts.filter(f => HostedFonts.includes(f.family)),
        });
      } else this.setState({ fonts, tempFonts: fonts });
    } catch (e) {
      logger.captureException(e);
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.self_hosted !== this.props.self_hosted) {
      const { tempFonts } = this.state;
      if (this.props.self_hosted) {
        this.setState({
          fonts: tempFonts.filter(f => HostedFonts.includes(f.family)),
        });
      } else this.setState({ fonts: tempFonts });
    }
  }

  onChange = (family: string) => {
    const font = this.state.fonts.find(f => f.family === family);
    if (family && font) {
      let query = `${font.family}:`;
      const variantsLength = font.variants.length;
      font.variants.forEach((v, i) => {
        if (i + 1 === variantsLength) query += v;
        else query += `${v},`;
      });
      this.props.onChange({
        family: font.family,
        category: font.category,
        query,
      });
    }
  };

  render() {
    const { value } = this.props;
    if (this.state.fonts.length === 0) return <p>Loading Fonts...</p>;
    return <SelectAdv type="single" value={value} onChange={this.onChange} options={this.state.fonts} />;
  }
}
