import { CommonText, css, styled } from '@lib/components';
import React, { useContext, useState } from 'react';
import { CancelOrderWrapper, ConfirmProgress, ConfirmProgressIcon, ConfirmProgressStatus, DetailInfo, Header, LineWrapper, ModalWrapper, ProgressLine, RowContent, SectionTitle, SectionWrapper, TableContent, TextPrimary, UnconfirmedIconWrapper } from './bookingDetail.component';
import { BackIcon, BookingSuccessImage, CancelOrderImage, CancelledIcon, ConfirmedIcon, EllipsisIcon, UnconfirmedIcon } from './bookingDetail.icon';
import { Translation } from "react-i18next";
import { MobXProviderContext, useLocalStore } from 'mobx-react';
import { UI } from '../../../../../../../../core/ui';
import { ModalBody } from '../settings/bookingSettingConstants';
import { displayId, displayNumberOfPeople } from '../../../bookingdisplayData';
import { MobileModal } from '../../../../settings-v2';
import { BookingDetail } from './bookingDetail';

export const ControlsWrapper = styled.div`
  background: #fefefe;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
  border-top: 1px solid #dfdfdf;
  padding: 8px 16px;
  z-index: 9;
`;


export const ActionButton = styled.div<{ status: string }>`
    display: flex;
    height: 48px;
    color: ${props => {
        if (props.status === "back") {
            return "#4B4F5E";
        }
        else {
            return "#F5F5F5";
        }
    }}; 
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: ${props => {
        if (props.status === "unconfirmed") {
            return "#E5AC00";
        } else if (props.status === "confirmed") {
            return "#A5C100";
        } else if (props.status === "back") {
            return "#F1F4F7";
        } else {
            return "#BC362F";
        }
    }};
    border: 1px solid #dfdfdf;
    cursor: pointer;
`;
const ConfirmIconWrapper = styled.div<{ active?: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
${({ active }) => {
        return active
            ? css`
          svg path {
            fill: #FB2F2F;
          }
        `
            : css`
          svg path {
            color: #DFDFDF;
          }
        `;
    }}
`;

type BookingDetailModalProps = {
    isOpen: boolean;
    handleClose: () => void;
}

export const BookingDetailModal: React.FC<BookingDetailModalProps> = ({ isOpen, handleClose }) => {
    const { store } = useContext(MobXProviderContext);
    const { bookingDetailLayout } = store;
    const bookingData = store.booking;

    if (!isOpen || !bookingData) {
        return <></>;
    }

    return (
        <Translation>
            {
                t => (
                    <MobileModal>
                        <ModalWrapper>
                            <Header>
                                <BackIcon onClick={() => {
                                    store.setBookingDetailLayout(1)
                                    store.setBooking(null);
                                    handleClose()
                                }} />
                                <CommonText weight={600} color='#0C202A' size="medium">
                                    {`#${bookingData.number} - ${bookingData.customer.name}`}
                                </CommonText>
                                <EllipsisIcon />
                            </Header>
                            <BookingDetail bookingData={bookingData} isMobile={true}/>
                            
                        </ModalWrapper>
                    </MobileModal>
                )
            }
        </Translation>
    )
};
