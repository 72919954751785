import React from 'react'
import { WebsiteForm } from '../../../../../mobx/components/website-form';
import { useStores } from '../../../../../mobx/useStores';
import { UI } from '../../../../../core/ui';
import { FormGroup, RotateLoader } from '@lib/components';
import { FastField } from 'formik';
import { SettingsActionButtonContainer, SettingsActionButtonWrapper } from '../../../restaurant/views/settings-v2';
import { ImageUpload } from '../website/upload_image';
import { FormUpload } from '@lib/components/';
type FormValues = T.Schema.Website.WebsiteSchema["favicon"];


export const FaviconV2 = () => {
    const store = useStores()
    const w = store.website!;
    let initialValues = w.favicon;

    return (
        <WebsiteForm<FormValues>
        submit={async (w, values) => {
            w.favicon = values;
            const update = {
                $set: { "favicon": values },
            };
            return { w, update };
        }}
        validators={{}}
        initialValues={initialValues}
        onSuccess={() => { }}
        onError={() => UI.notification.error("An error occurred")}
        onSuccessMessage="Settings Updated"
        onErrorMessage="">
        {({ form, error }) => {
            const { isSubmitting, setFieldValue } = form;
            return (
                <div className="p-4">

                    <FastField
                        name="image"
                        render={({ field }: any) => (
                            <FormGroup title='Favicon Image' help="Or Select a custom url image to use." optional={true}>
                                 <ImageUpload 
                                    value={[field.value]} 
                                    onChange={(files: any[]) => setFieldValue('image', files[0])} onRemove={() => setFieldValue('image', null)}/>
                            </FormGroup>
                        )}
                    />
                    <FastField
                        name="image_url"
                        render={({ field }: any) => (
                            <FormGroup title='Image URL' optional={true}>
                                <FormUpload
                                    maxSize={400}
                                    imagesOnly={true}
                                    inputAcceptTypes="url"
                                    customImage={true}
                                    value={[field.value]}
                                    onRemove={() => setFieldValue("image_url", null)}
                                    onChange={(files) => setFieldValue("image_url", files[0])} 
                                    />
                                    
                            </FormGroup>
                        )}
                    />

                    {error && <FormGroup error={error} />}

                    <SettingsActionButtonContainer className='no-sidebar'>
                        <SettingsActionButtonWrapper style={{left: 0}}>
                            <button 
                                type="button" 
                                className='btn-cancel'
                                onClick={ () => store.setActiveMobileMenu(
                                    store.activeMobileMenu.referrer.pop()!,
                                    Array.from(new Set(store.activeMobileMenu.referrer))
                                )}>
                                Cancel
                            </button>
                            <button  type="submit" disabled={isSubmitting}>
                            {isSubmitting && <RotateLoader size={2} color="white" />}
                            {!isSubmitting && "Save"}
                            </button>
                        </SettingsActionButtonWrapper>
                    </SettingsActionButtonContainer>
                </div>
            );
        }}
    </WebsiteForm>
    )
}