import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { MobxComponent } from '../../../../../mobx/components';
import {  IconArrowForward, IconWrapperRounded } from '../../../../components/v2/icons';
import { MainContainer } from '../../../../components/v2/layout.c';
import { ContentContainer, SettingsActionButtonContainer, SettingsActionButtonWrapper, SettingsHeader, SettingsHeaderMenuContainer, SettingsHeaderMenuItem, SettingsHeaderTextBreadcrumbs, SettingsHeaderTextContainer, SettingsHeaderTextTitle, SettingsHeaderTextWrapper, SettingsMenuContainer, SettingsMenuRow, SettingsMenuRowHeader, SettingsMenuRowItem, SettingsMenuRowWrapper } from '../../../restaurant/views/settings-v2';
import { AboutUsIcon, ColorIcon, ContactFormIcon, CustomIcon, DomainIcon, FavIcons, FontsIcon, FooterIcon, GalleryIcon, HeaderIcon, LocationIcon, NavBarIcon, SearchEngineOptimizeIcon } from '../../../restaurant/views/settings/layout/icon';
import { DomainsV2 } from './domains';
import { FontsV2 } from './fonts';
import { ColorsV2 } from './colors';
import { FaviconV2 } from './favicon';
import { SEOV2 } from './seo';
import { NavbarV2 } from './navbar';
import { HeaderV2 } from './header';
import { AboutUsV2 } from './about-us';
import { GalleryV2 } from './gallery';
import { LocationsV2 } from './locations';
import { ContactFormV2 } from './contact-form';
import { FooterV2 } from './footer';
import { CustomV2 } from './custom';
import { FieldSubdomain, FormGroup, RotateLoader } from '@lib/components';
import { logger } from '@lib/common';
import { UI } from '../../../../../core/ui';
import styled from 'styled-components';

const LWrapper = styled.div`
    padding: 16px;
    @media (min-width: 768px) {
        padding: 0;
    }
`

interface Props { }
interface State {
	active: string;
	create_subdomain_value: string;
	create_subdomain_error: string;
	create_loading: boolean;
	loading: boolean;
	error: boolean;
}

@inject("store") @observer
export class DashboardWebsiteV2 extends MobxComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
			active: "",
			create_subdomain_value: "",
			create_subdomain_error: "",
			create_loading: false,
			loading: true,
			error: false,
		};
    }

    componentDidMount(): void {
        this.injected.store.setActiveMobileMenu('settings')
        this.website_find()
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        document.getElementById('scroll-root')?.scroll({top: 0, behavior: 'smooth'});
    }

    componentWillUnmount(): void {
        this.injected.store.setActiveMobileMenu('', [])
    }


    loading = () => {
		return (
			<LWrapper>
				<RotateLoader size={3} />
			</LWrapper>
		);
	}
	start = () => {
        const { store } = this.injected
		const { reseller, api } = store;

		const { create_subdomain_error, create_subdomain_value, create_loading } = this.state;
		return (
			<LWrapper>

				<h1 className="m-b-4">Create A Landing Page Website</h1>

				<p className="bigger m-b-8 lhp-lg">
					A landing page website can be used either as a home page for your business or a store locator page connecting to all your restaurant ordering sites.
					This feature is completely optional but is useful if you need a simple website or store location selector.
				</p>

				<FormGroup
					title="Website Sub-domain Name"
					help="A custom domain name can be added after creating your website"
					error={create_subdomain_error}
					no_border={true}
					small_title={true}
					className="">
					<FieldSubdomain
						restaurant_id=""
						website_id=""
						base_domain={reseller!.store_host}
						value={create_subdomain_value}
						checkDomain={api.proxy_subdomain_check}
						onChange={(subdomain) => this.setState({ create_subdomain_value: subdomain })}
						onCheckCallback={(error) => this.setState({ create_subdomain_error: error })} />
				</FormGroup>

                <SettingsActionButtonContainer className='no-sidebar'>
                        <SettingsActionButtonWrapper style={{left: 0}}>
                            <button
                                type="button"
                                onClick={this.website_create}
                                disabled={create_loading}>
                            {create_loading && <RotateLoader size={2} color="white" />}
                            {!create_loading && "Save"}
                            </button>
                        </SettingsActionButtonWrapper>
                    </SettingsActionButtonContainer>

			</LWrapper>
		);
	}

    website_create = async () => {
		const s = this.injected.store;
		const { create_subdomain_value } = this.state;
		if (!create_subdomain_value) {
			UI.notification.error("Enter a valid subdomain");
			return;
		}
		try {
			this.setState({ create_loading: true });
			const res = await s.api.website_create({
				subdomain: this.state.create_subdomain_value,
			});
			if (res.outcome) {
				UI.notification.error(res.message, { timeout: 6000 });
			}
			else {
				s.setWebsite(res.website);
			}
		}
		catch (e) {
			logger.captureException(e);
			UI.notification.error("Something went wrong, try again or contact us", { timeout: 6000 });
		}
		finally {
			this.setState({ create_loading: false });
		}
	}

    website_find = async () => {
		const s = this.injected.store;
		try {
			const res = await s.api.website_find({});
			if (res.outcome) {
				UI.notification.error(res.message, { timeout: 6000 });
			}
			else {
				s.setWebsite(res.website);
			}
			this.setState({ loading: false });
		}
		catch (e) {
			logger.captureException(e);
			UI.notification.error("Something went wrong loading your website, try refresh the page", { timeout: 8000 });
		}
	}

    render() {
        const { store } = this.injected;
        const { saveRestaurant } = this;
        const { activeMobileMenu, activeMobileModal, setActiveDesignMode, restrictions, website} = store;
        const pathname = store.router.s.path;
        const pathNameLength = store.router.s.path.split("/").length - 1;
        const pathNameEnd = store.router.s.path.split("/")[pathNameLength];
        const { loading } = this.state;
        return (<>

            {loading && this.loading()}

            {(!loading && !website && restrictions.website.create) && this.start()}
            {(!loading && !website && !restrictions.website.create) && (
                <LWrapper>
                    <div className="text-center">
                        <p className="big center lhp">Your account does not have the necessary permission to create a website. Contact your admin</p>
                    </div>
                </LWrapper>
            )}
            {(!loading && website && restrictions.website.edit) &&
                <MainContainer
                    style={{paddingLeft: 0}}
                    className={(store.organisation && store.organisation?.dashboard) || ''}>
                    <ContentContainer style={{marginTop: 0}} className='landing-page'>
                    {!activeMobileMenu.referrer.includes('landing-page')
                    &&
                    <>
                        <SettingsMenuContainer className="border-bottom-mobile">
                            <SettingsMenuRowHeader> General</SettingsMenuRowHeader>
                            <SettingsMenuRow onClick={() => store.setActiveMobileMenu('domains', 'landing-page')}>
                                <SettingsMenuRowWrapper>
                                    <SettingsMenuRowItem>
                                        <IconWrapperRounded className='icon-wrapper-rounded'>
                                            <DomainIcon />
                                        </IconWrapperRounded>
                                        <p>Domains</p>
                                        <IconArrowForward />
                                    </SettingsMenuRowItem>
                                </SettingsMenuRowWrapper>
                            </SettingsMenuRow>
                            <SettingsMenuRow onClick={() => store.setActiveMobileMenu('fonts', 'landing-page')}>
                                <SettingsMenuRowWrapper>
                                    <SettingsMenuRowItem>
                                        <IconWrapperRounded className='icon-wrapper-rounded'>
                                            <FontsIcon />
                                        </IconWrapperRounded>
                                        <p>Fonts</p>
                                        <IconArrowForward />
                                    </SettingsMenuRowItem>
                                </SettingsMenuRowWrapper>
                            </SettingsMenuRow>
                            <SettingsMenuRow onClick={() => store.setActiveMobileMenu('colors', 'landing-page')}>
                                <SettingsMenuRowWrapper>
                                    <SettingsMenuRowItem>
                                        <IconWrapperRounded className='icon-wrapper-rounded'>
                                            <ColorIcon />
                                        </IconWrapperRounded>
                                        <p>Colors</p>
                                        <IconArrowForward />
                                    </SettingsMenuRowItem>
                                </SettingsMenuRowWrapper>
                            </SettingsMenuRow>
                            <SettingsMenuRow onClick={() => store.setActiveMobileMenu('favicon', 'landing-page')}>
                                <SettingsMenuRowWrapper>
                                    <SettingsMenuRowItem>
                                        <IconWrapperRounded className='icon-wrapper-rounded'>
                                            <FavIcons />
                                        </IconWrapperRounded>
                                        <p>Favicon</p>
                                        <IconArrowForward />
                                    </SettingsMenuRowItem>
                                </SettingsMenuRowWrapper>
                            </SettingsMenuRow>
                            <SettingsMenuRow onClick={() => store.setActiveMobileMenu('search-engine-optimization', 'landing-page')}>
                                <SettingsMenuRowWrapper>
                                    <SettingsMenuRowItem>
                                        <IconWrapperRounded className='icon-wrapper-rounded'>
                                            <SearchEngineOptimizeIcon />
                                        </IconWrapperRounded>
                                        <p>Search Engine Optimization</p>
                                        <IconArrowForward />
                                    </SettingsMenuRowItem>
                                </SettingsMenuRowWrapper>
                            </SettingsMenuRow>
                        </SettingsMenuContainer>
                        <SettingsMenuContainer>
                            <SettingsMenuRowHeader>Website Sections</SettingsMenuRowHeader>
                            <SettingsMenuRow onClick={() => store.setActiveMobileMenu('navbar', 'landing-page')}>
                                <SettingsMenuRowWrapper>
                                    <SettingsMenuRowItem>
                                        <IconWrapperRounded className='icon-wrapper-rounded'>
                                            <NavBarIcon />
                                        </IconWrapperRounded>
                                        <p>Nav Bar</p>
                                        <IconArrowForward />
                                    </SettingsMenuRowItem>
                                </SettingsMenuRowWrapper>
                            </SettingsMenuRow>
                            <SettingsMenuRow onClick={() => store.setActiveMobileMenu('header', 'landing-page')}>
                                <SettingsMenuRowWrapper>
                                    <SettingsMenuRowItem>
                                        <IconWrapperRounded className='icon-wrapper-rounded'>
                                            <HeaderIcon />
                                        </IconWrapperRounded>
                                        <p>Header</p>
                                        <IconArrowForward />
                                    </SettingsMenuRowItem>
                                </SettingsMenuRowWrapper>
                            </SettingsMenuRow>
                            <SettingsMenuRow onClick={() => store.setActiveMobileMenu('about-us', 'landing-page')}>
                                <SettingsMenuRowWrapper>
                                    <SettingsMenuRowItem>
                                        <IconWrapperRounded className='icon-wrapper-rounded'>
                                            <AboutUsIcon />
                                        </IconWrapperRounded>
                                        <p>About Us</p>
                                        <IconArrowForward />
                                    </SettingsMenuRowItem>
                                </SettingsMenuRowWrapper>
                            </SettingsMenuRow>
                            <SettingsMenuRow onClick={() => store.setActiveMobileMenu('gallery', 'landing-page')}>
                                <SettingsMenuRowWrapper>
                                    <SettingsMenuRowItem>
                                        <IconWrapperRounded className='icon-wrapper-rounded'>
                                            <GalleryIcon />
                                        </IconWrapperRounded>
                                        <p>Gallery</p>
                                        <IconArrowForward />
                                    </SettingsMenuRowItem>
                                </SettingsMenuRowWrapper>
                            </SettingsMenuRow>
                            <SettingsMenuRow onClick={() => store.setActiveMobileMenu('locations', 'landing-page')}>
                                <SettingsMenuRowWrapper>
                                    <SettingsMenuRowItem>
                                        <IconWrapperRounded className='icon-wrapper-rounded'>
                                            <LocationIcon />
                                        </IconWrapperRounded>
                                        <p>Locations</p>
                                        <IconArrowForward />
                                    </SettingsMenuRowItem>
                                </SettingsMenuRowWrapper>
                            </SettingsMenuRow>
                            <SettingsMenuRow onClick={() => store.setActiveMobileMenu('contact-form', 'landing-page')}>
                                <SettingsMenuRowWrapper>
                                    <SettingsMenuRowItem>
                                        <IconWrapperRounded className='icon-wrapper-rounded'>
                                            <ContactFormIcon />
                                        </IconWrapperRounded>
                                        <p>Contact Form</p>
                                        <IconArrowForward />
                                    </SettingsMenuRowItem>
                                </SettingsMenuRowWrapper>
                            </SettingsMenuRow>
                            <SettingsMenuRow onClick={() => store.setActiveMobileMenu('footer', 'landing-page')}>
                                <SettingsMenuRowWrapper>
                                    <SettingsMenuRowItem>
                                        <IconWrapperRounded className='icon-wrapper-rounded'>
                                            <FooterIcon />
                                        </IconWrapperRounded>
                                        <p>Footer</p>
                                        <IconArrowForward />
                                    </SettingsMenuRowItem>
                                </SettingsMenuRowWrapper>
                            </SettingsMenuRow>
                            <SettingsMenuRow onClick={() => store.setActiveMobileMenu('custom-css-&-javascript', 'landing-page')}>
                                <SettingsMenuRowWrapper>
                                    <SettingsMenuRowItem>
                                        <IconWrapperRounded className='icon-wrapper-rounded'>
                                            <CustomIcon />
                                        </IconWrapperRounded>
                                        <p>Custom CSS & Javascript</p>
                                        <IconArrowForward />
                                    </SettingsMenuRowItem>
                                </SettingsMenuRowWrapper>
                            </SettingsMenuRow>
                        </SettingsMenuContainer>
                    </>}

                    {(activeMobileMenu.name === 'domains' 
                    && activeMobileMenu.referrer.includes('landing-page'))
                    && (<>
                    <SettingsMenuContainer>
                        <SettingsMenuRow onClick={() => store.setActiveMobileMenu('sub-domain', 'domains')}>
                            <SettingsMenuRowWrapper>
                                <SettingsMenuRowItem>
                                    <IconWrapperRounded className='icon-wrapper-rounded'>
                                        <DomainIcon />
                                    </IconWrapperRounded>
                                    <p>Sub-domain</p>
                                    <IconArrowForward />
                                </SettingsMenuRowItem>
                            </SettingsMenuRowWrapper>
                        </SettingsMenuRow>
                        <SettingsMenuRow onClick={() => store.setActiveMobileMenu('custom-domain', 'domains')}>
                            <SettingsMenuRowWrapper>
                                <SettingsMenuRowItem>
                                    <IconWrapperRounded className='icon-wrapper-rounded'>
                                        <DomainIcon />
                                    </IconWrapperRounded>
                                    <p>Custom Domain</p>
                                    <IconArrowForward />
                                </SettingsMenuRowItem>
                            </SettingsMenuRowWrapper>
                        </SettingsMenuRow>
                    </SettingsMenuContainer>
                    <SettingsActionButtonContainer className='no-sidebar'>
                        <SettingsActionButtonWrapper className='desktop-only' style={{left: 0}}>
                            <button 
                            type="button" 
                            className='btn-cancel'
                            onClick={ () => store.setActiveMobileMenu(
                                store.activeMobileMenu.referrer.pop()!,
                                Array.from(new Set(store.activeMobileMenu.referrer))
                            )}>
                            Cancel
                        </button>
                        </SettingsActionButtonWrapper>
                    </SettingsActionButtonContainer>
                    </>
                    )}
        
                    { activeMobileMenu.referrer.includes('domains') && <DomainsV2 type={activeMobileMenu.name} />}

                    {activeMobileMenu.name == 'fonts'
                    && activeMobileMenu.referrer.includes('landing-page') && (
                        <FontsV2 />
                    )}
                    {activeMobileMenu.name == 'colors'
                    && activeMobileMenu.referrer.includes('landing-page') && (
                        <ColorsV2 />
                    )}
                    {activeMobileMenu.name == 'favicon'
                    && activeMobileMenu.referrer.includes('landing-page') && (
                        <FaviconV2 />
                    )}
                    {activeMobileMenu.name == 'search-engine-optimization'
                    && activeMobileMenu.referrer.includes('landing-page') && (
                        <SEOV2 />
                    )}
                    {activeMobileMenu.name == 'navbar'
                    && activeMobileMenu.referrer.includes('landing-page') && (
                        <NavbarV2 />
                    )}
                    {activeMobileMenu.name == 'header'
                    && activeMobileMenu.referrer.includes('landing-page') && (
                        <HeaderV2 />
                    )}
                    {activeMobileMenu.name == 'about-us'
                    && activeMobileMenu.referrer.includes('landing-page') && (
                        <AboutUsV2 />
                    )}
                    {activeMobileMenu.name == 'gallery'
                    && activeMobileMenu.referrer.includes('landing-page') && (
                        <GalleryV2 />
                    )}
                    {activeMobileMenu.name == 'locations'
                    && activeMobileMenu.referrer.includes('landing-page') && (
                        <LocationsV2 />
                    )}
                    {activeMobileMenu.name == 'contact-form'
                    && activeMobileMenu.referrer.includes('landing-page') && (
                        <ContactFormV2 />
                    )}
                    {activeMobileMenu.name == 'footer'
                    && activeMobileMenu.referrer.includes('landing-page') && (
                        <FooterV2 />
                    )}
                    {activeMobileMenu.name == 'custom-css-&-javascript'
                    && activeMobileMenu.referrer.includes('landing-page') && (
                        <CustomV2 />
                    )}
                </ContentContainer>
        
            </MainContainer> }
            {(!loading && website && !restrictions.website.edit) && (
					<LWrapper>
						<div className="text-center">
							<p className="big center lhp">Your account does not have the necessary permission to edit the website. Contact your admin</p>
						</div>
					</LWrapper>
				)}

        </>)
    }
}